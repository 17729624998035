import React from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';
import { Dialog, Box, Typography, Button, DialogTitle, IconButton, LinearProgress, Grid } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const UploadItemModal = ({ open, OnClose }) => {
  const primaryMain = colors?.primaryMain;
  const borderColor = colors?.borderColor;

  return (
    <Dialog
      maxWidth={'sm'}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: '600px', maxWidth: '600px' } }}
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }}>
        {/* Title Text */}
        <Typography color={primaryMain} fontSize={`20px`} fontWeight={600} component="span">
          Upload Item - Search Terms
        </Typography>
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={OnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <GridCloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Main Content with Floating Border Style */}
      <Box sx={{ borderTop: `1px solid ${borderColor}`, overflowY: 'none !important' }}>
        <Box mx={2} my={2}>
          {' '}
          {/* Step 1 */}
          <fieldset
            style={{
              border: `1px solid ${borderColor}`,
              borderRadius: '8px',
              padding: '16px',
              marginBottom: '24px'
            }}
          >
            <legend style={{ padding: '0 8px', fontWeight: 500 }}>Step 1</legend>
            <Box display={'flex'} alignItems={'center'}>
              <Typography marginRight={'8px'} variant="body1">
                Download Item-Search Term CSV Template
              </Typography>
              <Button startIcon={<CloudDownloadIcon />} variant="contained" color="primary">
                Download Template
              </Button>
            </Box>
          </fieldset>
          {/* Step 2 */}
          <fieldset
            style={{
              border: `1px solid ${borderColor}`,
              borderRadius: '8px',
              padding: '16px',
              marginBottom: '24px'
            }}
          >
            <legend style={{ padding: '0 8px', fontWeight: 500 }}>Step 2</legend>
            <Typography variant="body1">Fill in the Items and Search Terms you want to track</Typography>
          </fieldset>
          {/* Step 3 */}
          <fieldset
            style={{
              border: `1px solid ${borderColor}`,
              borderRadius: '8px',
              padding: '16px',
              marginBottom: '16px'
            }}
          >
            <legend style={{ padding: '0 8px', fontWeight: 500 }}>Step 3</legend>
            <Box display={'flex'} alignItems={'center'}>
              <Typography marginRight={'8px'} variant="body1">
                Choose the edited CSV file
              </Typography>
              <Button startIcon={<CloudUploadIcon />} variant="contained" color="primary">
                Choose File
              </Button>
            </Box>
            <Box mt={2}>
              <Typography variant="body2">Item - Search Terms.csv</Typography>
              <LinearProgress
                variant="determinate"
                value={60} // Assuming 60% upload progress
                sx={{ mt: 1, height: 8, borderRadius: 5 }}
              />
            </Box>
          </fieldset>
          {/* Action Buttons */}
          <Grid container justifyContent="flex-end" pt={2} mt={3} sx={{ borderTop: `1px solid ${borderColor}` }}>
            <Grid item>
              <Button
                className={'actionBtnOutline'}
                sx={{ minWidth: '100px', mr: 2 }}
                variant="outlined"
                color="primary"
                onClick={OnClose}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button className={'actionBtn'} sx={{ minWidth: '100px' }} variant="contained" color="primary">
                Upload
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadItemModal;
