import React from "react";
import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import { useAppSelector } from "store";
import dayjs from "dayjs";
import { endDateValidator } from 'helper/commonHelper'; 
import { CAMPAIGN } from "constant/campaignConstant";
import { CustomSwitch } from "ui-component/customSwitch";

const CampaignSettingsDetails = () => {
  const { campaignDetailViewData } = useAppSelector(
    (state) => state?.userDetailsSlice,
  );
  const [campaignData, setCampaignData] = React.useState({});

  React.useEffect(() => {
    setCampaignData({});
  }, []);

  React.useEffect(() => {
    setCampaignData(campaignDetailViewData || {});
  }, [campaignDetailViewData]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: "flex", fontWeight: "bold" }}>
          {required && <Box sx={{ color: "red", mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };
  const renderValue = (value) => {
    return (
      <Typography component="span" sx={{ pl: "4px" }}>
        {value}
      </Typography>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h4">Campaign Setting</Typography>{" "}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Campaign Name", false)}
        {renderValue(campaignData?.name)}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Profile", false)}
        {renderValue(campaignData?.advertiser?.name)}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Budget Type", false)}
        {renderValue(campaignData?.budget_type)}
      </Grid>
      {(campaignData?.budget_type == "daily" ||
        campaignData?.budget_type == "both") && (
        <Grid item sm={6}>
          {renderLabel("Daily Budget", false)}
          {renderValue(campaignData?.daily_budget)}
        </Grid>
      )}
      {(campaignData?.budget_type == "total" ||
        campaignData?.budget_type == "both") && (
        <Grid item sm={6}>
          {renderLabel("Total Budget", false)}
          {renderValue(campaignData?.total_budget)}
        </Grid>
      )}
      <Grid item sm={6}>
        {renderLabel("Status", false)}
        {renderValue(campaignData?.status)}
      </Grid>
      <Grid item sm={6}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 2 }}>{renderLabel("Date Range", false)}</Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 5 }}>
            {renderValue(
              campaignData?.start_date
                ? dayjs(campaignData?.start_date).format("MM/DD/YYYY")
                : "",
            )}
          </Box>
          <Box>
            {renderValue(
              campaignData?.end_date && !endDateValidator(campaignData?.end_date) ? dayjs(campaignData?.end_date).format('MM/DD/YYYY') : ''
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Campaign Type", false)}
        {renderValue(campaignData?.campaign_type)}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Targeting Type", false)}
        {renderValue(campaignData?.targeting_type)}
      </Grid>
      <Grid item sm={12}>
        {renderLabel("Placement")}
        <FormControlLabel
          label="Search Ingrid"
          name="is_search_ingrid"
          className='CustomSwitchToggle'
          checked={campaignData?.is_search_ingrid}
          disabled
          control={<CustomSwitch label="Search Ingrid" />}
        />

        {campaignData?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
          <FormControlLabel
            label="Search Carousel"
            checked={campaignData?.is_search_carousel}
            name="is_search_carousel"
            className='CustomSwitchToggle'
            control={<CustomSwitch label="Search Carousel" />}
            disabled
          />
        )}

        <FormControlLabel
          label="Item Buybox"
          name="is_item_buybox"
          className='CustomSwitchToggle'
          checked={campaignData?.is_item_buybox}
          disabled
          control={<CustomSwitch label="Item Buybox" />}
        />

        {campaignData?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
          <FormControlLabel
            label="Item Carousel"
            name="is_item_carousel"
            className='CustomSwitchToggle'
            checked={campaignData?.is_item_carousel}
            control={<CustomSwitch label="Item Carousel" />}
            disabled
          />
        )}

        {campaignData?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
          <FormControlLabel
            label="Home Page"
            name="is_home_page"
            className='CustomSwitchToggle'
            checked={campaignData?.is_home_page}
            disabled
            control={<CustomSwitch label="Home Page" />}
          />
        )}

        {campaignData?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
          <FormControlLabel
            label="Stock Up"
            name="is_stock_up"
            className='CustomSwitchToggle'
            checked={campaignData?.is_stock_up}
            disabled
            control={<CustomSwitch label="Stock Up" />}
          />
        )}

        <FormControlLabel
          label="Others"
          checked={campaignData?.is_others}
          name="is_others"
          className='CustomSwitchToggle'
          disabled
          control={<CustomSwitch label="Others" />}
        />
      </Grid>
      <Grid item sm={12}>
        {renderLabel("Placement Bid Multiplier")}
      </Grid>
      <Grid item sm={3}>
        {renderLabel("Search Ingrid")}
        {renderValue(campaignData?.placement_bid_search_ingrid)}
      </Grid>
      <Grid item sm={3}>
        {renderLabel("Item Buybox")}
        {renderValue(campaignData?.placement_bid_item_buybox)}
      </Grid>
      {campaignData?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
        <Grid item sm={3}>
          {renderLabel("Home Page")}
          {renderValue(campaignData?.placement_bid_home_page)}
        </Grid>
      )}

      {campaignData?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
        <Grid item sm={3}>
          {renderLabel("Stock Up")}
          {renderValue(campaignData?.placement_bid_stock_up)}
        </Grid>
      )}

      <Grid item sm={12}>
        {renderLabel("Platform Bid Multiplier")}
      </Grid>
      <Grid item sm={3}>
        {renderLabel("Desktop")}
        {renderValue(campaignData?.platform_bid_desktop)}
      </Grid>
      <Grid item sm={3}>
        {renderLabel("APP")}
        {renderValue(campaignData?.platform_bid_app)}
      </Grid>
      <Grid item sm={3}>
        {renderLabel("Mobile")}
        {renderValue(campaignData?.platform_bid_mobile)}
      </Grid>
    </Grid>
  );
};

export default CampaignSettingsDetails;