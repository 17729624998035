import { useState } from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';
import ConnectWalmartSeller from './connectWalmartSeller';

// material-ui
import {
  Dialog,
  Grid,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
  Link,
  DialogActions,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import WalmartMarketplace from 'assets/images/dashna/walmartMarketplace.svg';
import WalmartConnect from 'assets/images/dashna/walmartConnect.svg';

const CreateStore = (props) => {
  const { addNewStore = false, setIsOpenModalAddStore, otherProps } = props;
  const [open, setOpen] = useState(true);
  const [isPageSeller, setIsPageSeller] = useState(false);
  //Color
  const borderColor = colors?.borderColor;
  const primaryTextColor = colors?.primaryTextColor;
  const darkfigmaFontColor = colors?.darkfigmaFontColor;
  const linkColor = colors?.linkColor;
  const blurTextColor = colors?.blurTextColor;

  const handleClose = () => {
    setOpen(false);
    setIsOpenModalAddStore(false);
  };

  const clickToNext = () => {
    setIsPageSeller(true);
  };

  return (
    <>
      {isPageSeller ? (
        <ConnectWalmartSeller addNewStore={addNewStore} setIsOpenModalAddStore={setIsOpenModalAddStore} otherProps={otherProps} />
      ) : (
        <Dialog
          maxWidth={'md'}
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ sx: { width: '760px', maxWidth: '760px' } }}
        >
          <DialogTitle>
            <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'} pb={1.5}>
              Connect to Walmart
            </Typography>
            {addNewStore && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} pb={3.5}>
                <Typography className={'fontInter'} color={primaryTextColor} fontSize={'16px'}>
                  Connect your Walmart account to begin syncing your data to Dashna.
                </Typography>
              </Grid>
              <Grid item xs={12} pb={1.5}>
                <Typography className={'fontInter'} color={primaryTextColor} fontSize={'20px'} fontWeight={700}>
                  Dashna requires access to:
                </Typography>
              </Grid>
              <Grid item xs={12} p={3} mb={2.5} border={`1px solid ${borderColor}`} borderRadius={'10px'}>
                <Box display={'flex'} alignItems={'center'} gap={'12px'} mb={2}>
                  <Box>
                    <Link to="#">
                      <img src={WalmartMarketplace} alt="Walmart Marketplace" />
                    </Link>
                  </Box>
                  <Box>
                    <Typography className={'fontInter'} color={darkfigmaFontColor} fontSize={'18px'} fontWeight={700}>
                      Walmart Marketplace
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={'fontInter'}
                      px={1}
                      py={'1px'}
                      border={`1px solid ${linkColor}`}
                      borderRadius={'4px'}
                      fontSize={'10px'}
                      color={linkColor}
                    >
                      Required
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className={'fontInter'} fontSize={'16px'} color={blurTextColor}>
                    We use this connection to pull your Marketplace data and allow you to view insights and analytics in Dashna.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} p={3} mb={4.5} border={`1px solid ${borderColor}`} borderRadius={'10px'}>
                <Box display={'flex'} alignItems={'center'} gap={'12px'} mb={2}>
                  <Box>
                    <Link to="#">
                      <img src={WalmartConnect} alt="Walmart Connect" />
                    </Link>
                  </Box>
                  <Box>
                    <Typography className={'fontInter'} color={darkfigmaFontColor} fontSize={'18px'} fontWeight={700}>
                      Walmart Connect
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={'fontInter'}
                      px={1}
                      py={'1px'}
                      border={`1px solid ${linkColor}`}
                      borderRadius={'4px'}
                      fontSize={'10px'}
                      color={linkColor}
                    >
                      Required
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className={'fontInter'} fontSize={'16px'} color={blurTextColor}>
                    We use this connection to pull your Connect data and allow you to manage your advertising from Dashna.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {' '}
            <Button
              className={'actionBtn'}
              sx={{ minWidth: '100px' }}
              onClick={() => clickToNext()}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CreateStore;
