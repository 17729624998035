import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';

const TwoColorProgress = ({ value1, value2, variant, value1Color }) => {
  const theme = useTheme();
  const GrayLight = theme.palette.grey[300];
  // const GrayArrow = theme.palette.grey[400];
  const BlackDark = colors.darkPaper;
  return (
    <Box position="relative" display="flex" alignItems="center" height={10} borderRadius={5} overflow="hidden">
      <Box width="100%" position="absolute">
        <LinearProgress
          variant={variant}
          value={value1}
          sx={{
            height: '13px',
            backgroundColor: GrayLight,
            '& .MuiLinearProgress-bar': {
              backgroundColor: `${value1Color}`,
              height: '100%'
            }
          }}
        />
      </Box>
      <Box
        position="absolute"
        top="50%"
        left={`${value2}%`}
        style={{ transform: 'translateY(-50%) translateX(-1px)' }}
        width="2px"
        height="100%"
        sx={{
          backgroundColor: `${BlackDark}`
        }}
      />
    </Box>
  );
};

export default TwoColorProgress;
