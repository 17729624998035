import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { InfoOutlined } from '@mui/icons-material';

const CustomDialogMessage = ({ open, onClose, Message, tittle }) => {
  return (
    <Dialog open={open} keepMounted onClose={onClose} maxWidth="sm" aria-describedby="alert-dialog-slide-description">
      <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Typography variant="div" sx={{ mr: 1 }}>
              {' '}
              {tittle}
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', alignItems: 'unset', justifyContent: '', marginTop: '15px', marginBottom: '5px' }}>
            <Typography sx={{ paddingRight: '15px', border: '' }}>
              <InfoOutlined sx={{ color: 'grey' }} />
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: '1.5', fontSize: '18px' }}>
              {Message}
            </Typography>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: '10px', marginRight: '10px' }}>
        <Button variant="contained" color="secondary" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialogMessage;
