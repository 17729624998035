// react
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

//materil ui
import { Box, FormControl, MenuItem, Select, TablePagination, Tooltip, Typography, Link, CircularProgress } from '@mui/material';
import MUIDataTable from 'mui-datatables';

///project import
import { useAppDispatch, useAppSelector } from 'store';
import { GetTopProductData } from 'store/thunk/userThunk';
import { formateValue } from 'helper/commonHelper';
import TopProductDefaultImage from 'assets/images/icons/img_.png';

const TopProductDashboard = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { selectedDashboardAdvertiser, dashboardDateRange, commonAdvertiser, TopProductData, commonDateRange } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  const [sortBy, setSortBy] = useState('ad_sales');
  const [newData, setNewData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });
    return formattedData;
  };

  useEffect(() => {
    if (TopProductData?.results) {
      const finalData = TopProductData.results.map((item) => formateData(Object.entries(item)));
      setNewData(finalData);
      setIsLoading(false);
    }
  }, [TopProductData]);

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    if (params?.token) {
      if (dashboardDateRange && Object.keys(dashboardDateRange).length && selectedDashboardAdvertiser.length) {
        setIsLoading(true);
        setIsNoDataFound(false);
        getTopProductListWithPagination(0, selectedDashboardAdvertiser, dashboardDateRange);
        setPage(0);
      }
    } else {
      setIsNoDataFound(false);
      const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
      if (advertiserFilter.length > 0) {
        setIsLoading(true);
        getTopProductListWithPagination(0, advertiserFilter, commonDateRange);
        setPage(0);
      }
    }
  }, [selectedDashboardAdvertiser, dashboardDateRange, commonAdvertiser, commonDateRange, sortBy]);

  const getTopProductListWithPagination = (page, advertiserFilter, commonDateRange = { startDate: '', endDate: '' }) => {
    let request = {
      page,
      changedColumn: sortBy?.length > 0 ? sortBy : '',
      advertiserFilter,
      commonDateRange
    };

    if (params?.token) {
      request = { ...request, secretKey: params.token };
    }

    dispatch(GetTopProductData(request));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (params?.token) {
      getTopProductListWithPagination(newPage, selectedDashboardAdvertiser, dashboardDateRange);
    } else {
      const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
      getTopProductListWithPagination(newPage, advertiserFilter, commonDateRange);
    }
  };

  const options = {
    filter: false,
    print: false,
    viewColumns: false,
    align: 'center',
    responsive: false,
    search: false,
    count: TopProductData?.count || 0,
    page: page,
    rowsPerPage: 5,
    download: false,
    pagination: true,
    serverSide: true,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: (
          <Box p={2} display="flex" justifyContent="center">
            Sorry, no matching records found
          </Box>
        )
      }
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        handlePageChange(null, tableState.page);
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={(event, newPage) => changePage(newPage)}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]} // Removes the rows per page dropdown
          onRowsPerPageChange={() => {}}
          labelRowsPerPage="" // Hides the "Rows per page:" text
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`} // Custom displayed rows text
        />
      );
    }
  };

  const column = [
    {
      label: 'Item',
      name: 'item_id',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            textAlign: 'left'
          }
        }),
        customBodyRender: (value, rowData) => {
          const data = TopProductData?.results[rowData?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {data?.item_image ? (
                <Box className="top-product-img">
                  <img src={data?.item_image} alt="top-product-img" />
                </Box>
              ) : (
                <Box className="top-product-img">
                  <img src={TopProductDefaultImage} alt="top-product-img" />
                </Box>
              )}
              <Box>
                <Typography className="top-product-item-title">
                  {data?.item_name?.length > 40 ? (
                    <Tooltip
                      placement="top"
                      sx={{ fontSize: '10px' }}
                      title={
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {data?.item_name}
                        </Typography>
                      }
                      arrow
                    >
                      {data?.item_name?.substring(0, 40)}...
                    </Tooltip>
                  ) : (
                    data?.item_name
                  )}
                </Typography>

                {data?.item_name ? (
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${value?.replace(/,/g, '')}`, '_blank')}
                  >
                    <Typography className="top-product-item-title">{value?.replace(/,/g, '')}</Typography>
                  </Link>
                ) : (
                  <Typography sx={{ fontSize: '10px' }}>-------</Typography>
                )}
              </Box>
            </Box>
          );
        }
      }
    },
    {
      label: 'Sales',
      name: 'ad_sales',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'Spend',
      name: 'ad_spend',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        setCellHeaderProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'RoAS',
      name: 'roas',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'ACoS',
      name: 'acos',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    },
    {
      label: 'Units Sold',
      name: 'units_sold',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: 'table-align-right'
        })
      }
    }
  ];

  return (
    <Box
      className={params?.token ? 'white-paper-card-dashboard chart-card' : 'white-paper-card-dashboard'}
      sx={{
        ...((isLoading || isNoDataFound) && {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        })
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={20} color="inherit" />
        </Box>
      ) : isNoDataFound ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>No data found</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box mb={2}>
              <Typography className="dashboard-card-title">Top Products</Typography>
            </Box>
            <Box>
              <FormControl className="filter-select" sx={{ minWidth: '100px !important' }}>
                <Select
                  value={sortBy}
                  onChange={handleChange}
                  size="small"
                  className="ad-performance-select"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem className="top-product-value-dropdown" value="ad_sales">
                    Sales
                  </MenuItem>
                  <MenuItem className="top-product-value-dropdown" value="ad_spend">
                    Spend
                  </MenuItem>
                  <MenuItem className="top-product-value-dropdown" value="roas">
                    RoAS
                  </MenuItem>
                  <MenuItem className="top-product-value-dropdown" value="acos">
                    ACoS
                  </MenuItem>
                  <MenuItem className="top-product-value-dropdown" value="units_sold">
                    Units Sold
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box my={1}>
            <MUIDataTable className="top-product-table pnl-by-item" data={newData} columns={column} options={options} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TopProductDashboard;
