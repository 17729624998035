import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes, { verificationRoutes } from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { SuperAdminRoutes } from './SuperAdminRoutes';
import { useSelector } from 'react-redux';
// import { useAppSelector } from 'store';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const userData = JSON.parse(localStorage.getItem('user_data'));

  //Remove <MainLayout /> when process is remaining
  const NewMainRoutes = { ...MainRoutes };
  if (userData?.is_first_time_login) {
    //Merge verificationRoutes only after Login
    const allRoutes = [...verificationRoutes, ...NewMainRoutes.children];
    NewMainRoutes.children = allRoutes;
  }

  const isSuperUser = useSelector((state) => state.superUserSlice.isSuperUser);
  const superAdmin = isSuperUser !== null ? isSuperUser : JSON.parse(localStorage.getItem('isSuperAdmin'));
  return useRoutes(superAdmin ? [SuperAdminRoutes, AuthenticationRoutes] : [NewMainRoutes, AuthenticationRoutes]);
}
