import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';
import { WithoutCompany } from './advertiserPanel/withoutCompany';
import WithCompany from './advertiserPanel/withCompany';

const Advertiser = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Card>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '10px 24px 0px 24px' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="With Company" value="1" />
            <Tab label="Withot Company" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><WithCompany /></TabPanel>
        <TabPanel value="2"><WithoutCompany /></TabPanel>
      </TabContext>
    </Card>
  );
};

export default Advertiser;
