import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

const BasicSetting = (props) => {
    const { setData, data } = props;
    const { BudgetProfiles, campaignShortData } = useAppSelector((state) => state?.userDetailsSlice);

    useEffect(() => {
        if (campaignShortData) {
            setData({ ...data, totalCampaign: campaignShortData?.length })
        }
    }, [campaignShortData])

    return (
        <Box>
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                name="Profile"
                className="custom-auto"
                value={data?.profile || {}}
                sx={{ maxWidth: "400px" }}
                size={'small'}
                options={BudgetProfiles || []}
                getOptionLabel={(option) => {
                    return option?.name || ""
                }}
                onChange={(event, newValue) => {
                    setData({ ...data, profile: newValue });
                }}
                renderInput={(params) =>
                    <TextField color="secondary" sx={{ width: 'auto' }} {...params} label="Profile" />
                }
            />
            {data?.profile &&
                <Box mt={2} p={1} sx={{ backgroundColor: "#eee", maxWidth: "400px", borderRadius: "5px" }}>
                    {campaignShortData?.length > 0 ?
                        <Typography>Total Campaigns: {campaignShortData?.length}</Typography>
                        :
                        <Typography>No Campaigns Available in this Advertiser.</Typography>
                    }
                </Box>
            }
        </Box>
    );
};

export default BasicSetting;
