import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
export default function WarningModalDialog({ open, cancelCallback, warningCallback, Message }) {
  return (
    <Box>
      <Dialog
        open={open}
        onClose={cancelCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="div">Budget Warning</Typography>
            <IconButton onClick={cancelCallback}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText sx={{ display: 'flex', alignItems: 'center' }} id="alert-dialog-description">
            <Box sx={{ display: 'flex', alignItems: 'center', pt: 3 }}>
              <WarningIcon color="secondary" fontSize="large" />

              <Box sx={{ color: 'black', display: 'flex', flexDirection: 'column' }} ml={2}>
                <Typography> {Message} </Typography>
                <Typography mt={1}> Are you sure you want to continue? </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={cancelCallback} sx={{ marginRight: '8px' }} color="secondary" variant="outlined">
            Cancel
          </Button>

          <Button onClick={warningCallback} autoFocus color="secondary" variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
