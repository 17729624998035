import * as React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { EditSharedLinkProfile } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProfileModal = (props) => {
  const { open, onClose, menuEditData, OnConfirm, allUserDetails } = props;
  console.log('allUserDetails ', allUserDetails, menuEditData);
  const [clickAll, setClickAll] = useState(false);
  const [clickRemoveAll, setClickRemoveAll] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [loadingApiResp, setLoadingApiResp] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (menuEditData && menuEditData?.length > 0) {
      setMainData(menuEditData);
      setCheckedData(allUserDetails?.profile_ids);
    } else {
      setMainData([]);
      setCheckedData([]);
      setClickRemoveAll(false);
      setClickAll(false);
    }
  }, [menuEditData]);

  const handleClickAll = (event) => {
    const checked = event.target.checked;
    setClickAll(checked);
    if (checked) {
      setCheckedData(mainData);
      setClickRemoveAll(checked);
      setOriginalData(mainData);
    } else {
      setCheckedData([]);
      setClickRemoveAll(false);
      setOriginalData([]);
    }
  };

  const handleClickRemoveItem = (event) => {
    const checked = event.target.checked;
    setClickRemoveAll(checked);
    setClickAll(false);
    setCheckedData([]);
    setOriginalData([]);
  };

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedData((prevChecked) => [...prevChecked, item]);
      setOriginalData((prevChecked) => [...prevChecked, item]);
    } else {
      setCheckedData((prevChecked) => prevChecked?.filter((checkedItem) => checkedItem !== item));
      setOriginalData((prevChecked) => prevChecked?.filter((checkedItem) => checkedItem !== item));
      // setMainData((prev) => prev?.filter((checkedValue) => checkedValue !== item));
    }
  };

  const onConfirmClick = () => {
    setLoadingApiResp(true);
    const checkedCampaignIds = checkedData?.map((obj) => obj?.id);
    dispatch(EditSharedLinkProfile({ Ids: checkedCampaignIds, mainId: allUserDetails?.id }))
      .then((res) => {
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          OnConfirm();
        }
        setLoadingApiResp(false);
        onClose();
      })
      .catch((error) => {
        console.error('Error downloading file', error);
      });
  };

  const handleFieldChangeMainData = (event) => {
    const searchData = event.target.value;
    console.log('searchData ', searchData.length);
    if (searchData?.length > 0) {
      const tempData = menuEditData?.filter((obj) => obj?.name?.includes(searchData));
      if (tempData) {
        setMainData(tempData);
      }
    } else {
      setMainData(menuEditData);
    }
  };

  const handleFieldChangeCheckedData = (event) => {
    const searchDataMain = event.target.value;
    console.log('searchDataMain ', searchDataMain);
    if (searchDataMain.length > 0) {
      const tempData = originalData?.filter((obj) => obj?.name?.includes(searchDataMain));
      if (tempData) {
        setCheckedData(tempData);
      }
    } else {
      setCheckedData(originalData);
    }
  };

  return (
    <Dialog
      className="bulk-opration-modal"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="lg"
      sx={{ marginTop: '40px !important' }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="div"> Edit Profile </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form noValidate>
        <DialogContent dividers>
          <Box>
            <Typography> Select Profiles</Typography>
            <Box sx={{ display: 'flex', mx: -2, mt: 1 }}>
              <Box sx={{ mx: 2, width: '50%' }}>
                <Box sx={{ border: '1px solid #ccc' }}>
                  <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                    <FormControlLabel
                      checked={clickAll}
                      control={<Checkbox onClick={(e) => handleClickAll(e)} />}
                      label="Select All"
                      disabled={mainData && mainData?.length === 0}
                    />
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                    <Box sx={{ mt: 1 }}>
                      <FormControl variant="standard" sx={{ border: '1px solid #ccc' }}>
                        <Input
                          id="input-with-icon-adornment"
                          className="afterBorder"
                          onChange={(e) => handleFieldChangeMainData(e)}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ overflow: 'auto', height: '250px', position: 'relative' }}>
                      {mainData && mainData.length > 0 ? (
                        mainData?.map((item) => {
                          const isChecked =
                            (checkedData && checkedData?.includes(item)) ||
                            (checkedData?.some((dataItem) => dataItem.id === item.id) &&
                              allUserDetails?.profile_ids.some((profile) => profile.id === item.id));
                          return (
                            <Box key={item?.id}>
                              <FormGroup>
                                <FormControlLabel
                                  checked={isChecked}
                                  control={<Checkbox onChange={(e) => handleCheckboxChange(e, item)} />}
                                  label={item?.name}
                                />
                              </FormGroup>
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            overflow: 'auto',
                            height: '250px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography variant="h5">No data</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mx: 2, width: '50%' }}>
                <Box sx={{ border: '1px solid #ccc' }}>
                  <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                    <FormControlLabel
                      checked={clickRemoveAll}
                      control={<Checkbox onClick={(e) => handleClickRemoveItem(e)} />}
                      label={`${checkedData && checkedData?.length > 0 ? checkedData?.length : 0} items`}
                    />
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                    <Box sx={{ mt: 1 }}>
                      <FormControl variant="standard" sx={{ border: '1px solid #ccc' }}>
                        <Input
                          id="input-with-icon-adornment-checked"
                          className="afterBorder"
                          onChange={handleFieldChangeCheckedData}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ overflow: 'auto', height: '250px', position: 'relative' }}>
                      {checkedData && checkedData?.length > 0 ? (
                        <Box sx={{ overflow: 'auto', height: '250px' }}>
                          {checkedData &&
                            checkedData?.length > 0 &&
                            checkedData?.map((item) => (
                              <Box key={item?.campaign_id}>
                                <FormGroup>
                                  <FormControlLabel
                                    checked={true}
                                    control={<Checkbox onChange={(e) => handleCheckboxChange(e, item)} />}
                                    label={item?.name}
                                  />
                                </FormGroup>
                              </Box>
                            ))}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            overflow: 'auto',
                            height: '250px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography variant="h5">No data</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}></Box>*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ marginRight: '8px' }} color="secondary" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={() => onConfirmClick()}
            autoFocus
            color="secondary"
            variant="contained"
            disabled={loadingApiResp || (checkedData && checkedData?.length === 0)}
          >
            {loadingApiResp && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProfileModal;
