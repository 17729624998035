import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { getApiUsageAnalyzeData } from "store/thunk/userThunk";
import MainCard from "ui-component/cards/MainCard";
import ReportItemBox from '../ReportItemBox';

function GetAPIUsageAnalyze() {
  const dispatcher = useAppDispatch();
  const { apiUsageAnalyzeData } = useAppSelector(
    (state) => state?.userDetailsSlice,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatcher(getApiUsageAnalyzeData()).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <MainCard title="API Usage Analyzer">
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <ReportItemBox
            isLoading={isLoading}
            itemLabel="LAST HOUR API USAGE"
            itemValue={
              apiUsageAnalyzeData?.count || ""
            }
          />
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default GetAPIUsageAnalyze;
