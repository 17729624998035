import { useNavigate } from 'react-router';
import { useState, forwardRef } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { createDashboardLink } from 'store/thunk/userThunk';
// material-ui
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { endOfDay, isBefore } from 'date-fns';
import { DatePicker } from 'rsuite';
import CustomAutocomplete from '../../../ui-component/multi-select/CustomAutocomplete';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import moment from 'moment';

const CalendarIcon = forwardRef((props, ref) => {
  return <img className="calendar-icon" src="/calendar-icon.svg" alt="" {...props} ref={ref} />;
});

const CreaeteLink = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState({ profile: null });
  const [individualLink, setIndividualLink] = useState(false);
  const [valueExpiryDate, setValueExpiryDate] = useState('none');
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [description, setDescription] = useState(null);
  const [filterValue, setFilterValue] = useState({ profile: [] });
  const [tableFilter, setTableFilter] = useState({
    profile: ''
  });
  const [isSelectAll, setIsSelectAll] = useState({
    profile: []
  });
  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'profile') {
      paramKey = 'id';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
      tableStateFilterKey = 'profile';
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
      tableStateFilterKey = 'profile';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  const handleSubmit = () => {
    if (tableFilter?.profile) {
      const allSelectedProfiles = tableFilter?.profile;
      const profileIds = allSelectedProfiles.split(',').map(Number);
      const secretKey = btoa(`dashboard-shared-link@${moment().format('MM-DD-YYYY-hh:mm:ss')}`);
      const requestParams = {
        profile_ids: profileIds,
        secret_Key: secretKey,
        is_single_profile: individualLink,
        expiration_date: valueExpiryDate === 'start_date' ? moment(expiryDate).format('YYYY-MM-DD') : null,
        description: description,
        navigation: navigate
      };
      dispatch(createDashboardLink(requestParams));
    }
  };

  return (
    <MainCard title="Create Share Link" sx={{ height: '100%' }}>
      <Grid container className="share-link-wrapper">
        <Grid item sm={12}>
          <Typography variant="h5" className="font-16" pb={1}>
            <span className="red-star">*</span> Profile
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <CustomAutocomplete
            multiple
            id="tags-outlined"
            name="adgroup_name"
            color="secondary"
            label="Campaign State"
            options={allUserDetails || []}
            value={filterValue?.profile || []}
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => setIsDropdownOpen(false)}
            onChange={(e, value) => {
              handleAutoCompleteOnChange(value, 'profile');
            }}
            optionLabelkey="name"
            optionIdkey="id"
            inputValue={isDropdownOpen ? searchText?.profile : ''}
            onInputChange={(event, value, reason) => {
              if (reason !== 'reset') {
                setSearchText({ ...searchText, profile: value });
              }
            }}
            selectAllCheckboxCondiion={handleAutoCompleteSelectAll('profile')}
            placeholderCondition={filterValue?.profile?.length > 0}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            limitTags={2}
          />
        </Grid>
        <Grid item sm={6} display="flex" alignItems="center" pl={3} mt={'-2px'}>
          <FormControlLabel
            className="font-16"
            control={
              <Checkbox checked={individualLink} onChange={(event) => setIndividualLink(event.target.checked)} color="primary" />
            }
            label="Create individual share link for each profile selected"
          />
        </Grid>
        <Grid item sm={6} mt={2}>
          <Typography variant="h5" className="font-16" pt={2} pb={1}>
            <span className="red-star">*</span> Expiration Date
          </Typography>
          <Box display="flex" justifyContent="flex-start" alignItems="center" ml={2}>
            <FormControl fullWidth>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="expiration-date"
                className="expirydate-radio"
                value={valueExpiryDate}
                onChange={(event) => {
                  setValueExpiryDate(event.target.value);
                }}
              >
                <FormControlLabel value="none" control={<Radio />} className="font-16" label="None" />
                <FormControlLabel value="start_date" control={<Radio />} className="font-16" label="Set Date" />
              </RadioGroup>
            </FormControl>

            {valueExpiryDate === 'start_date' && (
              <Box className="custom-daterange-picker custom-expiry-date-picker">
                <DatePicker
                  format="MM/dd/yyyy"
                  cleanable={false}
                  caretAs={CalendarIcon}
                  style={{ width: 250 }}
                  size="sm"
                  value={expiryDate}
                  onChange={(value) => setExpiryDate(value)}
                  shouldDisableDate={(date) => isBefore(date, endOfDay(new Date()))}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item sm={9}>
          <Typography variant="h5" className="font-16" pt={2} pb={1}>
            Description
          </Typography>
          <TextField
            className="multiline-textfield"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item sm={12} mt={4}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/shared-dashboards');
            }}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            sx={{ mr: 1, fontWeight: 400 }}
            disabled={tableFilter?.profile ? false : true}
          >
            Create Link
          </Button>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default CreaeteLink;
