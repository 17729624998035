import { Divider, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppSelector } from 'store';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useAppDispatch } from 'store';
import { getSelectFromItemsFromId } from 'store/thunk/userThunk';
import { useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CampaignBrandContext from 'context/brand';
import { PageValue } from 'store/constant';

const SearchTable = () => {
  const { selectedData, setSelectedData, campaignData } = useContext(CampaignBrandContext);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const { selectdItemData } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  const columns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectdItemData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                <Box>
                  <Typography>{data?.product_name}</Typography>
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'product_type',
      label: 'Item Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'price',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'add_all',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectdItemData[tableMeta?.rowIndex];
          const disable = selectedData?.includes(data);
          return (
            <>
              <IconButton
                sx={{ color: colors.secondaryMain }}
                disabled={disable}
                onClick={() => {
                  setSelectedData([...selectedData, data]);
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          );
        }
      }
    }
  ];

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    const advertiserId = campaignData?.campaign?.advertiser;
    if (advertiserId) {
      dispatch(getSelectFromItemsFromId({ advertiserId, page, rowPerPage, search, changedColumn, direction }));
    }
  };

  const options = {
    filter: false,
    print: false,
    page: page,
    search: false,
    viewColumns: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPage: rowPerPage,
    selectableRows: 'none',
    rowsPerPageOptions: PageValue,
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onChangePage: (page) => {
      setPage(page);
      getAdvertiserListWithPagination(page, rowPerPage, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    }
  };
  return (
    <>
      <Box sx={{ px: 3 }}>
        <TextField
          sx={{ padding: 3, minWidth: '300px' }}
          id="outlined-basic"
          placeholder="Search from item list"
          size="small"
          color="secondary"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        {/* <MainCard sx={{ paddingTop: 5}}> */}
        <Divider />
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          sx={{ padding: 0 }}
          data={[]}
          columns={columns}
          options={options}
          className="pnl-by-item"
        />
        {/* {modalOpen && (
        <CampaignModal open={modalOpen} onClose={handledClose}  />
      )} */}
        {/* </MainCard>    */}-
      </Box>
    </>
  );
};

export default SearchTable;
