import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Box, Typography, Divider, Button, Popover, FormControlLabel, FormGroup, Checkbox, Link } from '@mui/material';

const minimumChecked = 5;
const ManageDashboardMetrics = ({ title, cardsData, selectedCards, setSelectedCards }) => {
  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataMetrics, setDataMetrics] = useState([]);
  const [error, setError] = useState({ atLeastFive: false, onlyFive: false });

  useEffect(() => {
    createAdPerformanceDataMetricsList(cardsData);
  }, []);

  const handleClick = (event) => {
    setError({ ...error, atLeastFive: false, onlyFive: false });
    createAdPerformanceDataMetricsList(cardsData);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    const adPerformanceDataMetricsList = dataMetrics.map((l) => {
      if (l?.name === item?.name) {
        return {
          ...l,
          isChecked: isChecked
        };
      } else {
        return {
          ...l
        };
      }
    });
    setDataMetrics(adPerformanceDataMetricsList);
    if (error?.atLeastFive) {
      setError({ ...error, atLeastFive: false });
    }
    if (error?.onlyFive) {
      setError({ ...error, onlyFive: false });
    }
  };

  const handleApplyMetrics = () => {
    const getAllChecked = dataMetrics.filter((l) => l?.isChecked === true);
    if (getAllChecked.length < minimumChecked) {
      setError({ ...error, atLeastFive: true });
    } else if (getAllChecked.length > minimumChecked) {
      setError({ ...error, onlyFive: true });
    } else {
      setSelectedCards(getAllChecked);
      handleClose();
    }
  };

  const createAdPerformanceDataMetricsList = (cardsData) => {
    const allSelectedCardsName = selectedCards.map((l) => l?.name);
    const tempAdPerformanceData = [...(cardsData || [])];
    const adPerformanceDataMetricsList = tempAdPerformanceData.map((l) => {
      return {
        ...l,
        isChecked: allSelectedCardsName.includes(l?.name) ? true : false
      };
    });
    setDataMetrics(adPerformanceDataMetricsList);
  };

  return (
    <Box>
      <Link
        sx={{
          color: primaryMain,
          ':hover': {
            color: primaryMain,
            textDecoration: 'underline !important'
          },
          ':focus': {
            color: `${primaryMain} !important`
          }
        }}
        aria-describedby={id}
        href="javascript:void(0)"
        underline="hover"
        color="primary"
        onClick={(e) => handleClick(e)}
      >
        {title}
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ overflow: 'auto', position: 'relative' }}>
          {dataMetrics && dataMetrics.length > 0 ? (
            <Box p={2}>
              {dataMetrics?.map((item) => {
                return (
                  <Box key={item?.name}>
                    <FormGroup>
                      <FormControlLabel
                        checked={item?.isChecked}
                        control={<Checkbox style={{}} onChange={(e) => handleCheckboxChange(e, item)} />}
                        label={item?.display_field}
                      />
                    </FormGroup>
                  </Box>
                );
              })}
              <Box>
                {error?.atLeastFive && (
                  <Typography variant="subtitle2" style={{ color: 'red' }}>
                    Select atleast five
                  </Typography>
                )}
                {error?.onlyFive && (
                  <Typography variant="subtitle2" style={{ color: 'red' }}>
                    Select only five
                  </Typography>
                )}
              </Box>
              <Divider />
              <Box p={2} pb={0} display="flex" justifyContent="flex-end" alignItems="center">
                <Box>
                  <Button variant="contained" onClick={() => handleApplyMetrics()}>
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                overflow: 'auto',
                height: '250px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h5">No data</Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default ManageDashboardMetrics;
