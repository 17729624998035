import { Box, Button } from '@mui/material';
import React from 'react';
import imagePath from 'assets/images/bulkDataFaild.jpg';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store';

const BulkOperationFaild = () => {
  const navigate = useNavigate();
  const { verifyCampaignFile } = useAppSelector((state) => state?.userDetailsSlice);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
        <img src={imagePath} alt="Bulk Data Fail" style={{ width: '35%', height: '35%', objectFit: 'cover' }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', marginBottom: 5 }}>
        <Button variant="contained" sx={{ marginRight: 10 }}>
          <a className="link-dashna-ui" href={verifyCampaignFile?.url} download style={{ color: '#fff' }}>
            Download File
          </a>
        </Button>

        <Button variant="contained" onClick={() => navigate('/bulk-operations')}>
          Upload Again
        </Button>
      </Box>
    </>
  );
};

export default BulkOperationFaild;
