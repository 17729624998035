import React from 'react';
import CampaignSettingsDetails from './CampaignSetting';
import AdgroupSettingsDetails from './AdgroupSettings';
import AdItemsDetails from './AdItemsDetails';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import KeywordsDetails from './KeywordsDetails';
import { getCampaignDetailsById } from 'store/thunk/userThunk';
import { useAppDispatch, useAppSelector } from 'store';
import { setCampaignDetailViewData } from 'store/slices/userDetailsSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BrandCampaignSettingsDetails from './BrandCampaignSetting';
import CampaignSettingsDetailsSkaletone from './CampaignSettingSkaletone';
import BrandCampaignData from './BrandCampaignData';
import VideoCampaignData from './VideoCampaignData';
import { CAMPAIGN } from 'constant/campaignConstant';

const CampaignDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignID } = useParams();
  const { campaignDetailViewData } = useAppSelector((state) => state?.userDetailsSlice);
  React.useEffect(() => {
    dispatch(setCampaignDetailViewData({})); //Reset Data as It shows old data
    if (campaignID) {
      dispatch(getCampaignDetailsById({ id: campaignID }));
    }
  }, [campaignID]);

  const handleGoBack = () => {
    navigate(`/campaign`);
  };

  return (
    <MainCard title="Campaign Details">
      <Box sx={{ mt: 1 }}>
        <Button
          variant="contained"
          sx={{ position: 'absolute', top: '18px', right: '25px' }}
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
        >
          Go Back
        </Button>
        {!campaignDetailViewData?.campaign_type && <CampaignSettingsDetailsSkaletone />}
        {campaignDetailViewData?.campaign_type == 'sponsoredProducts' && <CampaignSettingsDetails />}
        {campaignDetailViewData?.campaign_type == 'sba' && <BrandCampaignSettingsDetails />}
        {campaignDetailViewData?.campaign_type == 'sba' && <BrandCampaignData />}
        {campaignDetailViewData?.campaign_type == 'video' && <BrandCampaignSettingsDetails />}
        {campaignDetailViewData?.campaign_type == 'video' && campaignDetailViewData?.adgroup?.length > 0 && (
          <VideoCampaignData media_data={campaignDetailViewData?.adgroup[0]} />
        )}
        <AdgroupSettingsDetails />

        <AdItemsDetails
          bidVisibility={campaignDetailViewData?.campaign_type != 'sba' && campaignDetailViewData?.targeting_type == 'auto'}
        />
        {campaignDetailViewData?.targeting_type !== CAMPAIGN.TARGETING_TYPE.AUTO ? <KeywordsDetails /> : null}
      </Box>
    </MainCard>
  );
};

export default CampaignDetails;
