export default function componentStyleOverrides(theme) {
  const customIconSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8564 6.51117C17.1813 6.83707 17.1806 7.3647 16.8547 7.68968L11.0049 13.523C10.6882 13.8389 10.1785 13.8483 9.85032 13.5444L4.00056 8.12772C3.66286 7.81503 3.64259 7.28778 3.95529 6.95008C4.26798 6.61238 4.79523 6.59211 5.13293 6.90481L10.3951 11.7774L15.6778 6.50952C16.0037 6.18454 16.5314 6.18528 16.8564 6.51117Z" fill="#0B081B"/>
</svg>
`;

  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter !important'
        }
      }
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          '&.MuiPaper-root': {
            background: '#fff0 !important'
          },
          '&::-webkit-scrollbar': {
            width: '0.5rem !important',
            backgroundColor: '#C5C5C5',
            height: '20px'
          },
          '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7A8183',
            outline: '1px solid slategrey'
          }
        },
        tableHead: {
          '& .MuiTable-head': {
            marginTop: '-50px'
          }
        }
      },
      responsiveBase: {
        '&::-webkit-scrollbar': {
          width: '0.5rem !important',
          backgroundColor: '#C5C5C5 !important',
          height: '20px !important'
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00) !important'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#7A8183 !important',
          outline: '1px solid slategrey !important'
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          '&.MuiToolbar-root': {
            position: 'absolute',
            top: '0%',
            right: '2%'
          }
        }
      }
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'white'
          },
          '&:nth-of-type(even)': {
            backgroundColor: 'white'
          },

          whiteSpace: 'nowrap !important'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          borderRadius: '10px !important',
          padding: '12px 24px !important',
          height: '40px !important',
          fontFamily: 'Inter !important',

          '&.MuiButton-text': {
            color: `${theme.colors.primaryMain} !important`,
            '&:hover': {
              color: `${theme.colors.primaryDark} !important`
            }
          },

          '&.MuiButton-contained': {
            backgroundColor: `${theme.colors.primaryMain} !important`,

            '&:hover': {
              backgroundColor: `${theme.colors.primaryDark} !important`
            }
          },

          '&.MuiButton-outlined': {
            border: `1px solid ${theme.colors.primaryMain} !important`,
            color: `${theme.colors.primaryMain} !important`,

            '&:hover': {
              backgroundColor: 'rgba(95, 96, 255, 0.10) !important',
              color: `${theme.colors.primaryDark} !important`
            }
          },

          '&.Mui-disabled': {
            background: `${theme.colors?.disableButton} !important`,
            border: `none !important`,
            color: `${theme.colors?.disableButtonTextColor} !important`
          },

          '&.MuiButtonGroup-grouped:not(:last-of-type)': {
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
            borderRightColor: 'transparent !important'
          },
          '&.MuiButtonGroup-grouped:not(:first-of-type)': {
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: '0px !important',
            marginLeft: '-1px !important'
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: 'none'
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            //  backgroundColor: background: rgba(95, 96, 255, 0.08); // TODO
            '&:hover': {
              backgroundColor: theme.menuSelectedBack
            },
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected
            }
          },
          '&:hover': {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          }
        }
      }
    },

    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: theme?.colors?.primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit'
          },
          '.MuiChip-label': {
            paddingLeft: '8px',
            paddingRight: '8px'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter !important',
          borderRight: '1px solid #E8E8E8', // Add border to table cells if needed
          whiteSpace: 'nowrap',
          paddingTop: '6px', //My css for decrese mui row padding
          paddingBottom: '6px' //My css for decrese mui row padding
        }
      }
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:hover': {
            '.MuiInputLabel-shrink': {
              color: theme?.colors?.primaryDark
            }
          },

          '& .MuiOutlinedInput-root': {
            //height: '40px !important',
            padding: '10px 16px !important',
            borderRadius: '8px !important',

            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme?.colors?.primaryDark
            },

            '&.MuiInputBase-multiline': {
              height: 'inherit !important'
            },

            '& .MuiOutlinedInput-input': {
              padding: '0px !important'
            }
          }
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '&:hover': {
            '.MuiInputLabel-shrink': {
              color: theme?.colors?.primaryDark
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme?.colors?.primaryDark
            }
          },
          '& .MuiOutlinedInput-root': {
            //height: '40px !important',
            padding: '10px 16px !important',
            borderRadius: '8px !important',

            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme?.colors?.primaryDark
            },

            '& .MuiOutlinedInput-input': {
              padding: '0px !important'
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                boxShadow: '0px 0px 0px 2px rgba(28, 63, 202, 0.14)'
              }
            },
            '&.MuiInputBase-multiline': {
              padding: '0px !important',
              backgroundColor: theme?.colors?.paper,
              //height: '40px !important',
              textarea: {
                padding: '10px !important'
              },
              '& .MuiTextField-root': {
                padding: '0px!important'
              },
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow: 'none' // Remove box shadow for multiline focused state
                }
              }
            }
          },
          '& .MuiInputLabel-root': {
            transform: 'translate(16px, 10px) scale(1) !important'
          },
          '& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75) !important'
          }
          // '& textarea': {
          //  padding:'10px 16px!important'
          // }
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `1px solid ${theme.colors?.grey300}`, // Adjust the color as needed
          '& .MuiMenuItem-root': {
            minHeight: 40
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          //height: '40px !important',
          borderRadius: '8px !important',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme?.colors?.primaryDark
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            boxShadow: '0px 0px 0px 2px rgba(28, 63, 202, 0.14)'
          }
        },
        outlined: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme?.colors?.primaryDark
          }
        },
        icon: {
          backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(customIconSvg)}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '20px',
          height: '20px',
          color: 'transparent',
          fontSize: '18px'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          border: `1px solid ${theme.colors?.grey300}` // Adjust the color as needed
        },

        //
        // popper: {
        //   zIndex: '999 !important' // Adjust the z-index to ensure the dropdown behaves as expected
        // },

        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            '.MuiInputLabel-shrink': {
              color: theme?.colors?.primaryDark
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme?.colors?.primaryDark
            }
          }
        },
        '& .trackMultilineField': {
          '& .MuiOutlinedInput-root': {
            height: 'auto!important'
          }
        },
        // Specific to the dropdown arrow
        popupIndicator: {
          '& .MuiSvgIcon-root': {
            backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(customIconSvg)}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            width: '20px', // Adjust according to your SVG size
            height: '20px', // Adjust according to your SVG size
            color: 'transparent' // Hide the default arrow icon
          }
        },
        // Ensure the clear indicator remains unchanged
        clearIndicator: {
          backgroundColor: 'inherit',
          '& .MuiSvgIcon-root': {
            color: 'inherit' // Ensure it inherits the color and is not affected by other styles
          }
        }
      }
    },

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: theme.colors?.paper,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.colors?.secondaryMain,
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.colors?.successMain,
        border: `6px solid ${theme.colors?.paper}`
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.colors?.grey100
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      marginLeft: 2
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.colors?.grey500,
      opacity: 1
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialogTitle-root': {
            color: theme.colors?.linkColor,
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal'
          },
          '.MuiDialogContentText-root': {
            color: 'var(--color-grey-Black, #0B081B)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
          },
          '.MuiDialogActions-root': {
            padding: 15
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: `${theme.colors?.linkColor} !important`,
          fontWeight: '400 !important',
          '&:hover': {
            color: `${theme.colors?.primaryMain} !important`, // Applying !important to hover color
            textDecoration: 'none !important',
            p: {
              fontWeight: '600 !important'
            }
          }
        }
      }
    }
  };
}
