import { debounce } from 'lodash';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Loader } from 'rsuite';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { PageValue } from 'store/constant';
import { getWithCompanyAdvertiser } from 'store/thunk/superUserThunk';
import MainCard from 'ui-component/cards/MainCard';

const WithCompany = () => {
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const { withCompanyList } = useAppSelector((state) => state?.superUserSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    getWithCompanyListWithPagination(page, rowPerPage);
  }, []);

  useEffect(() => {
    if (withCompanyList?.data?.results) {
      setLoading(false);
    }
  }, [withCompanyList]);

  const getWithCompanyListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    dispatch(getWithCompanyAdvertiser({ page, rowPerPage, search, changedColumn, direction }));
  };

  const columns = [
    {
      name: 'access_type',
      label: 'Access Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'advertiser_id',
      label: 'Advertiser ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'advertiser_type',
      label: 'Advertiser Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'company_name',
      label: 'Company Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    selectableRows: 'none',
    page: 0,
    jumpToPage: false,
    count: withCompanyList?.data?.count || 0,
    download: false,
    pagination: true,
    serverSide: true,
    rowsPerPageOptions: PageValue,
    rowsPerPage: 25,
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getWithCompanyListWithPagination(page, row, search);
    },
    onChangePage: (page) => {
      setPage(page);
      getWithCompanyListWithPagination(page, rowPerPage, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getWithCompanyListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getWithCompanyListWithPagination(0, rowPerPage, '', changedColumn, direction);
    },
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getWithCompanyListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  return (
    <MainCard title="With Company">
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={withCompanyList?.data?.results}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />
    </MainCard>
  );
};

export default WithCompany;
