import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const CustomTooltipPlacement = (props) => {
  const { content, tooltipMessage, color, FontWeight } = props;
  return (
    <Typography sx={{ display: 'flex', alignItems: 'center', color: color, fontWeight: FontWeight ? FontWeight : null }}>
      {content}
      <Tooltip
        sx={{ fontSize: '18px' }}
        title={
          <Typography variant="body1" sx={{ fontSize: 16 }}>
            {tooltipMessage}
          </Typography>
        }
        arrow
        placement="top"
      >
        <InfoOutlined
          // className="placeholder-text"
          fontSize="small"
          sx={{ color: color, marginLeft: '4px' }}
        />
      </Tooltip>
    </Typography>
  );
};

export default CustomTooltipPlacement;
