import MainCard from "ui-component/cards/MainCard";
import { Box, Grid } from "@mui/material";
import ReportItem from "./ReportItem";
import { Link } from "react-router-dom";
import CampaignReportImg from "../../assets/images/campaign-report.png";
import KeywordReportImg from "../../assets/images/keyword-report.png";
import ItemReportImg from "../../assets/images/item-report.png";

function DefaultReport() {
  return (
    <MainCard title="Default Reports">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Link to="/campaign-report">
              <ReportItem
                itemTitle="CAMPAIGN REPORT"
                itemDescription="Ultimate guide to understanding the impact of your marketing efforts and making strategic decisions that propel your brand forward."
                itemImage={CampaignReportImg}
              />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link to="/adgroup-report">
              <ReportItem itemTitle="ADGROUP REPORT" itemDescription="" />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link to="/item-report">
              <ReportItem
                itemTitle="ITEM REPORT"
                itemDescription="Essential tool for deciphering the impact of your offering, uncovering trends and optimizing your product strategy for unparalledted results."
                itemImage={ItemReportImg}
              />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link to="/keyword-report">
              <ReportItem
                itemTitle="KEYWORD REPORT"
                itemDescription="Essential tool for deciphering the effectiveness of your keywords, understanding user intent and optimizing your content strategy for maximum visibility and engagement."
                itemImage={KeywordReportImg}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
}

export default DefaultReport;