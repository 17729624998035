import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useAppSelector } from "store";
import dayjs from "dayjs";
import { endDateValidator } from 'helper/commonHelper'; 

const BrandCampaignSettingsDetails = () => {
  const { campaignDetailViewData } = useAppSelector(
    (state) => state?.userDetailsSlice,
  );
  const [campaignData, setCampaignData] = React.useState({});

  React.useEffect(() => {
    setCampaignData({});
  }, []);

  React.useEffect(() => {
    setCampaignData(campaignDetailViewData || {});
  }, [campaignDetailViewData]);


  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: "flex", fontWeight: "bold" }}>
          {required && <Box sx={{ color: "red", mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };
  const renderValue = (value) => {
    return (
      <Typography component="span" sx={{ pl: "4px" }}>
        {value}
      </Typography>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h4">Campaign Setting</Typography>{" "}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Campaign Name", false)}
        {renderValue(campaignData?.name)}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Profile", false)}
        {renderValue(campaignData?.advertiser?.name)}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Budget Type", false)}
        {renderValue(campaignData?.budget_type)}
      </Grid>
      {(campaignData?.budget_type == "daily" || campaignData?.budget_type == "both") && (
        <Grid item sm={6}>
          {renderLabel("Daily Budget", false)}
          {renderValue(campaignData?.daily_budget)}
        </Grid>
      )}
      {(campaignData?.budget_type == "total" || campaignData?.budget_type == "both") && (
        <Grid item sm={6}>
          {renderLabel("Total Budget", false)}
          {renderValue(campaignData?.total_budget)}
        </Grid>
      )}
      <Grid item sm={6}>
        {renderLabel("Status", false)}
        {renderValue(campaignData?.status)}
      </Grid>
      <Grid item sm={6}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 2 }}>{renderLabel("Date Range", false)}</Box>
        </Box>        
        <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 5 }}>
              {renderValue(
                campaignData?.start_date
                  ? dayjs(campaignData?.start_date).format("MM/DD/YYYY")
                  : "",
              )}
            </Box>
            <Box>
              {renderValue(
                campaignData?.end_date && !endDateValidator(campaignData?.end_date) ? dayjs(campaignData?.end_date).format("MM/DD/YYYY") : "",
              )}
            </Box>
        </Box>        
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Campaign Type", false)}
        {renderValue(campaignData?.campaign_type)}
      </Grid>
      <Grid item sm={6}>
        {renderLabel("Targeting Type", false)}
        {renderValue(campaignData?.targeting_type)}
      </Grid>
    </Grid>
  );
};

export default BrandCampaignSettingsDetails;