import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box } from '@mui/system';
// import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
const SuccessAndFail = ({ dialogOpen, SuccessFailData, handleOkButtonClick, label }) => {
  return (
    <div>
      <Dialog
        open={dialogOpen}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={`${SuccessFailData?.totalcount - SuccessFailData?.successcount !== 0 ? 'md' : 'sm'}`}
      >
        <DialogTitle id="alert-dialog-title" sx={{ borderBottom: '1px solid', borderColor: 'divider' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div"> Bulk Edit Results</Typography>{' '}
            <Typography onClick={handleOkButtonClick} sx={{ cursor: 'pointer' }} variant="h4">
              {' '}
              <CloseIcon />
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: '600px',
            minHeight: SuccessFailData?.totalcount - SuccessFailData?.successcount !== 0 ? '200px' : '0px'
          }}
        >
          <Typography sx={{ marginTop: '10px', fontWeight: 800 }}>Total Count: {SuccessFailData?.totalcount}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {/* {<CircularProgress size={20} />} */}
              {<TaskAltRoundedIcon color="success" />}
              {/* {<HighlightOffIcon color="error" />} */}
            </Box>
            <Typography sx={{ padding: 1 }}>
              {`Success Count`}: {` ${SuccessFailData?.successcount}`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {<CircularProgress size={20} />}
              {/* {<TaskAltRoundedIcon color="success" />} */}

              {<HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1 }}>
              {`Failed Count`}: {SuccessFailData?.totalcount - SuccessFailData?.successcount}
            </Typography>
          </Box>
          {SuccessFailData?.totalcount - SuccessFailData?.successcount !== 0 && (
            <>
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    overflow: 'auto',
                    height: '100px',
                    width: '100%',
                    my: 2,
                    // p: 1,

                    // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                    '&::-webkit-scrollbar': {
                      width: '0.1em',
                      backgroundColor: '#C5C5C5'
                    },
                    '&::-webkit-scrollbar-track': {
                      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#7A8183',
                      outline: '1px solid slategrey'
                    }
                  }}
                >
                  {SuccessFailData?.error_message?.map((item, index) => {
                    return (
                      <Box key={index} sx={{ display: 'flex' }}>
                        {' '}
                        <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>{<ErrorIcon color="error" />}</Box>
                        <Typography sx={{ padding: 1 }}>{item}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box> */}
              <Box>
                <TableContainer
                  sx={{
                    overflow: 'auto',
                    height: '300px',
                    width: '100%',
                    my: 2,
                    '& table': {
                      '& th, td': {
                        border: '1px solid black',
                        padding: '8px',
                        textAlign: 'center'
                      },
                      '& th': {
                        backgroundColor: '#f2f2f2'
                      }
                    },
                    '&::-webkit-scrollbar': {
                      width: '0.1em',
                      backgroundColor: '#C5C5C5'
                    },
                    '&::-webkit-scrollbar-track': {
                      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#7A8183',
                      outline: '1px solid slategrey'
                    }
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>{label}</TableCell>
                        <TableCell>Error</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {SuccessFailData?.error_message?.map((item, index) =>
                        (item?.name && item?.name != '') || (item?.message && item?.message != '') ? (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography>{item?.name || '-'}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{item?.message || '-'}</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ margin: 1 }} onClick={handleOkButtonClick} color="secondary" variant="contained" autoFocus size="small">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessAndFail;
