import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Button,
  FormHelperText,
  Autocomplete,
  Chip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import FormHelpText from 'rsuite/esm/FormHelpText';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { /* getAlldvertiserList, */ getCampaignNameExist } from 'store/thunk/userThunk';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useContext } from 'react';
import CampaignContext from 'context';
import { CAMPAIGN } from 'constant/campaignConstant';
import { CustomSwitch } from 'ui-component/customSwitch';

const CampaignSettingStep = () => {
  const { campaign, campaignData, setCampaignData, setCampaign, handleForwordButtonClick, dateType, setDateType } =
    useContext(CampaignContext);
  const navigate = useNavigate();
  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useAppDispatch();

  const [budgetType, setBudgetType] = useState('both');
  const [dailyBudget, setDailyBudget] = useState(null);
  const [advertiserType, setAdvertiserType] = useState('');
  const [nameValid, setNameValid] = useState(false);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // let data = "";
    // dispatch(getAlldvertiserList({ data }));

    if (campaign?.advertiser) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id == campaign?.advertiser);
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, []);

  const handledChangeData = (e) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.value });
  };

  const handledToggleChange = (e) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.checked });
  };

  const handledPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: e.target.value
      }));
    }
  };

  const dailyBudgetValid = advertiserType == '3p' ? 'For 3P, Minimum daily budget is 10' : 'For 1P, Minimum daily budget is 50';
  const totalBudgetValid = advertiserType == '3p' ? 'For 3P, Minimum total budget is 50' : 'For 1P, Minimum total budget is 100';

  const formSchema = Yup.object().shape({
    name: nameValid
      ? Yup.string()
          .required('Name is required')
          .test('name', 'Name is already exist.', function () {
            return false;
          })
      : Yup.string().required('Name is required'),
    advertiser: Yup.string().required('Advertiser is required'),
    budget_type: Yup.string().required('Budget Type is required'),
    daily_budget:
      budgetType == 'daily' || budgetType == 'both'
        ? Yup.string()
            .required('Daily Budget is required')
            .test('daily_budget', dailyBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 10 : Number(value) >= 50;
            })
        : '',
    total_budget:
      budgetType == 'both'
        ? Yup.string()
            .required('Total Budget is required')
            .test('total_budget', 'Total budget is greter than daily', function (value) {
              return Number(value) >= Number(dailyBudget);
            })
            .test('total_budget', totalBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
            })
        : budgetType == 'total'
        ? Yup.string()
            .required('Total Budget is required')
            .test('total_budget', totalBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
            })
        : '',
    start_date: dateType == 'select' ? Yup.string().required('Date is required').nullable() : null,
    end_date: Yup.string().when('start_date', (start_date, schema) => {
      if (start_date) {
        return schema.test('is-after-start', 'End date must be greater than start date', function (end_date) {
          if (!end_date) {
            return true;
          } else {
            return dayjs(end_date).isAfter(dayjs(start_date));
          }
        });
      }
      return schema;
    })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: campaign.name,
      advertiser: campaign.advertiser,
      budget_type: campaign.budget_type,
      daily_budget: campaign.daily_budget,
      total_budget: campaign.total_budget,
      start_date: campaign.start_date,
      end_date: campaign.end_date
    }
  });

  useEffect(() => {
    const budget_type_value = watch('budget_type');
    if (budget_type_value) {
      setBudgetType(budget_type_value);
    }
  }, [watch('budget_type')]);

  const startDateValue = watch('start_date');

  useEffect(() => {
    const dailyBudgetValue = watch('daily_budget');
    if (dailyBudgetValue) {
      setDailyBudget(dailyBudgetValue);
    }
  }, [watch('daily_budget')]);

  useEffect(() => {
    const advertiserTypeValue = watch('advertiser');
    if (advertiserTypeValue) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id === advertiserTypeValue);
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, [watch('advertiser')]);

  useEffect(() => {
    const campaign_name = watch('name');
    dispatch(getCampaignNameExist({ campaign_name })).then((res) => {
      if (res?.payload?.data?.error) {
        setNameValid(true);
      } else {
        setNameValid(false);
      }
    });
  }, [watch('name')]);

  const onButtonClick = (event) => {
    var _request = {};

    _request = {
      ...campaign,
      ...event,
      daily_budget: Number(event.daily_budget),
      total_budget: Number(event.total_budget),
      advertiser: Number(event.advertiser)
    };
    if (event.start_date && dateType == 'select') {
      if (!event.end_date) {
        delete _request['end_date'];
      }
    } else {
      delete _request['start_date'];
      delete _request['end_date'];
    }
    if (event.budget_type == 'total') {
      delete _request['daily_budget'];
    } else if (event.budget_type == 'daily') {
      delete _request['total_budget'];
    }
    if (campaign?.targeting_type != CAMPAIGN.TARGETING_TYPE.AUTO) {
      delete _request['placement_bid_home_page'];
      delete _request['placement_bid_stock_up'];
    }

    setCampaign({
      ...campaign,
      name: event.name,
      advertiser: event.advertiser,
      budget_type: event.budget_type,
      daily_budget: event.budget_type != 'total' ? event.daily_budget : null,
      total_budget: event.budget_type != 'daily' ? event.total_budget : null,
      start_date: event.start_date,
      end_date: event.end_date
    });

    console.log('_request ', _request);
    setCampaignData({ ...campaignData, campaign: _request });
    handleForwordButtonClick();
  };

  return (
    <form noValidate onSubmit={handleSubmit(onButtonClick)}>
      <Box>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item sm={6}>
            {/* {renderLabel('Campaign Name', true)} */}
            <Controller
              name={'name'}
              control={control}
              render={({ field }) => (
                <TextField
                  {...register('name')}
                  {...field}
                  name={'name'}
                  fullWidth
                  variant="outlined"
                  label="Campaign Name"
                  type="text"
                  error={errors?.name}
                  helperText={errors?.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            {/* {renderLabel('Profile', true)} */}
            <Controller
              name="advertiser"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...register('advertiser')}
                  {...field}
                  value={allUserDetails.find((obj) => obj.id == field.value) || null}
                  onChange={(e, value) => (value ? field.onChange(value?.id) : field.onChange(''))}
                  options={allUserDetails || []}
                  getOptionLabel={(option) => {
                    return option?.name || '';
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profile"
                      error={Boolean(errors.advertiser)}
                      helperText={errors.advertiser?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            {/* {renderLabel('Budget Type', true)} */}
            <Controller
              name={'budget_type'}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors?.budget_type}>
                  <InputLabel>Budget Type</InputLabel>
                  <Select
                    {...field}
                    label="Budget Type"
                    {...register('budget_type')}
                    fullWidth
                    name="budget_type"
                    error={errors?.budget_type}
                  >
                    <MenuItem value="daily">Daily Budget</MenuItem>
                    {/* <MenuItem value="total">Total Budget</MenuItem> */}
                    <MenuItem value="both">Both</MenuItem>
                  </Select>
                  {errors?.budget_type && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.budget_type?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Box sx={{ padding: 0.5 }}>
              <FormHelpText>Once a campaign becomes live, budget type cannot be changed</FormHelpText>
            </Box>
          </Grid>
          {(budgetType == 'daily' || budgetType == 'both') && (
            <Grid item sm={6}>
              {/* {renderLabel('Daily Budget', true)} */}
              <Controller
                name={'daily_budget'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register('daily_budget')}
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="daily_budget"
                    label="Daily Budget"
                    variant="outlined"
                    error={errors?.daily_budget}
                    onChange={(e) => {
                      setValue('daily_budget', e.target.value);
                    }}
                    helperText={errors?.daily_budget?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                )}
              />
              <Box sx={{ padding: 0.5 }}>
                <FormHelpText>For 3P, Minimum Daily Budget is 10; for 1P, Minimum Daily Budget is 50.</FormHelpText>
              </Box>
            </Grid>
          )}
          {(budgetType == 'total' || budgetType == 'both') && (
            <Grid item sm={6}>
              {/* {renderLabel('Total Budget', true)} */}
              <Controller
                name={'total_budget'}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    {...register('total_budget')}
                    type="number"
                    label="Total Budget"
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    name="total_budget"
                    error={errors?.total_budget}
                    onChange={(e) => {
                      setValue('total_budget', e.target.value);
                    }}
                    helperText={errors?.total_budget?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                )}
              />
              <Box sx={{ padding: 0.5 }}>
                <FormHelpText>For 3P, Minimum Daily Budget is 50; for 1P, Minimum Daily Budget is 100.</FormHelpText>
              </Box>
            </Grid>
          )}
          <Grid item sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 2 }}>{renderLabel('Date Range', true)}</Box>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="any"
                  name="radio-buttons-group"
                  value={dateType}
                  onChange={(e, value) => {
                    setDateType(value);
                  }}
                >
                  <FormControlLabel value="any" control={<Radio />} label="Any Date Range" variant="outlined" />
                  <FormControlLabel value="select" control={<Radio />} label="Select Date Range" variant="outlined" />
                </RadioGroup>
              </FormControl>
            </Box>
            {dateType == 'select' && (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ mr: 5 }}>
                  <Controller
                    name={'start_date'}
                    sx={{
                      background: 'red',
                      width: '100%'
                    }}
                    control={control}
                    render={() => (
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('start_date')}
                          error={errors?.start_date}
                          minDate={dayjs(new Date())}
                          defaultValue={campaign.start_date ? dayjs(campaign.start_date) : dayjs(new Date())}
                          closeOnSelect={true}
                          onChange={(value) => {
                            const dayjsDate = dayjs(value.$d);
                            const formattedDate = dayjsDate.format('YYYY-MM-DD');
                            setValue('start_date', formattedDate);
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.start_date && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.start_date?.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <Controller
                    name={'end_date'}
                    control={control}
                    render={() => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('end_date')}
                          error={errors?.end_date}
                          defaultValue={campaign.end_date ? dayjs(campaign.end_date) : null}
                          minDate={startDateValue ? dayjs(startDateValue).add(1, 'day') : dayjs(new Date()).add(1, 'day')}
                          onChange={(value) => {
                            const dayjsDate = dayjs(value.$d);
                            const formattedDate = dayjsDate.format('YYYY-MM-DD');
                            setValue('end_date', formattedDate);
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.end_date && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      {errors?.end_date?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item sm={6}>
            {renderLabel('Campaign Type', true)}
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="targeting_type"
                onChange={handledChangeData}
                value={campaign?.targeting_type}
              >
                <FormControlLabel
                  value={CAMPAIGN.TARGETING_TYPE.AUTO}
                  control={<Radio />}
                  label={CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO}
                />
                <FormControlLabel
                  value={CAMPAIGN.TARGETING_TYPE.MANUAL}
                  control={<Radio />}
                  label={CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            {renderLabel('Bidding Strategy', true)}
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="bidding_strategy"
                onChange={handledChangeData}
                value={campaign?.bidding_strategy}
              >
                <FormControlLabel value={CAMPAIGN.BIDDING_STRATEGY.FIXED} control={<Radio />} label={'Fixed'} />
                <FormControlLabel value={CAMPAIGN.BIDDING_STRATEGY.DYNAMIC} control={<Radio />} label={'Dynamic'} />
                <FormControlLabel
                  control={<Chip label="Beta" size="small" color="primary" clickable={false} style={{ marginLeft: '2px' }} />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            {renderLabel('Placement')}
            <FormControlLabel
              label="Search Ingrid"
              name="is_search_ingrid"
              className="CustomSwitchToggle"
              checked={campaign?.is_search_ingrid}
              onChange={handledToggleChange}
              disabled
              control={<CustomSwitch label="Search Ingrid" />}
            />

            {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
              <FormControlLabel
                label="Search Carousel"
                checked={campaign?.is_search_carousel}
                name="is_search_carousel"
                className="CustomSwitchToggle"
                onChange={handledToggleChange}
                control={<CustomSwitch label="Search Carousel" />}
              />
            )}

            <FormControlLabel
              label="Item Buybox"
              name="is_item_buybox"
              className="CustomSwitchToggle"
              checked={campaign?.is_item_buybox}
              onChange={handledToggleChange}
              disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
              control={<CustomSwitch label="Item Buybox" />}
            />

            {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
              <FormControlLabel
                label="Item Carousel"
                name="is_item_carousel"
                className="CustomSwitchToggle"
                checked={campaign?.is_item_carousel}
                onChange={handledToggleChange}
                control={<CustomSwitch label="Item Carousel" />}
              />
            )}

            {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
              <FormControlLabel
                label="Home Page"
                name="is_home_page"
                className="CustomSwitchToggle"
                checked={campaign?.is_home_page}
                onChange={handledToggleChange}
                disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                control={<CustomSwitch label="Home Page" />}
              />
            )}

            {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
              <FormControlLabel
                label="Stock Up"
                name="is_stock_up"
                className="CustomSwitchToggle"
                checked={campaign?.is_stock_up}
                onChange={handledToggleChange}
                disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                control={<CustomSwitch label="Stock Up" />}
              />
            )}

            <FormControlLabel
              label="Others"
              checked={campaign?.is_others}
              onChange={handledToggleChange}
              name="is_others"
              className="CustomSwitchToggle"
              disabled
              control={<CustomSwitch label="Others" />}
            />
          </Grid>
          <Grid item sm={12}>
            {renderLabel('Placement Bid Multiplier')}
          </Grid>
          <Grid item sm={3}>
            {/*renderLabel('Search Ingrid')*/}
            <TextField
              fullWidth
              type="number"
              label="Search Ingrid"
              variant="outlined"
              onWheel={(e) => e.target.blur()}
              placeholder="0-900"
              inputProps={{
                min: 0,
                max: 900
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="placement_bid_search_ingrid"
              value={campaign?.placement_bid_search_ingrid}
              onChange={handledPercentageChange}
            />
          </Grid>
          <Grid item sm={3}>
            {/*renderLabel('Item Buybox')*/}
            <TextField
              fullWidth
              type="number"
              label="Item Buybox"
              variant="outlined"
              onWheel={(e) => e.target.blur()}
              inputProps={{
                min: 0,
                max: 900
              }}
              placeholder="0-900"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="placement_bid_item_buybox"
              value={campaign?.placement_bid_item_buybox}
              onChange={handledPercentageChange}
            />
          </Grid>
          {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
            <Grid item sm={3}>
              {/*renderLabel('Home Page')*/}
              <TextField
                fullWidth
                type="number"
                label="Home Page"
                variant="outlined"
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 0,
                  max: 900
                }}
                placeholder="0-900"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="placement_bid_home_page"
                value={campaign?.placement_bid_home_page}
                onChange={handledPercentageChange}
              />
            </Grid>
          )}

          {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
            <Grid item sm={3}>
              {/* {renderLabel('Stock Up')} */}
              <TextField
                fullWidth
                type="number"
                label="Stock Up"
                variant="outlined"
                placeholder="0-900"
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 0,
                  max: 900
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="placement_bid_stock_up"
                value={campaign?.placement_bid_stock_up}
                onChange={handledPercentageChange}
              />
            </Grid>
          )}

          <Grid item sm={12}>
            {renderLabel('Platform Bid Multiplier')}
          </Grid>
          <Grid item sm={3}>
            {/* {renderLabel('Desktop')} */}
            <TextField
              fullWidth
              type="number"
              label="Desktop"
              variant="outlined"
              onWheel={(e) => e.target.blur()}
              inputProps={{
                min: 0,
                max: 900
              }}
              placeholder="0-900"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="platform_bid_desktop"
              value={campaign?.platform_bid_desktop}
              onChange={handledPercentageChange}
            />
          </Grid>
          <Grid item sm={3}>
            {/* {renderLabel('APP')} */}
            <TextField
              fullWidth
              type="number"
              label="App"
              variant="outlined"
              onWheel={(e) => e.target.blur()}
              placeholder="0-900"
              inputProps={{
                min: 0,
                max: 900
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="platform_bid_app"
              value={campaign?.platform_bid_app}
              onChange={handledPercentageChange}
            />
          </Grid>
          <Grid item sm={3}>
            {/*renderLabel('Mobile')*/}
            <TextField
              fullWidth
              type="number"
              label="Mobile"
              variant="outlined"
              onWheel={(e) => e.target.blur()}
              placeholder="0-900"
              inputProps={{
                min: 0,
                max: 900
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="platform_bid_mobile"
              value={campaign?.platform_bid_mobile}
              onChange={handledPercentageChange}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
          <>
            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
              CANCEL
            </Button>
            {/* <Button variant="outlined" color="secondary" sx={{ ml: 1 }} >PREVIOUS</Button> */}
            <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
              NEXT
            </Button>
          </>
        </Box>
      </Box>
    </form>
  );
};

export default CampaignSettingStep;
