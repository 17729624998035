import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'store';
import { Box, Stepper, Step, StepLabel, StepContent, Button /* , Paper, Typography */ } from '@mui/material';
import { getCampaignAllDetails, getBudgetPerformance, getBudgetProfiles, CreateProfileBudget } from 'store/thunk/userThunk';
import StepperCustomDotActive from 'ui-component/StepperCustomDotActive';
import MainCard from 'ui-component/cards/MainCard';
import BasicSetting from './BasicSetting';
import BudgetSetting from './BudgetSetting';
import AutomationSetting from './AutomationSetting';
import { useAppSelector } from 'store';
import WarningModalDialog from 'ui-component/warning-modal/WarningModal';

const steps = ['Basic Setting', 'Budget Setting', 'Automation Setting'];

export default function AddProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const { BudgetPerformanceData } = useAppSelector((state) => state?.userDetailsSlice);
  const [data, setData] = useState({
    profile: null,
    totalCampaign: 0,
    monthlyBudget: null,
    budgetEvenly: true,
    stopOverSpend: true,
    autoReEnable: true
  });
  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    dispatch(getBudgetProfiles());
  }, []);

  useMemo(() => {
    if (data?.profile?.id) {
      dispatch(getCampaignAllDetails({ advrid: data?.profile?.id, type: '' }));
      dispatch(getBudgetPerformance({ advertiserId: data?.profile?.id }));
    } else {
      setData({ ...data, profile: null });
    }
  }, [data?.profile]);

  const handleNext = () => {
    if (activeStep + 1 === 2) {
      if (Number(data?.monthlyBudget) >= 1) {
        if (BudgetPerformanceData.current_month_ad_spend * 1.1 >= Number(data?.monthlyBudget)) {
          setOpenDelete(true);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setError(null);
        }
      } else {
        setError({ ...error, message: 'Monthly budget cannot be less than $1' });
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const project = () => {
    switch (activeStep) {
      case 0:
        return <BasicSetting data={data} setData={setData} />;
      case 1:
        return <BudgetSetting data={data} setData={setData} error={error} setError={setError} />;
      case 2:
        return <AutomationSetting data={data} setData={setData} />;
      default:
        return <h1>No project match</h1>;
    }
  };

  const handleSubmit = () => {
    const raw = {
      advertiser: data?.profile?.id,
      total_campaign: data?.totalCampaign,
      monthly_budget: data?.monthlyBudget,
      split_budget_evenly: data?.budgetEvenly,
      stop_over_spend: data?.stopOverSpend,
      auto_re_enable: data?.autoReEnable
    };
    dispatch(CreateProfileBudget(raw));
  };

  return (
    <MainCard>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepperCustomDotActive}>{label}</StepLabel>
            <StepContent>
              <Box sx={{ height: 'auto' }}>{project()}</Box>
              <Box sx={{ mt: 5 }}>
                <div>
                  {index === steps.length - 1 ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={data?.profile ? false : true}
                      onClick={handleSubmit}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={data?.profile ? false : true}
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button color="secondary" variant="contained" onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Previous
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate('/budget-manager');
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {
        <WarningModalDialog
          cancelCallback={() => setOpenDelete(!openDelete)}
          warningCallback={() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setError(null);
            setOpenDelete(!openDelete);
          }}
          Message={
            BudgetPerformanceData.current_month_ad_spend >= Number(data?.monthlyBudget)
              ? 'Your entered budget has already been exceeded for this month. To prevent overspending, all campaigns will be paused immediately.'
              : BudgetPerformanceData.current_month_ad_spend * 1.1 >= Number(data?.monthlyBudget)
              ? 'Over 90% of your entered budget has been utilized this month. If you continue, your campaigns may exceed the budget soon and will be paused.'
              : ''
          }
          open={openDelete}
        />
      }
    </MainCard>
  );
}
