import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import colors from 'assets/scss/_themes-vars.module.scss';
import { Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

// Styled Box component
const Box = styled(MuiBox)(() => ({
  width: 35,
  height: 35,
  borderWidth: 1.4,
  borderColor: colors.secondaryMain,
  borderRadius: '50%',
  borderStyle: 'solid',
  color: 'white'
}));

const StepperCustomDotActive = (props) => {
  const { icon, active, completed } = props;
  return (
    <Box
      sx={{
        ...((active || completed) && { background: colors.secondaryMain, border: 'none' }),

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant="h3" sx={{ lineHeight: '0.9', color: active || completed ? 'white' : 'black' }}>
        {completed ? <Check /> : icon}
      </Typography>
    </Box>
  );
};

export default StepperCustomDotActive;
