import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const AdvertiserDashboard = Loadable(lazy(() => import('views/advertiserDashboard/index')));
// const Dashboard = Loadable(lazy(() => import('views/dashboard/index')));
const Dashboard = Loadable(lazy(() => import('views/Overview/index')));

import PrivateRoute from 'PrivateRoute';
import Campaign from 'views/campaign';
import CampaignDetails from 'views/campaign/campaignDetails';
import CreateProduct from 'views/campaign/createProduct';
import CreateBrand from 'views/campaign/createBrand';
import ComingSoonPage from 'views/pages/authentication/ComingSoon';
import Adgroup from 'views/adgroup';
import CreateAdgroup from 'views/adgroup/createAdgroup';
import Items from 'views/Items';
import DefaultReport from 'views/defaultReport';
import CampaignReport from 'views/reports/campaignReport';
import UserManagement from 'views/userManagement';
import GetLatestReportDate from 'views/reports/GetLatestReportDate';
import GetAPIUsageAnalyze from 'views/reports/GetAPIUsageAnalyze';
import ProfileManagement from 'views/profile';
import VideoManagement from 'views/campaign/campaignVideo/videoLibrary';
import CreateVideo from 'views/campaign/campaignVideo/createVideo';
import BulkOperation from 'views/bulkOperation';
import Keyword from 'views/keywords';
import BulkOperationResult from 'views/bulkOperation/bulkOperationResult';
import BulkOperationList from 'views/bulkOperation/bulkOperationList';
import CustomReport from 'views/customReport';
import DashboardData from 'views/dashboard/dashboardData';
import MainDashboard from 'views/reports/onDemandReports';
import SharedDashboards from 'views/dashboard/shared-dashboards';
import CreateLink from 'views/dashboard/shared-dashboards/create-link';
import BudgetManager from 'views/budget-manager';
import AddPrrofile from 'views/budget-manager/AddPrrofile';
import DashboardOverview from 'views/Overview';
import RankTracker from 'views/rankTracker';

//My routes
import VerifyEmail from 'views/pages/authentication/emailVerification/verifyEmail';
import EmailVerificationSuccess from 'views/pages/authentication/emailVerification/modals/emailVerificationSuccess';
import WelcomeToDashna from 'views/pages/authentication/emailVerification/modals/welcomeToDashna';
import CreateStore from 'views/pages/authentication/emailVerification/modals/createStore';
import ConnectWalmartSeller from 'views/pages/authentication/emailVerification/modals/connectWalmartSeller';
import ConnectAdvertiser from 'views/pages/authentication/emailVerification/modals/connectAdvertiser';
import StoreList from 'views/store';
import MultipleItemAdd from 'ui-component/multipale-item-add';
import UploadItemModal from 'views/upload-item-Modal';
// import RuleEngine from 'views/rule-engine';
// ==============================|| MAIN  ||============================== //

export const verificationRoutes = [
  {
    path: '/verify-email',
    element: (
      <PrivateRoute>
        <VerifyEmail />
      </PrivateRoute>
    )
  },
  {
    path: '/verification-success',
    element: (
      <PrivateRoute>
        <EmailVerificationSuccess />
      </PrivateRoute>
    )
  },

  {
    path: '/welcome-to-dashna',
    element: (
      <PrivateRoute>
        <WelcomeToDashna />
      </PrivateRoute>
    )
  },
  {
    path: '/create-store',
    element: (
      <PrivateRoute>
        <CreateStore />
      </PrivateRoute>
    )
  },
  {
    path: '/connect-to-walmart-seller',
    element: (
      <PrivateRoute>
        <ConnectWalmartSeller />
      </PrivateRoute>
    )
  },
  {
    path: '/add-advertiser',
    element: (
      <PrivateRoute>
        <ConnectAdvertiser />
      </PrivateRoute>
    )
  }
];

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'rank-tracker',
      element: (
        <PrivateRoute>
          <RankTracker />
        </PrivateRoute>
      )
    },
    {
      path: 'dashboard-report',
      element: (
        <PrivateRoute>
          <DashboardData />
        </PrivateRoute>
      )
    },
    {
      path: '*',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )
    },
    {
      path: 'shared-dashboards',
      element: (
        <PrivateRoute>
          <SharedDashboards />
        </PrivateRoute>
      )
    },
    {
      path: 'shared-dashboards/create-link',
      element: (
        <PrivateRoute>
          <CreateLink />
        </PrivateRoute>
      )
    },
    {
      path: 'dashboard-overview',
      element: (
        <PrivateRoute>
          <DashboardOverview />
        </PrivateRoute>
      )
    },
    {
      path: '/new-profile-modal',
      element: (
        <PrivateRoute>
          <MultipleItemAdd />
        </PrivateRoute>
      )
    },
    {
      path: '/upload-item',
      element: (
        <PrivateRoute>
          <UploadItemModal />
        </PrivateRoute>
      )
    },
    {
      path: 'on-demand-reports',
      element: (
        <PrivateRoute>
          <MainDashboard />
        </PrivateRoute>
      )
    },
    {
      path: '/',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )
    },

    {
      path: 'dashboard',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )
    },

    {
      path: 'dashboard/:tokenId',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      )
    },
    {
      path: 'advertiser',
      element: (
        <PrivateRoute>
          <AdvertiserDashboard />
        </PrivateRoute>
      )
    },
    {
      path: 'advertiser/:storeId',
      element: (
        <PrivateRoute>
          <AdvertiserDashboard />
        </PrivateRoute>
      )
    },
    {
      path: 'user-management',
      element: (
        <PrivateRoute>
          <UserManagement />
        </PrivateRoute>
      )
    },
    {
      path: 'campaign',
      element: (
        <PrivateRoute>
          <Campaign />
        </PrivateRoute>
      )
    },
    {
      path: 'campaign/:campaignId',
      element: (
        <PrivateRoute>
          <Campaign />
        </PrivateRoute>
      )
    },
    {
      path: 'adgroup',
      element: (
        <PrivateRoute>
          <Adgroup />
        </PrivateRoute>
      )
    },

    {
      path: 'adgroup/:campaignID',
      element: (
        <PrivateRoute>
          <Adgroup />
        </PrivateRoute>
      )
    },
    {
      path: 'create-adgroup',
      element: (
        <PrivateRoute>
          <CreateAdgroup />
        </PrivateRoute>
      )
    },
    {
      path: 'product-campaign-view/:campaignID',
      element: (
        <PrivateRoute>
          <CampaignDetails />
        </PrivateRoute>
      )
    },
    {
      path: 'create-brand',
      element: (
        <PrivateRoute>
          <CreateBrand />
        </PrivateRoute>
      )
    },
    {
      path: 'create-product',
      element: (
        <PrivateRoute>
          <CreateProduct />
        </PrivateRoute>
      )
    },
    {
      path: 'create-video',
      element: (
        <PrivateRoute>
          <CreateVideo />
        </PrivateRoute>
      )
    },
    {
      path: 'adgroup',
      element: (
        <PrivateRoute>
          <ComingSoonPage />
        </PrivateRoute>
      )
    },
    {
      path: 'keyword',
      element: (
        <PrivateRoute>
          <Keyword />
        </PrivateRoute>
      )
    },
    {
      path: 'keyword/:id',
      element: (
        <PrivateRoute>
          <Keyword />
        </PrivateRoute>
      )
    },
    {
      path: 'keyword/:id/:type',
      element: (
        <PrivateRoute>
          <Keyword />
        </PrivateRoute>
      )
    },
    {
      path: 'keyword/:id/:adgroupId/:type',
      element: (
        <PrivateRoute>
          <Keyword />
        </PrivateRoute>
      )
    },
    {
      path: 'items',
      element: (
        <PrivateRoute>
          <Items />
        </PrivateRoute>
      )
    },
    {
      path: 'items/:id',
      element: (
        <PrivateRoute>
          <Items />
        </PrivateRoute>
      )
    },
    {
      path: 'items/:id/:type',
      element: (
        <PrivateRoute>
          <Items />
        </PrivateRoute>
      )
    },
    {
      path: 'items/:id/:adgroupId/:type',
      element: (
        <PrivateRoute>
          <Items />
        </PrivateRoute>
      )
    },
    {
      path: 'default-report',
      element: (
        <PrivateRoute>
          <DefaultReport />
        </PrivateRoute>
      )
    },
    {
      path: 'my-report',
      element: (
        <PrivateRoute>
          <ComingSoonPage />
        </PrivateRoute>
      )
    },
    {
      path: 'campaign-report',
      element: (
        <PrivateRoute>
          <CampaignReport />
        </PrivateRoute>
      )
    },
    {
      path: 'adgroup-report',
      element: (
        <PrivateRoute>
          <CampaignReport />
        </PrivateRoute>
      )
    },
    {
      path: 'item-report',
      element: (
        <PrivateRoute>
          <CampaignReport />
        </PrivateRoute>
      )
    },
    {
      path: 'keyword-report',
      element: (
        <PrivateRoute>
          <CampaignReport />
        </PrivateRoute>
      )
    }, // adgroup, keyword, items
    {
      path: 'report-date',
      element: (
        <PrivateRoute>
          <GetLatestReportDate />
        </PrivateRoute>
      )
    },
    {
      path: 'api-usage',
      element: (
        <PrivateRoute>
          <GetAPIUsageAnalyze />
        </PrivateRoute>
      )
    },
    {
      path: 'profile',
      element: (
        <PrivateRoute>
          <ProfileManagement />
        </PrivateRoute>
      )
    },
    {
      path: 'video-management',
      element: (
        <PrivateRoute>
          <VideoManagement />
        </PrivateRoute>
      )
    },
    {
      path: 'bulk-operations',
      element: (
        <PrivateRoute>
          <BulkOperation />
        </PrivateRoute>
      )
    },
    {
      path: 'bulk-operations-result',
      element: (
        <PrivateRoute>
          <BulkOperationResult />
        </PrivateRoute>
      )
    },
    {
      path: 'bulk-operations-list',
      element: (
        <PrivateRoute>
          <BulkOperationList />
        </PrivateRoute>
      )
    },
    {
      path: 'custom-report',
      element: (
        <PrivateRoute>
          <CustomReport />
        </PrivateRoute>
      )
    },
    {
      path: 'store',
      element: (
        <PrivateRoute>
          <StoreList />
        </PrivateRoute>
      )
    },
    {
      path: 'budget-manager',
      element: (
        <PrivateRoute>
          <BudgetManager />
        </PrivateRoute>
      )
    },
    {
      path: 'budget-manager/add-profile',
      element: (
        <PrivateRoute>
          <AddPrrofile />
        </PrivateRoute>
      )
    }
    // {
    //   path: 'rule-engine',
    //   element: (
    //     <PrivateRoute>
    //       <RuleEngine />
    //     </PrivateRoute>
    //   )
    // }
  ]
};

export default MainRoutes;
