import colors from 'assets/scss/_themes-vars.module.scss';

// material-ui
import {
  Dialog,
  // DialogContent,
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  DialogTitle,
  IconButton,
  Autocomplete,
  Tooltip,
  Chip,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  DialogActions,
  DialogContent
} from '@mui/material';
import { useAppSelector, useAppDispatch } from 'store';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dropDownIcon from '../../assets/images/dashna/dropdownarrow.svg';
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
// import MainCard from 'ui-component/cards/MainCard';
import SelectedKeywordTable from './selectedKeywordTable';
import {
  setSelectedKeyword,
  setSelectItemKeywordTracking,
  setSelectSuggestedKeywordTracking
} from 'store/slices/userDetailsSlice';
import { GridCloseIcon } from '@mui/x-data-grid';
import {
  CreateKeywordRunTracker,
  getItemDetailsForKeywordTracking,
  getSuggestedDetailsForKeywordTracking
} from 'store/thunk/userThunk';
import { Loader } from 'rsuite';
// import plus from 'assets/images/dashna/plus.svg';

// project imports

const MultipleItemAdd = (props) => {
  const dispatch = useAppDispatch();
  const { open, OnClose, refreshListOnceUploaded } = props;
  const [advertiser, setAdvertiser] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [value, setValue] = useState('one');

  const { keywordTrackerDetails, keywordItemData, allUserDetails, SuggestedKeywordItemData } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  const primaryMain = colors?.primaryMain;
  const DarkMain = colors?.DarkMain;
  const Dark = colors?.primaryTextColor;
  const handleUploadKeyword = () => {
    if (keywordTrackerDetails) {
      const uniqueArray = [...new Set(keywordTrackerDetails.map((keyword) => keyword.toLowerCase()))];

      const request = {
        keywords: uniqueArray,
        item_id: selectedItem[0]?.item_id,
        advertise_id: advertiser?.id
      };

      dispatch(CreateKeywordRunTracker({ request, OnClose, refreshListOnceUploaded }));
    }
  };

  const handleAddSuggestedKeyword = (keyword) => {
    if (keywordTrackerDetails) {
      dispatch(setSelectedKeyword([...keywordTrackerDetails, keyword]));
    } else {
      dispatch(setSelectedKeyword([keyword]));
    }
  };

  const handleClickAddAll = () => {
    const addedAll = SuggestedKeywordItemData.map((item) => item?.searched_keyword);
    if (keywordTrackerDetails) {
      dispatch(setSelectedKeyword([...keywordTrackerDetails, ...addedAll]));
    } else {
      dispatch(setSelectedKeyword(addedAll));
    }
  };
  const [keywordItemOption, setKeywordItemOption] = useState();
  useEffect(() => {
    dispatch(setSelectSuggestedKeywordTracking([]));
    dispatch(setSelectedKeyword([]));
    dispatch(setSelectItemKeywordTracking([]));
  }, []);
  const [loader, setLoader] = useState();
  useEffect(() => {
    if (advertiser?.id > 0) {
      const id = advertiser?.id;

      dispatch(getItemDetailsForKeywordTracking({ id, setLoader }));
      dispatch(setSelectSuggestedKeywordTracking([]));
      dispatch(setSelectedKeyword([]));
    }
  }, [advertiser]);

  useEffect(() => {
    if (keywordItemData) {
      setKeywordItemOption(keywordItemData);
      dispatch(setSelectedKeyword([]));
    }
  }, [keywordItemData, advertiser]);
  const [loaderTable, setLoaderTable] = useState();
  useEffect(() => {
    if (selectedItem?.length > 0 && advertiser?.id > 0) {
      const itemId = selectedItem[0]?.item_id;
      const id = advertiser?.id;

      dispatch(setSelectedKeyword([]));
      dispatch(getSuggestedDetailsForKeywordTracking({ id, itemId, setLoaderTable }));
    }
  }, [advertiser, selectedItem]);

  const columns = [
    {
      name: 'searched_keyword',
      label: (
        <Typography fontSize={'14px'} color={Dark} fontWeight={'600'}>
          Search Term
        </Typography>
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              {value?.length > 20 ? (
                <Tooltip
                  placement="top"
                  fontSize={'14px'}
                  fontWeight={'400'}
                  color={Dark}
                  title={
                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                      {value}
                    </Typography>
                  }
                  arrow
                >
                  <Typography> {value.substring(0, 20)}... </Typography>
                </Tooltip>
              ) : (
                <Typography fontSize={'14px'} fontWeight={'400'} color={Dark}>
                  {' '}
                  {value}{' '}
                </Typography>
              )}
            </>
          );
        }
      }
    },
    {
      name: 'roas',
      label: (
        <Typography fontSize={'14px'} color={Dark} fontWeight={'600'}>
          RoAS
        </Typography>
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography fontSize={'14px'} fontWeight={400} color={Dark}>
              {value ? `${value == 0 ? value : `$${value.toFixed(2)}`}` : '-'}
            </Typography>
          );
        }
      }
    },
    {
      name: 'latest_search_rank',
      label: (
        <Typography fontSize={'14px'} color={Dark} fontWeight={'600'}>
          Rank
        </Typography>
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography fontSize={'14px'} fontWeight={400} color={Dark}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: 'Add',
      label: (
        <Typography
          className="cursor-pointer"
          fontSize={'14px'}
          fontWeight={'400'}
          color={SuggestedKeywordItemData?.length > 0 ? primaryMain : colors.grey500}
          onClick={handleClickAddAll}
        >
          +Add All
        </Typography>
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box>
              <Button
                textAlign={'center'}
                disabled={keywordTrackerDetails?.includes(tableMeta?.rowData[0])}
                style={{ color: keywordTrackerDetails?.includes(tableMeta?.rowData[0]) ? DarkMain : primaryMain }}
                onClick={() => handleAddSuggestedKeyword(`${tableMeta.rowData[0]}`)}
              >
                {' '}
                <AddCircleOutlineIcon
                  className="cursor-pointer"
                  color={primaryMain}
                  sx={{ width: '20px', height: '20px', fontWeight: 400 }}
                />
                {/* <img src={plus} alt="Delete Logo" /> */}
              </Button>
            </Box>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    tableBodyHeight: '224px',
    responsive: 'standard',
    rowsPerPage: 25,
    filter: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: loaderTable ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [keywordEntered, setKeywordEntered] = useState();
  const [sendData, setSendData] = useState(null);

  const handleSetKeyword = (e) => {
    const inputVal = e.target.value;
    const words = inputVal.split('\n').filter((line) => line.trim() !== '');

    if (words.length <= 200) {
      setKeywordEntered(inputVal);
      setSendData(words);
    }
  };

  const handleAddKeyword = () => {
    setKeywordEntered('');
    if (keywordTrackerDetails) {
      dispatch(setSelectedKeyword([...keywordTrackerDetails, ...sendData]));
      setSendData('');
    } else {
      dispatch(setSelectedKeyword(sendData));
      setSendData('');
    }
  };
  const renderContent = () => {
    switch (value) {
      case 'one':
        return (
          <Typography my={2} className="mt-12 custom-table-border">
            <Box pt={2}>
              {/* <MainCard sx={{ padding: 0 }}> */}
              <MUIDataTable
                className="pnl-by-item"
                data={loaderTable ? [] : SuggestedKeywordItemData || []}
                columns={columns}
                options={options}
              />
              {/* /</Box>    </MainCard> */}
            </Box>
          </Typography>
        );
      case 'two':
        return (
          <Typography my={2} className="mt-10">
            {' '}
            <Box>
              {/* <MainCard sx={{ padding: 0 }}> */}
              <TextField
                name={'adgroup_name'}
                fullWidth
                type="text"
                multiline
                color="secondary"
                placeholder="Please enter Search Terms"
                rows={7}
                value={keywordEntered}
                onChange={handleSetKeyword}
              />
              <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box ml={1}>{sendData?.length > 0 && `${sendData?.length} added`} </Box>
                <Box
                  mr={1}
                  fontSize={'14px'}
                  fontWeight={'400'}
                  color={primaryMain}
                  className="cursor-pointer"
                  onClick={handleAddKeyword}
                >
                  +Add
                </Box>
              </Box>
              {/* </MainCard> */}
            </Box>
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      maxWidth={'sm'}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: '1050px', maxWidth: '1050px' } }}
    >
      <DialogTitle id="alert-dialog-title">
        Add Item to Tracker
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={OnClose} // handleClose function to close the dialog
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <GridCloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box className="overflow-auto-one">
          <Box>
            <Grid container sx={{ pb: 0 }} spacing={2}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  multiple={false}
                  popupIcon={
                    <Box display={'flex'} mt={0.5} alignItems={'center'} justifyContent={'center'}>
                      <img style={{ margin: '5px' }} alt="dropdown icon" src={dropDownIcon} />{' '}
                    </Box>
                  }
                  options={allUserDetails || null}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={advertiser}
                  size={'medium'}
                  className="cursor-pointer"
                  onChange={(e, value) => {
                    if (value && value.id !== advertiser?.id) {
                      setAdvertiser(value);
                      setSelectedItem(null);
                    } else if (value == null) {
                      dispatch(setSelectSuggestedKeywordTracking([]));
                      setAdvertiser(value);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  renderInput={(params) => <TextField {...params} placeholder="Select the profile" label="Profile Name" />}
                />
              </Grid>

              {/* <Grid item xs={12} md={12}>
              <Autocomplete
                id="checkboxes-tags-demo"
                loading={loader ? true : false}
                options={keywordItemOption ? keywordItemOption : null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={selectedItem}
                multiple={false}
                className="cursor-pointer"
                size={'medium'}
                onChange={(e, value) => {
                  console.log('value++++++++++++++++ ', value);
                  if (value?.length == 0 && advertiser?.id > 0) {
                    setKeywordItemOption(keywordItemData);
                  } else if (value == null) {
                    setSelectedItem(null);
                    return dispatch(setSelectSuggestedKeywordTracking([]));
                  }

                  setSelectedItem(value);
                }}
                getOptionLabel={(option) => `${option?.product_name} ${option?.item_id}`}
                renderOption={(props, option) => <li {...props}>{option.product_name}</li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Item"
                    label="Item"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loader ? <Loader /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
                disabled={advertiser && !loader ? false : true}
              />
            </Grid> */}

              <Grid item xs={12} md={12}>
                <Autocomplete
                  className="Custom-multiline-autocomplete"
                  multiple={true}
                  popupIcon={
                    <Box display={'flex'} mt={0.5} alignItems={'center'} justifyContent={'center'}>
                      <img style={{ margin: '5px' }} alt="dropdown icon" src={dropDownIcon} />{' '}
                    </Box>
                  }
                  options={keywordItemOption ? keywordItemOption : null}
                  loading={loader ? true : false}
                  value={selectedItem || []}
                  getOptionLabel={(option) => `${option?.product_name || []} ${option?.item_id || []}`}
                  filterOptions={(options, state) =>
                    options.filter((option) =>
                      `${option?.product_name} ${option?.item_id}`.toLowerCase().includes(state.inputValue.toLowerCase())
                    )
                  }
                  onChange={(e, value) => {
                    let tempval = [...value];
                    if (selectedItem?.length > 0) {
                      tempval = value.filter((obj) => obj.product_name !== selectedItem[0]?.product_name);

                      if (tempval?.length == 0 && advertiser?.id > 0) {
                        setKeywordItemOption(keywordItemData);
                      } else if (tempval == null) {
                        setSelectedItem(null);
                        return dispatch(setSelectSuggestedKeywordTracking([]));
                      }
                    }
                    setSelectedItem(tempval);
                  }}
                  renderOption={(props, option) => (
                    <ListItem {...props}>
                      <ListItemAvatar>
                        <Avatar src={option?.images} alt={option?.product_name} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={option?.product_name}
                        secondary={<span style={{ color: primaryMain }}>{option?.item_id}</span>}
                      />
                      {/* /   <Icon style={{ marginLeft: 'auto', color: primaryMain }} /> Add icon here */}.
                    </ListItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Item"
                      label="Item"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loader ? <Loader /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  renderTags={(selectedOptions, getTagProps) =>
                    selectedOptions.map((option, index) => (
                      <Chip
                        className="multiline-autocomple-chip"
                        key={index}
                        avatar={<Avatar alt={option?.product_name} src={option?.images} />}
                        label={
                          <ListItem>
                            <ListItemText
                              primary={option?.product_name}
                              secondary={<span style={{ color: primaryMain }}>{option?.item_id}</span>}
                            />
                          </ListItem>
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  disabled={advertiser && !loader ? false : true}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="secondary tabs example"
                      fullWidth
                      className="tabs-width"
                    >
                      <Tab
                        value="one"
                        sx={{ fontSize: '14px', fontWeight: 600, color: colors.grey500, width: '100%' }}
                        label="Suggested Search Term"
                        fullWidth
                      />
                      <Tab
                        value="two"
                        sx={{ fontSize: '14px', fontWeight: 600, color: colors.grey500, width: '100%' }}
                        label="Enter Search Terms"
                        fullWidth
                      />
                      <hr />
                    </Tabs>
                    <Typography>{renderContent()}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Tabs textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example">
                      <Tab value="one" sx={{ fontSize: '14px', fontWeight: 400, color: Dark }} label="Selected Search Terms" />
                    </Tabs>
                    <Typography textAlign={'end'}>
                      {' '}
                      <Typography>
                        {' '}
                        <Box>
                          {/* <MainCard sx={{ padding: 0 }}> */}
                          <SelectedKeywordTable />
                          {/* </MainCard> */}
                        </Box>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={OnClose} className={'actionBtnOutline'} sx={{ minWidth: '100px' }} variant="outlined" color="primary">
          Close
        </Button>
        <Button
          onClick={handleUploadKeyword}
          disabled={
            !keywordTrackerDetails ||
            keywordTrackerDetails.length === 0 ||
            !selectedItem ||
            selectedItem.length === 0 ||
            !advertiser ||
            advertiser.length === 0
          }
          className={'actionBtn'}
          sx={{ minWidth: '100px' }}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleItemAdd;
