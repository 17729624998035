import { Button, Divider, IconButton, InputAdornment, Link, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppSelector } from 'store';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useAppDispatch } from 'store';
import { getSelectFromItemsFromId, postSearchItems } from 'store/thunk/userThunk';
import SearchIcon from '@mui/icons-material/Search';
import { PageValue } from 'store/constant';

const SearchTable = (props) => {
  const { onAddAllClick, selectedData, setSelectedData, advertiser_id } = props;
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [searchItem, setSearchItem] = useState('');

  const { selectdItemData, allUserDetails, searchItems } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  const handleSearchClick = () => {
    if (searchItem?.length > 0) {
      const advertiserIds = allUserDetails?.find((item) => item?.id == advertiser_id);
      const _request = {
        advertiserId: advertiserIds?.advertiser_id,
        searchText: searchItem
      };
      dispatch(postSearchItems({ _request }));
    }
  };

  const columns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectdItemData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px' }} src={data?.images} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <Typography sx={{}}>{data?.product_name}</Typography> */}

                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'product_type',
      label: 'Item Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'price',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'item_id',
      label: 'Action',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const dataaa = selectdItemData[tableMeta?.rowIndex];

          const data = selectdItemData?.find((item) => item?.item_id == dataaa.item_id);
          const disable = selectedData?.find((item) => item?.item_id == dataaa.item_id);
          return (
            <>
              <IconButton
                sx={{ color: colors.secondaryMain }}
                disabled={disable}
                onClick={() => {
                  setSelectedData([...selectedData, { ...data, bid_amount: '0.20' }]);
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          );
        }
      }
    }
  ];

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    if (advertiser_id) {
      dispatch(getSelectFromItemsFromId({ advertiser_id, page, rowPerPage, search, changedColumn, direction }));
    }
  };

  const options = {
    filter: false,
    print: false,
    page: page,
    search: false,
    viewColumns: false,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: 'none',
    // tableBodyHeight: '350px',
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPage: rowPerPage,
    selectableRows: 'none',
    rowsPerPageOptions: PageValue,
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onChangePage: (page) => {
      setPage(page);
      getAdvertiserListWithPagination(page, rowPerPage, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    }
  };
  return (
    <>
      <Box sx={{ px: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <TextField
            sx={{ padding: 3, minWidth: '300px' }}
            id="outlined-basic"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
            placeholder="Search from item list"
            size="small"
            color="secondary"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" sx={{ cursor: 'pointer' }} onClick={handleSearchClick}>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Button
            disabled={selectdItemData?.length == searchItems?.length}
            variant="outlined"
            color="secondary"
            size="small"
            onClick={onAddAllClick}
          >
            Add All
          </Button>
        </Box>
        <Divider />

        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          sx={{ padding: 0 }}
          data={searchItems}
          columns={columns}
          options={options}
          className="custom-table-scroll pnl-by-item"
        />
      </Box>
    </>
  );
};

export default SearchTable;
