// ** MUI Imports
import { styled } from '@mui/material/styles'
import MuiBox from '@mui/material/Box'
import colors from 'assets/scss/_themes-vars.module.scss';
import { Typography } from '@mui/material';

// Styled Box component
const Box = styled(MuiBox)(() => ({
  width: 35,
  height: 35,
  borderWidth: 1.4,
  borderColor: colors.secondaryMain,
  borderRadius: '50%',
  borderStyle: 'solid',
  color: 'white'
  // borderColor: hexToRGBA(theme.palette.primary.main, 0.12)
}))

const StepperCustomDot = (props) => {
  // ** Props
  const {   icon, completed
    // completed, error 
  } = props
  return (
    <Box
        sx={{
          ...(completed && { background: colors.secondaryMain, border: 'none'}),

          display:'flex', alignItems: 'center', justifyContent: 'center'
        }}
      >
        <Typography variant="h3" sx={{color: completed ? 'white' : 'black'}}>{icon}</Typography>
        </Box>
  )
}

export default StepperCustomDot
