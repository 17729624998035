// assets
import AdvertisingIcon from 'assets/images/menu/AdvertisingIcon';
import AutomationIcon from 'assets/images/menu/AutomationIcon';
import HomeIcon from 'assets/images/menu/HomeIcon';
import RankTrackerIcon from 'assets/images/menu/RankTrackerIcon';
import ReportingIcon from 'assets/images/menu/ReportingIcon';
import SettingIcon from 'assets/images/menu/SettingIcon';
import HelpCenterIcon from 'assets/images/menu/HelpCenterIcon';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// report overview advertising
const routes = {
  id: 'routes',
  type: 'group',
  children: [
    {
      id: 'overview',
      title: 'Home',
      type: 'item',
      url: '/dashboard',
      icon: HomeIcon
    },
    {
      id: 'advertising',
      title: 'Advertising',
      type: 'collapse',
      icon: AdvertisingIcon,
      children: [
        {
          id: 'profile',
          title: 'Profiles',
          type: 'item',
          url: '/advertiser',
          breadcrumbs: false
        },
        {
          id: 'campaign',
          title: 'Campaigns',
          type: 'item',
          url: '/campaign',
          breadcrumbs: false
        },
        {
          id: 'adgroup',
          title: 'Ad Groups',
          type: 'item',
          url: '/adgroup',
          breadcrumbs: false
        },
        {
          id: 'keyword',
          title: 'Keywords',
          type: 'item',
          url: '/keyword',
          breadcrumbs: false
        },
        {
          id: 'items',
          title: 'Ad Items',
          type: 'item',
          url: '/items',
          breadcrumbs: false
        },
        {
          id: 'bulk-operations',
          title: 'Bulk Operations',
          type: 'item',
          url: '/bulk-operations',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'report',
      title: 'Reporting',
      type: 'collapse',
      icon: ReportingIcon,
      children: [
        {
          id: 'shared-dashboards',
          title: 'Shared Dashboard',
          type: 'item',
          url: '/shared-dashboards',
          breadcrumbs: false
        },
        {
          id: 'on-demand-reports',
          title: 'On Demand Reports',
          type: 'item',
          url: '/on-demand-reports',
          breadcrumbs: false
        },
        {
          id: 'dashboard-report',
          title: 'Dashboard Report',
          type: 'item',
          url: '/dashboard-report',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'automation',
      title: 'Automation',
      type: 'collapse',
      icon: AutomationIcon,
      children: [
        {
          id: 'budget-manager',
          title: 'Budget Manager',
          type: 'item',
          url: '/budget-manager',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'rank-tracker',
      title: 'Rank Tracker',
      type: 'item',
      url: '/rank-tracker',
      icon: RankTrackerIcon,
      breadcrumbs: false
    }
  ]
};
const settingsRoutes = {
  id: 'settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: 'Settings',
      type: 'collapse',
      icon: SettingIcon,
      children: [
        {
          id: 'store',
          title: 'Stores',
          type: 'item',
          url: '/store',
          breadcrumbs: false
        },
        {
          id: 'user-management',
          title: 'Users',
          type: 'item',
          url: '/user-management',
          breadcrumbs: false
        },
        {
          id: 'video-management',
          title: 'Video Management',
          type: 'item',
          url: '/video-management',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'help',
      title: 'Help Center',
      type: 'item',
      url: 'https://support.dashna.com/portal/en/home',
      icon: HelpCenterIcon,
      breadcrumbs: false,
      external: true,
      target: '_blank'
    }
  ]
};

export default { routes, settingsRoutes };
