import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ReportingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M3 8.99819C3 7.23017 3 5.46215 3 3.69413C3 2.55053 3.68738 1.5262 4.68603 1.15435C4.96812 1.04911 5.25993 1 5.55823 1C7.12753 1 8.70008 1 10.2694 1C10.8011 1 11.2551 1.19645 11.6344 1.60688C12.3477 2.38214 13.0643 3.1539 13.7809 3.92916C14.0176 4.18525 14.2575 4.43782 14.4909 4.69741C14.8249 5.07276 15 5.52179 15 6.04097C15 8.80175 15 11.559 15 14.3198C15 15.0003 14.7698 15.5967 14.3483 16.0983C13.9203 16.6105 13.3821 16.9017 12.7498 16.9753C12.3899 17.0174 12.0268 16.9929 11.6636 16.9929C9.6274 16.9929 7.59119 16.9999 5.55499 16.9929C4.63415 16.9894 3.90786 16.5649 3.39557 15.7335C3.13942 15.3161 3.00324 14.8495 3.00324 14.3444C3.00324 12.5623 3.00324 10.7802 3.00324 8.99469L3 8.99819Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.9082 11.7307V6.48975" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.99805 11.7309V8.74561" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.0898 11.7305V7.24731" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default ReportingIcon;
