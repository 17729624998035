import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Box } from '@mui/material';

const BudgetManager = (props) => {
  const { data, columns, options, className } = props;

  return (
    <Box className={className}>
      <MUIDataTable data={data} columns={columns} options={options} className="pnl-by-item" />
    </Box>
  );
};

export default BudgetManager;
