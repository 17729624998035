import { Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, FormControlLabel, FormGroup } from '@mui/material';
import { useDataState } from 'context/DataContext';
// import { useDataState } from "context/DataContext";
import CustomReportDatePicker from 'layout/MainLayout/DateRangePicker/customReportDatePicker';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppSelector } from 'store';
import colors from 'assets/scss/_themes-vars.module.scss';

const FilterBy = (props) => {
  const {
    chips,
    finalSendData,
    selectChipData,
    onCheckboxClick,
    handleSelect,
    handleChipClick,
    handleCancel,

    // showChipList,
    selectedChip,
    tempData,
    handleDelete,
    // last7DaysData,
    // setLast7DaysData,
    setStartDate,
    setEndDate,
    startDate,
    endDate
    // anchorElLast7,
  } = props;
  const { allFilterData } = useAppSelector((state) => state?.reportDataSlice);
  const { loading } = useAppSelector((state) => state?.dataLoading);
  const [datePickerShow, setDatePickerShow] = useState(false);
  const div2Style = {
    position: 'absolute',
    top: '-5px',
    width: '94px',

    backgroundColor: colors?.grey400,
    // padding: '10px',
    opacity: 0,
    pointerEvents: 'none',
    zIndex: -1
  };

  const handleDateOpenClick = () => {
    console.log('clicked1');
    setDatePickerShow(!datePickerShow);
  };

  const handleclog = () => {
    setDatePickerShow(false);
  };

  const handleChangeParent = () => {
    setDatePickerShow(false);
  };

  const [LabelDate, setLabelDate] = useState('Last 7 Days');

  const dataState = useDataState();
  useEffect(() => {
    setLabelDate(dataState.data);
  }, [dataState]);

  return (
    <>
      <Box
        className="chip-dropdown-block"
        sx={{
          padding: '10px 20px',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        <ClickAwayListener onClickAway={handleclog}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {chips &&
              chips?.length > 0 &&
              chips?.map((chip, index) => {
                return (
                  <Box className="chip-dropdown-wrraper" key={index}>
                    <Chip
                      key={index}
                      label={`${chip?.label} ${
                        finalSendData?.[chip?.value]?.length > 0 ? `(${finalSendData?.[chip?.value]?.length})` : ''
                      }`}
                      variant="outlined"
                      onClick={chip?.label == LabelDate ? handleDateOpenClick : () => handleChipClick(event, chip)}
                      onDelete={chip?.label == LabelDate ? '' : () => handleDelete(chip, 'filter')}
                      sx={{
                        marginRight: '15px',
                        background: colors?.secondaryMain,
                        color: 'white',
                        '&:hover': {
                          color: 'black'
                        }
                      }}
                    />
                    {chip?.label == LabelDate && (
                      <Box style={div2Style}>
                        <Box>
                          <CustomReportDatePicker
                            datePickerShow={datePickerShow}
                            id={'container1'}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            // style={{ width: '100%' }}
                            startDate={startDate}
                            handleChangeParent={handleChangeParent}
                            endDate={endDate}
                            block
                          />
                        </Box>
                      </Box>
                    )}
                    {selectChipData && selectedChip === chip?.label && (
                      <Box className="chip-dropdown measures-box" sx={{ overflow: 'auto' }}>
                        {allFilterData[`${chip?.value}`] && allFilterData[`${chip?.value}`]?.length > 0 ? (
                          allFilterData[`${chip?.value}`]?.map((item, index) => (
                            <FormGroup key={index}>
                              <FormControlLabel
                                checked={tempData && tempData?.length > 0 && tempData?.includes(item?.value)}
                                control={<Checkbox onChange={(e) => onCheckboxClick(e, item?.value)} />}
                                label={item?.label}
                              />
                            </FormGroup>
                          ))
                        ) : loading ? (
                          <CircularProgress />
                        ) : (
                          'No data found'
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'fixed',
                            width: '100%',
                            padding: '10px',
                            borderTop: `1px solid ${colors?.grey300}`,
                            left: 0
                            // bottom: 0,
                            // zIndex: 999,
                          }}
                        >
                          <Button variant="outlined" onClick={() => handleCancel(chip)}>
                            Cancel
                          </Button>
                          <Button variant="contained" onClick={() => handleSelect(chip, 'filter')} disabled={!tempData?.length}>
                            Select
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {/* {last7DaysData &&
                <ClickAwayListener onClickAway={handleCloseDatePciker}>
                  <Box>
                    <Box className="">
                      <CustomReportDatePicker
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        style={{ width: '100%' }}
                        startDate={startDate}
                        endDate={endDate} block />
                    </Box>
                  </Box>
                </ClickAwayListener>
              } */}
                  </Box>
                );
              })}
          </Box>
        </ClickAwayListener>
      </Box>
    </>
  );
};

export default FilterBy;
