import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import EditBrandSetting from '../editBrandSetting';
import CloseIcon from '@mui/icons-material/Close';
import { getCampaignById, updateBrandCampaignDetails } from 'store/thunk/userThunk';
import HideImageIcon from '@mui/icons-material/HideImage';
import { useAppDispatch } from 'store';

const CreativeSetting = (props) => {
  const { brandData, index, addButton } = props;
  const [disabled, setDisabled] = useState(false);
  const [brandEditModal, setBrandEditModal] = useState({ open: false, type: '', brandId: null });
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handledEditBrandClose = () => {
    setBrandEditModal({ open: false, type: '', brandId: null });
    const id = brandData?.campaign_id;
    if (id) {
      dispatch(getCampaignById({ id }));
    }
  };

  const handledStatusUpdate = (status) => {
    setDisabled(true);
    let formData = new FormData();
    formData.append('status', status == true ? 'enabled' : 'disabled');
    const brandId = brandData?.id;
    dispatch(updateBrandCampaignDetails({ formData, brandId })).then(() => {
      setDisabled(false);
      const id = brandData?.campaign_id;
      if (id) {
        dispatch(getCampaignById({ id }));
      }
    });
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography variant="h5" sx={{ display: 'flex' }}>
          {required && <Box sx={{ color: 'red' }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <form noValidate>
        {addButton && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" onClick={() => setBrandEditModal({ open: true, type: 'add', brandId: null })}>
              Add New Creative
            </Button>
          </Box>
        )}
        <Box sx={{ mt: 2, padding: '15px', border: '1px solid', borderColor: `${colors.grey300}`, borderRadius: '10px' }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h4" sx={{ mr: 2 }}>
                    Creative {index == 0 ? 'A' : 'B'}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={disabled}
                        onChange={(e, value) => handledStatusUpdate(value)}
                        checked={brandData?.status == 'enabled' ? true : false}
                      />
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {addButton && (
                    <Button
                      sx={{ mr: 1 }}
                      color="secondary"
                      variant="outlined"
                      size="small"
                      onClick={() => setBrandEditModal({ open: true, type: 'copy', brandId: null })}
                    >
                      Copy
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={() => setBrandEditModal({ open: true, type: 'edit', brandId: brandData?.id })}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6}>
              {renderLabel('Review Status', false)}
              <Typography>{brandData?.status}</Typography>
            </Grid>
            <Grid item sm={6}>
              {renderLabel('Review Reason', false)}
              <Typography>{brandData?.reviewReason ? brandData?.reviewReason : '--'}</Typography>
            </Grid>
            <Grid item sm={6}>
              {renderLabel('Brand Logo', false)}
              <Box sx={{ mt: 1 }}>
                {brandData?.logo && brandData?.logo != '/media/' ? (
                  <Box>
                    {' '}
                    <img
                      key={brandData?.name}
                      src={brandData?.logo}
                      alt={brandData?.name}
                      style={{
                        width: '150px',
                        height: '100px',
                        margin: '5px',
                        borderRadius: '10px',
                        border: `1px solid ${colors.grey500}`
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: '100px',
                      width: '150px',
                      border: `1px solid ${colors.grey500}`,
                      background: colors.grey200,
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    disableElevation
                    size="large"
                    variant="contained"
                    color="secondary"
                  >
                    <HideImageIcon />
                    <Typography sx={{ mt: 1 }}>Image Not Available</Typography>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item sm={6}>
              {renderLabel('Brand Name', false)}
              <Typography>{brandData?.name}</Typography>
            </Grid>

            <Grid item sm={12}>
              {renderLabel('Headline Text', false)}
              <Typography>{brandData?.headlinetext}</Typography>
            </Grid>

            <Grid item sm={12}>
              {renderLabel('Logo Click URL', false)}

              <Typography>{brandData?.url}</Typography>
            </Grid>
          </Grid>
        </Box>
      </form>
      {brandEditModal.open && (
        <Dialog
          open={brandEditModal.open}
          keepMounted
          onClose={handledEditBrandClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> {brandEditModal?.type == 'add' ? 'Add' : 'Edit'} Creative</Typography>
              <IconButton onClick={handledEditBrandClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditBrandSetting brandEditModal={brandEditModal} brandData={brandData} onClose={handledEditBrandClose} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CreativeSetting;
