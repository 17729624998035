import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
  CircularProgress,
  MenuItem,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  IconButton
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useForm, Controller } from 'react-hook-form';
import CustomTextField from 'ui-component/textField';
import ProfilePermissions from '../../ui-component/user/permissions/profilePermissions';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useAppSelector } from 'store';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect } from 'react';
import { userRoles } from 'store/constant';
import { styled } from '@mui/material/styles';
import { CustomSwitch } from 'ui-component/customSwitch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: 14
  }
});

const EditModal = (props) => {
  const { open, handleClose, updateSeller, sellerData, isLoading } = props;
  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedProfilesUpdatedFinal, setSelectedProfilesUpdatedFinal] = useState([]);

  // Define the email pattern
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const formSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').trim(),
    last_name: Yup.string().required('Last Name is required').trim(),
    email: Yup.string().required('Email is required').matches(emailPattern, 'Please add a valid email address').trim(),
    user_type: Yup.string().required('Please select User Type').trim()
  });

  //Set selected User Profile Advertiser list
  useEffect(() => {
    if (!sellerData?.id) return;
    const advertiser_access = sellerData?.advertiser_access;
    const getUserAdvertiserList = allUserDetails?.filter((l) => advertiser_access?.includes(l?.id));
    setSelectedProfiles(getUserAdvertiserList);
  }, [sellerData]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: sellerData,
    mode: 'onTouched',
    resolver: yupResolver(formSchema)
  });

  const onSubmit = (data) => {
    const allSelectedAdvIds = selectedProfilesUpdatedFinal?.map((l) => l?.id);
    data.advertiser_access = allSelectedAdvIds;

    updateSeller(data);
  };

  const isActive = watch('is_active');
  const selectedUserType = watch('user_type') || '';

  const tooltipUserType = `
  <div>
    Admin - Can manage all users, except the owner.<br/>
    User - Can view and edit data.<br/>
    Viewer - Can only view data.<br/>
  </div>
`;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          fontSize: '20px',
          position: 'sticky',
          top: '0',
          zIndex: '2',
          backgroundColor: '#fff',
          borderBottom: '1px solid #e3e8ef'
        }}
      >
        {sellerData?.id ? 'Edit' : 'Add'} User{' '}
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <CustomTextField
                label="First Name"
                type={'text'}
                control={control}
                rules={{ required: true }}
                name={'first_name'}
                helperText={errors.first_name?.message}
                errors={errors}
                fullWidth
                {...register('first_name')}
              />
            </Grid>
            <Grid item sm={6}>
              <CustomTextField
                label="Last Name"
                control={control}
                rules={{ required: true }}
                name={'last_name'}
                helperText={errors.last_name?.message}
                errors={errors}
                fullWidth
                {...register('last_name')}
              />
            </Grid>
            <Grid item sm={6}>
              <CustomTextField
                label="Email"
                control={control}
                rules={{ required: true }}
                helperText={errors.email?.message}
                name={'email'}
                disabled={sellerData?.id ? true : false}
                errors={errors}
                fullWidth
                {...register('email')}
              />
            </Grid>
            <Grid item sm={6} display={'flex'}>
              <Box flex={1}>
                <Controller
                  name={'user_type'}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      id="standard-select-currency"
                      {...field}
                      select
                      fullWidth
                      label="User Type"
                      error={!!errors.user_type}
                      helperText={errors.user_type?.message}
                      {...register('user_type')}
                    >
                      {userRoles.map((l, i) => {
                        return (
                          <MenuItem key={`userRoles_${i}`} value={l?.value}>
                            {l?.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />
              </Box>
              <Box>
                <CustomWidthTooltip arrow title={<Box dangerouslySetInnerHTML={{ __html: tooltipUserType }} />}>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </CustomWidthTooltip>
              </Box>
            </Grid>
            {sellerData?.id && (
              <Grid item sm={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Tooltip arrow title={isActive ? 'Active' : 'Inactive'}>
                        <CustomSwitch
                          label="Active"
                          className='CustomSwitchToggle'
                          checked={isActive}
                          onChange={(e, value) => {
                            setValue('is_active', value);
                          }}
                        />
                      </Tooltip>
                    }
                    label="User Status"
                  />
                </FormGroup>
              </Grid>
            )}
            <Grid item sm={12} mt={3}>
              <Typography variant="h4" mb={2} sx={{ fontSize: '1.2rem' }}>
                Profile Permissions
              </Typography>
              <Box sx={{ border: '1px solid #ccc', borderRadius: '10px' }}>
                <ProfilePermissions
                  allProfiles={allUserDetails}
                  selectedUserType={selectedUserType}
                  setSelectedProfilesUpdatedFinal={setSelectedProfilesUpdatedFinal}
                  selectedProfiles={selectedProfiles}
                  userInfo={sellerData}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            fontSize: '20px',
            position: 'sticky',
            bottom: '0',
            zIndex: '2',
            backgroundColor: '#fff',
            borderTop: '1px solid #e3e8ef'
          }}
        >
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="15px" />}
            {sellerData?.id ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditModal;
