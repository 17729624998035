import { Button, Typography, Grid, Switch } from '@mui/material';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useEffect } from 'react';
import { Loader } from 'rsuite';
import { CloudDownload } from '@mui/icons-material';
import { useAppDispatch } from 'store';
// import * as React from "react";
import { styled } from '@mui/material/styles';
// import Switch from '@mui/material/Switch';
import colors from 'assets/scss/_themes-vars.module.scss';

const SecondarySwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: colors?.paper,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? colors?.secondaryMain : colors?.secondaryMain,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    marginLeft: 2
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? colors?.secondaryMain : colors?.secondaryMain,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

// import { YellowSwitch } from 'ui-component/switch/tableSwitch';
import { formatDataCustomReport, useFormattedDataOnce } from 'utils/utilsFunction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DownloadCustomReportData } from 'store/thunk/userThunk';
import { useAppSelector } from 'store';

const CustomTable = ({ handleChangeColorTable, ...props }) => {
  const { splitChips, allReportData, setSplitChips, customLoading, measureChips, mdSize, checkedColorTable } = props;
  const dispalyTitles = splitChips
    ?.map((obj) => ({ ...obj, label: obj.label === 'Day (Days)' ? 'Date' : obj.label }))
    .map((obj) => obj.label)
    .join(',');
  const [mainData, setMainData] = useState([]);
  const [displayColum, setDisplayColumn] = useState([]);
  const [displayColumCollapse, setDisplayColumnCollapse] = useState([]);
  const [collapseData, setCollapseData] = useState([]);
  const dispatch = useAppDispatch();
  const { allReportDataDownload } = useAppSelector((state) => state?.reportDataSlice);

  const { userData } = useAppSelector((state) => state.authorization);

  const user = userData?.permission?.custom_report || {};
  const downloadCustomReport = user?.download || {};
  useEffect(() => {
    if (checkedColorTable) {
      setCollapseData(allReportData?.data);
    } else {
      if (allReportData?.data) {
        let tempArray = [];
        allReportData?.data?.SPLIT?.data?.map((val) => {
          tempArray?.push({
            ...val,
            SPLIT: null,
            change_title: {
              title: val?.[allReportData?.data?.SPLIT?.keys],
              margin: '0px'
            }
          });
          if (val?.SPLIT?.data) {
            val?.SPLIT?.data?.map((item) => {
              tempArray?.push({
                ...item,
                SPLIT: null,
                change_title: {
                  title: item?.[val?.SPLIT?.keys],
                  margin: '5%'
                }
              });
              // change_title: item?.[val?.SPLIT?.keys] });
              if (item?.SPLIT?.data) {
                item?.SPLIT?.data?.map((value) => {
                  tempArray?.push({
                    ...value,
                    SPLIT: null,
                    change_title: {
                      title: value?.[item?.SPLIT?.keys],
                      margin: '20%'
                    }
                  });
                  // change_title: value?.[item?.SPLIT?.keys] });
                });
              }
            });
          }
        });
        // let newArrayFromB = Array.isArray(allReportData?.data) ? allReportData.data.map(item => ({ ...item })) : null;
        // console.log(newArrayFromB, "newArrayFromB");

        // setCollapseData(allReportData?.data ,"allReportData?.data")
        // console.log("tempArray===== ", tempArray);
        let mainRowData = [
          {
            ...(allReportData?.data ? { ...allReportData?.data, change_title: { title: 'Total', margin: '0%' } } : '')
          },
          ...tempArray
        ];
        setMainData(mainRowData);
      } else {
        // setCustomReportData([]);
        setMainData([]);
      }
    }
  }, [allReportData]);

  // let mainRowData = [
  //   {
  //     ...(allReportData?.data ? { ...allReportData?.data, change_title: { title: "Total", margin: "0%" } } : "")
  //   },
  //   ...customReportData];
  // console.log("mainRowData ", mainRowData);

  const columns = [
    {
      name: 'change_title',
      label: dispalyTitles,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography
              sx={{
                marginLeft: value?.margin,
                color: value?.title !== 'Total' && '#0076e6'
              }}
            >
              {value?.title}
            </Typography>
          );
        }
      }
    },
    {
      name: 'impression',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return <Typography>{value}</Typography>;
        // }
      }
    },
    {
      name: 'ad_spend',
      label: 'Ad Spend',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return <Typography>{`${value ? `$ ${value}` : 0}`}</Typography>;
        // }
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return <Typography>{`${value ? `${value} %` : 0}`}</Typography>;
        // }
      }
    },
    {
      name: 'cvr_units',
      label: 'CVR Units',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'roas',
      label: 'ROAS',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`$ ${value ? `${value}` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'advertised_sku_sales_14days',
      label: 'Adverstised SKU Sales',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'other_sku_sales_14days',
      label: 'Other SKU Sales',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'advertised_sku_units_14days',
      label: 'Advertised SKU Units 14 Days',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'other_sku_units_14days',
      label: 'Other SKU Units 14 Days',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      label: 'CVR Order 14 days',
      name: 'cvr_orders_14_days',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      label: 'ROAS 14 days',
      name: 'roas_14_days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'CVR units 30 days',
      name: 'cvr_units_30_days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'CVR Orders 30 days',
      name: 'cvr_orders_30_days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'ROAS 30 days',
      name: 'roas_30_days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'CVR Units 3days',
      name: 'cvr_units_3days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'CVR Orders 3days',
      name: 'cvr_orders_3days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'ROAS 3days',
      name: 'roas_3days',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      label: 'CVR units 14 days',
      name: 'cvr_units_14_days',
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  useEffect(() => {
    if (checkedColorTable) {
      let results = columns11.filter((objB) => {
        return measureChips.some((objA) => objA.label === objB.label);
      });
      if (results.length > 0) {
        {
          splitChips &&
            splitChips.forEach((obj) => {
              const exists = results.some((item) => item.name === obj.value);
              const shouldExclude = obj.value === 'date'; // Add any other exclusion conditions here

              if (!exists && !shouldExclude) {
                results.unshift({
                  name: obj.value,
                  label: obj.label,
                  options: {
                    filter: true,
                    sort: true
                  }
                });
              }
            });
        }
        results.unshift({
          name: 'date',
          label: 'Date',
          options: {
            filter: true,
            sort: true
          }
        });
      }
      setDisplayColumnCollapse(results);
    } else {
      let result = columns.filter((objB) => {
        return measureChips.some((objA) => objA.label === objB.label);
      });
      if (
        result.length > 0 &&
        !result.includes({
          name: 'change_title',
          label: dispalyTitles,
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
              return (
                <Typography
                  sx={{
                    marginLeft: value?.margin,
                    color: value?.title !== 'Total' && '#0076e6'
                  }}
                >
                  {value?.title}
                </Typography>
              );
            }
          }
        })
      ) {
        result.unshift({
          name: 'change_title',
          label: dispalyTitles,
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
              return (
                <Typography
                  sx={{
                    marginLeft: value?.margin,
                    color: value?.title !== 'Total' && '#0076e6'
                  }}
                >
                  {value?.title}
                </Typography>
              );
            }
          }
        });
      }
      setDisplayColumn(result);
    }
  }, [measureChips, checkedColorTable, allReportData]);

  const onAddDate = () => {
    setSplitChips([{ label: 'Day (Days)', value: 'date' }]);
  };

  const noDataFound = () => {
    return (
      <Box>
        <Typography>This Table requires at least one split</Typography>
        <Typography variant="h5">Please add date to the split.</Typography>
        <Button onClick={onAddDate} sx={{ backgroundColor: '#0076e6', color: 'white' }}>
          Add Date
        </Button>
      </Box>
    );
  };

  const selectAtleastOneMeasure = () => {
    return (
      <Box>
        <Typography>At least one measure should be selected</Typography>
        <Typography variant="h5">Please select measures from the card in the left.</Typography>
      </Box>
    );
  };

  const handleDownloadCsv = () => {
    if (allReportDataDownload?.length > 0) {
      const request = allReportDataDownload;
      dispatch(DownloadCustomReportData(request));
    }
  };

  const options = {
    filter: false,
    print: false,
    viewColumns: false,
    align: 'center',
    // selectableRows: 'none',
    // expandableRows:true,
    // resizableColumns:true,
    // page: 1,
    search: false,
    count: mainData?.length || 0,
    jumpToPage: false,
    download: false,
    pagination: false,
    serverSide: false,
    selectableRows: 'none',
    customToolbar: () => (
      <>
        {downloadCustomReport && (
          <Button sx={{ pl: '30px' }}>
            <CloudDownload sx={{ marginRight: '20px' }} onClick={handleDownloadCsv} />
          </Button>
        )}
      </>
    ),
    textLabels: {
      body: {
        noMatch: customLoading ? (
          <Loader />
        ) : mainData?.length === 0 ? (
          noDataFound()
        ) : measureChips?.length === 0 ? (
          selectAtleastOneMeasure()
        ) : (
          ''
        )
      }
    }
    // rowsPerPage: rowPerPage,
    // rowsPerPageOptions: [10, 20, 50],
    // expandableRows: true,
    // renderExpandableRow: (rowData, rowMeta) => {
    //   console.log('rowData', rowData, rowMeta);
    //   return (
    //     <TableRow>
    //       <TableCell></TableCell>
    //       <TableCell>abcd</TableCell>
    //       <TableCell>abcd</TableCell>
    //       <TableCell>abcd</TableCell>
    //       <TableCell>abcd</TableCell>
    //     </TableRow>
    //   );
    // },
  };

  const columns11 = [
    {
      name: 'impression',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'ad_spend',
      label: 'Ad Spend',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'cvr_units',
      label: 'CVR Units',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'roas',
      label: 'ROAS',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`$ ${value ? `${value}` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'advertised_sku_sales_14days',
      label: 'Adverstised SKU Sales',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'other_sku_sales_14days',
      label: 'Other SKU Sales',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'advertised_sku_units_14days',
      label: 'Advertised SKU Units 14 Days',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    },
    {
      name: 'other_sku_units_14days',
      label: 'Other SKU Units 14 Days',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value) => {
        //   return (<Typography>{`${value ? `${value} %` : ""}`}</Typography>)
        // },
      }
    }
  ];

  const displayDataForCollapse = useFormattedDataOnce(collapseData, formatDataCustomReport);

  const displayDataForSplit = useFormattedDataOnce(mainData, formatDataCustomReport);

  return (
    <Grid item md={12 - mdSize}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: '14px' }}>
            Table Report{' '}
            <Typography
              sx={{ display: 'flex', alignItems: 'center', color: colors?.secondaryMain, fontSize: '30px', marginLeft: '4px' }}
            >
              <ArrowDropDownIcon fontSize="large" />
            </Typography>
          </Typography>
        </Box>
        {splitChips?.length > 1 && (collapseData.length > 0 || mainData.length > 0) && (
          <Box className="custom-switch-table" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginRight: '8px' }}>{checkedColorTable ? 'Collapse' : 'Split'}</Typography>
            {/* <Switch checked={checkedColorTable} onChange={handleChangeColorTable} value="checked" /> */}
            <SecondarySwitch {...label} checked={checkedColorTable} onChange={handleChangeColorTable} value="checked" />
          </Box>
        )}
      </Box>
      <Box
        boxShadow={2}
        mt={1}
        sx={{
          height: '300px',
          overflowY: 'scroll',
          overflowX: 'visible',
          // display:"flex",
          '&::-webkit-scrollbar': {
            width: '0.5rem',
            backgroundColor: '#C5C5C5',
            height: '20px'
          },
          '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7A8183',
            outline: '1px solid slategrey'
          }
          // border: '1px solid gray !important'
        }}
      >
        {checkedColorTable ? (
          <MUIDataTable
            className="custom-report-table pnl-by-item"
            actions={[
              {
                icon: 'save'
              }
            ]}
            data={displayDataForCollapse}
            columns={displayColumCollapse}
            options={options}
          />
        ) : (
          <MUIDataTable
            className="custom-report-table pnl-by-item"
            actions={[
              {
                icon: 'save'
              }
            ]}
            data={displayDataForSplit}
            columns={displayColum}
            options={options}
          />
        )}
      </Box>
    </Grid>
  );
};

export default CustomTable;
