import { Box, Tab } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { useAppDispatch } from 'store';
import { /* getAlldvertiserList, */ getCampaignById } from 'store/thunk/userThunk';
import CampaignSetting from './campaignSetting';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import CreativeSetting from './creativeSetting';
import { useAppSelector } from 'store';
import { CAMPAIGN } from 'constant/campaignConstant';

const EditCampaignBrandSetting = (props) => {
    const { campaignModal, onClose } = props;

    const {  campaignById } = useAppSelector((state) => state?.userDetailsSlice);

    const dispatch = useAppDispatch();
    const [activeIndex, setActiveIndex] = useState(0)
    const [campaign, setCampaign] = useState({
        budget_type: "both",
        targeting_type: CAMPAIGN.TARGETING_TYPE.AUTO,
        name: "",
        advertiser: "",
        daily_budget: "",
        total_budget: "",
        start_date: "",
        end_date: "",
        status: "proposal"
      })
      const [value, setValue] = useState(1);
    
    
      const handleChange = (event, newValue) => {
          setValue(newValue);
      };

    useEffect(() => {
        window.scrollTo(0, 0);
        // let data = "";
        // dispatch(getAlldvertiserList({ data }));
    }, [])
    
    useEffect(() => {
        const id = campaignModal.id;
        if(id){
            dispatch(getCampaignById({id}))
        }
    }, [campaignModal])

    return (
        <>
            <TabContext value={value}>
                <Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example"
                            TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}>
                            <Tab label="Campaign Setting" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value={1} />
                            {(campaignById?.brand?.length !== 0) && <Tab label="Creative Setting" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value={2} />}
                        </TabList>
                    </Box>
                    <TabPanel value={1} sx={{ padding: 0 }}><CampaignSetting campaign={campaign} setCampaign={setCampaign} onClose={onClose} /></TabPanel>
                    {(campaignById?.brand?.length !== 0) && (<TabPanel value={2} sx={{ padding: 0 }}>
                        {campaignById && campaignById?.brand?.map((item, index)=>(
                        <CreativeSetting index={index} brandData={item} key={index} addButton={(campaignById?.brand?.length == 1) ? true : false}
                        activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                    )) }
                    </TabPanel>)}
                </Box>
            </TabContext>
            
        </>
    );
};

export default EditCampaignBrandSetting;
