import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, useMediaQuery, CircularProgress, FormHelperText } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import MainCard from 'ui-component/cards/MainCard';
import AccountPassword from './accountPassword';
import TextField from 'ui-component/textField';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { getProfileDetails, updateProfie, updateProfileDetails } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone';
import colors from 'assets/scss/_themes-vars.module.scss';
import { userRolesForFilter } from 'store/constant';

const AccountSettings = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const superAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const appDispatch = useAppDispatch();
  const { profileDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  useEffect(() => {
    appDispatch(getProfileDetails());
  }, []);

  const formSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required').trim(),
    last_name: Yup.string().required('Last name is required').trim()
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema)
  });

  useEffect(() => {
    if (profileDetails) {
      setValue('first_name', profileDetails?.first_name);
      setValue('last_name', profileDetails?.last_name);
      setValue('email', profileDetails?.email);
      setValue('company_name', profileDetails?.company_name);
      setSelectedPhoto(profileDetails?.profile);

      const userTypeId = userData?.user_type;
      const userTypeName = userRolesForFilter?.find((l) => l?.value === userTypeId)?.name;
      setValue('user_type', userTypeName);
    }
  }, [profileDetails]);

  const { getRootProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 204800,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg']
    },
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          setSelectedPhoto(URL.createObjectURL(file));
          setValue('profile', file);
        };
      }
    },
    onDropRejected: () => {
      dispatch(notificationFail('Profile image is not uploaded.'));
    }
  });

  const profileImage = watch('profile');

  // updatePassword function
  const updatePassword = (data) => {
    setLoading(true);
    let _request = {
      old_password: data?.old_password,
      new_password: data?.new_password,
      confirm_password: data?.confirm_password
    };
    appDispatch(updateProfie({ _request }));
    setLoading(false);
  };

  const onSubmit = (data) => {
    setLoading(true);
    setPasswordLoading(true);
    let formData = new FormData();

    formData.append('first_name', data?.first_name);
    formData.append('last_name', data?.last_name);
    if (profileImage) {
      formData.append('profile', data?.profile);
    }
    setPasswordLoading(false);
    appDispatch(updateProfileDetails({ formData })).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <MainCard sx={{ border: 'none' }} title="Account Settings">
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ mt: 2 }} container spacing={matchDownSM ? 0 : 2}>
              <Grid item sm={12}>
                {selectedPhoto ? (
                  <Box {...getRootProps({ className: 'dropzone' })}>
                    {' '}
                    <img
                      key={selectedPhoto.name}
                      src={selectedPhoto}
                      alt={selectedPhoto.name}
                      style={{
                        width: '150px',
                        height: '100px',
                        margin: '5px',
                        borderRadius: '10px',
                        border: `1px solid ${colors.grey500}`
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: '100px',
                      width: '150px',
                      border: `1px dashed ${colors.grey500}`,
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    disableElevation
                    size="large"
                    variant="contained"
                    color="secondary"
                    {...getRootProps({ className: 'dropzone' })}
                  >
                    <AddIcon sx={{ color: colors.grey500 }} />
                  </Box>
                )}
                {errors?.profile && <FormHelperText error>{errors?.profile}</FormHelperText>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  control={control}
                  rules={{ required: true }}
                  helperText={errors.first_name?.message}
                  name={'first_name'}
                  errors={errors}
                  fullWidth
                  {...register('first_name', { value: '' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  control={control}
                  rules={{ required: true }}
                  helperText={errors.last_name?.message}
                  name={'last_name'}
                  errors={errors}
                  fullWidth
                  {...register('last_name', { value: '' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email Address / Username"
                  control={control}
                  rules={{ required: true }}
                  disabled
                  name={'email'}
                  errors={errors}
                  fullWidth
                  {...register('email', { value: '' })}
                />
              </Grid>
              {!superAdmin ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Name"
                    control={control}
                    rules={{ required: true }}
                    disabled
                    name={'company_name'}
                    errors={errors}
                    fullWidth
                    {...register('company_name', { value: '' })}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="User Type"
                  control={control}
                  rules={{ required: true }}
                  disabled
                  name={'user_type'}
                  errors={errors}
                  fullWidth
                  {...register('user_type', { value: '' })}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: 'flex' }}>
              <AnimateButton>
                <Button disableElevation disabled={loading} size="large" type="submit" variant="contained" color="secondary">
                  {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                  Save
                </Button>
              </AnimateButton>
            </Box>
          </form>
        </>
      </MainCard>
      <AccountPassword updatePassword={updatePassword} passwordLoading={passwordLoading} />
    </>
  );
};

export default AccountSettings;
