import Check from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Box, Button, FormHelperText, TextField, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  // getAlldvertiserList,
  getBulkOperation,
  postVerifyCampaignFile
} from 'store/thunk/userThunk';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import MainCard from 'ui-component/cards/MainCard';
import BulkOperationModal from './bulkOperationResult/bulkOperationModal';
import colors from 'assets/scss/_themes-vars.module.scss';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool
};

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    borderLeft: '#a6d7ff 1px solid',
    marginLeft: '12px'
  }
}));

const ColorlibStepIconRoot = styled('div')(() => ({
  zIndex: 1,
  color: colors?.secondaryMain,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  border: `${colors?.secondaryMain} 1px solid`,
  justifyContent: 'center',
  alignItems: 'center'
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const BulkOperation = () => {
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [advertiser, setAdvertiser] = React.useState([]);
  const [advertiserWarning, setAdvertiserWarning] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [existingPopup, setExistingPopup] = React.useState(false);
  const fileInputRef = React.useRef(null);

  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  //Permission checking
  const bulkOperation = userData?.permission?.bulk_operation || {};
  const bulkOperationAccess = bulkOperation?.access;

  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    // const data = null;
    // dispatch(getAlldvertiserList({ data }));
  }, []);

  const downloadTemplete = () => {
    dispatch(getBulkOperation())
      .then((res) => {
        const fileLink = res?.payload?.data;
        const blob = new Blob([fileLink], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const fileName = `Bulk_Campaign_Creation_Template.xlsx`;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const onButtonClick = () => {
    fileInputRef.current.click();
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    if (advertiser?.length > 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append('advertiser_id', advertiser?.map((item) => item?.id)?.join(','));
      formData.append('campaign_file', selectedFile, selectedFile.name);
      dispatch(postVerifyCampaignFile({ formData })).then(() => {
        setLoading(false);
        setAdvertiser([]);
        setSelectedFile(null);
        fileInputRef.current.value = null;
        navigate('/bulk-operations-result', {
          state: { advertiser: advertiser, selectedFile: selectedFile, isHideSubmit: true }
        });
      });
    } else {
      setAdvertiserWarning(true);
    }
  };

  const renderLabel = (name) => {
    return (
      <Box>
        <Typography variant="h6" sx={{ padding: 0.5, display: 'flex' }}>
          {name}
        </Typography>
      </Box>
    );
  };

  const downloadExisting = () => {
    setExistingPopup(true);
  };

  const steps = [
    {
      label: (
        <Box maxWidth={'400px'}>
          {renderLabel('Please select the profile you want to update & create', true)}
          <Autocomplete
            id="checkboxes-tags-demo"
            // multiple
            multiple={true}
            options={allUserDetails}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={advertiser}
            size={'small'}
            onChange={(e, value) => {
              setAdvertiserWarning(false);
              setAdvertiser(value);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            renderInput={(params) => <TextField {...params} placeholder="Select the profile" />}
            disabled={!bulkOperationAccess}
          />
          {advertiserWarning && (
            <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
              Please select profile
            </FormHelperText>
          )}
        </Box>
      )
    },
    {
      label: (
        <>
          <Typography variant="h6" sx={{ padding: 0.5, display: 'flex' }}>
            Create & download a custom spreadsheet
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button disabled={!bulkOperationAccess} onClick={downloadExisting} sx={{ border: `${colors?.secondaryMain} 1px solid`, color: colors?.secondaryMain }}>
              Download existing data for editing
            </Button>
            <Button disabled={!bulkOperationAccess} onClick={downloadTemplete} sx={{ border: `${colors?.secondaryMain} 1px solid`, color: colors?.secondaryMain }}>
              Download template for creation
            </Button>
          </Box>
        </>
      )
    },
    // {
    //   label: (
    //     <>
    //       <Typography variant="h6" sx={{ padding: 0.5, display: 'flex' }}>
    //         Edit your file
    //       </Typography>
    //       <Typography sx={{ pl: 0.5 }} variant="subtitle2">
    //         Guidelines for editing bulk spreadsheets:
    //       </Typography>
    //       <Typography sx={{ pl: 0.5 }} variant="subtitle2">
    //         Make sure the items you apply are base items.
    //       </Typography>
    //       <Typography sx={{ pl: 0.5 }} variant="subtitle2">
    //         Make sure to apply the correct match type to your keyword
    //       </Typography>
    //     </>
    //   )
    // },
    {
      label: (
        <>
          <Typography variant="h6" sx={{ padding: 0.5, display: 'flex' }}>
            Upload your file to update or create campaigns
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <input ref={fileInputRef} style={{ display: 'none' }} type="file" accept=".xls, .xlsx" onChange={onFileChange} />
            {!selectedFile ? (
              <Button
                onClick={onButtonClick}
                disabled={!bulkOperationAccess}
                variant='contained'
                color="primary"
               
              >
                Choose File
              </Button>
            ) : (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <LoadingButton
                  onClick={onFileUpload}
                  loading={loading}
                  sx={{
                    color: '#fff',
                    backgroundColor: colors?.secondaryMain,
                    px: '20px',
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: colors?.secondaryMain
                    }
                  }}
                >
                  Upload
                </LoadingButton>
                <Button
                  onClick={() => {
                    fileInputRef.current.value = null;
                    setSelectedFile(null);
                  }}
                  sx={{
                    color: colors?.secondaryMain,
                    border: `${colors?.secondaryMain} 1px solid`,
                    px: '20px',
                    '&:hover': {
                      color: colors?.secondaryMain
                    }
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
          <Typography sx={{ pl: 0.5, pt: 2 }} variant="subtitle2">
            Supported file types: .xlsx & .xls
          </Typography>
        </>
      )
    }
  ];

  return (
    <>
      <MainCard title="Bulk Operations">
        <Box>
          <Typography variant="h5">Updates your campaigns or create new campaigns using a spreadsheets.</Typography>
        </Box>
        <Box>
          <Link
            to={bulkOperationAccess ? "/bulk-operations-list" : ''}
            variant="h5"
            style={{ color: colors?.secondaryMain, cursor: 'pointer', display: 'flex', aligntems: ' center', marginTop: '10px' }}
          >
            <CopyAllIcon sx={{ marginRight: '10px' }} />
            View Log
          </Link>
        </Box>
        <Box>
          <Stepper sx={{ mt: 2 }} orientation="vertical" connector={<QontoConnector />}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ p: 0 }}>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </MainCard>
      {existingPopup && (
        <BulkOperationModal open={existingPopup} onClose={() => setExistingPopup(false)} advertiserData={advertiser} />
      )}
    </>
  );
};

export default BulkOperation;
