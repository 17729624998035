import { TextField } from '@mui/material';
// import OutlinedDiv from './Outline';
import { Box } from '@mui/system';
import { useState } from 'react';
import OutlinedDiv from 'views/filterPerformance/Outline';
// import { useEffect } from 'react';

const FilterAdvanced = (props) => {
  // const [showError, setShowError] = useState(false);
  const { AdvanceItem, handleInputChangeAdvanced } = props;

  const [min, setMin] = useState();
  const [max, setMax] = useState();

  const handleKeyPress = (e) => {
    // Prevent the input of '-' character
    if (e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  };
  // useEffect(() => {
  //   if (min?.length > 0 && max?.length > 0 && Number(min) > Number(max)) {
  //     setShowError(true);
  //   } else {
  //     setShowError(false);
  //   }
  // }, [min, max]);

  return (
    <div key={AdvanceItem?.id} className="performance-wrapper">
      <OutlinedDiv showError={min?.length > 0 && max?.length > 0 && Number(min) > Number(max)} label={AdvanceItem?.name}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            sx={{
              '& fieldset': { border: 'none' }
              // padding: '0px !important',
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            id="minPrice"
            placeholder="Min"
            className="ddd"
            size="small"
            onKeyPress={handleKeyPress}
            disableFocusRipple
            onChange={(e) => {
              handleInputChangeAdvanced(
                AdvanceItem.value,
                'min',
                e.target.value || '',
                e.target.value?.length > 0 && max?.length > 0 && Number(e.target.value) > Number(max)
              );
              setMin(e.target.value);
            }}
            style={{ width: '45%', border: 'none', borderRadius: '5px', padding: '0px', outline: 'none', fontSize: '12px' }}
          />
          ~
          <TextField
            type="number"
            id="maxPrice"
            sx={{
              '& fieldset': { border: 'none' },
              padding: '2.5px 4px 2.5px 8px !important'
            }}
            size="small"
            className="ddd"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            disableFocusRipple
            onKeyPress={handleKeyPress}
            onChange={(e) => {
              handleInputChangeAdvanced(
                AdvanceItem.value,
                'max',
                e.target.value || '',
                min?.length > 0 && e.target.value?.length > 0 && Number(min) > Number(e.target.value)
              );
              setMax(e.target.value);
            }}
            placeholder="Max"
            style={{ width: '45%', border: 'none', borderRadius: '5px', padding: '2px', outline: 'none', fontSize: '12px' }}
          />
        </Box>
      </OutlinedDiv>
    </div>
  );
};

export default FilterAdvanced;
