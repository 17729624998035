import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "store";
import { verifyUser } from "store/thunk/authThunk";
import Loader from "ui-component/Loader";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const useridb64 = queryParameters.get("useridb64")
        const otpb64 = queryParameters.get("otpb64")
        
        if (otpb64 && useridb64) {
          let _request = {
            useridb64: useridb64,
            otpb64: otpb64,
            navigate: navigate
          }
          dispatch(verifyUser(_request))
        }

    }, []);
  
  return (
    <><Loader /></>
  );
};

export default VerifyOtp;
