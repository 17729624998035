import MainCard from 'ui-component/cards/MainCard';
import { Grid, Select, MenuItem, Box, Typography, Button, CircularProgress } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../store';
import {
  SendAllReport,
  // SendAllReport,
  // getAlldvertiserList,
  getCampaignReportData
} from '../../../store/thunk/userThunk';
import { useEffect, useState } from 'react';
import { attributeWindowList } from 'store/constant';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import CampaignReportList from './CampaignReportList';
import AdItemReportList from './AdItemReportList';
import KeywordReportList from './KeywordReportList';
import AdgroupReportList from './AdgroupReportList';
import DownloadIcon from '@mui/icons-material/Download';
import { notificationFail, notificationSuccess } from 'store/slices/notificationSlice';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  setAdGroupReportData,
  setAdItemReportData,
  setCampaignReportData,
  setKeywordReportData
} from 'store/slices/reportDataSlice';
// import // setCampaignReportData,
// // setAdItemReportData,
// // setKeywordReportData,
// // setAdGroupReportData
// 'store/slices/reportDataSlice';

function CampaignReport() {
  const { pathname } = window.location;
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();
  const [DownloadReportData, setDownloadReportData] = useState();
  const [attribute, setAttribute] = useState(14);
  const [advertiser, setAdvertiser] = useState('');
  const [reportApiPath, setReportApiPath] = useState('');
  const [reportName, setReportName] = useState('');
  const [dateRange, setDateRange] = useState(dayjs(new Date()));
  const [tableLoading, setTableLoading] = useState(false);
  const [inProcess, setInProcess] = useState({
    searchLoader: false,
    downloadLoader: false
  });

  const { commonAdvertiser } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  const user = userData?.permission?.default_report || {};
  const downloadReports = user?.download;

  useEffect(() => {
    setAttribute(14);
    setAdvertiser('');
    setDateRange(dayjs(new Date()));
    setInProcess({
      searchLoader: false,
      downloadLoader: false
    });

    // Clear Data From Store
    dispatcher(setAdItemReportData([]));
    dispatcher(setCampaignReportData([]));
    dispatcher(setKeywordReportData([]));
    dispatcher(setAdGroupReportData([]));

    switch (pathname) {
      case '/campaign-report':
        setReportApiPath('campaign-performance-report');
        setReportName('Campaign Reports');
        setDownloadReportData('campaign');
        // setDownloadReportData(campaignReportData);
        return;
      case '/adgroup-report':
        setReportApiPath('adgroup-report-list');
        setReportName('AdGroup Reports');
        setDownloadReportData('adgroup');
        // setDownloadReportData(adGroupData);
        return;
      case '/item-report':
        setReportApiPath('aditem-report-list');
        setReportName('AdItem Reports');
        setDownloadReportData('aditem');
        // setDownloadReportData(adItemsData);
        return;
      case '/keyword-report':
        setReportApiPath('keyword-report-list');
        setReportName('Keyword Reports');
        setDownloadReportData('keyword');
        // setDownloadReportData(adKeywordsData);
        return;
    }
  }, [pathname]);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  function format_date(date) {
    return date.format('YYYY-MM');
  }

  // const onAdvertiserChange = (event) => {
  //   delete fieldErrors.advertiser;
  //   setFieldErrors({ ...fieldErrors });
  //   setAdvertiser(event.target.value);
  // };

  useEffect(() => {
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
    setAdvertiser(advertiserFilter);
  }, [commonAdvertiser]);

  const onAttributeChange = (event) => {
    setAttribute(event.target.value);
  };

  const onDateRangeChange = (value) => {
    setDateRange(value);
  };

  const validateSubmitRequest = () => {
    const errors = {};
    const requiredMessage = 'This field is required';

    if (!advertiser || advertiser === '') {
      errors.advertiser = requiredMessage;
    }

    return errors;
  };

  const fetchReportData = () => {
    getReportData(false);
  };

  const downloadReport = () => {
    getReportData(true);
  };

  const getReportData = (download) => {
    const validationErr = validateSubmitRequest();
    // setFieldErrors(validationErr);

    if (Object.keys(validationErr).length > 0) {
      if (validationErr?.advertiser) {
        dispatcher(notificationFail('Please select at least one advertiser.'));
      }
    } else {
      if (download) {
        setInProcess({ ...inProcess, downloadLoader: true });
      } else {
        setInProcess({ ...inProcess, searchLoader: true });
      }
      setTableLoading(true);
      dispatcher(
        getCampaignReportData({
          advertiser_id: advertiser,
          attribute_window: attribute,
          date_range: format_date(dateRange),
          reportApiPath
        })
      ).then((response) => {
        setTableLoading(false);
        setInProcess({ downloadLoader: false, searchLoader: false });

        if (!response?.payload?.data?.data?.results?.length) {
          dispatcher(notificationSuccess('No Data Found'));
        }

        if (download && response?.payload) {
          const payload = {
            report_type: DownloadReportData,
            report_data: response?.payload?.data?.data
          };
          dispatcher(SendAllReport(payload)).then((resp) => {
            console.log(resp, 'fff');
            const fileContent = resp?.payload?.data;
            const blob = new Blob([fileContent], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            const fileName = `${DownloadReportData}_report.xlsx`;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });

          // window.open(response?.payload?.data?.file_url, '_blank', 'noreferrer');
        }
      });
    }
  };

  // useEffect(() => {
  //   let data = "";
  //   dispatcher(getAlldvertiserList({ data }));
  // }, [dispatcher]);

  const handleGoBack = () => {
    navigate(`/default-report`);
  };

  return (
    <MainCard title={reportName}>
      <Box>
        <Button
          variant="contained"
          sx={{ position: 'absolute', top: '18px', right: '25px' }}
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
        >
          Go Back
        </Button>
        <Grid container spacing={2}>
          {/* <Grid item sm={3}>
            {renderLabel("Profile", true)}
            <Select
              fullWidth
              name="advertiser"
              value={advertiser}
              onChange={onAdvertiserChange}
            >
              {allUserDetails?.map((item) => {
                return (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            {fieldErrors?.advertiser && (
              <FormHelperText sx={{ color: "#f44336", ml: "14px" }}>
                {fieldErrors?.advertiser}
              </FormHelperText>
            )}
          </Grid> */}
          <Grid item sm={3}>
            {renderLabel('Attribute Window', true)}
            <Select value={attribute} onChange={onAttributeChange} sx={{ width: '100%' }}>
              {attributeWindowList.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item sm={3}>
            {renderLabel('Monthly', true)}
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <DatePicker label={''} views={['month', 'year']} defaultValue={dateRange} onChange={onDateRangeChange} />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={3} sx={{ mt: '33px' }}>
            {inProcess.searchLoader ? (
              <Button>
                <CircularProgress size="1rem" />
              </Button>
            ) : (
              <Button onClick={fetchReportData}>
                <SearchIcon></SearchIcon> Search
              </Button>
            )}

            {inProcess.downloadLoader ? (
              <Button>
                <CircularProgress size="1rem" />
              </Button>
            ) : (
              downloadReports && (
                <Button onClick={downloadReport}>
                  <DownloadIcon />
                </Button>
              )
            )}
          </Grid>
        </Grid>
        {pathname === '/campaign-report' && (
          <Grid>
            <CampaignReportList tableLoading={tableLoading} />
          </Grid>
        )}
        {pathname === '/item-report' && (
          <Grid>
            <AdItemReportList tableLoading={tableLoading} />
          </Grid>
        )}
        {pathname === '/keyword-report' && (
          <Grid>
            <KeywordReportList tableLoading={tableLoading} />
          </Grid>
        )}
        {pathname === '/adgroup-report' && (
          <Grid>
            <AdgroupReportList tableLoading={tableLoading} />
          </Grid>
        )}
      </Box>
    </MainCard>
  );
}

export default CampaignReport;
