import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useState, useEffect, useRef } from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppDispatch } from 'store';
import { loginApi } from 'store/thunk/authThunk';
import Warning from 'assets/images/dashna/warning.svg';

// material-ui
import {
  Grid,
  Typography,
  Box,
  FormGroup,
  OutlinedInput,
  FormControl,
  TextField,
  // Button,
  InputLabel,
  InputAdornment,
  IconButton,
  Container,
  FormHelperText
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// project imports
import __ from 'underscore';
import DashnaLogo from 'assets/images/dashna/dashnaLogo.svg';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const { useridb64, otpb64 } = useParams();
  const emailInputRef = useRef(null);
  const [beError, setBeError] = useState(''); //Only For loginApi userthunk for Main signin page
  const [loading, setLoading] = useState(false); //Only For loginApi userthunk for Main signin page
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  //Color
  const pageBackground = colors?.pageBackground;
  const paper = colors?.paper;
  const borderColor = colors?.borderColor;
  const primaryTextColor = colors?.primaryTextColor;
  const linkColor = colors?.linkColor;
  const errorBackground = colors?.errorBackground;

  useEffect(() => {
    if (emailInputRef.current) {
      setTimeout(() => {
        emailInputRef.current.focus();
      }, 1000);
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //While input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    setError((prevState) => ({
      ...prevState,
      [name]: false
    }));

    setBeError(''); //Make Empty Backend Error
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  //Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    //Error checking
    let errs = {};
    const { email, password } = formValues;
    if (!email) {
      errs.email = true;
    }
    if (email) {
      const isValidEmail = validateEmail(email);
      if (!isValidEmail) errs.invalidEmail = true;
    }
    if (!password) {
      errs.password = true;
    }
    setError(errs);

    if (!__.isEmpty(errs)) {
      return;
    }

    setBeError(''); //Make Empty Backend Error

    //API Calling Start
    const request = {
      email: formValues?.email,
      password: formValues.password,
      otpb64: otpb64,
      useridb64: useridb64,
      display_module_fe: 'verification-success', //Next Screen to be display
      navigate: navigate,
      setLoading: setLoading,
      SetBeError: setBeError,
    };
    appDispatch(loginApi(request));

  };


  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'} backgroundColor={pageBackground}>
      <Container maxWidth="sm" sx={{ maxWidth: '700px !important' }}>
        <Grid item xs={12} md={12}>
          <Box backgroundColor={paper} borderRadius={'10px'} border={`1px solid ${borderColor}`} px={10} py={5}>
            <Grid item mb={6}>
              <Link to="#">
                <img src={DashnaLogo} alt="Company Logo" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Box alignItems="center" spacing={1} mb={1.5}>
                <Typography className={'fontInter'} fontWeight={600} color={primaryTextColor} variant={'h2'}>
                  Sign in to verify your email address
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <TextField
                    label="Email"
                    name="email"
                    error={error?.email || error?.invalidEmail}
                    value={formValues?.email}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                    inputRef={emailInputRef}
                    helperText={error?.email ? 'Please add email' : error?.invalidEmail ? 'Please add valid email' : ''}
                  />

                  <FormControl sx={{ mt: 2, mb: 3 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      name="password"
                      error={error?.password}
                      type={showPassword ? 'text' : 'password'}
                      value={formValues?.password}
                      onChange={handleInputChange}
                      margin="normal"
                      fullWidth
                      autoComplete="new-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    <FormHelperText error={true}>{error?.password ? 'Please add password' : ''}</FormHelperText>
                  </FormControl>

                  <Box display="flex" justifyContent="flex-end" marginBottom="24px">
                    <Link className={'fontLato'} color={linkColor} to="#">
                      Forgot password?
                    </Link>
                  </Box>

                  {beError?.length > 0 && (
                    <Grid item xs={12} pb={3}>
                      <Box
                        display={'flex'}
                        gap={2}
                        alignItems={'center'}
                        p={2}
                        borderRadius={'10px'}
                        backgroundColor={errorBackground}
                      >
                        <Box>
                          <img src={Warning} alt="Warning" />
                        </Box>
                        <Box>
                          <Typography>{beError}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  <LoadingButton loading={loading} className={'actionBtn'} color="primary" variant="contained" type="submit">
                    Sign in
                  </LoadingButton>
                </FormGroup>
              </form>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default VerifyEmail;
