import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from 'store';
import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { setSelectedKeyword } from 'store/slices/userDetailsSlice';
import { Box, Tooltip, Typography } from '@mui/material';
import colors from 'assets/scss/_themes-vars.module.scss';
import DeleteLogo from 'assets/images/dashna/delete.svg';

const SelectedKeywordTable = () => {
  const [data, setData] = useState([]);

  const primaryMain = colors?.primaryMain;
  const Dark = colors?.primaryTextColor;
  const { keywordTrackerDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();
  const handleDelete = (NameFind) => {
    const FilterData = keywordTrackerDetails.filter((item) => item?.toLowerCase() !== NameFind?.name?.toLowerCase());
    dispatch(setSelectedKeyword(FilterData));
  };

  useEffect(() => {
    if (keywordTrackerDetails) {
      const uniqueArray = [...new Set(keywordTrackerDetails.map((keyword) => keyword.toLowerCase()))];

      const convertedArray = uniqueArray?.map((item) => ({
        name: item
      }));

      setData(convertedArray);
    }
  }, [keywordTrackerDetails]);

  const columns = [
    {
      name: 'name',
      label: (
        <Typography fontSize={'14px'} color={Dark} fontWeight={'600'}>
          Search Term
        </Typography>
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              {value?.length > 30 ? (
                <Tooltip
                  placement="top"
                  fontSize={'14px'}
                  fontWeight={'400'}
                  color={Dark}
                  title={
                    <Typography variant="body1" sx={{ fontSize: 16 }}>
                      {value}
                    </Typography>
                  }
                  arrow
                >
                  <Typography> {value.substring(0, 30)}... </Typography>
                </Tooltip>
              ) : (
                <Typography fontSize={'14px'} fontWeight={'400'} color={Dark}>
                  {' '}
                  {value}{' '}
                </Typography>
              )}
            </>
          );
        }
      }
    },
    {
      name: 'Remove All',
      label: (
        <Typography
          className="cursor-pointer"
          fontSize={'14px'}
          fontWeight={'400'}
          color={keywordTrackerDetails.length > 0 ? primaryMain : colors?.grey500}
          textAlign={'end'}
          onClick={() => dispatch(setSelectedKeyword([]))}
        >
          Remove All
        </Typography>
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, rowData) => {
          const NameFind = data[rowData?.rowIndex];
          return (
            <Typography textAlign={'end'}>
              <IconButton onClick={() => handleDelete(NameFind)}>
                <img src={DeleteLogo} alt="Delete Logo" style={{ fontSize: '14px', fontWeight: 400, color: Dark }} />
              </IconButton>
            </Typography>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    tableBodyHeight: '252px',
    responsive: 'standard',
    rowsPerPage: 25,
    filter: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: true,
    serverSide: true,

    customHeadRender: ({ index, ...column }) => (index === 1 ? null : column)
  };

  return (
    <>
      <Box position={'relative'}>
        <MUIDataTable className="pnl-by-item custom-table-border-selected" data={data} columns={columns} options={options} />
        <Typography fontSize={'14px'} fontWeight={'400'} position={'absolute'} bottom={'27px'} left={'12px'}>
          {data.length > 0 && `${data?.length} selected`}
        </Typography>
      </Box>
    </>
  );
};

export default SelectedKeywordTable;
