import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Stack,
  Skeleton,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useAppSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  getVideLiabraryList,
  postAdgroupMedia,
  postCreateAdgroup,
  postCreateAditems,
  postCreateCampaign,
  updateAdgroup,
  updateCampaign
} from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import CampaignBrandContext from 'context/brand';
// import { useDropzone } from 'react-dropzone';
// import { notificationFail } from 'store/slices/notificationSlice';
// import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EnterKeywordTable from './enterItemsTable';
import { Link } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { setVideoLibraryList } from 'store/slices/userDetailsSlice';
import { PageValue } from 'store/constant';
import SuggestFromTable from 'views/campaign/editProduct/suggestedTable';

const ProductAdGroupSetting = () => {
  const {
    handleBackwordButtonClick,
    campaignData,
    setCampaignData,
    handleForwordButtonClick,
    searchableData,
    selectedData,
    setSelectedData
  } = useContext(CampaignBrandContext);
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState('2');
  const [error, setError] = useState(false);
  // const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [keyword, setkeyword] = useState();
  const [list, setList] = useState({});
  const [mediaLoading, setMediaLoading] = useState(false);
  const [loading, setLoading] = useState({ campaign: '', adgroup: '', item: '' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [videoList, setVideoList] = useState([]);
  const { selectdItemData, existingCampaignId, existingAdgroupId, videoLibraryList } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  const dispatch = useAppDispatch();
  const commonAdvertiserList = [campaignData?.campaign?.advertiser];
  const changedColumn = '';
  const direction = '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (commonAdvertiserList?.length > 0) {
      getVideoList(search, page);
    }
  }, []);

  useEffect(() => {
    if (videoLibraryList.results?.length > 0) {
      let array = [...videoList, ...videoLibraryList.results];
      let uniqueMap = new Map(array.map((item) => [item.id, item]));
      let uniqueArray = Array.from(uniqueMap.values());
      setVideoList(uniqueArray);
    }
  }, [videoLibraryList]);

  const getVideoList = (search, page) => {
    setMediaLoading(true);
    dispatch(getVideLiabraryList({ page, rowPerPage, search, changedColumn, direction, commonAdvertiserList })).then(() => {
      setMediaLoading(false);
    });
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const formSchema = Yup.object().shape({
    adgroup_name: Yup.string().required('Adgroup name is required.'),
    media: Yup.object().required('Please select at lease one media.')
    // headline_text: Yup.string().required('Headline text is required'),
    // logo_click_url: Yup.string().required('Logo click url is required').test('logo_click_url', 'Clickable URL must be https://www.walmart.com URL that displays product(s) for the advertised brand.', function (value) {
    //     if(value?.startsWith("https://www.walmart.com")){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }).test('logo_click_url', `Clickable URL not contains 'typeahead='`, function (value) {
    //     if(!value?.includes("typeahead=")){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }),
    // seller_id: campaign?.advertiserType == "3p" ? Yup.string().required('Seller id is required') : "",
    // brand_logo: Yup.mixed().required('Brand Logo is required').nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      adgroup_name: campaignData?.brand?.adgroup_name
      // media: {}
      // brand_name: campaignData?.brand?.adgroup_name,
      // headline_text: campaignData?.brand?.headline_text,
      // logo_click_url: campaignData?.brand?.logo_click_url,
      // brand_logo: campaignData?.brand?.brand_logo,
      // seller_id: campaignData?.brand?.seller_id
    }
  });

  const adgorupNameValue = watch('adgroup_name');
  const selectedMedia = watch('media');
  // const brandNameValue = watch('brand_name');
  // const headlineTextValue = watch('headline_text');
  // const logoClickUrl = watch('logo_click_url');
  // const brandLogo = watch('brand_logo');
  // const sellerId = watch('seller_id');

  const brandData = {
    adgroup_name: adgorupNameValue
    // brand_name: brandNameValue,
    // headline_text: headlineTextValue,
    // logo_click_url: logoClickUrl,
    // brand_logo: brandLogo,
    // seller_id: sellerId
  };

  const handleOkButtonClick = () => {
    if (
      loading.campaign == 'done' &&
      loading.adgroup == 'done' &&
      loading.item == 'done' &&
      loading.brand == 'done' &&
      !list?.errorMessages?.length
    ) {
      handleForwordButtonClick();
      setVideoList([]);
      dispatch(setVideoLibraryList([]));
    } else {
      const array = selectedData.filter((item) => !list?.successlist?.find((list) => list?.item_id == item?.item_id));
      setSelectedData(array);
    }
    setDialogOpen(false);
    setLoading({ campaign: '', adgroup: '', item: '' });
  };

  const onButtonClick = (event) => {
    setList({});
    if (!selectedData?.length) {
      setError(true);
    } else {
      setCampaignData({ ...campaignData, brand: brandData, adgroup: { name: event?.adgroup_name } });
      const temp = selectedData?.map((item) => {
        return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : '0.20' };
      });
      setDialogOpen(true);
      setLoading({ ...loading, campaign: 'process' });

      const _request = campaignData?.campaign;
      dispatch(existingCampaignId ? updateCampaign({ _request, existingCampaignId }) : postCreateCampaign({ _request })).then(
        (res) => {
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            setLoading({ ...loading, campaign: 'done', adgroup: 'process' });
            const _adgroupRequest = {
              name: event?.adgroup_name,
              status: 'enabled',
              campaign: res?.payload?.data?.data?.id
            };
            dispatch(
              existingAdgroupId ? updateAdgroup({ _adgroupRequest, existingAdgroupId }) : postCreateAdgroup({ _adgroupRequest })
            ).then((res) => {
              if (res?.payload?.status == 201 || res?.payload?.status == 200) {
                setLoading({ ...loading, campaign: 'done', adgroup: 'done', item: 'process' });

                const _aditemRequest = {
                  campaign: res?.payload?.data?.data?.campaign,
                  adgroup: res?.payload?.data?.data?.id,
                  add_items: temp
                };

                let _request = {
                  adgroup_id: res?.payload?.data?.data?.id,
                  media_id: selectedMedia?.id
                };

                dispatch(postCreateAditems({ _aditemRequest })).then((res) => {
                  setLoading(false);
                  if (res?.payload?.status == 201) {
                    setList(res?.payload?.data);
                    setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'process', item: 'done' });
                    dispatch(postAdgroupMedia(_request)).then((res) => {
                      if (res?.payload?.status == 201) {
                        setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'done', item: 'done' });
                      } else {
                        setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'done', item: 'error' });
                      }
                    });
                  } else {
                    setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'error' });
                  }
                });
              } else {
                setLoading({ ...loading, campaign: 'done', adgroup: 'error' });
              }
            });
          } else {
            setLoading({ ...loading, campaign: 'error' });
          }
        }
      );
    }
  };

  const handlePreviousButton = () => {
    setVideoList([]);
    dispatch(setVideoLibraryList([]));
    handleBackwordButtonClick();
    setCampaignData({ ...campaignData, brand: brandData });
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20'
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20'
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {data?.images ? (
                  <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                ) : (
                  <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
                )}
                <Box>
                  <Typography>{data?.product_name ? data?.product_name : '------'}</Typography>
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      flex: 0.1,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedData];
                  tempData = tempData?.filter((item) => item?.item_id != value);
                  setSelectedData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    toolbar: false,
    search: false
  };
  const onSearchChange = (e) => {
    setSearch(e.target.value);
    handleSearch(e.target.value);
  };
  const handleSearch = useCallback(
    debounce((data) => {
      setVideoList([]);
      const searchPage = 0;
      getVideoList(data, searchPage);
    }, 500),
    []
  );

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    // Check if the user has scrolled to the bottom of the container
    if (scrollHeight - clientHeight === Math.round(scrollTop) && videoLibraryList.next !== null) {
      // Increment the page count when reaching the bottom
      setPage((prevPage) => prevPage + 1);
      getVideoList(_, page + 1);
    }
  };
  return (
    <>
      <form noValidate onSubmit={handleSubmit(onButtonClick)}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              {/* {renderLabel('Adgroup Name', true)} */}
              <Controller
                name={'adgroup_name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('adgroup_name')}
                    {...field}
                    name={'adgroup_name'}
                    fullWidth
                    type="text"
                    label="Adgroup Name"
                    variant="outlined"
                    error={errors?.adgroup_name}
                    helperText={errors?.adgroup_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Choose Media', true)}
              {selectedMedia && (
                <Stack sx={{ width: '100%', margin: '5px 0px' }} spacing={1}>
                  <Alert>Selected - {selectedMedia?.name}</Alert>
                </Stack>
              )}
              <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: 1,
                    borderBottomColor: 'divider',
                    padding: '10px 15px'
                  }}
                >
                  <TextField placeholder="Search Media name/id" size="small" onChange={onSearchChange} />
                  <Button onClick={() => navigate('/video-management')}>{'Video Management >'}</Button>
                </Box>
                <Box onScroll={handleScroll} sx={{ height: '50vh', padding: 2, overflow: 'overlay' }}>
                  <Grid container spacing={3}>
                    {!mediaLoading && videoList?.length == 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%',
                          padding: 5
                        }}
                      >
                        <Typography variant="h5">No Video Record Found.</Typography>
                      </Box>
                    )}
                    {videoList?.length > 0 &&
                      videoList?.map((item, index) => (
                        <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                          <Tooltip title={`${item?.name} (Id: ${item?.media_id})`} placement="top" arrow>
                            <Box
                              sx={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '5px',
                                border: `3px solid ${selectedMedia?.id == item?.id ? colors.secondaryMain : '#f4f5f6'}`,
                                padding: 0.3,
                                '&:hover': {
                                  borderColor: colors.secondaryMain
                                  // background: colors.secondaryLight
                                }
                              }}
                              alt={item?.name}
                              component={'img'}
                              onClick={() => setValue('media', item)}
                              src={item?.thumbnail_url}
                            />
                          </Tooltip>
                        </Grid>
                      ))}
                    {mediaLoading &&
                      Array.from({ length: 8 }, (_, index) => (
                        <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                          <Skeleton
                            sx={{
                              width: '100%',
                              borderRadius: '5px'
                            }}
                            height={'150px'}
                            variant="rectangular"
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
              <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                {errors?.media?.message}
              </FormHelperText>
            </Grid>

            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="3">
                      <SuggestFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable keyword={keyword} setkeyword={setkeyword} />
                    </TabPanel>
                  </Box>
                </TabContext>

                {error && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    {'Please select atlease one item.'}
                  </FormHelperText>
                )}
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h5">{selectedData?.legth} Item(s)Added</Typography>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      columns={selectedColumns}
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
                CANCEL
              </Button>
              <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handlePreviousButton}>
                PREVIOUS
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
                {/* {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />} */}
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
      <Dialog open={dialogOpen} aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="div"> Message</Typography>{' '}
        </DialogTitle>
        <DialogContent dividers sx={{ minWidth: '600px', minHeight: '200px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.campaign == 'process' && <CircularProgress size={20} />}
              {loading.campaign == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.campaign == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Campaign (${campaignData?.campaign?.name}) ${existingCampaignId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.adgroup == 'process' && <CircularProgress size={20} />}
              {loading.adgroup == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.adgroup == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Adgroup (${campaignData?.adgroup?.name}) ${existingAdgroupId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.item == 'process' && <CircularProgress size={20} />}
              {loading.item == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.item == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`${list?.data?.successcount ? list?.data?.successcount : 0} items added successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              {list?.errorMessages?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex' }}>
                  {' '}
                  <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                    {list?.errorMessages?.length && <HighlightOffIcon color="error" />}
                  </Box>
                  <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.brand == 'process' && <CircularProgress size={20} />}
              {loading.brand == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.brand == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>{`Waiting to upload media`}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ margin: 1 }} onClick={handleOkButtonClick} color="secondary" variant="contained" autoFocus size="small">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductAdGroupSetting;
