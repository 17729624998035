import React, { useState } from 'react';
import { Grid, Box, Typography, Button, TextField, IconButton, InputAdornment, LinearProgress } from '@mui/material';
import colors from 'assets/scss/_themes-vars.module.scss';
import Warning from 'assets/images/dashna/warning.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router';
import { accountPasswordUpdateNew } from 'store/thunk/authThunk';
import CustomLoader from 'ui-component/custom-loader';
import SignUPFlowLeftSide from 'ui-component/sign-up-flow-left-side';
import { Link } from 'react-router-dom';

const PasswordConfirm = () => {
  const [formValues, setFormValues] = useState({
    confirmPassword: '',
    password: ''
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const uid = queryParameters.get('uid');
  const token = queryParameters.get('token');
  const [BeError, SetBeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const appDispatch = useAppDispatch();
  const errorBackground = colors?.errorBackground;
  const navigate = useNavigate();

  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: formErrors?.length > 0 ? colors.errorMain : colors.darkPrimaryMain
      }
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: formErrors?.length > 0 ? colors.errorMain : colors.darkPrimaryMain
      }
    }
  };

  const [passwordStrength, setPasswordStrength] = useState({
    message: '',
    color: '',
    progress: 0,
    isWeak: false
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: ''
    }));

    if (id === 'password') {
      evaluatePasswordStrength(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!formValues.password) {
      errors.password = 'Password is required!';
    } else if (formValues.password.length < 8) {
      errors.password = 'Password must be at least 8 characters!';
    }

    // Validate confirm password
    if (!formValues.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required!';
    } else if (formValues.confirmPassword !== formValues.password) {
      errors.confirmPassword = 'Passwords do not match!';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      const requestData = {
        password: formValues.password,
        confirm_password: formValues.confirmPassword,
        uid: uid,
        token: token
      };
      if (!passwordStrength?.isWeak) {
        appDispatch(
          accountPasswordUpdateNew({ requestData, navigate: navigate, setLoading: setLoading, SetBeError: SetBeError })
        );
      }
    }
  };

  const evaluatePasswordStrength = (password) => {
    let strength = {
      message: 'Your password is weak.',
      color: colors.errorMain,
      progress: 33,
      isWeak: true
    };

    const hasLength = password.length >= 8;
    const hasNumbers = /\d/.test(password);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasMixedCase = /[a-z]/.test(password) && /[A-Z]/.test(password);

    const conditionsMet = [hasLength, hasNumbers, hasSymbols, hasMixedCase].filter(Boolean).length;

    if (conditionsMet >= 3) {
      strength = {
        message: 'Your password is good.',
        color: colors.warningMain,
        progress: 66,
        isWeak: true
      };
    }

    if (hasLength && hasNumbers && hasSymbols && hasMixedCase) {
      strength = {
        message: 'Your password is strong. Great job!',
        color: colors.successMain,
        progress: 100,
        isWeak: false
      };
    }

    setPasswordStrength(strength);
  };

  return (
    <Box>
      <Grid container className="sign-main" columns={12}>
        <Grid sx={{ bgcolor: colors.darkPrimaryMain }} xs={5} className="sign-in-left-side">
          <SignUPFlowLeftSide />
        </Grid>
        <Grid xs={7} className="sign-in-right-side">
          <Box className="right-side-content-wrapper">
            <Box className="sign-in-right-form-container">
              <Typography className="sign-up-title">Reset Password</Typography>
              <Typography className="sign-up-sub-title" mt={1} mb={5}>
                Enter your new password details below
              </Typography>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <CustomLoader />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        sx={textFieldStyles}
                        id="password"
                        variant="outlined"
                        fullWidth
                        placeholder="New Password"
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        value={formValues?.password || ''}
                        onChange={handleInputChange}
                        error={!!formErrors.password}
                        helperText={formErrors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={textFieldStyles}
                        id="confirmPassword"
                        autoFocus="true"
                        variant="outlined"
                        fullWidth
                        placeholder="Confirm Password"
                        label="Confirm Password"
                        value={formValues?.confirmPassword || ''}
                        onChange={handleInputChange}
                        error={!!formErrors.confirmPassword}
                        helperText={formErrors.confirmPassword}
                      />
                      {formValues?.password?.length > 0 && (
                        <>
                          <LinearProgress
                            variant="determinate"
                            value={passwordStrength.progress}
                            sx={{
                              mt: 1,
                              height: 5,
                              borderRadius: 2,
                              bgcolor: colors.grey300,
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: passwordStrength.color
                              }
                            }}
                          />
                          <Typography sx={{ mt: 1, color: passwordStrength.color }}>{passwordStrength.message}</Typography>
                          {passwordStrength.isWeak && (
                            <Typography variant="body2" sx={{ mt: 1, color: colors.grey600 }}>
                              Please choose a password that is at least 8 characters long (letters, numbers, and symbols) to make
                              it more secure.
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>

                    {BeError?.length > 0 && (
                      <Grid item xs={12} pt={2} pb={1}>
                        <Box
                          display={'flex'}
                          gap={2}
                          alignItems={'center'}
                          p={2}
                          borderRadius={'10px'}
                          backgroundColor={errorBackground}
                        >
                          <Box>
                            <Link to="#">
                              <img src={Warning} alt="Warning" />
                            </Link>
                          </Box>
                          <Box>
                            <Typography>{BeError}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Button
                          fullWidth
                          type="submit"
                          className="button-for-sign-up"
                          variant="contained"
                          sx={{ bgcolor: colors.darkPrimaryMain, color: colors.paper, padding: '11px 9px' }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Typography variant="body2" textAlign={'center'}>
                          {`Don’t have an account?`} <Link to="/login">Sign In</Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </form>
            </Box>
            <Box>
              <Typography className="sign-up-sub-title">
                By continuing, you agree to Dashna’s Terms of Service and Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordConfirm;
