import * as React from 'react';
import { Checkbox, DialogContent, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const ProfilePermissions = (props) => {
  const { allProfiles, selectedUserType, setSelectedProfilesUpdatedFinal, selectedProfiles, allUserDetails, userInfo } = props;
  const [clickAll, setClickAll] = useState(false);
  const [clickRemoveAll, setClickRemoveAll] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [isDisableAll, setIsDisableAll] = useState(false);

  useEffect(() => {
    if (!allProfiles.length) return;
    setMainData(allProfiles);
    setCheckedData([]); //Set Profile Ids
    setClickRemoveAll(false);
    setClickAll(false);
  }, []);

  //If user type is Admin (role id = 3) then select all profile checkbox automatically
  useEffect(() => {
    if (selectedUserType === 3) {
      setCheckedData(allProfiles);
      setClickAll(true);
      setIsDisableAll(true);
    } else if (selectedUserType === userInfo?.user_type) {
      //If user change the user type, but he select his own user type again then display all its profiles selected again
      const userAdvertiserAccess = userInfo?.advertiser_access || [];
      const getUserAdvertiserList = allProfiles?.filter((l) => userAdvertiserAccess?.includes(l?.id));
      setCheckedData(getUserAdvertiserList);

      //Manage Select All Checkbox while modal load
      manageSelectAllChkbox(getUserAdvertiserList, allProfiles);
    } else {
      setCheckedData([]);
      setClickAll(false);
      setIsDisableAll(false);
    }
  }, [selectedUserType, selectedProfiles]);

  //for setting remove all checkbox
  useEffect(() => {
    if (checkedData.length) {
      setClickRemoveAll(true);
    } else {
      setClickRemoveAll(false);
    }

    setSelectedProfilesUpdatedFinal(checkedData); //set state from parent components

    //Manage Select All Checkbox while modal load
    manageSelectAllChkbox(checkedData, allProfiles);
  }, [checkedData]);

  useEffect(() => {
    setCheckedData(selectedProfiles);
    if (selectedProfiles?.length === allProfiles?.length) {
      setClickAll(true);
    }
  }, [selectedProfiles]);

  useEffect(() => {
    if (setSelectedProfilesUpdatedFinal?.length === allProfiles?.length) {
      setClickAll(true);
    } else {
      setClickAll(true);
    }
  }, [setSelectedProfilesUpdatedFinal]);

  //Manage Select All Checkbox
  const manageSelectAllChkbox = (a,b) => {
    if (a?.length === b?.length) {
      setClickAll(true);
    } else {
      setClickAll(false);
    }
  }

  //Select all profiles
  const handleSelectAllProfiles = (event) => {
    const checked = event.target.checked;
    setClickAll(checked);
    if (checked) {
      setCheckedData(mainData);
      setClickRemoveAll(checked);
      setOriginalData(mainData);
    } else {
      setCheckedData([]);
      setClickRemoveAll(false);
      setOriginalData([]);
    }
  };

  //Remove all profiles
  const handleRemoveAllProfiles = (event) => {
    const checked = event.target.checked;
    setClickRemoveAll(checked);
    setClickAll(false);
    setCheckedData([]);
    setOriginalData([]);
  };

  //While selecting individual profiles checkbox
  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedData((prevChecked) => [...prevChecked, item]);
      setOriginalData((prevChecked) => [...prevChecked, item]);
    } else {
      setCheckedData((prevChecked) => prevChecked?.filter((checkedItem) => checkedItem !== item));
      setOriginalData((prevChecked) => prevChecked?.filter((checkedItem) => checkedItem !== item));
    }
  };

  //For search from all profiles
  const handleSearchAllProfiles = (event) => {
    const searchData = event.target.value.toLowerCase();
    if (searchData?.length > 0) {
      const tempData = allProfiles?.filter((obj) => obj?.name?.toLowerCase()?.includes(searchData));
      if (tempData) {
        setMainData(tempData);
      }
    } else {
      setMainData(allProfiles);
    }
  };

  //Search from selected profiles
  const handleSearchSelectedProfiles = (event) => {
    const searchDataMain = event.target.value.toLowerCase();
    if (searchDataMain.length > 0) {
      const tempData = originalData?.filter((obj) => obj?.name?.toLowerCase()?.includes(searchDataMain));
      if (tempData) {
        setCheckedData(tempData);
      }
    } else {
      setCheckedData(originalData);
    }
  };

  return (
    <DialogContent dividers>
      <Box>
        <Box sx={{ display: 'flex', mx: -2, mt: 1 }}>
          <Box sx={{ mx: 2, width: '50%' }}>
            <Typography sx={{ mb: 1, fontWeight: '500' }}>Accessible Profiles</Typography>
            <Box sx={{ border: '1px solid #ccc' }}>
              <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                <FormControlLabel
                  checked={clickAll}
                  control={<Checkbox onClick={(e) => handleSelectAllProfiles(e)} />}
                  label="Select All"
                  disabled={isDisableAll}
                />
              </Box>
              <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                <Box sx={{ mt: 1 }}>
                  <FormControl variant="standard" sx={{ border: '1px solid #ccc', width: '100%', mb: 1 }}>
                    <Input
                      id="input-with-icon-adornment"
                      className="afterBorder"
                      onChange={(e) => handleSearchAllProfiles(e)}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>

                <Box sx={{ overflow: 'auto', height: '250px', position: 'relative' }}>
                  {mainData && mainData.length > 0 ? (
                    mainData?.map((item) => {
                      const isChecked =
                        (checkedData && checkedData?.includes(item)) ||
                        (checkedData?.some((dataItem) => dataItem.id === item.id) &&
                          allUserDetails?.profile_ids.some((profile) => profile.id === item.id));
                      return (
                        <Box key={item?.id}>
                          <FormGroup>
                            <FormControlLabel
                              checked={isChecked}
                              control={<Checkbox onChange={(e) => handleCheckboxChange(e, item)} />}
                              label={item?.name}
                              disabled={isDisableAll}
                            />
                          </FormGroup>
                        </Box>
                      );
                    })
                  ) : (
                    <Box
                      sx={{
                        overflow: 'auto',
                        height: '250px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography variant="h5">No data</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <KeyboardDoubleArrowRightIcon />
          </Box>
          <Box sx={{ mx: 2, width: '50%' }}>
            <Typography sx={{ mb: 1, fontWeight: '500' }}>Selected Profiles</Typography>
            <Box sx={{ border: '1px solid #ccc' }}>
              <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                <FormControlLabel
                  checked={clickRemoveAll}
                  control={<Checkbox onClick={(e) => handleRemoveAllProfiles(e)} />}
                  label={`${checkedData && checkedData?.length > 0 ? checkedData?.length : 0} items`}
                  disabled={isDisableAll}
                />
              </Box>
              <Box sx={{ borderBottom: '1px solid #ccc', px: 2 }}>
                <Box sx={{ mt: 1 }}>
                  <FormControl variant="standard" sx={{ border: '1px solid #ccc', width: '100%', mb: 1 }}>
                    <Input
                      id="input-with-icon-adornment-checked"
                      className="afterBorder"
                      onChange={handleSearchSelectedProfiles}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ overflow: 'auto', height: '250px', position: 'relative' }}>
                  {checkedData && checkedData?.length > 0 ? (
                    <Box sx={{ overflow: 'auto', height: '250px' }}>
                      {checkedData &&
                        checkedData?.length > 0 &&
                        checkedData?.map((item) => (
                          <Box key={item?.campaign_id}>
                            <FormGroup>
                              <FormControlLabel
                                checked={true}
                                control={<Checkbox onChange={(e) => handleCheckboxChange(e, item)} />}
                                label={item?.name}
                                disabled={isDisableAll}
                              />
                            </FormGroup>
                          </Box>
                        ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        overflow: 'auto',
                        height: '250px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography variant="h5">No data</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default ProfilePermissions;
