import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  FormHelperText,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
//   import SelectFromTable from './searchTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useAppSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
//   import EnterKeywordTable from './enterKeywordTable';
import {
  getAdgorupCampaignList,
  getAdvertiserById,
  // getAlldvertiserList,
  getSelectFromItemsFromId,
  getSuggestedKeyword,
  //   postMultipleKeyword,
  postMultipleKeywordID
} from 'store/thunk/userThunk';
import { useEffect } from 'react';
import { setSuggestedkeywordData } from 'store/slices/userDetailsSlice';
import EnterKeywordTable from '../editProduct/editKeywordItems/enterKeywordTable';
import SelectFromTable from '../editProduct/editKeywordItems/searchTable';
import { PageValue } from 'store/constant';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
// import SelectFromTable from '../editProduct/editAdgroupItems/selectFromTable';
// import EnterKeywordTable from '../editProduct/editAdgroupItems/enterItemTable';
// import EnterKeywordTable from '../editProduct/editKeywordItems/enterKeywordTable';

const TargetAdGroupSettingKeyword = (props) => {
  const { keywordModal, onClose, addKeywordModel } = props;

  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [selectedKeywordData, setSelectedKeywordData] = useState([]);
  const [selectedAdgroup, setSelectedAdgroup] = useState([]);
  const [error, setError] = useState({ adGroup: false, adItem: false });
  const [isMultipleAdgroup, setIsmultipleAdgroup] = useState(false);
  const [profileValue, setProfileValue] = useState(null);
  const [campaignValue, setCampaignValue] = useState([]);
  const { adgroupList } = useAppSelector((state) => state?.userDetailsSlice);
  const { campaignByIdData, allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [profileOption, setProfileOption] = useState([]);
  const [rowSelected, setRowSelected] = useState(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    // const data = null;
    // dispatch(getAlldvertiserList({ data }));
  }, []);

  useEffect(() => {
    let temp = allUserDetails?.map((item) => ({ id: item?.id, name: item?.name }));
    setProfileOption(temp);
  }, [allUserDetails]);

  useEffect(() => {
    if (selectedAdgroup?.length == 1) {
      setIsmultipleAdgroup(false);
      const adgroupId = selectedAdgroup[0]?.id;
      if (adgroupId) {
        dispatch(getSuggestedKeyword({ adgroupId }));
      }
    } else if (selectedAdgroup?.length >= 2) {
      setIsmultipleAdgroup(true);
      dispatch(setSuggestedkeywordData([]));
    } else {
      setIsmultipleAdgroup(false);
      dispatch(setSuggestedkeywordData([]));
    }
  }, [selectedAdgroup]);

  useEffect(() => {
    if (profileValue !== null) {
      const id = profileValue?.id;
      const advertiserId = profileValue?.id;
      const campaign_name = '';
      dispatch(getAdvertiserById({ id, campaign_name }));
      dispatch(getSelectFromItemsFromId({ advertiserId }));
    }
  }, [profileValue]);

  // useEffect(() => {
  //     setCampaignData([])
  //     if (campaignByIdData?.length > 0) {
  //         setCampaignData(campaignByIdData);
  //     }
  // }, [campaignByIdData])

  useEffect(() => {
    if (campaignValue?.length > 0) {
      const id = campaignValue?.map((item) => item?.id);
      dispatch(getAdgorupCampaignList({ id }));
    } else if (keywordModal?.id) {
      const id = keywordModal?.id;
      dispatch(getAdgorupCampaignList({ id }));
    }
  }, [keywordModal, campaignValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onButtonClick = (event) => {
    event?.preventDefault();
    if (selectedAdgroup?.length > 0 && selectedKeywordData?.length > 0 && !errorMinBid) {
      const tempArray = selectedKeywordData?.map((item) => {
        const temp = {
          keywordtext: item.keywordtext,
          matchtype: item.matchtype,
          bid_amount: Number(item.bid_amount)
        };
        return temp;
      });
      var adgroupIds = [];
      selectedAdgroup?.map((item) => {
        adgroupIds.push(item?.id);
      });
      setLoading(true);
      const _request = {
        // campaign_id: campaignValue.length > 0 ? campaignValue[0].id : keywordModal?.id,
        adgroup_id: adgroupIds,
        keyword: tempArray
      };
      console.log('_request ', _request);
      dispatch(postMultipleKeywordID({ _request })).then((res) => {
        console.log('res ', res);
        setLoading(false);
        // if (res.payload.status == 201 || res.payload.status == 200) {
        onClose();
        // }
      });
    } else {
      setError({
        adGroup: selectedAdgroup?.length > 0 ? false : true,
        adItem: selectedKeywordData?.length > 0 ? false : true,
        campaign: campaignValue?.length > 0 ? false : true,
        profile: profileValue?.length > 0 ? false : true
      });
    }
  };

  const [errorMinBid, setErrorMinBid] = useState(false);
  const [valueShow, SetValueShow] = useState(0);
  const [selectedDataValue, SetSelectedValue] = useState(null);
  //   console.log('setErrorMinBid ', setErrorMinBid);

  const selectedColumns = [
    {
      name: 'keywordtext',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography>{value}</Typography>
                  <Typography sx={{ color: colors.primaryMain }}>{data?.item_id}</Typography>
                </Box>
              </Box>
            </>
          );
        }
      }
    },

    {
      name: 'matchtype',
      label: 'Match Type',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <TextField
              size="small"
              value={data?.bid_amount}
              type="number"
              onWheel={(e) => e.target.blur()}
              onBlur={(e) => {
                if (Number(e.target.value) < 0.3) {
                  const newPrice = '0.30';
                  const updatedData = [...selectedKeywordData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedKeywordData(updatedData);
                }
              }}
              onChange={(e) => {
                const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                const updatedData = [...selectedKeywordData];
                updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                setSelectedKeywordData(updatedData);
                // if (e.target.value < 1.3) {
                //   setErrorMinBid(true);
                // } else {
                setErrorMinBid(false);
                // }

                //   let temp = JSON.parse(JSON.stringify(errorMinBid));
                //   if (e.target.value < 1.3) {
                //     console.log('temp111 ', temp);
                //     temp.push(tableMeta?.rowIndex);
                //     console.log('temp ', temp);
                //     setErrorMinBid(temp);
                //   } else {
                //     let index = temp?.indexOf(tableMeta?.rowIndex);
                //     temp?.splice(index, 1);
                //     console.log('temp www', temp);
                //     setErrorMinBid(temp);
                //   }
                // }}
                // error={errorMinBid?.includes(tableMeta?.rowIndex)}
                // helperText={errorMinBid?.includes(tableMeta?.rowIndex) ? 'please Apply above 1.3' : ''}
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              inputProps={{
                step: '0.01'
              }}
            />
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedKeywordData];
                  tempData = tempData?.filter((item, index) => index != tableMeta?.rowIndex);
                  setSelectedKeywordData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={selectedKeywordData?.length == 0}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedKeywordData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: true,
    print: false,
    jumpToPage: false,
    download: false,
    rowsSelected: rowSelected,
    pagination: true,
    fixedSelectColumn: false,
    filter: false,
    selectToolbarPlacement: 'none',
    rowsPerPageOptions: PageValue,
    search: false,
    viewColumns: false,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      console.log('dddd', selectedKeywordData, rowsSelectedData, allRows, rowsSelected);
      SetValueShow(rowsSelectedData?.length);
      setRowSelected(rowsSelected);
      // console.log('rowseleted', rowsSelected, campaignDetails);
      const selectedIds = rowsSelected.map((selectedIndex) => selectedKeywordData?.[selectedIndex]);

      SetSelectedValue(selectedIds);

      // const selectedNames = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.name);
      // const selectedAdviser = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.advertiser);
      // const ProfileName = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]?.profile_name);
      // const selectedForBudgetSetting = rowsSelected.map((selectedIndex) => campaignDetails?.results?.[selectedIndex]);
      // setSelectedArray(selectedIds);
    }
  };

  const handleRemoveRow = () => {
    let a = selectedKeywordData.filter(
      (itemA) =>
        !selectedDataValue.some(
          (itemB) =>
            itemB &&
            Object.keys(itemA).every((key) => Object.prototype.hasOwnProperty.call(itemB, key) && itemB[key] === itemA[key])
        )
    );
    console.log(a, selectedKeywordData, 'Ggg');
    setSelectedKeywordData(a);
    SetValueShow(0);
    Object.assign(options, { rowsSelected: [] });
    setRowSelected(null);
    SetValueShow(0);
    // console.log('rowseleted', rowsSelected, campaignDetails);

    SetSelectedValue(null);
  };
  const [myvalue, setMyValue] = useState('Change Type');
  const handleChangeType = (event) => {
    let indexesToUpdate = rowSelected; // Indices of the objects you want to replace
    let newValue = event.target.value; // New objects to replace the existing ones
    setMyValue(event.target.value);
    indexesToUpdate.forEach((index) => {
      if (index >= 0 && index < selectedKeywordData.length) {
        // Check if the index is within the valid range
        selectedKeywordData[index].matchtype = newValue;
        console.log('Updated object at index', index, 'with value:', newValue);
      } else {
        console.log('Index', index, 'is out of range.');
      }
    });
    setSelectedKeywordData(selectedKeywordData);
    Object.assign(options, { rowsSelected: [] });
    setRowSelected(null);
    SetValueShow(0);
  };

  const handleSetBidConfirm = () => {
    let indexesToUpdate = rowSelected; // Indices of the objects you want to replace
    let newValue = bidValue; // New objects to replace the existing ones

    indexesToUpdate.forEach((index) => {
      if (index >= 0 && index < selectedKeywordData.length) {
        // Check if the index is within the valid range
        selectedKeywordData[index].bid_amount = newValue;
        console.log('Updated object at index', index, 'with value:', newValue);
      } else {
        console.log('Index', index, 'is out of range.');
      }
    });
    handleSetBid();
    setSelectedKeywordData(selectedKeywordData);

    Object.assign(options, { rowsSelected: [] });
    setRowSelected(null);
    SetValueShow(0);
  };
  const [bidModal, setBidModal] = useState(false);
  const [bidValue, setBidValue] = useState(null);

  const handleSetBid = () => {
    setBidModal(!bidModal);
    setBidValue(null);
  };

  const [IsDropdownOpenCamSt, SetIsDropdownOpenCamSt] = useState(false);
  const [SearchText, setSearchText] = useState({ searchTextAdgroup: null });

  return (
    <>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              <Box sx={{ width: '-webkit-fill-available' }}>
                <Typography variant="h5">Selected Campaigns({keywordModal?.name?.length})</Typography>
                <Box
                  sx={{
                    overflow: 'auto',
                    height: '80px',
                    width: '100%',
                    my: 2,
                    p: 1,

                    // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                    '&::-webkit-scrollbar': {
                      width: '0.1em',
                      backgroundColor: '#C5C5C5'
                    },
                    '&::-webkit-scrollbar-track': {
                      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#7A8183',
                      outline: '1px solid slategrey'
                    }
                  }}
                >
                  {keywordModal &&
                    keywordModal?.name?.map((name, index) => (
                      <Typography key={index}>
                        {index + 1}.{name}
                      </Typography>
                    ))}
                </Box>
              </Box>
            </Grid>
            {addKeywordModel && (
              <>
                <Grid item sm={12}>
                  {renderLabel('Profile', true)}
                  <Autocomplete
                    id="combo-box-demo"
                    fullWidth
                    onChange={(e, value) => {
                      setProfileValue(value);
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    value={profileValue?.name}
                    options={profileOption?.length > 0 ? profileOption : []}
                    // {...register("campaign")}
                    // {...field}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField error={error.campaign} fullWidth {...params} placeholder="Select Profile" />
                    )}
                  />
                  {error.campaign && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      Please select Profile
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12}>
                  {renderLabel('Campaign', true)}
                  <Autocomplete
                    // multiple
                    id="combo-box-demo"
                    fullWidth
                    onChange={(e, value) => {
                      setCampaignValue([value]);
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    value={campaignValue?.name}
                    options={campaignByIdData?.length > 0 ? campaignByIdData : []}
                    // {...register("campaign")}
                    // {...field}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField error={error.campaign} fullWidth {...params} placeholder="Select Campaign" />
                    )}
                  />
                  {error.campaign && (
                    <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                      Please select Campaign
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            <Grid item sm={12}>
              <>
                <CustomAutocomplete
                  multiple
                  label="Adgroup Name"
                  id="tags-outlined"
                  name="adgroup_name"
                  value={selectedAdgroup || []}
                  options={adgroupList || []}
                  // getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    if (value.find((option) => option.all)) {
                      const allIdsCam = adgroupList?.map((obj) => obj.id);

                      if (value.length - 1 === allIdsCam.length) {
                        return setSelectedAdgroup([]);
                      } else {
                        return setSelectedAdgroup(adgroupList);
                      }
                    }
                    setSelectedAdgroup(value);
                  }}
                  onOpen={() => SetIsDropdownOpenCamSt(true)}
                  onClose={() => SetIsDropdownOpenCamSt(false)}
                  filterSelectedOptions
                  color="secondary"
                  optionLabelkey="name"
                  onInputChange={(event, value, reason) => {
                    if (reason !== 'reset') {
                      setSearchText({ ...SearchText, searchTextAdgroup: value });
                    }
                  }}
                  inputValue={IsDropdownOpenCamSt ? SearchText?.searchTextAdgroup : ''}
                  selectAllCheckboxCondiion={!!(selectedAdgroup?.length == adgroupList.length)}
                  placeholderCondition={selectedAdgroup?.length > 0}
                  isOptionEqualToValue={(option, value) => option?.id == value?.id}
                />

                {error.adGroup && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    Please select adgroup
                  </FormHelperText>
                )}
              </>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Keyword', true)}

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Enter Keywords" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <EnterKeywordTable
                        data={adgroupList}
                        selectedKeywordData={selectedKeywordData}
                        setSelectedKeywordData={setSelectedKeywordData}
                      />
                    </TabPanel>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SelectFromTable
                        isMultipleAdgroup={isMultipleAdgroup}
                        selectedKeywordData={selectedKeywordData}
                        setSelectedKeywordData={setSelectedKeywordData}
                      />
                    </TabPanel>
                  </Box>
                </TabContext>
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    {valueShow > 0 && (
                      <Box sx={{ display: 'flex' }}>
                        <Button
                          sx={{ marginRight: '10px', borderRadius: '12px' }}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={handleRemoveRow}
                        >
                          Remove
                        </Button>
                        <Button
                          sx={{ marginRight: '10px', borderRadius: '12px' }}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          // onClick={() => setSelectedKeywordData([])}
                        >
                          Apply Suggested Bid
                        </Button>
                        <Box sx={{ marginRight: '10px', borderRadius: '12px', color: '#FFC10E' }}>
                          <Select
                            // labelId="demo-simple-select-label"
                            // id="demo-simple-select"

                            variant="outlined"
                            // color="secondary"
                            // labelStyle={{ color: '#ff0000' }}
                            sx={{
                              color: '#FFC10E !important',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#FFC10E'
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#FFC10E'
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#FFC10E'
                              },
                              '.MuiSvgIcon-root ': {
                                fill: '#FFC10E !important'
                              },
                              width: '110px'
                            }}
                            value={myvalue || 'Change Type'}
                            size="small"
                            placeholder="Change Type"
                            onChange={handleChangeType}
                          >
                            <MenuItem value="Change Type" disabled>
                              Change Type
                            </MenuItem>
                            <MenuItem value="exact">exact</MenuItem>
                            <MenuItem value="phrase">phrase</MenuItem>
                            <MenuItem value="broad">broad</MenuItem>
                          </Select>
                        </Box>
                        <Button
                          sx={{ marginRight: '10px', borderRadius: '12px' }}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={handleSetBid}
                        >
                          Set Bid
                        </Button>
                      </Box>
                    )}
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      // sx={{ padding: 0 }}
                      data={selectedKeywordData}
                      columns={selectedColumns}
                      options={options}
                      className="pnl-by-item"
                    />
                    {bidModal && (
                      <Dialog
                        open={bidModal}
                        keepMounted
                        fullWidth
                        onClose={handleSetBid}
                        // maxWidth="lg"
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogTitle>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 700, color: 'black', pr: 2 }}>Set Bid</Typography>
                            <IconButton onClick={handleSetBid}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </DialogTitle>
                        <DialogContent dividers>
                          <TextField
                            value={bidValue}
                            onChange={(e) => setBidValue(e.target.value)}
                            type="number"
                            fullWidth
                            label="Bid"
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleSetBid} color="primary" variant="outlined">
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disable={bidValue?.length > 0 ? false : true}
                            onClick={handleSetBidConfirm}
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    )}
                  </MainCard>
                </Box>
              </Box>
              {error.adItem && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  Please select at lease one keyword
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              {errorMinBid && <Typography sx={{ color: 'red', marginRight: '16px' }}>Bid amount should be min 1.3! </Typography>}
              <Button variant="outlined" color="secondary" onClick={onClose}>
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ ml: 1, color: 'white' }}
                type="submit"
                disabled={loading && errorMinBid}
              >
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                UPDATE
              </Button>
            </>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TargetAdGroupSettingKeyword;
