import { IconButton, Button } from '@mui/material';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppSelector } from 'store';
import { useContext } from 'react';
import CampaignBrandContext from 'context/brand';
import { PageValue } from 'store/constant';

const SearchTable = () => {
  const { selectedKeywordData, setSelectedKeywordData } = useContext(CampaignBrandContext);
  const { suggestedkeywordData } = useAppSelector((state) => state?.userDetailsSlice);

  const onAddAllClick = () => {
    var tempArray = [...selectedKeywordData];

    suggestedkeywordData?.map((item) => {
      const existingItem = tempArray.find((existing) => existing.uuid === item.uuid);
      if (!existingItem) {
        tempArray.push({
          uuid: item?.uuid,
          keywordtext: item?.query,
          matchtype: item?.match_type,
          bid_amount: item?.suggested_bid ? item?.suggested_bid : '0.30',
          suggested_bid: item?.suggested_bid
        });
      }
    });
    setSelectedKeywordData(tempArray);
  };

  const columns = [
    {
      name: 'query',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'match_type',
      label: 'Match Type',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'score',
      label: 'Score',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'uuid',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const data = suggestedkeywordData?.find((item) => item?.uuid == value);
          const disable = selectedKeywordData?.find((item) => item?.uuid == value);
          const temp = {
            uuid: value,
            keywordtext: data?.query,
            matchtype: data?.match_type,
            bid_amount: data?.suggested_bid ? data?.suggested_bid : '0.30',
            suggested_bid: data?.suggested_bid
          };
          return (
            <>
              <IconButton
                sx={{ color: colors.secondaryMain }}
                disabled={disable ? true : false}
                onClick={() => {
                  setSelectedKeywordData([...selectedKeywordData, temp]);
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button variant="outlined" color="secondary" size="small" onClick={onAddAllClick}>
              Add All
            </Button>
          </th>
        )
      }
    }
  ];

  const options = {
    filter: true,
    filterTable: true,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    selectableRows: 'none',
    search: false,
    rowsPerPageOptions: PageValue,
    filterArrayFullMatch: true
  };
  return (
    <>
      <Box>
        <MainCard title="Suggested Keywords" sx={{ padding: 0 }}>
          <MUIDataTable
            actions={[
              {
                icon: 'save'
              }
            ]}
            sx={{ padding: 0 }}
            data={suggestedkeywordData}
            columns={columns}
            options={options}
            className="pnl-by-item"
          />
          {/* {modalOpen && (
        <CampaignModal open={modalOpen} onClose={handledClose}  />
      )} */}
        </MainCard>
      </Box>
    </>
  );
};

export default SearchTable;
