import { Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Loader } from 'rsuite';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { PageValue } from 'store/constant';
import { getBulkoperationsIdList } from 'store/thunk/userThunk';

const CampaignTable = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const data = location.state;
  const [loading, setLoading] = useState(true);
  const [mainCampaign, setMainCampaign] = useState([]);
  const { verifyCampaignFile } = useAppSelector((state) => state?.userDetailsSlice);

  useEffect(() => {
    if (verifyCampaignFile?.display_data?.bulk_data?.campaign) {
      setLoading(false);
      setMainCampaign(
        verifyCampaignFile?.display_data?.bulk_data?.campaign?.map((val) => {
          return {
            ...val,
            old_data: {
              old_name: val?.old_name,
              new_name: val?.name,
              old_targeting_type: val?.old_targeting_type,
              new_targeting_type: val?.targeting_type,
              old_budget_type: val?.old_budget_type,
              new_budget_type: val?.budget_type,
              old_total_budget: val?.old_total_budget,
              new_total_budget: val?.total_budget,
              old_end_date: val?.old_end_date,
              new_end_date: val?.end_date,
              old_daily_budget: val?.old_daily_budget,
              new_daily_budget: val?.daily_budget
            }
          };
        })
      );
    }
  }, [verifyCampaignFile]);

  const columns = [
    {
      name: 'action_type',
      label: 'Action Type',
      options: {
        filter: true,
        sort: data?.mainId && true
      }
    },
    {
      name: 'old_data',
      label: 'Campaign',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_name ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_name} --> ${value?.new_name}`}</Typography>
          ) : (
            <Typography>{value?.new_name}</Typography>
          );
        }
      }
    },
    {
      name: 'campaign_id',
      label: 'Campaign Id',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'old_data',
      label: 'Targeting Type',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_targeting_type ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_targeting_type} --> ${value?.new_targeting_type}`}</Typography>
          ) : (
            <Typography>{value?.new_targeting_type}</Typography>
          );
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'old_data',
      label: 'Budget Type',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_budget_type ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_budget_type} --> ${value?.new_budget_type}`}</Typography>
          ) : (
            <Typography>{value?.new_budget_type}</Typography>
          );
        }
      }
    },
    {
      name: 'old_data',
      label: 'Total Budget',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_total_budget ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_total_budget} --> ${value?.new_total_budget}`}</Typography>
          ) : (
            <Typography>{value?.new_total_budget}</Typography>
          );
        }
      }
    },
    {
      name: 'old_data',
      label: 'Daily Budget',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_daily_budget ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_daily_budget} --> ${value?.new_daily_budget}`}</Typography>
          ) : (
            <Typography>{value?.new_daily_budget}</Typography>
          );
        }
      }
    },
    {
      name: 'start_date',
      label: 'Start Date',
      options: {
        filter: true,
        sort: data?.mainId && true
      }
    },
    {
      name: 'old_data',
      label: 'End Date',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_end_date ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_end_date} --> ${value?.new_end_date}`}</Typography>
          ) : (
            <Typography>{value?.new_end_date}</Typography>
          );
        }
      }
    },
    {
      name: 'progress_status',
      label: 'Progress Status',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'error_message',
      label: 'Error Message',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    }
  ];

  const callApiOnSorting = (changedColumn = '', direction = '') => {
    dispatch(
      getBulkoperationsIdList({
        bulkId: data?.mainId,
        orderType: direction,
        orderColumn: changedColumn,
        orderKeyName: 'campaign'
      })
    );
  };

  const options = {
    search: false,
    viewColumns: false,
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    selectableRows: 'none',
    rowsPerPageOptions: PageValue,
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      callApiOnSorting(changedColumn, direction);
    }
  };

  return (
    <MUIDataTable
      actions={[
        {
          icon: 'save'
        }
      ]}
      // data={verifyCampaignFile?.display_data?.bulk_data?.campaign}
      data={mainCampaign || []}
      columns={columns}
      options={options}
      className="pnl-by-item"
    />
  );
};

export default CampaignTable;
