import React from "react";
import { Box, Card, CardContent, Typography, Skeleton } from "@mui/material";

function ReportItemBox(props) {
  const { itemLabel, itemValue, isLoading } = props;
  return (
    <Box
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        boxShadow: 2,
      }}
    >
      <Card sx={{ minWidth: "100%", minHeight: "150px" }}>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            color="textSecondary"
          >
            {itemLabel}
          </Typography>
          {isLoading ? <Skeleton variant="text" sx={{ height: '50px' }} /> :
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontWeight: "bold", mt: 2 }}
              color="inherit"
            >
              {itemValue}
            </Typography>
          }
        </CardContent>
      </Card>
    </Box>
  );
}

export default ReportItemBox;
