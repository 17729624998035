import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useAppDispatch } from "store";
import { updateCampaignModal } from "store/thunk/userThunk";

export default function CampaignSingleEdit(props){
    const {campaignEditModal, onClose} = props; 
    const [campaignName, setCampaignName] = useState(campaignEditModal.name);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

 const handleSaveButton = () => {
    setLoading(true);
    const _request = {
        [campaignEditModal?.type]: campaignEditModal?.type == "name" ? campaignName : Number(campaignName),
        advertiser: campaignEditModal?.advertiser
    }
    const existingCampaignId = campaignEditModal?.id;
    dispatch(updateCampaignModal({_request,existingCampaignId })).then((res) => {
        setLoading(false)
        if(res?.payload?.status == 201 || res?.payload?.status == 200) {
            onClose();
        }
    })
 }
    return(<Box>
        <Box sx={{margin: 1, mt: 2}}>
        <TextField value={campaignName} fullWidth onChange={(e) => setCampaignName(e.target.value)}/>
        </Box>
        <Box sx={{margin: 1, mt: 3}}>
        <Button sx={{mr:2}} color="secondary" variant="outlined" onClick={onClose}>Cancel</Button>
        <Button color="secondary" disabled={loading} variant="contained" onClick={handleSaveButton}>Save</Button>
        </Box>
    </Box>)
}

 