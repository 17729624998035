import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useContext } from 'react';
import CampaignContext from 'context';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { postCreateAdgroup, postCreateAditems, postCreateCampaign, updateAdgroup, updateCampaign } from 'store/thunk/userThunk';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EnterKeywordTable from './enterItemsTable';

import { CAMPAIGN } from 'constant/campaignConstant';
import { PageValue } from 'store/constant';
import SelectFromTableAdGroupSuggested from './suggestedTableAddCampaign';

const ProductAdGroupSetting = () => {
  const {
    handleBackwordButtonClick,
    campaignData,
    setCampaignData,
    searchableData,
    selectedData,
    setSelectedData,
    handleForwordButtonClick
  } = useContext(CampaignContext);

  const navigate = useNavigate();

  const { selectdItemData, existingCampaignId, existingAdgroupId } = useAppSelector((state) => state?.userDetailsSlice);
  const [value, setValue] = useState('2');
  const [error] = useState(false);
  const [keyword, setkeyword] = useState('');
  const [list, setList] = useState({});
  const [loading, setLoading] = useState({ campaign: '', adgroup: '', item: '' });

  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const formSchema = Yup.object().shape({
    adgroup_name: Yup.string().required('Adgroup name is required')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      adgroup_name: campaignData?.adgroup ? campaignData?.adgroup?.name : ''
    }
  });

  const adgorupNameValue = watch('adgroup_name');

  const onButtonClick = (event) => {
    setList({});
    const temp = selectedData?.map((item) => {
      return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : '0.20' };
    });
    setCampaignData({ ...campaignData, adgroup: { name: event?.adgroup_name } });

    setDialogOpen(true);
    setLoading({ ...loading, campaign: 'process' });
    const _request = campaignData?.campaign;
    dispatch(existingCampaignId ? updateCampaign({ _request, existingCampaignId }) : postCreateCampaign({ _request })).then(
      (res) => {
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          setLoading({ ...loading, campaign: 'done', adgroup: 'process' });
          const _adgroupRequest = {
            name: event?.adgroup_name,
            status: 'enabled',
            campaign: res?.payload?.data?.data?.id
          };
          dispatch(
            existingAdgroupId ? updateAdgroup({ _adgroupRequest, existingAdgroupId }) : postCreateAdgroup({ _adgroupRequest })
          ).then((res) => {
            if (res?.payload?.status == 201 || res?.payload?.status == 200) {
              setLoading({ ...loading, campaign: 'done', adgroup: 'done', item: 'process' });
              const _aditemRequest = {
                campaign: res?.payload?.data?.data?.campaign,
                adgroup: res?.payload?.data?.data?.id,
                add_items: temp
              };
              dispatch(postCreateAditems({ _aditemRequest })).then((res) => {
                if (res?.payload?.status == 201) {
                  setList(res?.payload?.data);
                  setLoading({ ...loading, campaign: 'done', adgroup: 'done', item: 'done' });
                } else {
                  setLoading({ ...loading, campaign: 'done', adgroup: 'done', item: 'error' });
                }
              });
            } else {
              setLoading({ ...loading, campaign: 'done', adgroup: 'error' });
            }
          });
        } else {
          setLoading({ ...loading, campaign: 'error' });
        }
      }
    );
  };

  const handlePreviousButton = () => {
    handleBackwordButtonClick();
    setCampaignData({ ...campaignData, adgroup: { name: adgorupNameValue } });
    setLoading({ campaign: '', adgroup: '', item: '' });
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20',
            suggested_bid: item?.suggested_bid
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20',
            suggested_bid: item?.suggested_bid
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const selectedColumns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <Typography sx={{}}>{data?.product_name}</Typography> */}

                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <TextField
                size="small"
                value={data?.bid_amount}
                type="number"
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => {
                  if (Number(e.target.value) < 0.2) {
                    const newPrice = '0.20';
                    const updatedData = [...selectedData];
                    updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                    setSelectedData(updatedData);
                  }
                }}
                onChange={(e) => {
                  const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                  const updatedData = [...selectedData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedData(updatedData);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  step: '0.01'
                }}
              />
            </>
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedData];
                  tempData = tempData?.filter((item) => item?.item_id != value);
                  setSelectedData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    toolbar: false,
    search: false
  };

  const handleOkButtonClick = () => {
    if (loading.campaign == 'done' && loading.adgroup == 'done' && loading.item == 'done' && !list?.errorMessages?.length) {
      handleForwordButtonClick();
    } else {
      const array = selectedData.filter((item) => !list?.successlist?.find((list) => list?.item_id == item?.item_id));
      setSelectedData(array);
    }
    setDialogOpen(false);
    setLoading({ campaign: '', adgroup: '', item: '' });
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onButtonClick)}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              {/* {renderLabel('Adgroup Name', true)} */}
              <Controller
                name={'adgroup_name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('adgroup_name')}
                    {...field}
                    name={'adgroup_name'}
                    fullWidth
                    type="text"
                    label="Adgroup Name"
                    variant="outlined"
                    error={errors?.adgroup_name}
                    helperText={errors?.adgroup_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="3">
                      {' '}
                      <SelectFromTableAdGroupSuggested onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable keyword={keyword} setkeyword={setkeyword} />
                    </TabPanel>
                  </Box>
                </TabContext>

                {error && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    {'Please select atlease one item.'}
                  </FormHelperText>
                )}
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h5">{selectedData?.length} Item(s)Added</Typography>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      columns={
                        campaignData?.campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                          ? selectedColumns?.filter((item) => item?.name != 'bid_amount' && item?.name != 'suggested_bid')
                          : selectedColumns
                      }
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
                CANCEL
              </Button>
              <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handlePreviousButton}>
                PREVIOUS
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
                {/* {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />} */}
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
      <Dialog open={dialogOpen} aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="div"> Message</Typography>{' '}
        </DialogTitle>
        <DialogContent dividers sx={{ minWidth: '600px', minHeight: '200px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.campaign == 'process' && <CircularProgress size={20} />}
              {loading.campaign == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.campaign == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Campaign (${campaignData?.campaign?.name}) ${existingCampaignId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.adgroup == 'process' && <CircularProgress size={20} />}
              {loading.adgroup == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.adgroup == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Adgroup (${campaignData?.adgroup?.name}) ${existingAdgroupId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.item == 'process' && <CircularProgress size={20} />}
              {loading.item == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.item == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`${list?.data?.successcount ? list?.data?.successcount : 0} items added successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              {list?.errorMessages?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex' }}>
                  {' '}
                  <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                    {list?.errorMessages?.length && <HighlightOffIcon color="error" />}
                  </Box>
                  <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ margin: 1 }} onClick={handleOkButtonClick} color="secondary" variant="contained" autoFocus size="small">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductAdGroupSetting;
