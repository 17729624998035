import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Typography } from '@mui/material';
import { useAppSelector } from 'store';
import colors from "assets/scss/_themes-vars.module.scss";

export default function DeleteModalDialog({ open, cancelCallback, deleteCallback, item = 'item', profile, IsLoadingDelete = false }) {
  const dataLoading = useAppSelector((state) => state.dataLoading);

  return (
    <div>
      <Dialog
        open={open}
        onClose={cancelCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>

        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure want to delete this ${item ? item : 'item'}?`}
            <br />
            <br />
            {profile == 'AdvertiserDashboard' && (
              <Typography sx={{ fontWeight: 700 }}>
                <Typography sx={{ color: 'red' }}>Note:</Typography> This will delete all data related to this profile permanently
                from this system, including campaigns, ad groups, reports etc.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={cancelCallback}>
            Cancel
          </Button>
          <Button
            onClick={deleteCallback}
            variant="contained"
            color="error"
            autoFocus
            disabled={dataLoading.loading || IsLoadingDelete}
          >
            {(dataLoading.loading || IsLoadingDelete) && (
              <CircularProgress
                sx={{ color: profile === 'AdvertiserDashboard' ? '#0000001f' : colors?.errorMain, mr: '10px' }}
                size="10px"
              />
            )}
            {profile == 'AdvertiserDashboard' ? 'Confirm' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
