import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './slices/authSlice';
import notificationReducer from './slices/notificationSlice';
import dataLoadingReducer from './slices/dataLoadingSlice';
import resetLoading from './slices/resetLoading';
import userDetailsSlice from './slices/userDetailsSlice';
import reportDataSlice from './slices/reportDataSlice';
import superUserSlice from './slices/superUserSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  authorization: authReducer,
  notification: notificationReducer,
  dataLoading: dataLoadingReducer,
  resetLoadingSlice: resetLoading,
  userDetailsSlice: userDetailsSlice,
  reportDataSlice: reportDataSlice,
  superUserSlice: superUserSlice,
});

export default reducer;
