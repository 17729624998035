import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import FilterIcon from 'assets/images/dashna/filter.svg';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { filterArray } from 'store/constant';
import { getSelectedFilterList, postSelectedFilterDataCampaign } from 'store/thunk/userThunk';
import { Add, Remove } from '@mui/icons-material';

export default function CustomFilters(props) {
  const { filterType, defaultFilterKey } = props;
  const { filteredData } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useDispatch();
  const [filterModal, setFilterModal] = useState({ open: false, id: null });
  const [selectedChip, setSelectedChip] = useState({});
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (filteredData?.length > 0) {
      if (filteredData[0][filterType]) {
        setSelectedChip(filteredData[0][filterType]);
      } else {
        setSelectedChip({});
      }
    }
  }, [filteredData]);

  const handleChipClick = (key, data) => {
    let updatedSelectedChip = {};
    if (selectedChip[key]?.find((ele) => ele?.id == data?.id)) {
      updatedSelectedChip = { ...selectedChip, [key]: selectedChip[key]?.filter((item) => item?.id != data?.id) };
    } else {
      updatedSelectedChip = { ...selectedChip, [key]: selectedChip[key]?.length ? [...selectedChip[key], data] : [data] };
    }
    setSelectedChip(updatedSelectedChip);
  };

  const handleApplyChanges = () => {
    const _request = {
      [filterType]: selectedChip
    };

    setLoading(true);
    dispatch(postSelectedFilterDataCampaign(_request)).then(() => {
      dispatch(getSelectedFilterList()).then(() => {
        setLoading(false);
        setFilterModal({ open: false });
      });
    });
  };

  const getSettings = (value, item) => {
    const getStatus =
      selectedChip[value]?.find((ele) => ele?.id == item?.id) ||
      item?.default ||
      (defaultFilterKey && defaultFilterKey === item?.value);

    const response = {};

    if (getStatus) {
      response.icon = <Remove />;
      response.variant = 'filled';
      response.bgcolor = colors?.darkTextHover;
      // return <Remove />;
    } else {
      response.icon = <Add />;
      response.variant = 'outlined';
      response.bgcolor = '';
      // return <Add />;
    }
    return response;
  };

  return (
    <Box sx={{ pl: '0px', marginLeft: '0px' }}>
      <Button
        sx={{
          py: '12px',
          fontWeight: 400,
          fontFamily: 'Inter',
          borderRadius: '8px',
          border: `1px solid ${colors.primaryMain}`,
          color: colors.primaryMain
        }}
        startIcon={<img src={FilterIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />}
        onClick={() => setFilterModal({ open: true })}
        color="secondary"
        size=""
        variant="outlined"
      >
        filter
      </Button>

      <Dialog open={filterModal.open} keepMounted maxWidth="md" aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="div">All Filters</Typography>
            <Typography
              sx={{ display: 'flex', justifyContent: 'end', cursor: 'pointer', alignItems: 'center' }}
              onClick={() => setFilterModal({ open: false })}
            >
              <CloseIcon />{' '}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ padding: 2 }}>
            {filterArray &&
              filterArray[filterType] &&
              Object?.keys(filterArray[filterType])?.map((value) => (
                <>
                  <Typography className="fs16 fw600" sx={{ mb: '10px', color: colors?.primaryTextColor }}>
                    {`${value} (${filterArray?.[filterType]?.[value]?.length})`}
                  </Typography>
                  <Box mb={1}>
                    {filterArray?.[filterType]?.[value]?.map((item) => (
                      <Chip
                        className="fs14 ffInter"
                        onClick={() => handleChipClick(value, item)}
                        label={item?.name}
                        key={item?.id}
                        color="secondary"
                        icon={getSettings(value, item)?.icon}
                        variant={getSettings(value, item)?.variant}
                        disabled={item?.default ? item?.default : false}
                        sx={{ borderRadius: '8px', bgcolor: getSettings(value, item)?.bgcolor, margin: '0px 10px 15px 0px' }}
                      />
                    ))}
                  </Box>
                </>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setFilterModal({ open: false })}>
            Close
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} color="secondary" onClick={handleApplyChanges} disabled={loading}>
            {/* loading */}
            {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
