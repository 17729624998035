import { Controller } from 'react-hook-form';
import { TextField, InputAdornment } from '@mui/material';
import { useState } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordField = (props) => {
  const { control, rules, disabled, name, errors, label, fullWidth, helperText } = props;
  const [show, setShow] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        minLength: 5,
        pattern: {
          value: /^(\S+$)/,
          message: 'invalid email address'
        }
      }}
      render={({ field }) => (
        <TextField
          {...field}
          type={show ? 'text' : 'password'}
          fullWidth={fullWidth}
          label={label}
          disabled={disabled}
          error={errors[name]}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                {show ? (
                  <VisibilityOffIcon onClick={() => setShow(!show)} />
                ) : (
                  <RemoveRedEyeIcon onClick={() => setShow(!show)} />
                )}
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export default PasswordField;
