import { useState, useEffect } from 'react';
import routes from './routes';
import { useAppSelector } from 'store';

//Function that remove particular url route from given routes array
const removeMenuRoutes = (items, urlToRemove) => {
  if (Array.isArray(items)) {
    return items
      .map((item) => {
        if (item.children) {
          // Recursively call removeMenuRoutes on the children
          item.children = removeMenuRoutes(item.children, urlToRemove);
        }
        return item.url != urlToRemove ? item : null;
      })
      .filter(Boolean); // Filter out any null values
  }
  return items;
};

export default function useMenuItems() {
  const [menuItems, setMenuItems] = useState({});
  const { userData } = useAppSelector((state) => state.authorization);

  useEffect(() => {
    //Permission checking
    const bulkOperation = userData?.permission?.bulk_operation || {};
    const bulkOperationAccess = bulkOperation?.access;
    const user = userData?.permission?.user || {};
    const userAccess = user?.access;
    const advertiser = userData?.permission?.advertiser || {};
    const advertiserAccess = advertiser?.access;

    const allMenuItems = {
      items: [routes?.routes, routes?.settingsRoutes]
    };

    let newMenuItems = {
      ...allMenuItems
    };

    //Remove Route link based on permission
    if (!bulkOperationAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/bulk-operations')
      };
    }
    if (!userAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/user-management')
      };
    }
    if (!advertiserAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/advertiser')
      };
    }

    setMenuItems(newMenuItems);
  }, [userData]);

  return menuItems;
}
