import React, { useState } from 'react';
import { IconButton, debounce, Typography, Tooltip, TableCell, Box } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable from 'mui-datatables';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect } from 'react';
import { deleteUser, getVerifiedUserList, postInviteUser } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import DeleteModalDialog from 'ui-component/DeleteModal';
import { useCallback } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { getUserType } from 'helper/commonHelper';
import { Loader } from 'rsuite';
import { PageValue } from 'store/constant';
import upIcon from 'assets/images/dashna/up.svg';
import downIcon from 'assets/images/dashna/down.svg';
import __ from 'underscore';

const VerifiedUserList = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [deleteData, setDeleteData] = useState();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { verifyUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    getAdvertiserListWithPagination(page, rowPerPage);
    if (verifyUserDetails?.results) {
      setLoading(false);
    }
  }, []);

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    dispatch(getVerifiedUserList({ page, rowPerPage, search, changedColumn, direction }));
  };

  const handleOnclick = (email) => {
    const _request = {
      email: email
    };
    dispatch(postInviteUser({ _request }));
  };

  const deleteSellerInfo = () => {
    setOpenDelete(true);
    const data = verifyUserDetails?.results?.find((item) => item?.id == deleteData);
    let user_id = data?.id;
    dispatch(deleteUser({ user_id })).then(() => {
      setOpenDelete(false);
      getAdvertiserListWithPagination(page, rowPerPage, search);
    });
  };

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
            {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  const columns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'email',
            columnLabel_1: 'Email'
          });
        }
      }
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'first_name',
            columnLabel_1: 'First Name'
          });
        }
      }
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'last_name',
            columnLabel_1: 'Last Name'
          });
        }
      }
    },

    {
      name: 'user_type',
      label: 'User Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{getUserType(value)}</Typography>;
        },
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'user_type',
            columnLabel_1: 'User Type'
          });
        }
      }
    },
    {
      name: 'is_active',
      label: 'Active',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'is_active',
            columnLabel_1: 'Active'
          });
        },
        customBodyRender: (value) => <Typography>{value ? 'Active' : 'Inactive'}</Typography>
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'id',
            columnLabel_1: 'Actions'
          });
        },
        customBodyRender: (value) => {
          const filter = verifyUserDetails?.results?.find((item) => item?.id == value);
          return (
            <>
              {filter?.is_invite && (
                <Tooltip title="Resend Mail">
                  <IconButton onClick={() => handleOnclick(filter?.email)}>
                    <SendIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    selectableRows: 'none',
    page: page,
    jumpToPage: false,
    count: verifyUserDetails?.count || 0,
    download: false,
    pagination: true,
    serverSide: true,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onChangePage: (page) => {
      setPage(page);
      getAdvertiserListWithPagination(page, rowPerPage, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    },
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    }
  };

  return (
    <MainCard title="User Invite">
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={verifyUserDetails?.results}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />

      {openDelete && (
        <DeleteModalDialog item="user" cancelCallback={toggleDeleteModal} deleteCallback={deleteSellerInfo} open={openDelete} />
      )}
    </MainCard>
  );
};

export default VerifiedUserList;
