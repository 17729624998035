import PrivateRoute from "PrivateRoute";
import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import UserManagement from 'views/userManagement';
import CompanyUsersList from 'views/superAdminDashboard/companyUsersList/index'
import ProfileManagement from 'views/profile';
import Advertiser from "views/superAdminDashboard/advertiser";
// import DashboardData from "views/dashboard/dashboardData";
// import DashboardData from "views/dashboard/dashboardData";
// import DashboardData from "views/dashboard/dashboardData";

const Dashboard = Loadable(lazy(() => import('views/superAdminDashboard/index')));
export const SuperAdminRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '*',
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            )
        },
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            )
        },
      
        {
            path: '/dashboard',
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            )
        },
        // {
        //     path: 'list1',
        //     element: (
        //         <PrivateRoute>
        //              <DashboardData />
        //         </PrivateRoute>
        //     )
        // },
        {
            path: '/company_list',
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            )
        },
        {
            path: 'user-management',
            element: (
                <PrivateRoute>
                    <UserManagement />
                </PrivateRoute>
            )
        },
        {
            path: '/company-users-list/:id',
            element: (
                <PrivateRoute>
                    <CompanyUsersList />
                </PrivateRoute>
            )
        },
        {
            path: 'profile',
            element: (
                <PrivateRoute>
                    <ProfileManagement />
                </PrivateRoute >
            )
        },
        {
            path: 'advertiser',
            element: (
                <PrivateRoute>
                    <Advertiser />
                </PrivateRoute >
            )
        } 
    ]
}
