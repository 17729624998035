import { useState } from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { updateProfileDetails } from 'store/thunk/userThunk';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
  // Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Warning from 'assets/images/dashna/warning.svg';
// project imports
import __ from 'underscore';
import { useEffect } from 'react';

const EmailVerificationSuccess = () => {
  const userAuthData = useAppSelector((state) => state.authorization);
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    open
    // setOpen
  ] = useState(true);
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    phone_number: '',
    location: '',
    annual_walmart_sales: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [beError, setBeError] = useState('');

  //Color

  const primaryTextColor = colors?.primaryTextColor;
  const errorBackground = colors?.errorBackground;

  //Auto Fill Values
  useEffect(() => {
    const first_name = userAuthData?.userData?.first_name;
    const last_name = userAuthData?.userData?.last_name;
    const company_name = userAuthData?.userData?.company_name;

    setFormValues({
      ...formValues,
      first_name,
      last_name,
      company_name
    });
  }, []);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  //While input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    setError((prevState) => ({
      ...prevState,
      ...(name === 'phone_number' && { ['valid_phone_number']: false }),
      [name]: false
    }));

    setBeError('');
  };

  //Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneRegex = /^\+?(\d[\d-.()\s]*){10,15}$/; //regular expression for phone numbers

    //Error checking
    let errs = {};
    const { first_name, last_name, company_name, phone_number, location, annual_walmart_sales } = formValues;
    if (!first_name) errs.first_name = true;
    if (!last_name) errs.last_name = true;
    if (!company_name) errs.company_name = true;
    if (!phone_number) errs.phone_number = true;
    if (!location) errs.location = true;
    if (!annual_walmart_sales) errs.annual_walmart_sales = true;

    if (!phoneRegex.test(phone_number)) {
      errs.valid_phone_number = true;
    }

    setError(errs);
    if (!__.isEmpty(errs)) {
      return;
    }

    //API Calling Start
    setLoading(true); //Invoke Loader....
    setBeError('');

    let formData = new FormData();
    formData.append('first_name', formValues?.first_name);
    formData.append('last_name', formValues?.last_name);
    formData.append('phone_number', formValues?.phone_number);
    formData.append('location', formValues?.location);
    formData.append('annual_walmart_sales', formValues?.annual_walmart_sales);
    formData.append('display_module_fe', 'create-store');

    appDispatch(updateProfileDetails({ formData, setBeError, setLoading, navigate }));
  };

  return (
    <>
      <Dialog
        maxWidth={'sm'}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
      >
        <DialogContent dividers>
          <Box px={8} py={4}>
            <Box pb={2.5}>
              <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                Thank you for verifying your email.{' '}
              </Typography>
              <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                Please complete your profile.
              </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Box display={'flex'} pl={1} gap={'18px'} flex={1}>
                  <Grid item xs={6}>
                    <TextField
                      label="First Name"
                      name="first_name"
                      error={error?.first_name}
                      value={formValues?.first_name}
                      onChange={handleInputChange}
                      margin="normal"
                      fullWidth
                      helperText={error?.first_name ? 'Please add first name' : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Last Name"
                      name="last_name"
                      error={error?.last_name}
                      value={formValues?.last_name}
                      onChange={handleInputChange}
                      margin="normal"
                      fullWidth
                      helperText={error?.last_name ? 'Please add last name' : ''}
                    />
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    label="Company Name"
                    name="company_name"
                    error={error?.company_name}
                    value={formValues?.company_name}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                    helperText={error?.company_name ? 'Please add company name' : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    error={error?.phone_number || error?.valid_phone_number}
                    value={formValues?.phone_number}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                    helperText={
                      error?.phone_number
                        ? 'Please add phone number'
                        : error?.valid_phone_number
                        ? 'Please add valid phone number'
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Location"
                    name="location"
                    error={error?.location}
                    value={formValues?.location}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                    helperText={error?.location ? 'Please add location' : ''}
                  />
                </Grid>

                <Grid item xs={12} pt={2} pb={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Annual Walmart Sales</InputLabel>
                    <Select
                      label="Annual Walmart Sales"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formValues?.annual_walmart_sales}
                      error={error?.annual_walmart_sales}
                      name="annual_walmart_sales"
                      onChange={handleInputChange}
                    >
                      <MenuItem value={'0-10000'}>$0 - $10,000</MenuItem>
                      <MenuItem value={'10000-100000'}>$10,000 - $100,000</MenuItem>
                      <MenuItem value={'100000-1000000'}>$100,000 - $1,000,000</MenuItem>
                      <MenuItem value={'100000-1000000'}>$100,000 - $1,000,000</MenuItem>
                      <MenuItem value={'1000000-10000000'}>$1,000,000 - $10,000,000</MenuItem>
                      <MenuItem value={'10000000+'}>$10,000,000+</MenuItem>
                    </Select>
                    <FormHelperText error={true}>
                      {error?.annual_walmart_sales ? 'Please select annual walmart sales' : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {beError?.length > 0 && (
                  <Grid item xs={12} pb={3}>
                    <Box
                      display={'flex'}
                      gap={2}
                      alignItems={'center'}
                      p={2}
                      borderRadius={'10px'}
                      backgroundColor={errorBackground}
                    >
                      <Box>
                        <img src={Warning} alt="Warning" />
                      </Box>
                      <Box>
                        <Typography>{beError}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    className={'actionBtn'}
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailVerificationSuccess;
