import React from 'react';
import { Grid, Typography, CircularProgress, Box, Link } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { GridRowModes, DataGrid, GridActionsCellItem, GridRowEditStopReasons, gridClasses } from '@mui/x-data-grid';

import { useAppSelector, useAppDispatch } from 'store';
import { updateAdItemsTableItem } from 'store/thunk/userThunk';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import colors from 'assets/scss/_themes-vars.module.scss';
import BidAmountEditInputCell from './CustomBidAmountCellField';

const SPONSOR_COLUMNS = {
  adgroup_name: true,
  item_name: true,
  bid_amount: true,
  status: true,
  actions: true
};

const BRAND_COLUMNS = {
  adgroup_name: true,
  item_name: true,
  bid_amount: false,
  status: true,
  actions: true
};

const AdgroupSettingsDetails = (props) => {
  const { bidVisibility } = props;
  const dispatch = useAppDispatch();

  //Permission checking
  const { userData } = useAppSelector((state) => state.authorization);
  const campaignPermission = userData?.permission?.campaign || {};
  const { update } = campaignPermission;

  const [columnVisible, setColumnVisible] = React.useState(SPONSOR_COLUMNS);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0
  });
  const [rows, setRows] = React.useState([]);
  const [inprogress, setInProgress] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  React.useEffect(() => {
    const newColumns = bidVisibility ? SPONSOR_COLUMNS : BRAND_COLUMNS;
    setColumnVisible(newColumns);
  }, [bidVisibility]);

  const { campaignDetailViewData } = useAppSelector((state) => state?.userDetailsSlice);

  React.useEffect(() => {
    const TableRows = campaignDetailViewData?.aditems;
    setRows(TableRows || []);
  }, [campaignDetailViewData]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };

    setInProgress([...inprogress, updatedRow.id]);
    // Call API For change in db
    const _adItemsRequest = {
      status: updatedRow.status
    };

    if (bidVisibility) {
      _adItemsRequest.bid_amount = updatedRow.bid_amount;
    }
    const existingAdItemId = updatedRow.id;
    const response = await dispatch(updateAdItemsTableItem({ _adItemsRequest, existingAdItemId }));

    //Remove item after response
    setInProgress(inprogress.filter((item) => item !== updatedRow.id));

    if (response.payload.status == 201 || response.payload.status == 200) {
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      return oldRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'adgroup_name',
      headerName: 'Adgroup Name',
      width: 150,
      editable: false
    },
    {
      field: 'item_name',
      headerName: 'Item',
      width: 350,
      editable: false,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {rowData?.images ? (
              <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={rowData?.images} />
            ) : (
              <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
            )}
            <Box>
              <Typography sx={{ whiteSpace: 'break-spaces' }}>{rowData?.product_name}</Typography>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => window.open(`https://www.walmart.com/ip/${rowData?.item_id}`, '_blank')}
              >
                <Typography sx={{ color: colors.primaryMain }}>{rowData?.item_id ? rowData?.item_id : '123456789'}</Typography>
              </Link>
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'bid_amount',
      headerName: 'Bid',
      width: 130,
      editable: true,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `$ ${parseFloat(params.value).toFixed(2).toLocaleString()}`;
      },
      renderEditCell: (params) => {
        const passParams = { ...params, value: parseFloat(params.value).toFixed(2) };
        return <BidAmountEditInputCell {...passParams} restrictAmt="0.20" />;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['enabled', 'disabled'],
      renderCell: (params) => params.value.toUpperCase()
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
              key={`save-${id}`}
              disabled={!update}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              key={`delete-${id}`}
              disabled={!update}
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={inprogress.includes(id) ? <CircularProgress size="1rem" /> : <EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            key={`edit-${id}`}
            disabled={!update}
          />
        ];
      }
    }
  ];

  return (
    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
      <Grid item sm={12}>
        <Typography variant="h4">AdItems</Typography>
      </Grid>
      <Grid item sm={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{
            '& .MuiDataGrid-virtualScroller ': {
              minHeight: '100px !important'
            },
            [`& .${gridClasses.cell}`]: {
              py: 1
            }
          }}
          getRowHeight={() => 'auto'}
          columnVisibilityModel={columnVisible}
        />
      </Grid>
    </Grid>
  );
};

export default AdgroupSettingsDetails;
