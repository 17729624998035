import { Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Loader } from 'rsuite';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { PageValue } from 'store/constant';
import { getBulkoperationsIdList } from 'store/thunk/userThunk';

const AdgroupTable = () => {
  const location = useLocation();
  const data = location.state;
  const [loading, setLoading] = useState(true);
  const [mainGroup, setMainGroup] = useState([]);
  const { verifyCampaignFile } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (verifyCampaignFile?.display_data?.bulk_data?.adgroup) {
      setLoading(false);
      setMainGroup(
        verifyCampaignFile?.display_data?.bulk_data?.adgroup?.map((val) => {
          return {
            ...val,
            old_data: {
              old_name: val?.old_name,
              new_name: val?.name
            }
          };
        })
      );
    }
  }, [verifyCampaignFile]);

  const columns = [
    {
      name: 'action_type',
      label: 'Action Type',
      options: {
        filter: true,
        sort: data?.mainId && true
      }
    },
    {
      name: 'old_data',
      label: 'Adgroup',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => {
          return value?.old_name ? (
            <Typography style={{ color: 'red' }}>{`${value?.old_name} --> ${value?.new_name}`}</Typography>
          ) : (
            <Typography>{value?.new_name}</Typography>
          );
        }
      }
    },
    {
      name: 'adgroup_id',
      label: 'Adgroup Id',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'campaign__name',
      label: 'Campaign',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'progress_status',
      label: 'Progress Status',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'error_message',
      label: 'Error Message',
      options: {
        filter: true,
        sort: data?.mainId && true,
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    }
  ];

  const callApiOnSorting = (changedColumn = '', direction = '') => {
    dispatch(
      getBulkoperationsIdList({ bulkId: data?.mainId, orderType: direction, orderColumn: changedColumn, orderKeyName: 'adgroup' })
    );
  };

  const options = {
    search: false,
    viewColumns: false,
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    selectableRows: 'none',
    rowsPerPageOptions: PageValue,
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      callApiOnSorting(changedColumn, direction);
    }
  };

  return (
    <MUIDataTable
      actions={[
        {
          icon: 'save'
        }
      ]}
      // data={verifyCampaignFile?.display_data?.bulk_data?.adgroup}
      data={mainGroup || []}
      columns={columns}
      options={options}
      className="pnl-by-item"
    />
  );
};

export default AdgroupTable;
