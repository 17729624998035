import React, { useState } from 'react';
import { Grid, Box, Typography, Button, TextField, IconButton, InputAdornment } from '@mui/material';
import colors from 'assets/scss/_themes-vars.module.scss';
import Warning from 'assets/images/dashna/warning.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router';
import { loginApi } from 'store/thunk/authThunk';
import CustomLoader from 'ui-component/custom-loader';
import SignUPFlowLeftSide from 'ui-component/sign-up-flow-left-side';
import { Link } from 'react-router-dom';

const SignIn = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const [BeError, SetBeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const appDispatch = useAppDispatch();
  const errorBackground = colors?.errorBackground;
  const navigate = useNavigate();

  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: formErrors?.length > 0 ? colors.errorMain : colors.darkPrimaryMain
      }
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: formErrors?.length > 0 ? colors.errorMain : colors.darkPrimaryMain
      }
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: ''
    }));
    SetBeError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        const formattedKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
        const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
        errors[key] = `${capitalizedKey} is required!`;
      } else if (key === 'email' && !emailRegex.test(formValues[key])) {
        errors[key] = 'Email is not valid!';
      }
    });
    if (formValues?.password && formValues?.password?.length < 8) {
      errors.password = 'Password must be at least 8 characters!';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      const requestData = {
        email: formValues.email.toLowerCase(),
        password: formValues.password,
        navigate: navigate,
        setLoading: setLoading,
        SetBeError: SetBeError
      };
      appDispatch(loginApi(requestData));
    }
  };

  return (
    <Box>
      <Grid container className="sign-main" columns={12}>
        <Grid sx={{ bgcolor: colors.darkPrimaryMain }} xs={5} className="sign-in-left-side">
          <SignUPFlowLeftSide />
        </Grid>
        <Grid xs={7} className="sign-in-right-side">
          <Box className="right-side-content-wrapper">
            <Box className="sign-in-right-form-container">
              <Typography className="sign-up-title">Welcome back</Typography>
              <Typography className="sign-up-sub-title" mt={1} mb={5}>
                Enter your login details below
              </Typography>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <CustomLoader />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        sx={textFieldStyles}
                        id="email"
                        autoFocus="true"
                        variant="outlined"
                        fullWidth
                        placeholder="Email Address"
                        label="Email Address"
                        value={formValues?.email || ''}
                        onChange={handleInputChange}
                        error={!!formErrors.email}
                        helperText={formErrors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={textFieldStyles}
                        id="password"
                        variant="outlined"
                        fullWidth
                        placeholder="Password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={formValues?.password || ''}
                        onChange={handleInputChange}
                        error={!!formErrors.password}
                        helperText={formErrors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {' '}
                      <Typography variant="body2" textAlign={'right'}>
                        <Link to="/forget-password"> Forgot password?</Link>
                      </Typography>
                    </Grid>
                    {BeError?.length > 0 && (
                      <Grid item xs={12} pt={2} pb={1}>
                        <Box
                          display={'flex'}
                          gap={2}
                          alignItems={'center'}
                          p={2}
                          borderRadius={'10px'}
                          backgroundColor={errorBackground}
                        >
                          <Box>
                            <Link to="#">
                              <img src={Warning} alt="Warning" />
                            </Link>
                          </Box>
                          <Box>
                            <Typography>{BeError}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Button
                          fullWidth
                          type="submit"
                          className="button-for-sign-up"
                          variant="contained"
                          sx={{ bgcolor: colors.darkPrimaryMain, color: colors.paper, padding: '11px 9px' }}
                        >
                          Sign In
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Typography variant="body2" textAlign={'center'}>
                          {`Don’t have an account?`} <Link to="/register">Sign Up</Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </form>
            </Box>
            <Box>
              <Typography className="sign-up-sub-title">
                By continuing, you agree to Dashna’s Terms of Service and Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignIn;
