import {  Grid, InputAdornment, MenuItem, Select, TextField, Typography, Button, FormHelperText, CircularProgress  } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import FormHelpText from 'rsuite/esm/FormHelpText';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { updateCampaign } from 'store/thunk/userThunk';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { endDateValidator } from 'helper/commonHelper'; 
import { CAMPAIGN } from 'constant/campaignConstant';

const CampaignSetting = (props) => {
    const { campaign, setCampaign, onClose } = props;
    const { allUserDetails, campaignById } = useAppSelector((state) => state?.userDetailsSlice);

    const dispatch = useAppDispatch();

    const [budgetType, setBudgetType] = useState("both");
    const [dailyBudget, setDailyBudget] = useState(null);
    const [loading, setLoading] = useState(null)
    const [dateType] = useState("any");
 
    const [advertiserType, setAdvertiserType] = useState("");

    useEffect(()=>{
        if(campaign?.advertiser){
            const advertiseObjArray = allUserDetails.filter(obj => obj.id == campaign?.advertiser);
            if(advertiseObjArray?.length){
                const advertiseObj =  advertiseObjArray[0];               
                setAdvertiserType(advertiseObj?.advertiser_type || "");
            }            
        }
    },[])
   

    const renderLabel = (name, required) => {
        return (
            <Box><Typography sx={{ padding: 0.5, display: 'flex' }}>{required && (<Box sx={{ color: 'red', mr: 0.5 }}>*</Box>)}  {name}</Typography></Box>
        )
    }

    const dailyBudgetValid = advertiserType == "3p" ? 'For 3P, Minimum daily budget is 10' : 'For 1P, Minimum daily budget is 50';
    const totalBudgetValid = advertiserType == "3p" ? 'For 3P, Minimum total budget is 50' : 'For 1P, Minimum total budget is 100';

    const formSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        advertiser: Yup.string().required("Advertiser is required"),
        budget_type: Yup.string().required("Budget Type is required"),
        daily_budget:
          budgetType == "daily" || budgetType == "both"
            ? Yup.string()
                .required("Daily Budget is required")
                .test("daily_budget", dailyBudgetValid, function (value) {
                  return advertiserType == "3p"
                    ? Number(value) >= 10
                    : Number(value) >= 50;
                })
            : "",
        total_budget:
          budgetType == "both"
            ? Yup.string()
                .required("Total Budget is required")
                .test(
                  "total_budget",
                  "Total budget is greter than daily",
                  function (value) {
                    return Number(value) >= Number(dailyBudget);
                  },
                )
                .test("total_budget", totalBudgetValid, function (value) {
                  return advertiserType == "3p"
                    ? Number(value) >= 50
                    : Number(value) >= 100;
                })
            : budgetType == "total"
            ? Yup.string()
                 .required("Total Budget is required")
                 .test("total_budget", totalBudgetValid, function (value) {
                    return advertiserType == "3p"
                      ? Number(value) >= 50
                      : Number(value) >= 100;
                  })
            : "",
        start_date:
          dateType == "select"
            ? Yup.string().required("Date is required").nullable()
            : null,
        end_date: Yup.string().when("start_date", (start_date, schema) => {
          if (start_date) {
            return schema.test(
              "is-after-start",
              "End date must be greater than start date",
              function (end_date) {
                if (!end_date) {
                  return true;
                } else {
                  return dayjs(end_date).isAfter(dayjs(start_date));
                }
              },
            );
          }
          return schema;
        }),
      });
      

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(formSchema),
        defaultValues: {
             name: campaign.name,
            advertiser: campaign.advertiser,
            budget_type:campaign.budget_type,
            daily_budget: campaign.daily_budget,
            total_budget:campaign.total_budget,
            start_date: campaign?.start_date,
            end_date: endDateValidator(campaign?.end_date) ? "" : campaign?.end_date,
            status: campaign?.status
        }
    });

    useEffect(() => {
        if(campaignById?.campaign && Object.keys(campaignById?.campaign).length > 0){
            setCampaign(campaignById?.campaign)
            setValue( 'name', campaignById?.campaign?.name )
            setValue( 'advertiser', campaignById?.campaign?.advertiser)
            setValue( 'budget_type', campaignById?.campaign?.budget_type)
            setValue( 'daily_budget', campaignById?.campaign?.daily_budget)
            setValue( 'total_budget', campaignById?.campaign?.total_budget)
            setValue( 'start_date', dayjs(campaignById?.campaign?.start_date))
            setValue( 'end_date', endDateValidator(campaignById?.campaign?.end_date) ? "" : dayjs(campaignById?.campaign?.end_date))
            setValue( 'status', campaignById?.campaign?.status)
        }
    }, [campaignById])
    
    useEffect(() => {
        const budget_type_value = watch("budget_type")
        if (budget_type_value) {
            setBudgetType(budget_type_value)
        }
    }, [watch("budget_type")])

    const startDateValue = watch('start_date');
    const endDateValue = watch('end_date');
    
    useEffect(() => {
    const dailyBudgetValue = watch('daily_budget');
    if(dailyBudgetValue){
        setDailyBudget(dailyBudgetValue)
    }
    },[watch('daily_budget')])

    const onButtonClick = (event) => {
        var _request = {};

        _request ={ 
            name: event.name,
            daily_budget: Number(event.daily_budget),
            total_budget: Number(event.total_budget),
            end_date: campaign?.end_date,
            status: event.status,
            advertiser: Number(event.advertiser)
        }

        if(campaignById?.campaign?.status == event?.status){
            delete _request['status'];        
        }

        
        const existingCampaignId = campaignById?.campaign?.id;
        if(existingCampaignId){
            setLoading(true)
            dispatch(updateCampaign({_request, existingCampaignId})).then((res) => {
                setLoading(false)
                if(res?.payload?.status == 201 || res?.payload?.status == 200) {
                    onClose();
                    setCampaign({});
                }
            })
        }

    }

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onButtonClick)}>
                <Box sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            {renderLabel("Campaign Name", true)}
                            <Controller
                                name={'name'}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...register("name")}
                                        {...field}
                                        name={"name"}
                                        fullWidth
                                        type='text'
                                        error={errors?.name}
                                        helperText={errors?.name?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            {renderLabel("Profile", true)}
                            <Controller
                                name={'advertiser'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...register("advertiser")}
                                            {...field}
                                            fullWidth
                                            disabled
                                            name="advertiser"
                                            error={errors?.advertiser}
                                        >
                                            {allUserDetails?.map((item) => {
                                                return (<MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem >)
                                            })}
                                        </Select>
                                        {errors?.advertiser && (<FormHelperText error sx={{ margin: "3px 14px 0px 14px" }}>{errors?.advertiser?.message}</FormHelperText>)}
                                    </>
                                )}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            {renderLabel("Budget Type", true)}
                            <Controller
                                name={'budget_type'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                        disabled
                                            {...field}
                                            {...register("budget_type")}
                                            fullWidth
                                            name="budget_type"
                                            error={errors?.budget_type}
                                        >
                                            <MenuItem value="daily">Daily Budget</MenuItem >
                                            <MenuItem value="total">Total Budget</MenuItem >
                                            <MenuItem value="both">Both</MenuItem >
                                        </Select>
                                        {/* {errors?.budget_type && (<FormHelperText error sx={{ margin: "3px 14px 0px 14px" }}>{errors?.budget_type?.message}</FormHelperText>)} */}
                                    </>
                                )}
                            />
                        </Grid>
                        {(budgetType == "daily" || budgetType == "both") && (
                            <Grid item sm={6}>
                                {renderLabel("Daily Budget", true)}
                                <Controller
                                    name={'daily_budget'}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register("daily_budget")}
                                            fullWidth
                                            type='number'
                                            onWheel={(e) => e.target.blur()}

                                            name="daily_budget"
                                            error={errors?.daily_budget}
                                            onChange={(e) => {setValue('daily_budget' , e.target.value)}
                                        }
                                            helperText={errors?.daily_budget?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                <Box sx={{ padding: 0.5 }}>
                                    <FormHelpText>For 3P, Minimum Daily Budget is 10; for 1P, Minimum Daily Budget is 50.</FormHelpText>
                                </Box>

                            </Grid>)}
                        {(budgetType == "total" || budgetType == "both") && (
                            <Grid item sm={6}>
                                {renderLabel("Total Budget", true)}
                                <Controller
                                    name={'total_budget'}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            {...register("total_budget")}
                                            type='number'
                                            onWheel={(e) => e.target.blur()}

                                            name="total_budget"
                                            error={errors?.total_budget}
                                            onChange={(e) => {setValue('total_budget' , e.target.value)}}
                                            helperText={errors?.total_budget?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                <Box sx={{ padding: 0.5 }}>
                                    <FormHelpText>For 3P, Minimum Daily Budget is 50; for 1P, Minimum Daily Budget is 100.</FormHelpText>
                                </Box>
                            </Grid>
                        )}
                            <Grid item sm={6}>
                                    {renderLabel("Status", true)}
                                    <Controller
                                name={'status'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            fullWidth
                                            value={field?.value}
                                            name="status"
                                            {...field}
                                            {...register("status")}
                                               >
                                            {field?.value == "completed" && (<MenuItem value="completed">Completed</MenuItem>)}
                                            {field?.value == "proposal" && (<MenuItem value="proposal" >Proposal</MenuItem>)}
                                            {field?.value == "scheduled" && (<MenuItem value="scheduled" >Scheduled</MenuItem>)}
                                            <MenuItem value={'enabled'} >Enabled</MenuItem>
                                            <MenuItem value={'paused'}>Paused</MenuItem >
                                        </Select>
                                        </>
                                )}
                            />
                                        </Grid>
                        <Grid item sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Box sx={{ mr: 2 }}>{renderLabel("Date Range", true)}</Box>
                            </Box>
                               {(dateType == "select" || dateType == "any") && 
                               <Box sx={{display: 'flex'}}>
                                <Box sx={{ mr: 5}}>
                                <Controller
                                    name={'start_date'}
                                    sx={{
                                        background: 'red',
                                        width: '100%'
                                    }}
                                    control={control}
                                    render={() => (
                                        <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                {...register("start_date")}
                                                error={errors?.start_date}
                                                minDate={dayjs(new Date())}
                                                value={dayjs(startDateValue)}
                                                disabled
                                                closeOnSelect={true} onChange={(value) => {
                                                    const dayjsDate = dayjs(value.$d);
                                                    const formattedDate = dayjsDate.format('YYYY-MM-DD');
                                                    setValue('start_date', formattedDate);
                                                    
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                 {(errors?.start_date) && (<FormHelperText error sx={{ margin: "3px 14px 0px 14px" }}>{errors?.start_date?.message}</FormHelperText>)}
                                </Box>
                                <Box >
                                <Controller
                                    name={'end_date'}
                                    control={control}
                                    render={() => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker 
                                            {...register("end_date")}
                                            error={errors?.end_date}
                                            value={dayjs(endDateValue)}
                                            minDate={startDateValue ? dayjs(startDateValue).add(1,'day') : dayjs(new Date()).add(1,'day')}
                                            onChange={(value) => {
                                                const dayjsDate = dayjs(value.$d);
                                                const formattedDate = dayjsDate.format('YYYY-MM-DD');
                                                
                                                setValue('end_date', formattedDate)
                                                setCampaign({...campaign, end_date: formattedDate})
                                        }} />
                                        </LocalizationProvider>
                                    )}
                                />
                                {(errors?.end_date) && (<FormHelperText error sx={{ margin: "3px 14px 0px 14px" }}>{errors?.end_date?.message}</FormHelperText>)}
                                </Box>

                           
                            </Box>}
                        </Grid>
                        <Grid item sm={6}>
                            {renderLabel("Campaign Type", true)}
                            <Box >
                                <TextField value={CAMPAIGN.CAMPAIGN_TYPE.SB} fullWidth disabled />
                                {/* <Typography variant="h4">SB</Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
                        <>
                            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>CANCEL</Button>
                            <Button variant="contained" color="secondary" disabled={loading} sx={{ ml: 1 }} type="submit">
                            {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                            Update</Button>
                        </>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default CampaignSetting;
