// import { useEffect } from 'react';
import { Navigate } from 'react-router';
// import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { useLocation } from 'react-router-dom';

function PrivateRoute(props) {
  const { children } = props;
  const location = useLocation();
  const route = location?.pathname;
  const userAuthData = useAppSelector((state) => state.authorization);

  //Redirect to Dashboard, if User has no permission to access following Routes
  const permission = userAuthData?.userData?.permission; //Logged In User All Permissions
  const firstTime = userAuthData?.userData?.is_first_time_login;

  const routePermissions = {
    '/user-management': permission?.user,
    '/advertiser': permission?.advertiser,
    '/bulk-operations': permission?.bulk_operation,
    '/bulk-operations-list': permission?.bulk_operation,
    '/bulk-operations-result': permission?.bulk_operation
  };

  if (routePermissions[route]?.access === false) {
    return <Navigate to="/dashboard-overview" />;
  }

  if (firstTime) {
    if (route !== `/${userAuthData?.userData?.display_module_fe}`) {
      return <Navigate to={`/${userAuthData?.userData?.display_module_fe}`} />;
    }
  }

  return userAuthData?.userData?.access ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
