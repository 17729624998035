import PropTypes from 'prop-types';
import { useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import { useAppSelector } from 'store';
import { formateValue } from 'helper/commonHelper';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const BudgetSettingChart = ({ isLoading, selectedField }) => {
  const theme = useTheme();

  const primary200 = theme.palette.primary[200];
  const primaryDark = theme.palette.primary.dark;
  const secondaryMain = theme.palette.secondary.main;

  const { BudgetPerformanceData } = useAppSelector((state) => state?.userDetailsSlice);

  const lineChartData = {
    height: 300,
    width: 800,
    type: 'area',
    options: {
      colors: selectedField?.map((item) => {
        return item?.color
      }),
      chart: {
        stacked: false,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },

      dataLabels: {
        enabled: false
      },

      xaxis: {
        type: 'datetime',
        categories: BudgetPerformanceData?.months
      },

      stroke: {
        curve: 'smooth'
      },
      fill: {
        opacity: 1
      },

      tooltip: {
        followCursor: true,
        y: selectedField?.map((item) => {
          return {
            formatter: function (value) {
              return formateValue(item.name, value)
            }
          }
        })
      },
      yaxis: selectedField?.map((item, index) => {
        return {
          labels: {
            style: {
              colors: [item?.color] // Color for "Ad Sales" and "Ad Spends" axis labels
            },
            formatter: (value) => {
              return Number.isInteger(value) ? value : value?.toFixed(2)
            }// Dollar sign for "Ad Sales" and "Ad Spends" axis
          },
          title: {
            style: {
              color: item?.color
            }
          },
          opposite: index == 0 ? false : true,
        }
      })
    },
    series: selectedField?.map((item) => {
      return {
        name: item?.display_field,
        data: BudgetPerformanceData?.[item?.name],
        yAxisIndex: 0
      }
    })
  };

  // ===========================|| DASHBOARD - TOTAL ORDER YEAR CHART ||=========================== //
  useEffect(() => {
    const newChartData = {
      ...lineChartData.options
    };

    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`line-chart`, 'updateOptions', newChartData);
    }
  }, [primary200, primaryDark, secondaryMain, isLoading]);

  return <Chart {...lineChartData} />;
};

BudgetSettingChart.propTypes = {
  isLoading: PropTypes.bool
};

export default BudgetSettingChart;