import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getMenuItems } from "menu-items";
import { setUserData } from "store/slices/authSlice";
import { notificationFail } from "store/slices/notificationSlice";
import { setCompanyDetails, setCompanyUsersDetails, setSuperAdmin, setWithCompanyAdvertiser, setWithoutCompanyAdvertiser } from "store/slices/superUserSlice";
import apiClient from "utils/apiClient";

export const getCompanyDetails = createAsyncThunk('getCompanyDetails', async ({ page }, { dispatch }) => {
    try {
        const response = await apiClient().get(`/accounts/retrieve-companies-list/?page=${page}`);
        dispatch(setCompanyDetails(response?.data));
        return response;
    } catch (error) {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
    }
});


export const getCompanyUsersDetails = createAsyncThunk('getCompanyUsersDetails', async ({ id }, { dispatch }) => {
    try {
        const response = await apiClient().get(`/accounts/retrieve-users-list?company_id=${id}`);
        dispatch(setCompanyUsersDetails(response?.data));
        return response;
    } catch (error) {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
    }
});


export const ghostLogin = createAsyncThunk('ghostLogin', async ({ id }, { dispatch }) => {
    try {
        const response = await apiClient().post(`/accounts/create-ghost-user/`, { user_id: Number(id) });
        localStorage.setItem('user_data', JSON.stringify(response?.data?.data?.tokens));
        localStorage.setItem('user_profile', JSON.stringify(response?.data?.data?.profile));
        localStorage.setItem("isSuperAdmin", response?.data?.data?.is_belongs_to_sa_section);
        dispatch(setSuperAdmin(response?.data?.data?.is_belongs_to_sa_section));
        // getMenuItems(response?.data?.data?.is_belongs_to_sa_section);
        dispatch(setUserData(response?.data?.data?.tokens));
        return response;
    } catch (error) {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
    }
});


export const getWithCompanyAdvertiser = createAsyncThunk('getWithCompanyAdvertiser', async ({ page, rowPerPage, search, changedColumn, direction }, { dispatch }) => {
    try {
        let changedColumnSort = (direction == "asc" ? "-" : "") + changedColumn;
        const response = await apiClient().get(`/advertisers/advertisers-list-su?page_size=${rowPerPage}&page=${page + 1}` + (search ? `&search=${search}` : `` + (changedColumn ? `&ordering=${changedColumnSort}` : ``)));
        dispatch(setWithCompanyAdvertiser(response?.data));
        return response;
    } catch (error) {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
    }
});


export const getWithoutCompanyAdvertiser = createAsyncThunk('getWithoutCompanyAdvertiser', async ({ page, rowPerPage, search, changedColumn, direction }, { dispatch }) => {
    try {
        let changedColumnSort = (direction == "asc" ? "-" : "") + changedColumn;
        const response = await apiClient().get(`/advertisers/walmart-advertisers-list?page_size=${rowPerPage}&page=${page + 1}` + (search ? `&search=${search}` : `` + (changedColumn ? `&ordering=${changedColumnSort}` : ``)));
        dispatch(setWithoutCompanyAdvertiser(response?.data));
        return response;
    } catch (error) {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
    }
});

