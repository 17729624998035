import React from 'react';
import UserManagement from './userList';
import VerifiedUserList from './verifiedUserList';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';

const Index = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Card>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '10px 24px 0px 24px'}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="User List" value="1" />
            <Tab label="User Invite" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><UserManagement /></TabPanel>
        <TabPanel value="2"><VerifiedUserList /></TabPanel>
      </TabContext>
    </Card>
  );
};

export default Index;
