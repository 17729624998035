import { Autocomplete, Checkbox, TextField, createFilterOptions } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { showValues } from 'store/constant';

const CustomAutocomplete = (props) => {
  const {
    id,
    name,
    color,
    label,
    options,
    value,
    onOpen,
    onClose,
    onChange,
    optionLabelkey,
    optionIdkey,
    inputValue,
    onInputChange,
    groupBy,
    selectAllCheckboxCondiion,
    placeholderCondition,
    isOptionEqualToValue,
    limitTags,
    noFilterOptions,
    norenderTags,
    size,
    sx,
    extraClass
  } = props;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // Define default sx styles
  // const defaultSx = {
  //   '.MuiAutocomplete-input': {
  //     opacity: placeholderCondition ? 0 : 1
  //   }
  // };

  // const combinedSx = {
  //   ...defaultSx,
  //   ...(sx || {})
  // };

  return (
    <Autocomplete
      multiple
      id={id}
      size={size ? size : ''}
      sx={sx ? sx : ''}
      // sx={combinedSx}
      name={name}
      color={color ? color : ''}
      label={label}
      className={`custom-auto ${extraClass ? extraClass : ''}`}
      options={options}
      value={value}
      onOpen={onOpen}
      onClose={onClose}
      onChange={onChange}
      getOptionLabel={(option) => option[optionLabelkey] || option?.name}
      getOptionKey={(option) => option?.[optionIdkey] || 'id'}
      groupBy={groupBy}
      inputValue={inputValue}
      onInputChange={onInputChange}
      filterOptions={
        noFilterOptions
          ? undefined
          : (options, params) => {
              const filter = createFilterOptions();
              const filtered = filter(options, {
                ...params,
                inputValue: params.inputValue || '' // Ensure inputValue is not null
              });
              return [{ name: 'Select All...', all: true }, ...filtered];
            }
      }
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option?.[optionIdkey] || option?.id}>
            <Checkbox
              key={option?.[optionIdkey] || option?.id}
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option.all ? selectAllCheckboxCondiion : selected}
            />
            {option[optionLabelkey] || option?.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          label={label}
          color="secondary"
          sx={{ width: 'auto' }}
          {...params}
          placeholder={placeholderCondition ? '' : `Search ${label}`}
        />
      )}
      renderTags={norenderTags ? undefined : () => showValues(value, options, optionLabelkey)}
      limitTags={limitTags || -1}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default CustomAutocomplete;
