import { Box, Card, CardContent, Typography } from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import colors from "assets/scss/_themes-vars.module.scss";

function ReportItem(props) {
  const { itemTitle, itemDescription, itemImage } = props;
  return (
    <Box
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        textAlign: "center",
        boxShadow: 2,
      }}
    >
      <Card sx={{ minWidth: "100%", minHeight: "300px", cursor: "pointer" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "144px",
            }}
          >
            {itemImage ? (
              <img src={itemImage} alt={itemTitle} style={{ width: "84px" }} />
            ) : (
              <AssessmentOutlinedIcon
                sx={{ fontSize: "126px", color: colors.secondaryMain }}
              />
            )}
          </Box>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: colors.primaryMain }}
          >
            {itemTitle}
          </Typography>
          <Typography component="p" gutterBottom sx={{ fontSize: "10px" }}>
            {itemDescription}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ReportItem;