import { Grid, TextField, Typography, Button, FormHelperText, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useState } from 'react';
import { postBrandCampaignDetails, updateBrandCampaignDetails } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import { notificationFail } from 'store/slices/notificationSlice';
import { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const EditBrandSetting = (props) => {
    const {brandData, brandEditModal, onClose} = props;
    const [campaignData, setCampaignData] = useState({})
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [editImage, setEditImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (brandEditModal?.type != "add") {
            if(brandData?.logo && brandData?.logo != "/media/"){
                const url = brandData?.logo
                setSelectedPhoto(url)
                const fileName = 'myFile.png'
            
                fetch(url)
                  .then(async response => {
                    const contentType = response.headers.get('content-type')
                    const blob = await response.blob()
                    const file = new File([blob], fileName, { contentType })
                    // access file here
                    setEditImage(file)
                  })
            }
        }
    },[])
    
    const renderLabel = (name, required) => {
        return (
            <Box><Typography sx={{ padding: 0.5, display: 'flex' }}>{required && (<Box sx={{ color: 'red', mr: 0.5 }}>*</Box>)}  {name}</Typography></Box>
        )
    }

    const formSchema = Yup.object().shape({
        brand_name: Yup.string().required('Brand name is required'), 
        headline_text: Yup.string().required('Headline text is required'), 
        logo_click_url: Yup.string().required('Logo click url is required').test('logo_click_url', 'Clickable URL must be https://www.walmart.com URL that displays product(s) for the advertised brand.', function (value) {
            if(value?.startsWith("https://www.walmart.com")){
                return true;
            }else{
                return false;
            }

        }),
        seller_id: Yup.string().required('Seller id is required'), 
        brand_logo: brandEditModal?.type == "add" ? Yup.mixed().required('Brand Logo is required').nullable() : null,

    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(formSchema),
        defaultValues: {
            brand_name: brandEditModal?.type != "add" ? brandData?.name: "",
            headline_text: brandEditModal?.type != "add" ? brandData?.headlinetext : "" ,
            logo_click_url: brandEditModal?.type != "add" ? brandData?.url?.split("&%20hidden_facet=retailer_id%3A")?.[0] : "",
            seller_id: brandEditModal?.type != "add" ? brandData?.url?.split("&%20hidden_facet=retailer_id%3A")?.[1]: ""
        }
    });

    const brandNameValue = watch('brand_name');
    const headlineTextValue = watch('headline_text');
    const logoClickUrl = watch('logo_click_url');
    const brandLogo = watch('brand_logo');

    const brandDataList = {
        brand_name: brandNameValue,
        headline_text: headlineTextValue,
        logo_click_url: logoClickUrl,
        brand_logo: brandLogo
    }

    const onButtonClick = (event) => {
        setCampaignData({ ...campaignData, brand: brandDataList });
        setLoading(true)
        let formData = new FormData();
        if(event?.brand_logo){
            formData.append('logo', event?.brand_logo);
        }else if(editImage){
            formData.append('logo', editImage);
            
        }
        formData.append('campaign', brandData?.campaign_id);
        formData.append('adgroup', brandData?.adgroup_id);
        formData.append('name', event?.brand_name);
        formData.append('headlinetext', event?.headline_text);
        formData.append('url', event?.logo_click_url + "&%20hidden_facet=retailer_id%3A" + event?.seller_id);

        if (brandEditModal?.brandId && brandEditModal?.type == "edit") {
            const brandId = brandEditModal?.brandId;
            dispatch(updateBrandCampaignDetails({ formData, brandId })).then(() => {
                setLoading(false);
                onClose();
            })
        } else {
            dispatch(postBrandCampaignDetails(formData)).then(() => {
                setLoading(false);
                onClose();
            })
        }
    }

    const { getRootProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        maxSize: 204800,
        accept: {
          'image/*': ['.png']
        },
        onDrop: async (acceptedFiles) => {
          if (acceptedFiles?.length > 0) {
              const file = acceptedFiles[0];
              const img = new Image();
              img.src = URL.createObjectURL(file);
              img.onload = () => {
                  if(img.width == 300 && img.height == 180){
                      setSelectedPhoto(URL.createObjectURL(file));
                      setValue('brand_logo', file)
                  }else{
                    dispatch(notificationFail("Image dimensions should be 300px wide and 180px tall."))
                  }
              };
          }
        },
        onDropRejected: () => {
            dispatch(notificationFail("Brand logo image file size should be ≤ 200kb and format can be png."))
        }
      });

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onButtonClick)}>
                <Box>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        {/* <Grid item sm={12}>
                            {renderLabel("Adgroup Name", true)}
                            <Controller
                                name={'adgroup_name'}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...register("adgroup_name")}
                                        {...field}
                                        name={"adgroup_name"}
                                        fullWidth
                                        type='text'
                                        error={errors?.adgroup_name}
                                        helperText={errors?.adgroup_name?.message}
                                    />
                                )}
                            />
                        </Grid> */}
                        <Grid item sm={12}>
                            {renderLabel("Brand Logo", true)}

                            {selectedPhoto ? <Box {...getRootProps({ className: 'dropzone' })}> <img
                                key={selectedPhoto.name}
                                src={selectedPhoto}
                                alt={selectedPhoto.name}
                                style={{ width: "150px", height: "100px", margin: "5px", borderRadius: '10px',
                                border: `1px solid ${colors.grey500}`,  }}
                            /></Box> :
                                    <Box sx={{ height: '100px', width: '150px', border: `1px dashed ${colors.grey500}`, borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    disableElevation
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    {...getRootProps({ className: 'dropzone' })}
                                >
                                    <AddIcon sx={{ color: colors.grey500 }} />
                                </Box>                                
                            }
                            <FormHelperText>Should be 300px wide and 180px tall, and format can be png with size ≤ 200 kb</FormHelperText>
                            {errors?.brand_logo && <FormHelperText error >{errors?.brand_logo?.message}</FormHelperText>}
                        </Grid>

                        <Grid item sm={12}>
                            {renderLabel("Brand Name", true)}
                            <Controller
                                name={'brand_name'}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...register("brand_name")}
                                        {...field}
                                        name={"brand_name"}
                                        fullWidth
                                        type='text'
                                        error={errors?.brand_name}
                                        helperText={errors?.brand_name?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            {renderLabel("Headline Text", true)}
                            <Controller
                                name={'headline_text'}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...register("headline_text")}
                                        {...field}
                                        name={"headline_text"}
                                        fullWidth
                                        type='text'
                                        error={errors?.headline_text}
                                        helperText={errors?.headline_text?.message}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item sm={12} xs={12}>
                            {/* {renderLabel("Logo Click URL", true)} */}
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {renderLabel("Logo Click URL", true)} 
                                <Tooltip title="The destination URL or landing page URL. It is the website address that a visitor lands on when he/she clicks on the ad. This field is required.">
                                    <IconButton sx={{padding: 0}}>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            </Grid>
                            <Grid item sm={5} xs={12}>
                            <Controller
                                name={'logo_click_url'}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...register("logo_click_url")}
                                        {...field}
                                        name={"logo_click_url"}
                                        fullWidth
                                        type='text'
                                        error={errors?.logo_click_url}
                                        helperText={errors?.logo_click_url?.message}
                                    />
                                )}
                            />
                            </Grid>
                                <Box sx={{display: 'flex',  padding: '30px 0px 0px 16px'}}>
                                <Typography variant='h5'>&%20hidden_facet=retailer_id%3A</Typography>
                                </Box>
                            <Grid item sm={3} xs={12}>
                            <Controller
                                name={'seller_id'}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...register("seller_id")}
                                        {...field}
                                        name={"seller_id"}
                                        fullWidth
                                        type='text'
                                        error={errors?.seller_id}
                                        helperText={errors?.seller_id?.message}
                                    />
                                 )}
                            /> 
                            </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
                        <>
                            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>CANCEL</Button>
                            {/* <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handlePreviousButton} >PREVIOUS</Button> */}
                            <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit" disabled={loading}>
                            {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
                            Update</Button>
                        </>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default EditBrandSetting;
