import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch } from 'store';
import { getCompanyUsersDetails, ghostLogin } from 'store/thunk/superUserThunk';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { setCompanyUsersDetails } from 'store/slices/superUserSlice';
import { Loader } from 'rsuite';
import { useState } from 'react';
import { getUserType } from 'helper/commonHelper';
import { PageValue } from 'store/constant';

const CompanyUsersList = () => {
  const { id } = useParams();
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Data = useSelector((state) => state.superUserSlice.companyUsersDetails);

  useEffect(() => {
    dispatcher(setCompanyUsersDetails([]));
    setLoading(true);
    dispatcher(getCompanyUsersDetails({ id }));
    if (Data?.results) {
      setLoading(false);
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGhostLogin = () => {
    dispatcher(ghostLogin({ id }));
  };

  const columns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'user_type',
      label: 'User Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{getUserType(value)}</Typography>;
        }
      }
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        filter: true,
        sort: false,
        customBodyRender: () => {
          return (
            <>
              <Button variant="contained" onClick={handleGhostLogin}>
                Login
              </Button>
            </>
          );
        }
      }
    }
  ];
  const options = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    count: Data?.count,
    pagination: true,
    serverSide: true,
    selectableRows: 'none',
    rowsPerPageOptions: PageValue,
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : !Data?.results ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    }
  };

  return (
    <MainCard title={'Company Users List'}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 1 }}>
        <Button variant="contained" onClick={handleGoBack} startIcon={<ArrowBackIcon />}>
          Go Back
        </Button>
      </Box>
      <Box>
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={Data?.results}
          columns={columns}
          options={options}
          className="pnl-by-item"
        />
      </Box>
    </MainCard>
  );
};

export default CompanyUsersList;
