import React, { memo } from 'react';
import { Box, Typography, Rating, ListItem, List } from '@mui/material';
import DashnaIcon from '../../assets/images/icons/Group.svg';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SignUPFlowLeftSide = () => {
  const cards = [
    {
      label: 'Dashna is a money saver!',
      title: `“Dashna has saved me time and money, and I feel more confident about my advertising strategy now. It's a must-have tool for any Walmart seller serious about growing their business."`,
      author: '-Advertising Manager',
      rating: 5
    },
    {
      label: 'Clear Insights',
      title: `“Dashna simplified everything for me. I now have clear insights into how my ads are performing and where I can improve. The dashboard is user-friendly, which is a huge plus for someone like me who isn't very tech-savvy.”`,
      author: '-Director of Advertising',
      rating: 5
    }
  ];

  const SwipeAbleTextMobileStepper = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step) => {
      setActiveStep(step);
    };

    return (
      <>
        <AutoPlaySwipeableViews
          index={activeStep}
          transitionDuration={1000}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={5000}
          direction="incremental"
        >
          {cards.map((card, index) => (
            <Box key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box className="review-card">
                  <Typography className="review-card-font" variant="h6">
                    {card.label}
                  </Typography>
                  <Typography className="sub-title-css" my={1}>
                    {card.title}
                  </Typography>
                  <Typography display={'flex'} justifyContent={'space-between'}>
                    <Typography className="sub-title-css">{card.author}</Typography>
                    <Typography className="sub-title-css">
                      <Rating name="read-only" value={card.rating} readOnly />
                    </Typography>
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          {cards.map((_, index) => (
            <Box
              key={index}
              sx={{
                height: 12,
                width: 12,
                borderRadius: '50%',
                bgcolor: index === activeStep ? 'rgba(255,255,255,0.3)' : 'grey.50',
                mx: 0.5,
                cursor: 'pointer'
              }}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </Box>
      </>
    );
  };

  return (
    <Box className="left-side-content-wrapper">
      <Box className="dashna-logo">
        <img src={DashnaIcon} alt="login icon" />
      </Box>
      <Box>
        <Typography className="title-css">Powering Your Analytics</Typography>
        <Typography className="title-css"> & Ads in a Dash!</Typography>
        <Typography className="sub-title-css">
          Get started with the only Walmart Exclusive ad platform built specifically with Walmart Sellers in mind. Unleash the
          power of Walmart Connect today with Dashna!
        </Typography>
        <List className="sub-title-list">
          <ListItem>
            <Typography>Campaign Management</Typography>
          </ListItem>
          <ListItem>
            <Typography> Smart Budget Manager</Typography>
          </ListItem>
          <ListItem>
            <Typography> Keyword Tracking</Typography>
          </ListItem>
          <ListItem>
            <Typography> Custom Reporting</Typography>
          </ListItem>
          <ListItem>
            <Typography> And much more!</Typography>
          </ListItem>
        </List>
        <SwipeAbleTextMobileStepper />
      </Box>

      <Box></Box>
    </Box>
  );
};

export default memo(SignUPFlowLeftSide);
