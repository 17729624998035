import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { useAppDispatch } from 'store';
import { getBulkoperationsList } from 'store/thunk/userThunk';
import MainCard from 'ui-component/cards/MainCard';
import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Loader } from 'rsuite';
import { setVerifyCampaignFile } from 'store/slices/userDetailsSlice';
import { PageValue } from 'store/constant';

export default function BulkOperationList() {
  const { bulkOperations, verifyCampaignFile } = useAppSelector((state) => state?.userDetailsSlice);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [tableLoading, setTableLoading] = useState(false);
  const [bulkOperationsList, setBulkOperationsList] = useState([]);

  useEffect(() => {
    setTableLoading(true);
    dispatch(getBulkoperationsList({ page, rowPerPage })).then(() => {
      setTableLoading(false);
    });
  }, []);

  const onViewLog = (idData) => {
    if (verifyCampaignFile) {
      dispatch(setVerifyCampaignFile({}));
    }
    setTableLoading(true);
    navigate('/bulk-operations-result', { state: { isHideSubmit: false, mainId: idData } });
  };
  useEffect(() => {
    if (bulkOperations) {
      setBulkOperationsList(bulkOperations);
    }
  }, [bulkOperations]);

  const columns = [
    {
      name: 'file_name',
      label: 'First Name/Source Campaign',
      options: {
        sort: true
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true
      }
    },
    {
      name: 'created_at',
      label: 'Add Time',
      options: {
        sort: true,
        customBodyRender: (value) => {
          return moment(value).format('MM/DD/YYYY h:m:s');
        }
      }
    },
    {
      name: 'updated_at',
      label: 'Last Update Time',
      options: {
        sort: true,
        customBodyRender: (value) => {
          return moment(value).format('MM/DD/YYYY h:m:s');
        }
      }
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        sort: false,
        customBodyRender: (value) => (
          <IconButton onClick={() => onViewLog(value)}>
            <ListAltIcon />
          </IconButton>
        )
      }
    }
  ];

  const getBulkPageWiseList = (page, rowPerPage, changedColumn = '', direction = '') => {
    setTableLoading(true);
    dispatch(getBulkoperationsList({ page, rowPerPage, changedColumn, direction })).then(() => {
      setTableLoading(false);
    });
  };

  const handleGoBack = () => {
    navigate('/bulk-operations');
  };

  const options = {
    filter: false,
    print: false,
    // selectableRows: 'none',
    page: page,
    count: bulkOperations?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: true,
    selectableRows: 'none',
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    textLabels: {
      body: {
        noMatch: tableLoading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    },
    customToolbar: () => (
      <>
        <Button variant="contained" onClick={() => handleGoBack()} startIcon={<ArrowBackIcon />}>
          Go Back
        </Button>
      </>
    ),
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getBulkPageWiseList(page, row);
    },
    onChangePage: (page) => {
      setPage(page);
      getBulkPageWiseList(page, rowPerPage);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      getBulkPageWiseList(0, rowPerPage, changedColumn, direction);
    }
  };

  return (
    <MainCard title="Bulk Operations">
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={tableLoading ? [] : bulkOperationsList?.results}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />
    </MainCard>
  );
}
