import * as React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { DownloadExistingData, getCampaignAllDetails } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { setAllCampaignShortData } from 'store/slices/userDetailsSlice';
import moment from 'moment';
import colors from 'assets/scss/_themes-vars.module.scss';
// import { useAppDispatch } from 'store';
// import { getCampaignAllDetails } from 'store/thunk/userThunk';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BulkOperationModal = (props) => {
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const { open, onClose, advertiserData } = props;
  console.log('advertiserData ', advertiserData);
  const [clickAll, setClickAll] = useState(false);
  const [clickRemoveAll, setClickRemoveAll] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingApiResp, setLoadingApiResp] = useState(false);
  const [loadingChecked, setLoadingChecked] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const { campaignShortData } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  const apiCall = (data) => {
    const campaignDetailsParams = {};
    if (advertiserData?.length > 0) {
      campaignDetailsParams.advrid = advertiserData?.map((item) => item?.id);
    }
    if (data) {
      campaignDetailsParams.impressions = data;
    }
    const type = 'sponsoredProducts';
    campaignDetailsParams.type = type;
    dispatch(getCampaignAllDetails(campaignDetailsParams)).then((resp) => {
      if (resp?.payload) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(setAllCampaignShortData([]));
    apiCall();
  }, []);

  useEffect(() => {
    if (campaignShortData && campaignShortData?.length > 0) {
      setMainData(campaignShortData);
    } else {
      setMainData([]);
      setCheckedData([]);
      setClickRemoveAll(false);
      setClickAll(false);
    }
  }, [campaignShortData]);

  const handleCheck = (event) => {
    const checked = event.target.checked;
    setLoading(true);
    apiCall(checked);
  };

  const handleClickAll = (event) => {
    const checked = event.target.checked;
    setClickAll(checked);
    if (checked) {
      setCheckedData(mainData);
      setClickRemoveAll(checked);
      setOriginalData(mainData);
    } else {
      setCheckedData([]);
      setClickRemoveAll(false);
      setOriginalData([]);
    }
  };

  const handleClickRemoveItem = (event) => {
    const checked = event.target.checked;
    setClickRemoveAll(checked);
    setClickAll(false);
    setCheckedData([]);
    setOriginalData([]);
  };

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedData((prevChecked) => [...prevChecked, item]);
      setOriginalData((prevChecked) => [...prevChecked, item]);
    } else {
      setCheckedData((prevChecked) => prevChecked?.filter((checkedItem) => checkedItem !== item));
      setOriginalData((prevChecked) => prevChecked?.filter((checkedItem) => checkedItem !== item));
      // setMainData((prev) => prev?.filter((checkedValue) => checkedValue !== item));
    }
  };

  const onConfirmClick = () => {
    setLoadingApiResp(true);
    const checkedCampaignIds = checkedData?.map((obj) => obj?.id);
    dispatch(DownloadExistingData({ Ids: checkedCampaignIds }))
      .then((resp) => {
        setLoadingApiResp(false);
        const fileContent = resp?.payload?.data;
        const blob = new Blob([fileContent], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const fileName = `BulkUpdateTemplate ${todayDate}.xlsx`;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        onClose();
      })
      .catch((error) => {
        console.error('Error downloading file', error);
      });
  };

  const handleFieldChangeMainData = (event) => {
    const searchData = event.target.value;
    if (searchData) {
      setLoading(true);
      setTimeout(() => {
        const tempData = campaignShortData?.filter((obj) => obj?.name?.includes(searchData));
        if (tempData) {
          setMainData(tempData);
          setLoading(false);
        }
      }, 2000);
    } else {
      setMainData(campaignShortData);
    }
  };

  const handleFieldChangeCheckedData = (event) => {
    const searchDataMain = event.target.value;
    if (searchDataMain) {
      setLoadingChecked(true);
      setTimeout(() => {
        const tempData = originalData?.filter((obj) => obj?.name?.includes(searchDataMain));
        if (tempData) {
          setCheckedData(tempData);
          setLoadingChecked(false);
        }
      }, 2000);
    } else {
      setCheckedData(originalData);
    }
  };

  return (
    <Dialog
      className="bulk-opration-modal"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="lg"
      // sx={{ width: '100%' }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="div"> Select Campaign to Download</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form noValidate>
        <DialogContent dividers>
          <Box sx={{ mb: 1 }}>
            <Typography> Exclude</Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Checkbox sx={{ p: 0 }} onClick={(e) => handleCheck(e)} style={{ color: `${colors?.secondaryMain}` }} />
            <Typography> campaigns with zero impressions in last 30 days</Typography>
          </Box>
          <Box>
            <Typography> Campaign</Typography>
            <Box sx={{ display: 'flex', mx: -2, mt: 1 }}>
              <Box sx={{ mx: 2, width: '50%' }}>
                <Box sx={{ border: `1px solid ${colors?.grey400}` }}>
                  <Box sx={{ borderBottom: `1px solid ${colors?.grey400}`, px: 2 }}>
                    <FormControlLabel
                      checked={clickAll}
                      control={<Checkbox style={{ color: `${colors?.secondaryMain}` }} onClick={(e) => handleClickAll(e)} />}
                      label="Select All"
                      disabled={mainData && mainData?.length === 0}
                    />
                  </Box>
                  <Box sx={{ borderBottom: `1px solid ${colors?.grey400}`, px: 2 }}>
                    <Box sx={{ mt: 1 }}>
                      <FormControl variant="standard" sx={{ border: `1px solid ${colors?.grey400}` }}>
                        <Input
                          id="input-with-icon-adornment"
                          className="afterBorder"
                          onChange={handleFieldChangeMainData}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ overflow: 'auto', height: '250px', position: 'relative' }}>
                      {loading && (
                        <CircularProgress
                          sx={{ color: 'black', mr: '10px' }}
                          size="20px"
                          className="bulk-opration-modal-circular"
                        />
                      )}
                      {mainData && mainData?.length > 0 ? (
                        mainData?.map((item) => {
                          return (
                            <Box key={item?.campaign_id}>
                              <FormGroup>
                                <FormControlLabel
                                  checked={checkedData && checkedData?.includes(item)}
                                  control={
                                    <Checkbox
                                      style={{ color: `${colors?.secondaryMain}` }}
                                      onChange={(e) => handleCheckboxChange(e, item)}
                                    />
                                  }
                                  label={item?.name}
                                />
                              </FormGroup>
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            overflow: 'auto',
                            height: '250px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography variant="h5">No data</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mx: 2, width: '50%' }}>
                <Box sx={{ border: `1px solid ${colors?.grey400}` }}>
                  <Box sx={{ borderBottom: `1px solid ${colors?.grey400}`, px: 2 }}>
                    <FormControlLabel
                      checked={clickRemoveAll}
                      control={
                        <Checkbox style={{ color: `${colors?.secondaryMain}` }} onClick={(e) => handleClickRemoveItem(e)} />
                      }
                      label={`${checkedData && checkedData?.length > 0 ? checkedData?.length : 0} items`}
                    />
                  </Box>
                  <Box sx={{ borderBottom: `1px solid ${colors?.grey400}`, px: 2 }}>
                    <Box sx={{ mt: 1 }}>
                      <FormControl variant="standard" sx={{ border: `1px solid ${colors?.grey400}` }}>
                        <Input
                          id="input-with-icon-adornment-checked"
                          className="afterBorder"
                          onChange={handleFieldChangeCheckedData}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ overflow: 'auto', height: '250px', position: 'relative' }}>
                      {loadingChecked && (
                        <CircularProgress
                          sx={{ color: 'black', mr: '10px' }}
                          size="20px"
                          className="bulk-opration-modal-circular"
                        />
                      )}
                      {checkedData && checkedData?.length > 0 ? (
                        <Box sx={{ overflow: 'auto', height: '250px' }}>
                          {checkedData &&
                            checkedData?.length > 0 &&
                            checkedData?.map((item) => (
                              <Box key={item?.campaign_id}>
                                <FormGroup>
                                  <FormControlLabel
                                    checked={true}
                                    control={
                                      <Checkbox
                                        style={{ color: `${colors?.secondaryMain}` }}
                                        onChange={(e) => handleCheckboxChange(e, item)}
                                      />
                                    }
                                    label={item?.name}
                                  />
                                </FormGroup>
                              </Box>
                            ))}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            overflow: 'auto',
                            height: '250px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography variant="h5">No data</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}></Box>*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ marginRight: '8px' }} color="secondary" variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={() => onConfirmClick()}
            autoFocus
            color="secondary"
            variant="contained"
            disabled={loadingApiResp || (checkedData && checkedData?.length === 0)}
          >
            {loadingApiResp && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BulkOperationModal;
