import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import StepperCustomDot from 'ui-component/StepperCustomDot';
import MainCard from 'ui-component/cards/MainCard';
import CampaignSettingStep from './campaignSettingsStep';
import AdGroupSettingStep from './adGroupSettingStep';
import dayjs from 'dayjs';
import CampaignBrandContext from 'context/brand';
import TargetAdGroupSetting from './targetSettingStep';
import CompleteStep from './completeStep';
import { useEffect } from 'react';
import { CAMPAIGN } from 'constant/campaignConstant';

const CreateVideo = () => {
  const steps = [
    'Campaign Setting',
    'Adgroup Setting',
    'Target Setting',
    'Complete',
  ];

  const [activeStep, setActiveStep] = useState(1);

  const [campaignData, setCampaignData] = useState({
    campaign: {},
    adgroup: {},
    aditem: {}
  });
  const [dateType, setDateType] = useState("any");
  const [searchableData, setSearchableData] = useState([]);
  const [selectedData,setSelectedData] = useState([]);
  const [selectedKeywordData,setSelectedKeywordData] = useState([]);

  const [campaign, setCampaign] = useState({
    budget_type: "both",
    campaign_type: "video",
    targeting_type: CAMPAIGN.TARGETING_TYPE.MANUAL,
    rollover: true,
    name: "",
    advertiser: "",
    daily_budget: "",
    total_budget: "",
    start_date: dayjs(new Date()).format('YYYY-MM-DD'),
    end_date: ""
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  const handleForwordButtonClick = () => {
    setActiveStep(activeStep + 1)
  }
    const handleBackwordButtonClick = () => {
    setActiveStep(activeStep - 1);
  }

  const project = () => {
    switch (activeStep) {
      case 1:
        return (
          <CampaignSettingStep  />
        );
      case 2:
        return (
          <AdGroupSettingStep />
        );

      case 3:
        return (
          <TargetAdGroupSetting />
        );
        case 4:
        return (
          <CompleteStep />
        );


      default:
        return <h1>No project match</h1>;
    }
  };

  return (
    <MainCard title="Campaign List">
      <CampaignBrandContext.Provider value={{ campaign, setCampaign, campaignData, setCampaignData, 
      handleForwordButtonClick, handleBackwordButtonClick, searchableData, setSearchableData, dateType, 
      setDateType, selectedData, setSelectedData, selectedKeywordData, setSelectedKeywordData, setActiveStep}}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel
        connector={<StepConnector sx={{marginTop: 0.7}} />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepperCustomDot}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box>
        {/* </StepperWrapper> */}
        <Box sx={{ height: 'auto' }}>{project()}</Box>
      </Box>
      </CampaignBrandContext.Provider>
    </MainCard>
  );
};

export default CreateVideo;
