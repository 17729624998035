import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomModalDescription = ({ open, handleClose, handleSave, title, description, is_Bulk, is_inline }) => {
  console.log('description++++++++++ ', description, is_Bulk);
  const [inputValue, setInputValue] = useState(is_Bulk && is_inline ? '' : description?.description);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSaveClick = async () => {
    const request = {
      id: is_Bulk && is_inline ? description?.map((item) => item?.id) : description.id,
      description: inputValue,
      is_bulk: is_Bulk && is_inline
    };

    handleSave(request);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle variant="h4">
        {title}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          id="dialog-description"
          label="Description"
          multiline
          rows={4}
          value={inputValue}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="secondary" variant="outlined" sx={{ mr: 2 }} onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSaveClick} color="secondary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModalDescription;
