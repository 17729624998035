import { useSelector } from 'react-redux';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import './assets/scss/_themes-vars.module.scss';
import './assets/scss/public-styles.scss';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useAppSelector, useAppDispatch } from 'store';

import { notificationClear } from './store/slices/notificationSlice';
import { useEffect } from 'react';
import { DataProvider } from 'context/DataContext';
import Maintenance from 'views/maintanance';

// ==============================|| APP ||============================== //

const App = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const notificationInfo = useAppSelector((state) => state.notification);
  const { commonLoader } = useAppSelector((state) => state?.userDetailsSlice);
  const appDispatch = useAppDispatch();
  const MaintenancePageShow = false;

  useEffect(() => {
    const container = document.body;
    container.style.zoom = '100%';
    return () => {
      container.style.zoom = '100%';
    };
  }, []);

  const clearNotification = () => {
    appDispatch(notificationClear());
  };

  const openNotification = () => {
    return notificationInfo.message ? true : false;
  };

  return (
    <StyledEngineProvider injectFirst>
      {MaintenancePageShow ? (
        <Maintenance />
      ) : (
        <DataProvider>
          <ThemeProvider className="min-width-class" theme={themes(customization)}>
            <CssBaseline />
            {notificationInfo?.message && (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                className="notification-snackbar"
                open={openNotification()}
                onClose={clearNotification}
                autoHideDuration={notificationInfo.status ? 2500 : 10000}
              >
                <Alert
                  className="notification-alert"
                  onClose={clearNotification}
                  severity={notificationInfo.status ? 'success' : 'error'}
                  sx={{
                    width: '100%',
                    color: theme.palette.background.default,
                    bgcolor: notificationInfo.status ? theme.palette.success.main : theme.palette.error.main
                  }}
                  variant="filled"
                >
                  {notificationInfo.message}
                </Alert>
              </Snackbar>
            )}
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 101 }} open={commonLoader}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </ThemeProvider>
        </DataProvider>
      )}
    </StyledEngineProvider>
  );
};

export default App;
