import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  Chip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import FormHelpText from 'rsuite/esm/FormHelpText';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { /* getAlldvertiserList, */ getCampaignById, updateCampaignModal } from 'store/thunk/userThunk';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { endDateValidator } from 'helper/commonHelper';
import { CAMPAIGN } from 'constant/campaignConstant';
import { CustomSwitch } from 'ui-component/customSwitch';

const EditCampaignSetting = (props) => {
  const { campaignModal, onClose, GetApi } = props;

  const { allUserDetails, campaignById } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useAppDispatch();

  const [campaign, setCampaign] = useState({
    budget_type: 'both',
    targeting_type: CAMPAIGN.TARGETING_TYPE.AUTO,
    bidding_strategy: CAMPAIGN.BIDDING_STRATEGY.FIXED,
    rollover: true,
    is_search_ingrid: true,
    is_others: true,
    is_item_buybox: true,
    is_home_page: true,
    is_stock_up: true,
    is_search_carousel: false,
    is_item_carousel: false,
    placement_bid_search_ingrid: null,
    placement_bid_item_buybox: null,
    placement_bid_home_page: null,
    placement_bid_stock_up: null,
    platform_bid_desktop: null,
    platform_bid_app: null,
    platform_bid_mobile: null,
    name: '',
    advertiser: '',
    daily_budget: '',
    total_budget: '',
    end_date: '',
    status: 'enabled'
  });

  const [budgetType, setBudgetType] = useState('both');
  const [dailyBudget, setDailyBudget] = useState(null);
  const [loading, setLoading] = useState(null);
  const [dateType] = useState('any');

  const [advertiserType, setAdvertiserType] = useState('');

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // let data = "";
    // dispatch(getAlldvertiserList({ data }));
  }, []);

  useEffect(() => {
    if (campaign?.advertiser) {
      const advertiseObjArray = allUserDetails.filter((obj) => obj.id == campaign?.advertiser);
      if (advertiseObjArray?.length) {
        const advertiseObj = advertiseObjArray[0];
        setAdvertiserType(advertiseObj?.advertiser_type || '');
      }
    }
  }, [campaign]);

  useEffect(() => {
    const id = campaignModal.id;

    console.log('id ', id);
    if (id) {
      dispatch(getCampaignById({ id }));
    }
  }, [campaignModal]);

  const handledChangeData = (e) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.value });
  };

  const handledToggleChange = (e) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.checked });
  };

  const handledPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: e.target.value
      }));
    }
  };

  const dailyBudgetValid = advertiserType == '3p' ? 'For 3P, Minimum daily budget is 10' : 'For 1P, Minimum daily budget is 50';
  const totalBudgetValid = advertiserType == '3p' ? 'For 3P, Minimum total budget is 50' : 'For 1P, Minimum total budget is 100';

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    advertiser: Yup.string().required('Advertiser is required'),
    budget_type: Yup.string().required('Budget Type is required'),
    daily_budget:
      budgetType == 'daily' || budgetType == 'both'
        ? Yup.string()
            .required('Daily Budget is required')
            .test('daily_budget', dailyBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 10 : Number(value) >= 50;
            })
        : '',
    total_budget:
      budgetType == 'both'
        ? Yup.string()
            .required('Total Budget is required')
            .test('total_budget', 'Total budget is greter than daily', function (value) {
              return Number(value) >= Number(dailyBudget);
            })
            .test('total_budget', totalBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
            })
        : budgetType == 'total'
        ? Yup.string()
            .required('Total Budget is required')
            .test('total_budget', totalBudgetValid, function (value) {
              return advertiserType == '3p' ? Number(value) >= 50 : Number(value) >= 100;
            })
        : '',
    start_date: dateType == 'select' ? Yup.string().required('Date is required').nullable() : null,
    end_date: Yup.string().when('start_date', (start_date, schema) => {
      if (start_date) {
        return schema.test('is-after-start', 'End date must be greater than start date', function (end_date) {
          if (!end_date) {
            return true;
          } else {
            return dayjs(end_date).isAfter(dayjs(start_date));
          }
        });
      }
      return schema;
    })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: campaign.name,
      advertiser: campaign.advertiser,
      budget_type: campaign.budget_type,
      daily_budget: campaign.daily_budget,
      total_budget: campaign.total_budget,
      start_date: campaign?.start_date,
      end_date: endDateValidator(campaign?.end_date) ? '' : campaign?.end_date
    }
  });

  useEffect(() => {
    if (campaignById?.campaign && Object?.keys(campaignById?.campaign)?.length > 0) {
      setCampaign(campaignById?.campaign);
      setValue('name', campaignById?.campaign?.name);
      setValue('advertiser', campaignById?.campaign?.advertiser);
      setValue('budget_type', campaignById?.campaign?.budget_type);
      setValue('daily_budget', campaignById?.campaign?.daily_budget);
      setValue('total_budget', campaignById?.campaign?.total_budget);
      setValue('start_date', dayjs(campaignById?.campaign?.start_date));
      setValue('end_date', endDateValidator(campaignById?.campaign?.end_date) ? '' : dayjs(campaignById?.campaign?.end_date));
    }
  }, [campaignById]);

  useEffect(() => {
    const budget_type_value = watch('budget_type');
    if (budget_type_value) {
      setBudgetType(budget_type_value);
    }
  }, [watch('budget_type')]);

  const startDateValue = watch('start_date');
  const endDateValue = watch('end_date');

  useEffect(() => {
    const dailyBudgetValue = watch('daily_budget');
    if (dailyBudgetValue) {
      setDailyBudget(dailyBudgetValue);
    }
  }, [watch('daily_budget')]);

  const onButtonClick = (event) => {
    var _request = {};

    _request = {
      ...campaign,
      ...event,
      daily_budget: Number(event.daily_budget),
      total_budget: Number(event.total_budget),
      advertiser: Number(event.advertiser),
      end_date: campaign?.end_date
    };
    delete _request['start_date'];
    delete _request['campaign_type'];
    delete _request['targeting_type'];

    if (campaignById?.campaign?.status == campaign?.status) {
      delete _request['status'];
    }

    if (!event.end_date) {
      delete _request['end_date'];
    }

    if (event.budget_type == 'total') {
      delete _request['daily_budget'];
    } else if (event.budget_type == 'daily') {
      delete _request['total_budget'];
    }

    if (campaign?.targeting_type != CAMPAIGN.TARGETING_TYPE.AUTO) {
      delete _request['placement_bid_home_page'];
      delete _request['placement_bid_stock_up'];
    }

    delete _request['id'];
    delete _request['updated_at'];

    setCampaign({
      ...campaign,
      name: event.name,
      advertiser: event.advertiser,
      budget_type: event.budget_type,
      daily_budget: event.budget_type != 'total' ? event.daily_budget : null,
      total_budget: event.budget_type != 'daily' ? event.total_budget : null,
      start_date: event.start_date,
      end_date: event.end_date
    });

    const existingCampaignId = campaignById?.campaign?.id;
    if (existingCampaignId) {
      setLoading(true);
      dispatch(updateCampaignModal({ _request, existingCampaignId })).then((res) => {
        setLoading(false);
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          onClose();
          setCampaign({});
          GetApi();
        }
      });
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onButtonClick)}>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              {renderLabel('Campaign Name', true)}
              <Controller
                name={'name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('name')}
                    {...field}
                    name={'name'}
                    fullWidth
                    type="text"
                    error={errors?.name}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              {renderLabel('Profile', true)}
              <Controller
                name={'advertiser'}
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...register('advertiser')}
                      {...field}
                      fullWidth
                      disabled
                      name="advertiser"
                      error={errors?.advertiser}
                    >
                      {allUserDetails?.map((item) => {
                        return (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.advertiser && (
                      <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                        {errors?.advertiser?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              {renderLabel('Budget Type', true)}
              <Controller
                name={'budget_type'}
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      disabled
                      {...field}
                      {...register('budget_type')}
                      fullWidth
                      name="budget_type"
                      error={errors?.budget_type}
                    >
                      <MenuItem value="daily">Daily Budget</MenuItem>
                      <MenuItem value="total">Total Budget</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                    </Select>
                  </>
                )}
              />
            </Grid>
            {(budgetType == 'daily' || budgetType == 'both') && (
              <Grid item sm={6}>
                {renderLabel('Daily Budget', true)}
                <Controller
                  name={'daily_budget'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...register('daily_budget')}
                      fullWidth
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="daily_budget"
                      error={errors?.daily_budget}
                      onChange={(e) => {
                        setValue('daily_budget', e.target.value);
                      }}
                      helperText={errors?.daily_budget?.message}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  )}
                />
                <Box sx={{ padding: 0.5 }}>
                  <FormHelpText>For 3P, Minimum Daily Budget is 10; for 1P, Minimum Daily Budget is 50.</FormHelpText>
                </Box>
              </Grid>
            )}
            {(budgetType == 'total' || budgetType == 'both') && (
              <Grid item sm={6}>
                {renderLabel('Total Budget', true)}
                <Controller
                  name={'total_budget'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      {...register('total_budget')}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="total_budget"
                      error={errors?.total_budget}
                      onChange={(e) => {
                        setValue('total_budget', e.target.value);
                      }}
                      helperText={errors?.total_budget?.message}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  )}
                />
                <Box sx={{ padding: 0.5 }}>
                  <FormHelpText>For 3P, Minimum Daily Budget is 50; for 1P, Minimum Daily Budget is 100.</FormHelpText>
                </Box>
              </Grid>
            )}
            <Grid item sm={6}>
              {renderLabel('Status', true)}
              <Select
                fullWidth
                name="status"
                value={campaign.status}
                onChange={(e) => setCampaign({ ...campaign, status: e.target.value })}
              >
                {campaign?.status == 'completed' && <MenuItem value="completed">Completed</MenuItem>}
                {campaign?.status == 'proposal' && <MenuItem value="proposal">Proposal</MenuItem>}
                {campaign?.status == 'scheduled' && <MenuItem value="scheduled">Scheduled</MenuItem>}
                <MenuItem value={'enabled'}>Enabled</MenuItem>
                <MenuItem value={'paused'}>Paused</MenuItem>
              </Select>
            </Grid>
            <Grid item sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 2 }}>{renderLabel('Date Range', true)}</Box>
              </Box>
              {(dateType == 'select' || dateType == 'any') && (
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ mr: 5 }}>
                    <Controller
                      name={'start_date'}
                      sx={{
                        background: 'red',
                        width: '100%'
                      }}
                      control={control}
                      render={() => (
                        <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                          <DatePicker
                            {...register('start_date')}
                            error={errors?.start_date}
                            minDate={dayjs(new Date())}
                            value={startDateValue}
                            disabled
                            closeOnSelect={true}
                            onChange={(value) => {
                              const dayjsDate = dayjs(value.$d);
                              const formattedDate = dayjsDate.format('YYYY-MM-DD');
                              setValue('start_date', formattedDate);
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors?.start_date && (
                      <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                        {errors?.start_date?.message}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <Controller
                      name={'end_date'}
                      control={control}
                      render={() => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            {...register('end_date')}
                            error={errors?.end_date}
                            value={endDateValue}
                            minDate={startDateValue ? dayjs(startDateValue).add(1, 'day') : dayjs(new Date()).add(1, 'day')}
                            onChange={(value) => {
                              const dayjsDate = dayjs(value.$d);
                              const formattedDate = dayjsDate.format('YYYY-MM-DD');

                              setValue('end_date', formattedDate);
                              setCampaign({ ...campaign, end_date: formattedDate });
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors?.end_date && (
                      <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                        {errors?.end_date?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item sm={6}></Grid>
            <Grid item sm={6}>
              {renderLabel('Campaign Type', true)}
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="targeting_type"
                  onChange={handledChangeData}
                  value={campaign?.targeting_type}
                >
                  <FormControlLabel
                    value={CAMPAIGN.TARGETING_TYPE.AUTO}
                    control={<Radio />}
                    disabled
                    label={CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO}
                  />
                  <FormControlLabel
                    value={CAMPAIGN.TARGETING_TYPE.MANUAL}
                    control={<Radio />}
                    disabled
                    label={CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              {renderLabel('Bidding Strategy', true)}
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="bidding_strategy"
                  onChange={handledChangeData}
                  value={campaign?.bidding_strategy}
                >
                  <FormControlLabel value={CAMPAIGN.BIDDING_STRATEGY.FIXED} control={<Radio />} label={'Fixed'} />
                  <FormControlLabel value={CAMPAIGN.BIDDING_STRATEGY.DYNAMIC} control={<Radio />} label={'Dynamic'} />
                  <FormControlLabel
                    control={<Chip label="Beta" size="small" color="primary" clickable={false} sx={{ marginLeft: '2px' }} />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Placement')}
              <FormControlLabel
                label="Search Ingrid"
                name="is_search_ingrid"
                className="CustomSwitchToggle"
                checked={campaign?.is_search_ingrid}
                onChange={handledToggleChange}
                disabled
                control={<CustomSwitch label="Search Ingrid" />}
              />

              {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
                <FormControlLabel
                  label="Search Carousel"
                  checked={campaign?.is_search_carousel}
                  name="is_search_carousel"
                  className="CustomSwitchToggle"
                  onChange={handledToggleChange}
                  control={<CustomSwitch label="Search Carousel" />}
                />
              )}

              <FormControlLabel
                label="Item Buybox"
                name="is_item_buybox"
                className="CustomSwitchToggle"
                checked={campaign?.is_item_buybox}
                onChange={handledToggleChange}
                disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                control={<CustomSwitch label="Item Buybox" />}
              />

              {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL && (
                <FormControlLabel
                  label="Item Carousel"
                  name="is_item_carousel"
                  className="CustomSwitchToggle"
                  checked={campaign?.is_item_carousel}
                  onChange={handledToggleChange}
                  control={<CustomSwitch label="Item Carousel" />}
                />
              )}

              {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
                <FormControlLabel
                  label="Home Page"
                  name="is_home_page"
                  className="CustomSwitchToggle"
                  checked={campaign?.is_home_page}
                  onChange={handledToggleChange}
                  disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                  control={<CustomSwitch label="Home Page" />}
                />
              )}

              {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
                <FormControlLabel
                  label="Stock Up"
                  name="is_stock_up"
                  className="CustomSwitchToggle"
                  checked={campaign?.is_stock_up}
                  onChange={handledToggleChange}
                  disabled={campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO}
                  control={<CustomSwitch label="Stock Up" />}
                />
              )}

              <FormControlLabel
                label="Others"
                checked={campaign?.is_others}
                onChange={handledToggleChange}
                name="is_others"
                className="CustomSwitchToggle"
                disabled
                control={<CustomSwitch label="Others" />}
              />
            </Grid>
            <Grid item sm={12}>
              {renderLabel('Placement Bid Multiplier')}
            </Grid>
            <Grid item sm={3}>
              {renderLabel('Search Ingrid')}
              <TextField
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="0-900"
                inputProps={{
                  min: 0,
                  max: 900
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="placement_bid_search_ingrid"
                value={campaign?.placement_bid_search_ingrid}
                onChange={handledPercentageChange}
              />
            </Grid>
            <Grid item sm={3}>
              {renderLabel('Item Buybox')}
              <TextField
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 0,
                  max: 900
                }}
                placeholder="0-900"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="placement_bid_item_buybox"
                value={campaign?.placement_bid_item_buybox}
                onChange={handledPercentageChange}
              />
            </Grid>
            {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
              <Grid item sm={3}>
                {renderLabel('Home Page')}
                <TextField
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  inputProps={{
                    min: 0,
                    max: 900
                  }}
                  placeholder="0-900"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  name="placement_bid_home_page"
                  value={campaign?.placement_bid_home_page}
                  onChange={handledPercentageChange}
                />
              </Grid>
            )}

            {campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.AUTO && (
              <Grid item sm={3}>
                {renderLabel('Stock Up')}
                <TextField
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder="0-900"
                  inputProps={{
                    min: 0,
                    max: 900
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  name="placement_bid_stock_up"
                  value={campaign?.placement_bid_stock_up}
                  onChange={handledPercentageChange}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              {renderLabel('Platform Bid Multiplier')}
            </Grid>
            <Grid item sm={3}>
              {renderLabel('Desktop')}
              <TextField
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 0,
                  max: 900
                }}
                placeholder="0-900"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="platform_bid_desktop"
                value={campaign?.platform_bid_desktop}
                onChange={handledPercentageChange}
              />
            </Grid>
            <Grid item sm={3}>
              {renderLabel('APP')}
              <TextField
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="0-900"
                inputProps={{
                  min: 0,
                  max: 900
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="platform_bid_app"
                value={campaign?.platform_bid_app}
                onChange={handledPercentageChange}
              />
            </Grid>
            <Grid item sm={3}>
              {renderLabel('Mobile')}
              <TextField
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder="0-900"
                inputProps={{
                  min: 0,
                  max: 900
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="platform_bid_mobile"
                value={campaign?.platform_bid_mobile}
                onChange={handledPercentageChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>
                CANCEL
              </Button>
              <Button variant="contained" color="secondary" disabled={loading} sx={{ ml: 1 }} type="submit">
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                Update
              </Button>
            </>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default EditCampaignSetting;
