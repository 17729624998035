import { Grid, Box, Skeleton } from '@mui/material';

function DashboardSkeleton() {
  return (
    <Grid container spacing={2} mt={2} className="Dashboard-skeleton">
      <Grid item xs={6}>
        <Box display="flex" justifyContent="center" p={2} className="bg-white">
          <Box className="skeleton-parent">
            <Skeleton className="skeleton" variant="rectangular" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="center" p={2} className="bg-white">
          <Box className="skeleton-parent">
            <Skeleton className="skeleton" variant="rectangular" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="center" p={2} className="bg-white">
          <Box className="skeleton-parent">
            <Skeleton className="skeleton" variant="rectangular" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="center" p={2} className="bg-white">
          <Box className="skeleton-parent">
            <Skeleton className="skeleton" variant="rectangular" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default DashboardSkeleton;
