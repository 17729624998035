import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaignData: [],
  adGroupData: [],
  adItemsData: [],
  adKeywordsData: [],
  allReportData: [],
  allReportDataDownload: [],
  allFilterData: []
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setCampaignReportData: (state, action) => ({
      ...state,
      campaignReportData: action.payload
    }),
    setAdItemReportData: (state, action) => ({
      ...state,
      adItemsData: action.payload
    }),
    setKeywordReportData: (state, action) => ({
      ...state,
      adKeywordsData: action.payload
    }),
    setAdGroupReportData: (state, action) => ({
      ...state,
      adGroupData: action.payload
    }),
    setCustomReportData: (state, action) => ({
      ...state,
      allReportData: action.payload
    }),
    setCustomReportDataDownload: (state, action) => ({
      ...state,
      allReportDataDownload: action.payload
    }),
    setAllFilterData: (state, action) => ({
      ...state,
      allFilterData: action.payload
    })
  }
});

export const {
  setCampaignReportData,
  setAdItemReportData,
  setKeywordReportData,
  setAllFilterData,
  setAdGroupReportData,
  setCustomReportData,
  setCustomReportDataDownload
} = reportSlice.actions;

export default reportSlice.reducer;
