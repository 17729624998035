import { Grid, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { getLatestReportDateData/* , getAlldvertiserList */ } from "store/thunk/userThunk";
import MainCard from "ui-component/cards/MainCard";
import ReportItemBox from "../ReportItemBox";
import dayjs from "dayjs";
import { renderLabel } from '../../../ui-component/label';

function GetLatestReportDate() {
  const dispatch = useAppDispatch();
  const { latestReportDateData, allUserDetails } = useAppSelector(
    (state) => state?.userDetailsSlice,
  );
  const [advertiser, setAdvertiser] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onAdvertiserChange = (event) => {
    setAdvertiser(event.target.value);
    setIsLoading(true);
    dispatch(getLatestReportDateData({ id: event.target.value })).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    // const data = null;
    // dispatch(getAlldvertiserList({ data }))
  }, [])


  return (
    <MainCard title="Latest Report Date">
      <Grid container spacing={2}>
        <Grid item sm={4}>
          {renderLabel("Profile", true)}
          <Select
            fullWidth
            name="advertiser"
            value={advertiser}
            onChange={onAdvertiserChange}
          >
            {allUserDetails?.map((item) => {
              return (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item sm={4}>
          <ReportItemBox
            isLoading={isLoading}
            itemLabel="LATEST REPORT DATE"
            itemValue={
              latestReportDateData?.latestReportDate
                ? dayjs(latestReportDateData?.latestReportDate).format(
                  "MM/DD/YYYY",
                )
                : ""
            }
          />
        </Grid>
      </Grid>
    </MainCard >
  );
}

export default GetLatestReportDate;