import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    companyDetails: [],
    companyUsersDetails: [],
    withCompanyList: [],
    withoutCompanyList: [],
    isSuperUser: null,
};

const superUserDetailsSlice = createSlice({
    name: 'superUserDetailsSlice',
    initialState,
    reducers: {
        setCompanyDetails: (state, action) => (
            {
                ...state,
                companyDetails: action.payload
            }
        ),
        setCompanyUsersDetails: (state, action) => (
            {
                ...state,
                companyUsersDetails: action.payload
            }
        ),
        setSuperAdmin: (state, action) => (
            {
                ...state,
                isSuperUser: action.payload
            }
        ),
        setWithCompanyAdvertiser: (state, action) => (
            {
                ...state,
                withCompanyList: action.payload
            }
        ),
        setWithoutCompanyAdvertiser: (state, action) => (
            {
                ...state,
                withoutCompanyList: action.payload
            }
        )
    }
});

export const { setCompanyDetails, setCompanyUsersDetails, setSuperAdmin, setWithCompanyAdvertiser, setWithoutCompanyAdvertiser } = superUserDetailsSlice.actions;

export default superUserDetailsSlice.reducer;