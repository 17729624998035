import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
// import Logo from 'ui-component/Logo.png';
import { MENU_OPEN } from 'store/actions';

import DashnaLogo from 'assets/images/dashna/dashnaLogo.svg';
import DashnaLogosmall from 'assets/images/dashna/DashnaLogosmall.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  return (
    <ButtonBase
      style={{ textDecoration: 'none', transition: 'all .2s ease-in-out' }}
      disableRipple
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={config.defaultPath}
    >
      <img src={!leftDrawerOpened ? DashnaLogosmall : DashnaLogo} alt="Company Logo" style={{ height: '40px' }} />

      {/* <Typography
        variant="h3"
        color={'#ffc220'}
        sx={{
          textDecoration: 'none',
          fontFamily: 'sans-serif',
          fontWeight: '800',
          '&:hover': {
            textDecoration: 'none'
          }
        }}
      >
        WAL
      </Typography>
      <Typography
        variant="h3"
        color={'#0071ce'}
        sx={{
          textDecoration: 'none',
          fontFamily: 'sans-serif',
          mr: '2px',
          fontWeight: '800',
          '&:hover': {
            textDecoration: 'none'
          }
        }}
      >
        METRICS
      </Typography> */}
    </ButtonBase>
  );
};

export default LogoSection;
