const { Switch } = require('@mui/material');
import { styled } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';
export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0,
  '& + .MuiFormControlLabel-label': {
    marginLeft:'10px'
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: colors?.paper,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? colors?.grey600 : colors?.secondaryMain,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: colors?.successMain,
      border: `6px solid ${colors?.paper}`
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    marginLeft: 2
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? colors?.grey500 : colors?.secondaryMain,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));
