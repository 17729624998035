import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormHelperText
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SelectFromTable from './searchTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useAppSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import { useContext } from 'react';
import CampaignContext from 'context';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store';
import EnterKeywordTable from './enterKeywordTable';
import { getSuggestedKeyword, postTargetSettingDetails } from 'store/thunk/userThunk';
import { useEffect } from 'react';
import { PageValue } from 'store/constant';

const TargetAdGroupSetting = () => {
  const { handleBackwordButtonClick, handleForwordButtonClick, selectedKeywordData, setSelectedKeywordData } =
    useContext(CampaignContext);
  const navigate = useNavigate();

  const [value, setValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { adgoupDetails } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    const adgroupId = adgoupDetails?.id;
    if (adgroupId) {
      dispatch(getSuggestedKeyword({ adgroupId }));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onButtonClick = (event) => {
    event.preventDefault();
    if (selectedKeywordData?.length > 0) {
      setError(false);
      const tempArray = selectedKeywordData?.map((item) => {
        const temp = {
          keywordtext: item.keywordtext,
          matchtype: item.matchtype,
          bid_amount: item.bid_amount
        };
        return temp;
      });
      setLoading(true);
      const _request = {
        campaign: adgoupDetails?.campaign,
        adgroup: adgoupDetails?.id,
        keyword: tempArray
      };
      dispatch(postTargetSettingDetails(_request)).then((res) => {
        setLoading(false);
        if (res.payload.status) {
          handleForwordButtonClick();
        }
      });
    } else {
      setError(true);
    }
  };

  const selectedColumns = [
    {
      name: 'keywordtext',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <Box component={"img"} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} /> */}
                <Box>
                  <Typography>{value}</Typography>
                  <Typography sx={{ color: colors.primaryMain }}>{data?.item_id}</Typography>
                </Box>
              </Box>
            </>
          );
        }
      }
    },

    {
      name: 'matchtype',
      label: 'Match Type',
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'bid_amount',
      label: 'bid',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let data = selectedKeywordData[tableMeta?.rowIndex];
          return (
            <>
              <TextField
                size="small"
                value={data?.bid_amount}
                type="number"
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => {
                  if (Number(e.target.value) < 0.3) {
                    const newPrice = '0.30';
                    const updatedData = [...selectedKeywordData];
                    updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                    setSelectedKeywordData(updatedData);
                  }
                }}
                onChange={(e) => {
                  const newPrice = Number(e.target.value == 0) ? null : Number(e.target.value);
                  const updatedData = [...selectedKeywordData];
                  updatedData[tableMeta?.rowIndex] = { ...data, bid_amount: newPrice };
                  setSelectedKeywordData(updatedData);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                inputProps={{
                  step: '0.01'
                }}
              />
            </>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Add All',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedKeywordData];
                  tempData = tempData?.filter((item, index) => index != tableMeta?.rowIndex);
                  setSelectedKeywordData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={selectedKeywordData?.length == 0}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedKeywordData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    search: false,
    viewColumns: false
  };

  return (
    <>
      <form noValidate onSubmit={onButtonClick}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              {renderLabel('Keyword', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Enter Keywords" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <EnterKeywordTable />
                    </TabPanel>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SelectFromTable />
                    </TabPanel>
                  </Box>
                </TabContext>
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedKeywordData}
                      columns={selectedColumns}
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
              {error && (
                <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                  {'Please select atlease one keyword.'}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
                CANCEL
              </Button>
              <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handleBackwordButtonClick}>
                PREVIOUS
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit" disabled={loading}>
                {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TargetAdGroupSetting;
