import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function HelpCenterIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M12.75 13.8226H9.75L6.41249 16.0425C5.91749 16.3725 5.25 16.0201 5.25 15.4201V13.8226C3 13.8226 1.5 12.3226 1.5 10.0726V5.57251C1.5 3.32251 3 1.82251 5.25 1.82251H12.75C15 1.82251 16.5 3.32251 16.5 5.57251V10.0726C16.5 12.3226 15 13.8226 12.75 13.8226Z"
        stroke="#353D49"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00011 8.52002V8.36255C9.00011 7.85255 9.31513 7.58254 9.63013 7.36504C9.93763 7.15504 10.2451 6.88505 10.2451 6.39005C10.2451 5.70005 9.69011 5.14502 9.00011 5.14502C8.31011 5.14502 7.75513 5.70005 7.75513 6.39005"
        stroke="#353D49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.99662 10.3125H9.00337" stroke="#353D49" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default HelpCenterIcon;
