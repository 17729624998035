import { useEffect, useState } from 'react';
// import { useAppSelector } from "store";
import { useDispatch } from 'react-redux';
import { getSelectedFilterList, postSelectedFilterDataCampaign } from 'store/thunk/userThunk';
import { Dialog, DialogTitle, DialogContent, FormControlLabel, Button, Checkbox, DialogActions, Box } from '@mui/material';
import { setCommonLoader } from 'store/slices/userDetailsSlice';

const CustomColumnSelector = (props) => {
  const { open, handleClose, tableName, columns, defaultSelected, actionColumnName = '' } = props;
  const dispatch = useDispatch();
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (defaultSelected) {
      let tempColumns = JSON.parse(JSON.stringify(defaultSelected));
      tempColumns = defaultSelected?.map((column) => {
        return {
          name: column?.name,
          display: column?.options?.display ?? true,
          label: column?.label || column?.name,
          default: column?.default || false
        };
      });
      tempColumns = tempColumns.filter((obj) => obj.name !== actionColumnName);
      setSelectedColumns(tempColumns);
    }
  }, [defaultSelected]);

  const handleCheckboxChange = (index) => {
    setSelectedColumns((prevColumns) => {
      let updatedColumns = [...prevColumns];
      updatedColumns[index] = { ...updatedColumns[index], display: !updatedColumns[index]?.display };
      return updatedColumns;
    });
  };

  const handleSave = () => {
    const tempSaveColumns = [...columns];
    selectedColumns?.map((col) => {
      const selectedIndex = tempSaveColumns.findIndex((obj) => obj.name === col.name);
      tempSaveColumns[selectedIndex] = { ...tempSaveColumns[selectedIndex], display: col.display };
    });

    const _request = {
      [tableName]: tempSaveColumns
    };
    dispatch(setCommonLoader(true));
    dispatch(postSelectedFilterDataCampaign(_request)).then(() => {
      dispatch(getSelectedFilterList()).then(() => {
        dispatch(setCommonLoader(false));
        handleClose();
      });
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select columns</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            {selectedColumns.slice(0, Math.ceil(selectedColumns.length / 2)).map((column, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    disabled={column?.default || false}
                    checked={column?.display || false}
                    onChange={() => handleCheckboxChange(index)}
                    color="primary"
                  />
                }
                label={column.label}
                disabled={column?.default || false}
              />
            ))}
          </Box>
          <Box display="flex" flexDirection="column">
            {selectedColumns.slice(Math.ceil(selectedColumns.length / 2)).map((column, index) => (
              <FormControlLabel
                key={Math.ceil(selectedColumns.length / 2) + index}
                control={
                  <Checkbox
                    disabled={column?.default || false}
                    checked={column?.display || false}
                    onChange={() => handleCheckboxChange(Math.ceil(selectedColumns.length / 2) + index)}
                    color="primary"
                  />
                }
                label={column.label}
                disabled={column?.default || false}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomColumnSelector;
