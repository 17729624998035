import { TextField, Tooltip } from '@mui/material';
import React from 'react';
import { CURRENT_RANK_MINIMUM_VALUE } from 'store/constant';

const InputComponent = ({ ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label, showErrorCurrentRank, showError }) => {
  return (
    <Tooltip
      placement="top"
      title={
        showError
          ? 'The minimum value cannot be greater than the maximum value.'
          : showErrorCurrentRank
          ? `The minimum value cannot be greater than ${CURRENT_RANK_MINIMUM_VALUE}.`
          : ''
      }
    >
      <TextField
        variant="outlined"
        label={label}
        multiline
        color={showError || showErrorCurrentRank ? 'error' : 'secondary'}
        border={showError || showErrorCurrentRank ? '1px solid red' : '1px solid #ccc'}
        size="small"
        // borderRadius="12px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        className="performance-outline"
        sx={{
          backgroundColor: '#f8fafc',
          alignItems: 'center',
          '&:focus': {
            border: 'yellow'
          }
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: InputComponent,
          inputMode: 'numeric',
          min: 0,
          max: 900
        }}
        helperText={showError}
        error={showError}
        inputProps={{ children: children, className: 'filter-performance-padding' }}
      />
    </Tooltip>
  );
};
export default OutlinedDiv;
