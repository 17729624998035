import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AutomationIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          d="M16.5 11.25C16.5 14.1525 14.1525 16.5 11.25 16.5L12.0375 15.1875"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 6.75C1.5 3.8475 3.8475 1.5 6.75 1.5L5.9625 2.8125"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7903 7.6274C12.0828 7.6274 12.3828 7.6274 12.6753 7.6274C12.9753 7.6199 13.2378 7.6874 13.3803 7.9874C13.5078 8.2649 13.4178 8.5124 13.2378 8.7449C11.9328 10.4249 10.6428 12.1124 9.33779 13.7999C8.81279 14.4824 8.29529 15.1574 7.77029 15.8399C7.60529 16.0499 7.39529 16.1624 7.12529 16.1024C6.87779 16.0499 6.71279 15.8924 6.64529 15.6374C6.61529 15.5174 6.63029 15.3974 6.66029 15.2774C6.99029 13.8749 7.32029 12.4799 7.65029 11.0774C7.71029 10.8074 7.71779 10.8074 7.44779 10.8074C6.84779 10.8074 6.24029 10.8074 5.64029 10.8074C5.35529 10.8074 5.12279 10.7174 4.99529 10.4549C4.86779 10.1924 4.93529 9.9524 5.10779 9.7274C6.88529 7.4249 8.66279 5.1149 10.4403 2.8124C10.6578 2.5274 10.9653 2.4449 11.2578 2.5799C11.5353 2.7149 11.6553 2.9999 11.5728 3.3524C11.2578 4.6874 10.9353 6.0224 10.6203 7.3499C10.5528 7.6199 10.5528 7.6199 10.8228 7.6199C11.1528 7.6199 11.4753 7.6199 11.8053 7.6199L11.7903 7.6274Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default AutomationIcon;
