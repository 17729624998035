import { useAppDispatch, useAppSelector } from 'store';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'assets/scss/_themes-vars.module.scss';
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  IconButton,
  Typography,
  Divider,
  Link
} from '@mui/material';
import {
  useState
  // useEffect
} from 'react';
// import IconImg from 'assets/images/dashna/icon.svg';
// import IconSetting from 'assets/images/dashna/setting.svg';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import __ from 'underscore';
import { getItemKeywordChart } from 'store/thunk/userThunk';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const organicRankDefaultValue = 201;
const sponsorRankDefaultValue = 51;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: 14
  }
});

const SearchRankGraph = (props) => {
  const dispatch = useAppDispatch();
  const { selectedRankTrackerDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [
    chartHeight
    // setChartHeight
  ] = useState('300px');
  const [itemName, setItemName] = useState();
  const [itemNameFull, setItemNameFull] = useState();
  const [itemImage, setItemImage] = useState('');
  const [itemId, setItemId] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [selectedDuration, setSelectedDuration] = useState({});
  const [topRankVal, setTopRankVal] = useState();
  const [topRankDate, setTopRankDate] = useState('');
  const [lowestRankVal, setLowestRankVal] = useState();
  const [lowestRankDate, setLowestRankDate] = useState();
  const [averageRankVal, setAverageRankVal] = useState();
  const [chartSeriesData, setChartSeriesData] = useState([]);
  const [chartXAxiesDate, setChartXAxiesDate] = useState([]);
  const [totalXaxiesTick, setTotalXaxiesTick] = useState(15);
  const { open, handleClose, itemInfo } = props;

  //Color
  const primaryMain = colors?.primaryMain;
  // const primaryTextColor = colors?.primaryTextColor;
  // const grey700 = colors?.grey700;
  const grey300 = colors?.grey300;
  const grey50 = colors?.grey50;

  //Options for Rank Type Dropdowns
  const durationPeriod = [
    {
      id: 1,
      name: '30 Days',
      value: 30
    },
    {
      id: 2,
      name: '90 Days',
      value: 90
    },
    {
      id: 3,
      name: '1 Year',
      value: 365
    },
    {
      id: 4,
      name: 'All time',
      value: 'all'
    }
  ];

  // useEffect(() => {

  // }, [itemInfo]);

  useEffect(() => {
    if (__.isEmpty(selectedRankTrackerDetails)) {
      return;
    }

    const itemId = itemInfo?.item_id;
    let productName = itemInfo?.product_name;
    let searchTerm = itemInfo?.search_term;
    let itemImageUrl = itemInfo?.primary_image_url;
    if (productName?.length > 40) {
      productName = `${productName.substring(0, 40)}...`;
    }
    setItemId(itemId);
    setItemName(productName);
    setItemNameFull(itemInfo?.product_name);
    setSearchTerm(searchTerm);
    setItemImage(itemImageUrl);

    const { organic_rank_stats, sponsored_rank_stats, organic_rank, sponsored_rank, date } = selectedRankTrackerDetails;
    const type = itemInfo?.type;
    if (type === 'ORG') {
      const topRankDate = organic_rank_stats?.top_rank_date
        ? moment(organic_rank_stats?.top_rank_date).format('MMMM D, YYYY')
        : '';
      const lowestRankDate = organic_rank_stats?.lowest_rank_date
        ? moment(organic_rank_stats?.lowest_rank_date).format('MMMM D, YYYY')
        : '';

      const lowestRankORG = organic_rank_stats?.lowest_rank === 0 ? '201+' : organic_rank_stats?.lowest_rank;

      setTopRankVal(organic_rank_stats?.top_rank);
      setTopRankDate(topRankDate && `(${topRankDate})`);
      setLowestRankVal(lowestRankORG);
      setLowestRankDate(lowestRankDate && `(${lowestRankDate})`);
      setAverageRankVal(organic_rank_stats?.average_rank);

      const organicRankArr = organic_rank.map((value) => (value === 0 ? organicRankDefaultValue : value));
      setChartSeriesData(organicRankArr);

      if (organic_rank.length <= 15) {
        setTotalXaxiesTick(organic_rank.length);
      }
    } else {
      const topRankDate = moment(sponsored_rank_stats?.top_rank_date).format('MMMM D, YYYY');
      const lowestRankDate = moment(sponsored_rank_stats?.lowest_rank_date).format('MMMM D, YYYY');

      const lowestRankSP = sponsored_rank_stats?.lowest_rank === 0 ? '50+' : sponsored_rank_stats?.lowest_rank;

      setTopRankVal(sponsored_rank_stats?.top_rank);
      setTopRankDate(`(${topRankDate})`);
      setLowestRankVal(lowestRankSP);
      setLowestRankDate(`(${lowestRankDate})`);
      setAverageRankVal(sponsored_rank_stats?.average_rank);

      const sponsoredRankArr = sponsored_rank.map((value) => (value === 0 ? sponsorRankDefaultValue : value));
      setChartSeriesData(sponsoredRankArr);

      if (sponsored_rank.length <= 15) {
        setTotalXaxiesTick(sponsored_rank.length);
      }
    }

    setChartXAxiesDate(date);
  }, [selectedRankTrackerDetails]);

  useEffect(() => {
    setSelectedDuration(durationPeriod[0]);
  }, []);

  // const handleSetting = () => {};

  const options = {
    type: 'line',
    height: chartHeight,
    options: {
      chart: {
        stacked: false,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      xaxis: {
        type: 'datetime',
        categories: chartXAxiesDate,
        labels: {
          formatter: function (value) {
            return moment(value).format('MMM DD'); // Format the date as 'Jul 14'
          }
        },
        tickAmount: totalXaxiesTick //display total number of legend in x axies
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      tooltip: {
        followCursor: true
      },
      yaxis: [{ reversed: true }]
    },
    series: [
      {
        name: 'Value',
        data: chartSeriesData,
        yAxisIndex: 0
      }
    ]
  };

  const handleChangeDuration = (duration) => {
    const request = {
      item_id: itemInfo?.item_id,
      keyword: itemInfo?.search_term,
      days: duration?.value
    };
    dispatch(getItemKeywordChart(request));
    setSelectedDuration(duration);
  };

  return (
    <>
      <BootstrapDialog
        PaperProps={{ sx: { width: '900px', maxWidth: '900px' } }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, ml: 1, p: 2, fontWeight: 600, fontFamily: 'Inter', fontSize: '20px', color: primaryMain }}
          id="customized-dialog-title"
        >
          Search Rank
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ ml: 1 }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  borderRadius={'6px'}
                  // bgcolor={'#E8E8E8'}
                  width={'48px'}
                  height={'48px'}
                >
                  <img src={itemImage} alt="multiselect icon" style={{ width: '40px', height: '40px' }} />
                </Box>
                <Box>
                  <CustomWidthTooltip arrow title={<Box dangerouslySetInnerHTML={{ __html: itemNameFull }} />}>
                    <Typography sx={{ color: '#0B081B', fontFamily: 'Inter', fontSize: '16px', fontWeight: 600 }}>
                      {itemName}
                    </Typography>
                  </CustomWidthTooltip>
                  <Link
                    underline="none"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${itemId}`, '_blank')}
                  >
                    <Typography sx={{ color: primaryMain, fontFamily: 'Inter', fontSize: '16px', fontWeight: 600 }}>
                      {itemId}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={0.8}>
              <Box display={'flex'} gap={2} alignItems={'center'}>
                <Box>
                  <TextField
                    disabled={true}
                    id="search_term"
                    name="searchTerm"
                    label="Search Term"
                    value={searchTerm}
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <Autocomplete
                    disableClearable
                    limitTags={2}
                    id="checkboxes-tags-demo"
                    name="duration"
                    className="custom-auto"
                    options={durationPeriod || []}
                    value={selectedDuration}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(e, value) => {
                      handleChangeDuration(value);
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option?.name}</li>;
                    }}
                    renderInput={(params) => (
                      <TextField color="secondary" sx={{ minWidth: '150px', width: '100%' }} {...params} label="Date Range" />
                    )}
                  />
                </Box>
              </Box>
              {/* <Box>
                <IconButton onClick={handleSetting}>
                  <img src={IconSetting} alt="setting icon" />
                </IconButton>
              </Box> */}
            </Box>
          </Box>
          <Box display={'flex'} mt={3} gap={'50px'}>
            <Box
              borderTop={`2.5px solid ${primaryMain}`}
              bgcolor={grey50}
              display={'flex'}
              flexDirection={'column'}
              gap={'2px'}
              width={'230px'}
              padding={'11px 10px'}
              className="br6"
            >
              <Typography className={'ffInter fw400 clr1'}>Top Rank</Typography>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography className={'ffInter fw700 fs24 clr2'}>{topRankVal}</Typography>
                <Typography className={'ffInter fw400 fs12 clr1'}>{topRankDate}</Typography>
              </Box>
            </Box>
            <Divider sx={{ borderColor: grey300 }} orientation="vertical" variant="middle" flexItem />
            <Box
              borderTop={`2.5px solid ${primaryMain}`}
              bgcolor={grey50}
              display={'flex'}
              flexDirection={'column'}
              gap={'2px'}
              width={'230px'}
              padding={'11px 10px'}
              className="br6"
            >
              <Typography className={'ffInter fw400 clr1'}>Lowest Rank</Typography>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography className={'ffInter fw700 fs24 clr2'}>{lowestRankVal}</Typography>
                <Typography className={'ffInter fw400 fs12 clr1'}>{lowestRankDate}</Typography>
              </Box>
            </Box>
            <Divider sx={{ borderColor: grey300 }} orientation="vertical" variant="middle" flexItem />
            <Box
              borderTop={`2.5px solid ${primaryMain}`}
              bgcolor={grey50}
              display={'flex'}
              flexDirection={'column'}
              gap={'2px'}
              width={'230px'}
              padding={'11px 10px'}
              className="br6"
            >
              <Typography className={'ffInter fw400 clr1'}>Average Rank</Typography>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography className={'ffInter fw700 fs24 clr2'}>{averageRankVal}</Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={3} ml={'-14px'} width={'101%'}>
            <Chart classname="dashboard-chart" {...options} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontFamily: 'Inter',
              borderRadius: '8px',
              py: '12px',
              mr: '14px'
            }}
            onClick={handleClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default SearchRankGraph;
