import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AdvertisingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M5.91874 4.2225C5.91874 4.2225 9.64725 4.0125 14.4485 1.3125V12.9075C14.4485 12.9075 9.91732 10.2375 5.91874 10.05L3.9157 10.005C3.9157 10.005 1.3125 9.6 1.3125 7.1175C1.3125 4.635 3.70565 4.1775 4.00573 4.1775C4.30581 4.1775 5.91874 4.2225 5.91874 4.2225Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.91943 4.22241V10.0499" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.1938 10.1774C7.07377 11.4974 7.43386 13.3274 7.929 14.2424C8.42413 15.1574 8.55167 15.5324 7.05126 16.1399C5.55086 16.7474 5.19076 15.5624 5.19076 15.5624L3.24023 9.81738L3.90791 10.0124L4.70313 10.0274L5.58837 10.0499L6.54863 10.1024L7.1938 10.1774Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4478 9.00752C16.4803 8.46002 16.3078 6.66002 16.3078 6.66002C16.3078 6.66002 16.3078 4.93502 14.4478 4.77002V9.01502V9.00752Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default AdvertisingIcon;
