import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from '@mui/system';
import { format, isBefore, startOfDay } from 'date-fns';

const ExpirationDateModal = ({ open, onClose, data, handleSelectedDate, is_Bulk, is_inline }) => {
  const [option, setOption] = useState('setDate');
  const [selectedDate, setSelectedDate] = useState(
    is_Bulk && is_inline ? null : data?.expiration_date ? new Date(data?.expiration_date) : null
  );

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const handleDateChange = (newDate) => {
    if (newDate && !isNaN(newDate.getTime())) {
      setSelectedDate(newDate);
    } else {
      setSelectedDate(null);
    }
  };

  const dataToSend = {
    selectedDate: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '',
    optionToSelect: option,
    id: is_Bulk && is_inline ? data?.map((item) => item?.id) : data?.id,
    is_bulk: is_Bulk && is_inline
  };
  const isDateBeforeToday = selectedDate && isBefore(startOfDay(selectedDate), startOfDay(new Date()));
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h4">
        Expiration Date
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ display: 'flex' }} component="fieldset">
          <RadioGroup
            color="secondary"
            sx={{ display: 'flex', flexDirection: 'row' }}
            value={option}
            onChange={handleOptionChange}
          >
            <FormControlLabel value="none" control={<Radio color="secondary" />} label="None" />
            <FormControlLabel value="setDate" control={<Radio color="secondary" />} label="Set Date" />
          </RadioGroup>
        </FormControl>
        {option === 'setDate' && (
          <Box my={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Expiration Date"
                value={selectedDate}
                onChange={handleDateChange}
                sx={{ color: 'secondary' }}
                minDate={new Date()}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={option === 'setDate' && (selectedDate == null || isDateBeforeToday)}
          onClick={() => {
            handleSelectedDate(dataToSend);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpirationDateModal;
