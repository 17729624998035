import PropTypes from 'prop-types';

//react
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppSelector, useAppDispatch } from 'store';
import { showValues } from 'store/constant';
import { getDashboardAdvertiserList } from 'store/thunk/userThunk';

// material-ui
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, Checkbox, TextField, createFilterOptions } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CustomCompareDatePicker from 'ui-component/compare-date-range-picker/CustomCompareDatePicker';

// project imports
import LogoSection from '../LogoSection';
import { setSelectedDashboardAdvertiser } from 'store/slices/userDetailsSlice';
import { notificationFail } from 'store/slices/notificationSlice';

const SharedDashboardHeader = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { dashboardAdvertiserList } = useAppSelector((state) => state?.userDetailsSlice);
  const defaultStartDate = moment().subtract(30, 'days').startOf('day').toDate();
  const defaultEndDate = moment().endOf('day').toDate();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [compareStartDate, setCompareStartDate] = useState(defaultStartDate);
  const [compareEndDate, setCompareEndDate] = useState(defaultEndDate);
  const [isCompareDate, setIsCompareDate] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState({ profile: null });
  const [value, setValue] = useState([]);
  const [allProfiles, setAllProfiles] = useState('');

  useEffect(() => {
    if (params && params?.token) {
      const encryptedToken = atob(params?.token);
      const splitToken = encryptedToken.split('@');
      setAllProfiles(splitToken[1]);

      dispatch(getDashboardAdvertiserList({ secretKey: params?.token }));
    }
  }, []);

  useEffect(() => {
    if (!isDropdownOpen) {
      const profiles = allProfiles;

      if (value.length > 0) {
        dispatch(setSelectedDashboardAdvertiser(value.map((item) => item?.id)));
      } else {
        dispatch(setSelectedDashboardAdvertiser([]));
      }

      if (value.length === 0 && profiles?.length === 0) {
        setAllProfiles('');
      }
    }
  }, [value, isDropdownOpen]);

  useEffect(() => {
    if (dashboardAdvertiserList.length > 0 && allProfiles != '') {
      const allSelectedProfiles = allProfiles.split(',');
      const valueData = [];

      allSelectedProfiles.length > 0 &&
        allSelectedProfiles.map((val) => {
          if (valueData.findIndex((advert) => advert?.id === val) === -1) {
            valueData.push(dashboardAdvertiserList.find((data) => data?.id == val));
          }
        });
      setValue(valueData);
    } else if (dashboardAdvertiserList.length > 0 && allProfiles == '') {
      if (params?.token) {
        const allIds = dashboardAdvertiserList.map((obj) => obj.id);
        setAllProfiles(allIds.join(','));
        setValue(dashboardAdvertiserList);
      } else {
        setValue([]);
      }
    }
  }, [dashboardAdvertiserList]);

  const handleSelectAll = (event, all) => {
    if (all) {
      if (event.target.checked) {
        setAllProfiles(dashboardAdvertiserList.map((obj) => obj.id).join(','));
      } else {
        setAllProfiles('');
      }
    }
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    if (!value.length) {
      dispatch(notificationFail('Please select atleast one advertiser'));
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%', mr: 2 }}>
          <CustomCompareDatePicker
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setCompareStartDate={setCompareStartDate}
            setCompareEndDate={setCompareEndDate}
            compareStartDate={compareStartDate}
            compareEndDate={compareEndDate}
            isCompareDate={isCompareDate}
            setIsCompareDate={setIsCompareDate}
            style={{ width: '250px' }}
          />
        </Box>
        <Box sx={isCompareDate ? { mr: 2 } : { width: '100%', mr: 2 }}>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            name="Profile"
            className="custom-auto"
            value={value || []}
            options={dashboardAdvertiserList || []}
            size={'small'}
            inputValue={isDropdownOpen ? searchText?.profile : ''}
            onInputChange={(event, value, reason) => {
              if (reason !== 'reset') {
                setSearchText({ ...searchText, profile: value });
              }
            }}
            renderTags={() => showValues(value, dashboardAdvertiserList)}
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => handleClose()}
            filterOptions={(options, params) => {
              const filter = createFilterOptions();
              const filtered = filter(options, {
                ...params,
                inputValue: params.inputValue || '' // Ensure inputValue is not null
              });
              return [{ name: 'Select All...', all: true }, ...filtered];
            }}
            onChange={(event, newValue) => {
              if (newValue.findIndex((option) => option.all) !== -1) {
                const allIds = dashboardAdvertiserList.map((obj) => obj.id);
                if (value.length === allIds.length) {
                  setAllProfiles('');
                } else {
                  setAllProfiles(allIds.join(','));
                }

                return setValue(value.length === allIds.length ? [] : dashboardAdvertiserList);
              }
              setValue(newValue);
              setAllProfiles(newValue.map((obj) => obj.id).join(','));
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  onChange={(event) => handleSelectAll(event, option.all)}
                  checked={option.all ? !!(value.length === dashboardAdvertiserList.length) : selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 250 }}
            renderInput={(params) =>
              value.length > 0 ? (
                <TextField color="secondary" sx={{ width: 'auto' }} {...params} label="Profile" />
              ) : (
                <TextField color="secondary" sx={{ width: 'auto' }} label="Profile" {...params} placeholder="Search Advertiser" />
              )
            }
            limitTags={3}
          />
        </Box>
      </Box>
    </Box>
  );
};

SharedDashboardHeader.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default SharedDashboardHeader;
