import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function HomeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M3.46875 7.125H5.15625C6.5625 7.125 7.125 6.5625 7.125 5.15625V3.46875C7.125 2.0625 6.5625 1.5 5.15625 1.5H3.46875C2.0625 1.5 1.5 2.0625 1.5 3.46875V5.15625C1.5 6.5625 2.0625 7.125 3.46875 7.125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.46875 16.5H5.15625C6.5625 16.5 7.125 15.9375 7.125 14.5312V12.8438C7.125 11.4375 6.5625 10.875 5.15625 10.875H3.46875C2.0625 10.875 1.5 11.4375 1.5 12.8438V14.5312C1.5 15.9375 2.0625 16.5 3.46875 16.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8438 7.125H14.5312C15.9375 7.125 16.5 6.5625 16.5 5.15625V3.46875C16.5 2.0625 15.9375 1.5 14.5312 1.5H12.8438C11.4375 1.5 10.875 2.0625 10.875 3.46875V5.15625C10.875 6.5625 11.4375 7.125 12.8438 7.125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8438 16.5H14.5312C15.9375 16.5 16.5 15.9375 16.5 14.5312V12.8438C16.5 11.4375 15.9375 10.875 14.5312 10.875H12.8438C11.4375 10.875 10.875 11.4375 10.875 12.8438V14.5312C10.875 15.9375 11.4375 16.5 12.8438 16.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default HomeIcon;
