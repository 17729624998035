import {
  Grid,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  FormHelperText,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import colors from 'assets/scss/_themes-vars.module.scss';
import SearchTable from './searchTable';
import SelectFromTable from './selectFromTable';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useAppSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  postBrandCampaignDetails,
  postCreateAdgroup,
  postCreateAditems,
  postCreateCampaign,
  updateAdgroup,
  updateBrandCampaignDetails,
  updateCampaign
} from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import CampaignBrandContext from 'context/brand';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import { notificationFail } from 'store/slices/notificationSlice';
import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EnterKeywordTable from './enterItemsTable';
import { Link } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PageValue } from 'store/constant';
import SuggestFromTable from 'views/campaign/editProduct/suggestedTable';

const ProductAdGroupSetting = () => {
  const {
    handleBackwordButtonClick,
    campaignData,
    campaign,
    setCampaignData,
    handleForwordButtonClick,
    searchableData,
    selectedData,
    setSelectedData
  } = useContext(CampaignBrandContext);
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState('2');
  const [error, setError] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [keyword, setkeyword] = useState();
  const [list, setList] = useState({});

  const [loading, setLoading] = useState({ campaign: '', adgroup: '', item: '' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { selectdItemData, existingCampaignId, existingAdgroupId, brandId } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectedPhoto(campaignData?.brand?.brand_logo);
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const formSchema = Yup.object().shape({
    adgroup_name: Yup.string().required('Adgroup name is required'),
    brand_name: Yup.string().required('Brand name is required'),
    headline_text: Yup.string().required('Headline text is required'),
    logo_click_url: Yup.string()
      .required('Logo click url is required')
      .test(
        'logo_click_url',
        'Clickable URL must be https://www.walmart.com URL that displays product(s) for the advertised brand.',
        function (value) {
          if (value?.startsWith('https://www.walmart.com')) {
            return true;
          } else {
            return false;
          }
        }
      )
      .test('logo_click_url', `Clickable URL not contains 'typeahead='`, function (value) {
        if (!value?.includes('typeahead=')) {
          return true;
        } else {
          return false;
        }
      }),
    seller_id: campaign?.advertiserType == '3p' ? Yup.string().required('Seller id is required') : '',
    brand_logo: Yup.mixed().required('Brand Logo is required').nullable()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
    defaultValues: {
      adgroup_name: campaignData?.brand?.adgroup_name,
      brand_name: campaignData?.brand?.adgroup_name,
      headline_text: campaignData?.brand?.headline_text,
      logo_click_url: campaignData?.brand?.logo_click_url,
      brand_logo: campaignData?.brand?.brand_logo,
      seller_id: campaignData?.brand?.seller_id
    }
  });

  const adgorupNameValue = watch('adgroup_name');
  const brandNameValue = watch('brand_name');
  const headlineTextValue = watch('headline_text');
  const logoClickUrl = watch('logo_click_url');
  const brandLogo = watch('brand_logo');
  const sellerId = watch('seller_id');

  const brandData = {
    adgroup_name: adgorupNameValue,
    brand_name: brandNameValue,
    headline_text: headlineTextValue,
    logo_click_url: logoClickUrl,
    brand_logo: brandLogo,
    seller_id: sellerId
  };

  const handleOkButtonClick = () => {
    if (
      loading.campaign == 'done' &&
      loading.adgroup == 'done' &&
      loading.item == 'done' &&
      loading.brand == 'done' &&
      !list?.errorMessages?.length
    ) {
      handleForwordButtonClick();
    } else {
      const array = selectedData.filter((item) => !list?.successlist?.find((list) => list?.item_id == item?.item_id));
      setSelectedData(array);
    }
    setDialogOpen(false);
    setLoading({ campaign: '', adgroup: '', item: '' });
  };

  const onButtonClick = (event) => {
    setList({});
    if (!selectedData?.length) {
      setError(true);
    } else {
      setCampaignData({ ...campaignData, brand: brandData, adgroup: { name: event?.adgroup_name } });
      const temp = selectedData?.map((item) => {
        return { item_id: item?.item_id, bid_amount: item.bid_amount ? item.bid_amount : '0.20' };
      });
      setDialogOpen(true);
      setLoading({ ...loading, campaign: 'process' });

      const _request = campaignData?.campaign;
      dispatch(existingCampaignId ? updateCampaign({ _request, existingCampaignId }) : postCreateCampaign({ _request })).then(
        (res) => {
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            setLoading({ ...loading, campaign: 'done', adgroup: 'process' });
            const _adgroupRequest = {
              name: event?.adgroup_name,
              status: 'enabled',
              campaign: res?.payload?.data?.data?.id
            };
            dispatch(
              existingAdgroupId ? updateAdgroup({ _adgroupRequest, existingAdgroupId }) : postCreateAdgroup({ _adgroupRequest })
            ).then((res) => {
              if (res?.payload?.status == 201 || res?.payload?.status == 200) {
                setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'process' });

                const _aditemRequest = {
                  campaign: res?.payload?.data?.data?.campaign,
                  adgroup: res?.payload?.data?.data?.id,
                  add_items: temp
                };

                let formData = new FormData();
                var logo_url = event?.logo_click_url;
                const check = logo_url?.startsWith('https://www.walmart.com');

                if (check) {
                  logo_url = logo_url?.replace('https://www.walmart.com', '');
                }
                logo_url = encodeURIComponent(
                  campaign?.advertiserType == '3p' ? logo_url + '&%20hidden_facet=retailer_id%3A' + event?.seller_id : logo_url
                );
                logo_url = 'https://www.walmart.com' + logo_url;
                // let logoUrl =  encodeURIComponent((campaign?.advertiserType == "3p") ? (event?.logo_click_url + "&%20hidden_facet=retailer_id%3A" + event?.seller_id) : (event?.logo_click_url))
                formData.append('logo', event?.brand_logo);
                formData.append('campaign', res?.payload?.data?.data?.campaign);
                formData.append('adgroup', res?.payload?.data?.data?.id);
                formData.append('name', event?.brand_name);
                formData.append('headlinetext', event?.headline_text);
                formData.append('url', logo_url);

                dispatch(brandId ? updateBrandCampaignDetails({ formData, brandId }) : postBrandCampaignDetails(formData)).then(
                  (res) => {
                    setLoading(false);
                    if (res?.payload?.status == 201) {
                      setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'done', item: 'process' });
                      dispatch(postCreateAditems({ _aditemRequest })).then((res) => {
                        if (res?.payload?.status == 201) {
                          setList(res?.payload?.data);
                          setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'done', item: 'done' });
                        } else {
                          setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'done', item: 'error' });
                        }
                      });
                    } else {
                      setLoading({ ...loading, campaign: 'done', adgroup: 'done', brand: 'error' });
                    }
                  }
                );
              } else {
                setLoading({ ...loading, campaign: 'done', adgroup: 'error' });
              }
            });
          } else {
            setLoading({ ...loading, campaign: 'error' });
          }
        }
      );
    }
  };

  const handlePreviousButton = () => {
    handleBackwordButtonClick();
    setCampaignData({ ...campaignData, brand: brandData });
  };

  const onAddAllClick = () => {
    var tempArray = [...selectedData];
    if (searchableData?.length >= 1) {
      searchableData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20'
          });
        }
      });
    } else {
      selectdItemData?.map((item) => {
        const isItemAlreadyExists = tempArray.some((existingItem) => existingItem.id === item?.id);
        if (!isItemAlreadyExists) {
          tempArray.push({
            id: item?.id,
            product_name: item?.product_name,
            images: item?.images,
            item_id: item?.item_id,
            price: item?.price,
            bid_amount: '0.20'
          });
        }
      });
    }
    setSelectedData(tempArray);
  };

  const { getRootProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 204800,
    accept: {
      'image/*': ['.png']
    },
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width == 300 && img.height == 180) {
            setSelectedPhoto(file);
            setValue('brand_logo', file);
          } else {
            dispatch(notificationFail('Image dimensions should be 300px wide and 180px tall.'));
          }
        };
      }
    },
    onDropRejected: () => {
      dispatch(notificationFail('Brand logo image file size should be ≤ 200kb and format can be png.'));
    }
  });

  const selectedColumns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectedData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {data?.images ? (
                  <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                ) : (
                  <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
                )}
                <Box>
                  <Typography>{data?.product_name ? data?.product_name : '------'}</Typography>
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'item_id',
      label: 'Add All',
      flex: 0.1,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => {
                  var tempData = [...selectedData];
                  tempData = tempData?.filter((item) => item?.item_id != value);
                  setSelectedData(tempData);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          );
        },
        customHeadRender: ({ index }) => (
          <th key={index}>
            <Button
              disabled={!selectedData?.length}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setSelectedData([])}
            >
              Remove All
            </Button>
          </th>
        )
      }
    }
  ];

  const selectedOptions = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: false,
    pagination: true,
    viewColumns: false,
    serverSide: false,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    toolbar: false,
    search: false
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onButtonClick)}>
        <Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item sm={12}>
              {/* {renderLabel('Adgroup Name', true)} */}
              <Controller
                name={'adgroup_name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('adgroup_name')}
                    {...field}
                    name={'adgroup_name'}
                    fullWidth
                    type="text"
                    label="Adgroup Name"
                    variant="outlined"
                    error={errors?.adgroup_name}
                    helperText={errors?.adgroup_name?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12}>
              {renderLabel('Brand Logo', true)}

              {selectedPhoto ? (
                <Box {...getRootProps({ className: 'dropzone' })}>
                  {' '}
                  <img
                    key={selectedPhoto.name}
                    src={URL.createObjectURL(selectedPhoto)}
                    alt={selectedPhoto.name}
                    style={{
                      width: '150px',
                      height: '100px',
                      margin: '5px',
                      borderRadius: '10px',
                      border: `1px solid ${colors.grey500}`
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    height: '100px',
                    width: '150px',
                    border: `1px dashed ${colors.grey500}`,
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  disableElevation
                  size="large"
                  variant="contained"
                  color="secondary"
                  {...getRootProps({ className: 'dropzone' })}
                >
                  <AddIcon sx={{ color: colors.grey500 }} />
                </Box>
              )}
              <FormHelperText>Should be 300px wide and 180px tall, and format can be png with size ≤ 200 kb</FormHelperText>
              {errors?.brand_logo && <FormHelperText error>{errors?.brand_logo?.message}</FormHelperText>}
            </Grid>

            <Grid item sm={12}>
              {/* {renderLabel('Brand Name', true)} */}
              <Controller
                name={'brand_name'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('brand_name')}
                    {...field}
                    name={'brand_name'}
                    fullWidth
                    type="text"
                    label="Brand Name"
                    variant="outlined"
                    error={errors?.brand_name}
                    helperText={errors?.brand_name?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12}>
              {/* {renderLabel('Headline Text', true)} */}
              <Controller
                name={'headline_text'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('headline_text')}
                    {...field}
                    name={'headline_text'}
                    fullWidth
                    type="text"
                    label="Headline Text"
                    variant="outlined"
                    error={errors?.headline_text}
                    helperText={errors?.headline_text?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {renderLabel('Logo Click URL', true)}
                <Tooltip title="The destination URL or landing page URL. It is the website address that a visitor lands on when he/she clicks on the ad. This field is required.">
                  <IconButton sx={{ padding: 0 }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            {/* <Box sx={{display: 'flex'}}> */}
            <Grid item sm={5} xs={12}>
              <Controller
                name={'logo_click_url'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...register('logo_click_url')}
                    {...field}
                    name={'logo_click_url'}
                    fullWidth
                    type="text"
                    error={errors?.logo_click_url}
                    helperText={errors?.logo_click_url?.message}
                  />
                )}
              />
            </Grid>
            {campaign?.advertiserType == '3p' && (
              <Box sx={{ display: 'flex', padding: '30px 0px 0px 16px' }}>
                <Typography variant="h5">&%20hidden_facet=retailer_id%3A</Typography>
              </Box>
            )}
            <Grid item sm={3} xs={12}>
              {campaign?.advertiserType == '3p' && (
                <Controller
                  name={'seller_id'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...register('seller_id')}
                      {...field}
                      name={'seller_id'}
                      fullWidth
                      type="text"
                      error={errors?.seller_id}
                      helperText={errors?.seller_id?.message}
                    />
                  )}
                />
              )}
            </Grid>
            {/* </Box> */}
            {/* </Grid> */}

            <Grid item sm={12}>
              {renderLabel('Item', true)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                  <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { backgroundColor: colors.secondaryMain } }}
                      >
                        <Tab label="Search" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="1" />
                        <Tab label="Select from" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="2" />
                        <Tab label="Suggested" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="3" />
                        <Tab label="Enter Items" sx={{ '&.Mui-selected': { color: colors.secondaryMain } }} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                      <SearchTable />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                      <SelectFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="3">
                      <SuggestFromTable onAddAllClick={onAddAllClick} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0 }}>
                      <EnterKeywordTable keyword={keyword} setkeyword={setkeyword} />
                    </TabPanel>
                  </Box>
                </TabContext>

                {error && (
                  <FormHelperText error sx={{ margin: '3px 14px 0px 14px' }}>
                    {'Please select atlease one item.'}
                  </FormHelperText>
                )}
                <Box sx={{ mt: 5, border: 1, borderColor: 'divider', borderRadius: '12px' }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      padding: '20px 30px 20px 30px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h5">{selectedData?.legth} Item(s)Added</Typography>
                    <Typography variant="h6" sx={{ color: colors.grey500 }}>
                      Item bid is only valid for Auto Campaigns
                    </Typography>
                  </Box>
                  <MainCard sx={{ padding: 0, maxHeight: '1050px', overflow: 'auto' }}>
                    <MUIDataTable
                      actions={[
                        {
                          icon: 'save'
                        }
                      ]}
                      sx={{ padding: 0 }}
                      data={selectedData}
                      columns={selectedColumns}
                      options={selectedOptions}
                      className="pnl-by-item"
                    />
                  </MainCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <>
              <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={() => navigate('/campaign')}>
                CANCEL
              </Button>
              <Button variant="outlined" color="secondary" sx={{ ml: 1 }} onClick={handlePreviousButton}>
                PREVIOUS
              </Button>
              <Button variant="contained" color="secondary" sx={{ ml: 1 }} type="submit">
                {/* {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />} */}
                NEXT
              </Button>
            </>
          </Box>
        </Box>
      </form>
      <Dialog open={dialogOpen} aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="div"> Message</Typography>{' '}
        </DialogTitle>
        <DialogContent dividers sx={{ minWidth: '600px', minHeight: '200px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.campaign == 'process' && <CircularProgress size={20} />}
              {loading.campaign == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.campaign == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Campaign (${campaignData?.campaign?.name}) ${existingCampaignId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.adgroup == 'process' && <CircularProgress size={20} />}
              {loading.adgroup == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.adgroup == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`Adgroup (${campaignData?.adgroup?.name}) ${existingAdgroupId ? 'updated' : 'created'} successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.brand == 'process' && <CircularProgress size={20} />}
              {loading.brand == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.brand == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>{`Waiting to upload creative`}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
              {loading.item == 'process' && <CircularProgress size={20} />}
              {loading.item == 'done' && <TaskAltRoundedIcon color="success" />}
              {loading.item == 'error' && <HighlightOffIcon color="error" />}
            </Box>
            <Typography sx={{ padding: 1.5 }}>
              {`${list?.data?.successcount ? list?.data?.successcount : 0} items added successfully`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              {list?.errorMessages?.map((item, index) => (
                <Box key={index} sx={{ display: 'flex' }}>
                  {' '}
                  <Box sx={{ width: '25px', display: 'flex', alignItems: 'center' }}>
                    {list?.errorMessages?.length && <HighlightOffIcon color="error" />}
                  </Box>
                  <Typography sx={{ padding: 1.5 }}>{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ margin: 1 }} onClick={handleOkButtonClick} color="secondary" variant="contained" autoFocus size="small">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductAdGroupSetting;
