import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { useAppDispatch } from 'store';
import { getReviewLog } from 'store/thunk/userThunk';
import colors from 'assets/scss/_themes-vars.module.scss';
import dayjs from 'dayjs';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const ReviewLogModal = (props) => {
  const { reviewLogModal } = props;
  const dispatch = useAppDispatch();
  const { reviewLogData } = useAppSelector((state) => state?.userDetailsSlice);

  useEffect(() => {
    const id = reviewLogModal?.id;
    if (id) {
      dispatch(getReviewLog({ id }));
    }
  }, []);

  const convertDate = (value) => {
    const dayjsDate = dayjs(value.$d);
    const formattedDate = dayjsDate.format('MM/DD/YYYY HH:MM:ss');
    return formattedDate;
  };

  return (
    <>
      {reviewLogData.length ? (
        <Box>
          <Typography sx={{ mt: 2, mb: 2 }}>Campaign: {reviewLogModal?.name}</Typography>
          {reviewLogData?.map((item, index) => (
            <Box
              key={index}
              sx={{ minWidth: 600, mb: 2, border: 1, padding: 2, borderColor: colors.grey300, borderRadius: '7px' }}
            >
              {item?.reviewComments?.length > 0 ? (
                <Accordion sx={{ padding: 0 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box sx={{ width: '100%' }}>
                      {item?.reviewStatus == 'complete' && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography>
                            <b>Review Completed</b> by Walmart, {convertDate(item?.updated_at)}
                          </Typography>
                          {item?.reviewStatus == 'cancelled' && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {' '}
                              <RemoveCircleIcon sx={{ color: colors.grey500, mr: 1 }} />
                              <Typography> Cancelled</Typography>
                            </Box>
                          )}
                        </Box>
                      )}

                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography>
                          <b>Review Submitted</b> by {convertDate(item?.created_at)}
                        </Typography>
                        {item?.reviewStatus == 'cancelled' && (
                          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            {' '}
                            <RemoveCircleIcon sx={{ color: colors.grey500, mr: 1 }} />
                            <Typography> Cancelled</Typography>
                          </Box>
                        )}

                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-23px' }}>Comments</Box>
                      </Box>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <>
                      <Divider sx={{ mt: 2 }} />
                      <Box sx={{ display: 'flex', padding: '15px 10px' }}>
                        <Box sx={{ width: '30%' }}>
                          <Typography variant="h5"> Comment Type </Typography>
                        </Box>
                        <Box sx={{ width: '70%' }}>
                          <Typography variant="h5">Comments</Typography>
                        </Box>
                      </Box>
                      <Box>
                        {item?.reviewComments?.map((comment, index) => (
                          <>
                            <Divider />
                            <Box key={index} sx={{ display: 'flex', padding: '10px' }}>
                              <Box sx={{ width: '30%' }}> {comment?.commentType}</Box>
                              <Box sx={{ width: '70%' }}>{comment?.comments}</Box>
                            </Box>
                          </>
                        ))}
                      </Box>
                    </>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <>
                  <Box sx={{ width: '100%' }}>
                    {item?.reviewStatus == 'complete' && (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography>
                          <b>Review Completed</b> by Walmart, {convertDate(item?.updated_at)}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {' '}
                          <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                          <Typography> Good, No Comments</Typography>
                        </Box>

                        {item?.reviewStatus == 'cancelled' && (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {' '}
                            <RemoveCircleIcon sx={{ color: colors.grey500, mr: 1 }} />
                            <Typography> Cancelled</Typography>
                          </Box>
                        )}
                      </Box>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography>
                        <b>Review Submitted</b> by {convertDate(item?.created_at)}
                      </Typography>
                      {item?.reviewStatus == 'complete' && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {' '}
                          {/* <CheckCircleIcon sx={{ color: colors.grey500, mr: 1 }} />
                          <Typography> No more Comment</Typography> */}
                        </Box>
                      )}
                      {item?.reviewStatus == 'cancelled' && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                          {' '}
                          <RemoveCircleIcon sx={{ color: colors.grey500, mr: 1 }} />
                          <Typography> Cancelled</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ minWidth: 600, height: 100 }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default ReviewLogModal;
