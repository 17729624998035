// import MUIDataTable from 'mui-datatables';
// import { Typography } from '@mui/material';
// import { formateValue } from 'helper/commonHelper';
// import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { TableCell, Box } from '@mui/material';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { useState } from 'react';
// import { useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { formatData, useFormattedDataOnce } from 'utils/utilsFunction';
import upIcon from 'assets/images/dashna/up.svg';
import downIcon from 'assets/images/dashna/down.svg';
import __ from 'underscore';

const AccountPerformance = ({ monthlyReport }) => {
  //   console.log("monthlyReport ", monthlyReport);
  //   const [displayData, setDisplayData] = useState([]);
  //  console.log("displayData11", displayData);

  //   const formateData = (keyArr) => {
  //     const formattedData = {};
  //     keyArr.forEach(([key, value]) => {
  //       formattedData[key] = formateValue(key, value);
  //     });

  //     return formattedData;
  //   }

  //   useEffect(() => {
  //     const finalData = monthlyReport?.map((item) => { return formateData(Object.entries(item)) })
  //     setDisplayData(finalData)
  //   }, [monthlyReport])

  //   // const displayData = [
  //   //   {
  //     month: "March 2022",
  //     impressions: 1,
  //     clicks: 1,
  //     ctr: 1,
  //     conversion_rate: 1,
  //     asp: 1,
  //     cpc: 1,
  //     units_sold: 1,
  //     ad_spend: 1,
  //     ad_sales: 1,
  //     roas: 1,
  //     acos: 1,
  //   },
  // ];

  const displayData = useFormattedDataOnce(monthlyReport, formatData);

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false,
    isParentLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent={isParentLabelAlignRight ? 'flex-end' : 'flex-start'} gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
            {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  const columns = [
    {
      name: 'month',
      label: 'Month',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'month',
            columnLabel_1: 'Month'
          });
        }
      }
    },
    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ad_spend',
            columnLabel_1: 'Spend'
          });
        }
      }
    },
    {
      name: 'ad_sales',
      label: 'Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ad_sales',
            columnLabel_1: 'Sales'
          });
        }
      }
    },
    {
      name: 'unitsold',
      label: 'Units',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'unitsold',
            columnLabel_1: 'Units'
          });
        }
      }
    },
    {
      name: 'asp',
      label: 'ASP (Sales/Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'asp',
            columnLabel_1: 'ASP (Sales/Units)'
          });
        }
      }
    },
    {
      name: 'impressions',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'impressions',
            columnLabel_1: 'Impressions'
          });
        }
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'clicks',
            columnLabel_1: 'Clicks'
          });
        }
      }
    },
    {
      name: '0',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: '0',
            columnLabel_1: 'Orders'
          });
        }
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ctr',
            columnLabel_1: 'CTR'
          });
        }
      }
    },

    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_orders',
            columnLabel_1: 'CVR (Orders)'
          });
        }
      }
    },
    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'roas',
            columnLabel_1: 'RoAS'
          });
        }
      }
    },
    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'acos',
            columnLabel_1: 'ACoS'
          });
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    // selectableRows: 'none',
    // page: 1,
    // count: displayData?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: false,
    serverSide: false,
    selectableRows: 'none',
    search: false,
    viewColumns: false
    // rowsPerPage: rowPerPage,
    // rowsPerPageOptions: [10, 20, 50],
  };

  return (
    <MainCard titleInner="Account Performance By Month">
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={displayData}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />
    </MainCard>
  );
};

export default AccountPerformance;
