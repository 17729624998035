import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppSelector } from 'store';
import { useAppDispatch } from 'store';
// import { accountPasswordCreate } from 'store/thunk/authThunk';

// material-ui
import { Dialog, Grid, DialogContent, Link, Typography, Button } from '@mui/material';

// project imports
import WelcomeCone from 'assets/images/dashna/welcomeCone.svg';
import { useEffect } from 'react';
import { setUserData } from 'store/slices/authSlice';

const WelcomeToDashna = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuthData = useAppSelector((state) => state.authorization);
  const [
    open
    // setOpen
  ] = useState(true);
  const [userName, setUsername] = useState('Steve');

  //Color
  const primaryTextColor = colors?.primaryTextColor;
  const linkColor = colors?.linkColor;

  // const handleClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    const userName = userAuthData?.userData?.first_name;
    setUsername(userName);
  }, []);

  const clickToNext = () => {
    const restdata = {
      ...JSON.parse(localStorage.getItem('user_data')),
      display_module_fe: 'create-store'
    };
    localStorage.setItem('user_data', JSON.stringify(restdata));
    dispatch(setUserData(restdata));
    navigate('/create-store');
  };

  return (
    <>
      <Dialog
        maxWidth={'sm'}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
      >
        <DialogContent dividers>
          <Grid
            container
            px={8}
            py={4}
            textAlign={'center'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={12} pb={3.5}>
              <Link to="#">
                <img src={WelcomeCone} alt="Welcome" />
              </Link>
            </Grid>
            <Grid item xs={12} pb={3.5}>
              <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                Welcome to <span style={{ color: linkColor }}>Dashna</span>, {userName}.
              </Typography>
              <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                Your account has been created.
              </Typography>
            </Grid>
            <Grid item xs={12} pb={3.5}>
              <Typography className={'fontInter'} color={primaryTextColor} fontSize={'16px'}>
                Next, connect Dashna to your Walmart
              </Typography>
              <Typography className={'fontInter'} color={primaryTextColor} fontSize={'16px'}>
                accounts to sync your data.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={'actionBtn'}
                sx={{ minWidth: '100px' }}
                onClick={() => clickToNext()}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WelcomeToDashna;
