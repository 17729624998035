  import axios from 'axios';
  import Config from '../utils/config';

  const baseURL = Config.API_URL;

  const apiClient = (isFormData = false) => {
    let headers = {
      'Access-Control-Allow-Origin': '*'
      // 'Access-Control-Request-Headers': 'origin, Content-Type, x-requested-with, accept'
    };

    let userdata = window.localStorage.getItem('user_data');

    if (userdata) {
      userdata = JSON.parse(userdata);
      headers = {
        ...headers,
        tenantid: userdata?.tenant_id,
        Authorization: `Bearer ${userdata.access}`,
        'Access-Control-Allow-Headers': 'origin,x-requested-with,content-type,accept'
      };
    }

    if (isFormData) {
      Object.assign(headers, { 'content-type': 'multipart/form-data' });
    }
    // return axios.create({
    //   baseURL,
    //   withCredentials: false,
    //   headers
    // });
    const instance = axios.create({
      baseURL,
      withCredentials: false,
      headers
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.message === "Network Error") {
          localStorage.removeItem('user_data');
          // Redirect to the login page when a 401 response is received
          window.location.href = '/'; // Change '/login' to the actual URL of your login page
        }
        return Promise.reject(error);
      }
    );

    return instance;

  };
  export default apiClient;
