import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import StepperCustomDot from 'ui-component/StepperCustomDot';
import MainCard from 'ui-component/cards/MainCard';
import ProductAdGroupSetting from './adGroupSettingStep';
import TargetAdGroupSetting from './targetSettingStep';
import dayjs from 'dayjs';
import CompleteStep from './completeStep';
import { useEffect } from 'react';
import AdgroupContext from 'context/adgroup';
import { CAMPAIGN } from 'constant/campaignConstant';
// import TARGETING_TYPE from 'constant/campaignConstant';

const CreateAdgroup = () => {
  const steps = [
    // 'Campaign Setting',
    'Adgroup Setting',
    'Complete'
  ];

  const [activeStep, setActiveStep] = useState(1);
  const [stepsArray, setStepArray] = useState(steps);
  const [searchableData, setSearchableData] = useState([]);
  const [dateType, setDateType] = useState("any");
  const [selectedData,setSelectedData] = useState([]);
  const [selectedKeywordData,setSelectedKeywordData] = useState([]);

  const [campaign, setCampaign] = useState({
    budget_type: "both",
    campaign_type: "sponsoredProducts",
    targeting_type: CAMPAIGN.TARGETING_TYPE.AUTO,
    rollover: true,
    is_search_ingrid: true,
    is_others: true,
    is_item_buybox: true,
    is_home_page: true,
    is_stock_up: true,
    is_search_carousel: false,
    is_item_carousel: false,
    placement_bid_search_ingrid: null,
    placement_bid_item_buybox: null,
    placement_bid_home_page:null,
    placement_bid_stock_up: null,
    platform_bid_desktop: null,
    platform_bid_app: null,
    platform_bid_mobile: null,
    name: "",
    advertiser: "",
    daily_budget: "",
    total_budget: "",
    start_date: dayjs(new Date()).format('YYYY-MM-DD'),
    end_date: ""
  })



  const [campaignData, setCampaignData] = useState({
    campaign: {},
    adgroup: {},
    aditem: {}
  });


  useEffect(() => {
    window.scrollTo(0, 0);
},[])


  const project = () => {
    switch (activeStep) {
      
      case 1:
        return (
          <ProductAdGroupSetting />
        );
      case 2:
        if(campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL){
          return (
            <TargetAdGroupSetting />
          );
        }else {
          return (
            <CompleteStep />
          );
        }
      case 3:
        return (
          <CompleteStep />
        );
      default:
        return <h1>No project match</h1>;
    }
  };

  const handleForwordButtonClick = () => {
    setActiveStep(activeStep + 1);
  }

  const handleBackwordButtonClick = () => {
    setActiveStep(activeStep - 1);
  }

  useEffect(() => {
    if (campaign?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL) {
      setStepArray([
        // 'Campaign Setting',
        'Adgroup Setting',
        'Target Setting',
        'Complete'
      ]);
    } else {
      setStepArray([
        // 'Campaign Setting',
        'Adgroup Setting',
        'Complete'
      ]);
    }

  },[campaign?.targeting_type])
 
  return (
    <MainCard title="Adgroup">
      <AdgroupContext.Provider value={{campaign, setCampaign, campaignData, setCampaignData, 
        handleForwordButtonClick, handleBackwordButtonClick, searchableData, setSearchableData, 
        dateType, setDateType, selectedData,setSelectedData, selectedKeywordData,setSelectedKeywordData,setStepArray,
        setActiveStep}}> 
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel
        connector={<StepConnector sx={{marginTop: 0.7}} />}
        >
          {stepsArray.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepperCustomDot}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box>
        {/* </StepperWrapper> */}
        <Box sx={{ height: 'auto' }}>{project()}</Box>
        
      </Box>
      </AdgroupContext.Provider>
    </MainCard>
  );
};

export default CreateAdgroup;
