import { formateValue, formateValueForCustomReport } from 'helper/commonHelper';
import { useEffect, useState } from 'react';

export const useFormattedDataOnce = (data, formatFunction) => {
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const formattedData = data.map((item) => formatFunction(Object.entries(item)));
      setDisplayData(formattedData);
    }
  }, [data, formatFunction]);

  return displayData;
};

export const formatData = (keyArr) => {
  const formattedData = {};
  keyArr.forEach(([key, value]) => {
    // Assuming formateValue is available in your project
    formattedData[key] = formateValue(key, value);
  });
  return formattedData;
};

export const formatDataCustomReport = (keyArr) => {
  const formattedData = {};
  keyArr.forEach(([key, value]) => {
    // Assuming formateValue is available in your project
    formattedData[key] = formateValueForCustomReport(key, value);
  });
  return formattedData;
};
