import * as React from 'react';
import Popover from '@mui/material/Popover';
import { TextField } from '@mui/material';
// import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useState } from 'react';

export default function BasicPopover({
  tableFilter,
  setTableFilter,
  searchAdGroup,
  setSearchAdGroup,
  searchCampaign,
  setSearchCampaign,
  from,
  isNoBorder
}) {
  const tempFilters = JSON.parse(JSON.stringify(tableFilter));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedCampaign(newValue ? newValue.split(/\n/).length + ` ${from} Inputs` : '');
    tempFilters['campaign'] = newValue.split(/\n/).join(',');
    setTableFilter(tempFilters);
  };

  const debouncedOnChange = debounce(handleChange, 1000);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {isNoBorder ? (
        <TextField
          value={selectedCampaign}
          InputProps={{
            readOnly: true
          }}
          sx={{
            '& fieldset': { border: 'none' },
            padding: '2.5px 4px 2.5px 8px !important'
          }}
          placeholder={`Search ${from}`}
          color="secondary"
          onClick={handleClick}
          fullWidth
        />
      ) : (
        <TextField
          value={selectedCampaign}
          InputProps={{
            readOnly: true
          }}
          placeholder={`Search ${from}`}
          color="secondary"
          onClick={handleClick}
          fullWidth
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <TextField
          multiline
          rows={4}
          value={from === 'AdGroup' ? searchAdGroup : searchCampaign}
          color="secondary"
          placeholder={` Search ${from} `}
          fullWidth
          sx={{ mt: 1 }}
          onChange={(event) => {
            // handleCampaignChange(e);
            debouncedOnChange(event);
            if (from === 'AdGroup') {
              setSearchAdGroup(event.target.value);
            } else {
              setSearchCampaign(event.target.value);
            }
          }}
        />
      </Popover>
    </div>
  );
}
