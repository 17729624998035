import React from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { GridRowModes, DataGrid, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { useAppSelector, useAppDispatch } from 'store';
import { updateKeywordItemsTableItem } from 'store/thunk/userThunk';
import BidAmountEditInputCell from './CustomBidAmountCellField';

const KeywordsDetails = () => {
  const dispatch = useAppDispatch();

  //Permission checking
  const { userData } = useAppSelector((state) => state.authorization);
  const campaignPermission = userData?.permission?.campaign || {};
  const { update } = campaignPermission;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0
  });
  const [rows, setRows] = React.useState([]);
  const [inprogress, setInProgress] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const { campaignDetailViewData } = useAppSelector((state) => state?.userDetailsSlice);

  React.useEffect(() => {
    const TableRows = campaignDetailViewData?.keyword;
    setRows(TableRows || []);
  }, [campaignDetailViewData]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setInProgress([...inprogress, updatedRow.id]);
    // Call API For change in db
    const _keywordItemsRequest = {
      bid_amount: updatedRow.bid_amount,
      state: updatedRow.state
    };

    const existingKeyItemId = updatedRow.id;
    const response = await dispatch(updateKeywordItemsTableItem({ _keywordItemsRequest, existingKeyItemId }));

    //Remove item after response
    setInProgress(inprogress.filter((item) => item !== updatedRow.id));

    if (response.payload.status == 201 || response.payload.status == 200) {
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      return oldRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'adgroup_name',
      headerName: 'Adgroup Name',
      width: 150,
      editable: false
    },
    {
      field: 'keywordtext',
      headerName: 'Keyword Text',
      width: 120,
      editable: false
    },
    {
      field: 'matchtype',
      headerName: 'Match Type',
      width: 110,
      editable: false
    },
    {
      field: 'bid_amount',
      headerName: 'Bid',
      width: 130,
      editable: true,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `$ ${parseFloat(params.value).toFixed(2).toLocaleString()}`;
      },
      renderEditCell: (params) => {
        const passParams = { ...params, value: parseFloat(params.value).toFixed(2) };
        return <BidAmountEditInputCell {...passParams} restrictAmt="0.30" />;
      }
    },
    {
      field: 'state',
      headerName: 'Status',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['enabled', 'paused'],
      renderCell: (params) => params.value.toUpperCase()
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
              key={`save-${id}`}
              disabled={!update}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              key={`delete-${id}`}
              disabled={!update}
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={inprogress.includes(id) ? <CircularProgress size="1rem" /> : <EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            key={`edit-${id}`}
            disabled={!update}
          />
        ];
      }
    }
  ];

  return (
    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
      <Grid item sm={12}>
        <Typography variant="h4">Keywords</Typography>
      </Grid>
      <Grid item sm={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{
            '& .MuiDataGrid-virtualScroller ': {
              minHeight: '100px !important'
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default KeywordsDetails;
