import { Button, CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import CampaignContext from 'context';
import React, { useState } from 'react'
import { useContext } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { useAppDispatch } from 'store';
import { getEnterKeywordList } from 'store/thunk/userThunk';
import MainCard from 'ui-component/cards/MainCard';

const EnterKeywordTable = (props) => {
  const { keyword, setkeyword } = props;
  const { selectedData, setSelectedData, campaignData } = useContext(CampaignContext);
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch();
  const handleAddButton = () => {
    const values = [...new Set(keyword.trim().split("\n"))];
    const item_id = values.toString();
    setLoading(true)
    const advertiserId = campaignData?.campaign?.advertiser;
    if (advertiserId) {
      dispatch(getEnterKeywordList({ item_id, advertiserId })).then((res) => {
        setLoading(false)

        if (res?.payload?.data?.data?.length > 0) {
          const array = [];
          values?.map((item) => {
            const findItem = selectedData?.find((id) => id.item_id == item);
            if (selectedData?.length == 0 || !findItem) {
              const find = res?.payload?.data?.data?.find((a) => a.item_id == item)
              if (find) {
                array.push({ ...find, bid_amount: "0.20" })
              } else {
                array.push({ item_id: item, bid_amount: "0.20" })
              }
            }
          })

          setSelectedData([...selectedData, ...array])
        } else {
          let array = []
          values?.map((item) => {
            const find = selectedData?.find((a) => a?.item_id == item);
            if (!find) {
              array.push({ item_id: item, bid_amount: "0.20" })
            }
          })
          setSelectedData([...selectedData, ...array])
        }
      })
    }
    setkeyword("")
  }

  const handleSetKeyword = (e) => {
    const inputVal = e.target.value;
    const words = inputVal.trim().split(/\s+/);
    if (words.length <= 200) {
      setkeyword(inputVal);
    }
  }
  
    return (
        <>
        <Box>
          <MainCard sx={{ padding: 0 }}>
            <TextField
              name={"adgroup_name"}
              fullWidth
              type='text'
              multiline
              color="secondary"
              placeholder='Please enter item id, one item id per line'
              rows={4}
              value={keyword}
              onChange={handleSetKeyword}
            />
            <Box sx={{mt:2 , display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Box>You can add up to 200 items.</Box>
              <Box> 
              <Button variant="outlined" size='small' color="secondary"
                disabled={!keyword}
                onClick={handleAddButton}
              >{loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}Add</Button>
            </Box>
          </Box>
        </MainCard>
      </Box>
    </>
  );
};

export default EnterKeywordTable;
