import { useState, useEffect } from 'react';

import moment from 'moment'; // Add this line to import moment
import subDays from 'date-fns/subDays';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import { DateRangePicker, Stack } from 'rsuite';
import {
  addMonths,
  endOfMonth,
  startOfMonth
  // subMonths
} from 'date-fns';
import { Typography } from '@mui/material';
// import { dateRangePicker } from 'store/thunk/dashboardThunk';
// import { useAppDispatch } from 'store';

const CustomDatePicker = (props) => {
  const { setStartDate, setEndDate, startDate, endDate, alignItems, disableAfterDate } = props;
  const customDateFormat = 'MM/dd/yyyy';
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (disableAfterDate) {
      setShow(true);
    }
  }, [disableAfterDate]);

  const handleDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleChange = (value) => {
    if (value) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
    setShow(true);
  };

  // const handleTodayDate = () => {
  //   const today = moment().endOf('day').toDate();

  //   handleDateRange(today, today);
  //   setShow(true);
  // };

  const handleLast7Days = () => {
    const today = moment().endOf('day').subtract(1, 'days');
    const start = moment(today).subtract(6, 'days').startOf('day').toDate();
    handleDateRange(start, today.toDate());
    setShow(true);
  };

  const handleLast30Days = () => {
    const today = moment().endOf('day').subtract(1, 'days');
    const start = moment(today).subtract(29, 'days').startOf('day').toDate();
    handleDateRange(start, today.toDate());
    setShow(true);
  };

  const handleLast60Days = () => {
    const today = moment().endOf('day').subtract(1, 'days');
    const start = moment(today).subtract(59, 'days').startOf('day').toDate();
    handleDateRange(start, today.toDate());
    setShow(true);
  };

  const handleMonthToDate = () => {
    const today = moment().endOf('day').subtract(1, 'days');
    const start = moment(today).startOf('month').toDate();
    handleDateRange(start, today.toDate());
    setShow(true);
  };

  const handleLastMonth = () => {
    const start = moment().subtract(1, 'month').startOf('month').toDate();
    const end = moment(start).endOf('month').toDate();
    handleDateRange(start, end);
    setShow(true);
  };

  // const handleCustomRange = () => {
  //   const today = moment().endOf('day').subtract(1, 'days');
  //   const start = moment(today).subtract(179, 'days').startOf('day').toDate();
  //   handleDateRange(start, today.toDate());
  //   setShow(true);
  // };

  // const handleLast12Months = () => {
  //   const today = moment().endOf('day');
  //   const start = moment(today).subtract(12, 'months').startOf('month').toDate();
  //   const end = moment(today).toDate(); // Set the end date to today's date
  //   handleDateRange(start, end);
  //   setShow(true);
  // };

  // const handleYearToDate = () => {
  //   const today = moment().endOf('day');
  //   const start = moment(today).startOf('year').toDate();
  //   handleDateRange(start, today.toDate());
  //   setShow(true);
  // };

  // const handleLastYear = () => {
  //   const start = moment().subtract(1, 'year').startOf('year').toDate();
  //   const end = moment(start).endOf('year').toDate();
  //   handleDateRange(start, end);
  //   setShow(true);
  // };

  const predefinedRanges = [
    // {
    //   label: 'Today',
    //   value: [new Date(), new Date()],
    //   placement: 'left',
    //   onClick: handleTodayDate
    // },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), subDays(new Date(), 1)],
      placement: 'left',
      onClick: handleLast7Days
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), subDays(new Date(), 1)],
      placement: 'left',
      onClick: handleLast30Days
    },
    {
      label: 'Last 60 days',
      value: [subDays(new Date(), 59), subDays(new Date(), 1)],
      placement: 'left',
      onClick: handleLast60Days
    },
    {
      label: 'Month to Date',
      value: [startOfMonth(new Date()), subDays(new Date(), 1)],
      placement: 'left',
      onClick: handleMonthToDate
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
      onClick: handleLastMonth
    }
    // {
    //   label: 'Last 12 months',
    //   value: [subMonths(new Date(), 11), new Date()],
    //   placement: 'left',
    //   onClick: handleLast12Months
    // },
    // {
    //   label: 'Year to Date',
    //   value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    //   placement: 'left',
    //   onClick: handleYearToDate
    // },
    // {
    //   label: 'Last Year',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear() - 1, 11, 31)],
    //   placement: 'left',
    //   onClick: handleLastYear
    // },
    // {
    //   label: 'Custom Range',
    //   placement: 'left',
    //   value: [new Date(), new Date()],
    //   onClick: handleCustomRange
    // },
    // {
    //   label: 'clear',
    //   appearance: 'default',
    //   value: [startDate, endDate],
    //   style: { marginLeft: '370px' },
    //   onClick: handleClean
    // }
  ];

  const disabledDate = (date) => {
    const today = moment().subtract(1, 'days').endOf('day');
    const start = moment(today).subtract(59, 'days').startOf('day');
    return !(date >= start && date <= today);
  };

  return (
    <Stack className="custom-daterange-picker" direction="column" alignItems={alignItems ? 'normal' : 'flex-start'}>
      {startDate && endDate && <Typography className="date-range-picker-label">Select Date Range</Typography>}
      <DateRangePicker
        size="lg"
        ranges={predefinedRanges}
        format={customDateFormat}
        value={[startDate, endDate]}
        placeholder="Select Date Range"
        style={{ width: '100%', maxWidth: '100%', minWidth: '250px' }}
        onChange={handleChange}
        onClean={handleClean}
        placement="bottom"
        shouldDisableDate={show === true ? disabledDate : undefined}
      />
    </Stack>
  );
};

export default CustomDatePicker;
