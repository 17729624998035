import { Box, Menu, MenuItem } from "@mui/material";

const ShowSplitFilterPlus = (props) => {
  const { mainOpenMuenu, mainArray, mainChips, onAddSplitData, type, mainClose } = props;

  return (
    <>
      <Menu anchorEl={mainOpenMuenu} open={Boolean(mainOpenMuenu)} onClose={mainClose}>
        <Box className="popup-view">
          {mainArray && mainArray?.length > 0 ? (
            mainArray
              ?.filter((obj) => !mainChips.some((chip) => chip?.value === obj?.value))
              ?.map((item, index) => (
                <MenuItem key={index} onClick={() => onAddSplitData(item, type)}>
                  {item.label}
                </MenuItem>
              ))
          ) : (
            <Box>No Data Available</Box>
          )}
        </Box>
      </Menu>
    </>
  );
}

export default ShowSplitFilterPlus;