import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import React from 'react'
import MainCard from 'ui-component/cards/MainCard'
import BulkOperationTable from './bulkOperationTable';
import BulkOperationFaild from './bulkOperationFaild';
import { useAppSelector } from 'store';
import { useLocation, useNavigate } from 'react-router';
import { getBulkoperationsIdList, postBulkOperation } from 'store/thunk/userThunk';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';

const BulkOperationResult = () => {
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    console.log("tableLoading ", tableLoading);
    const { verifyCampaignFile } = useAppSelector((state) => state?.userDetailsSlice);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const data = location.state;
    
    useEffect(() => {
        if (!data?.isHideSubmit && data?.mainId) {
            setTableLoading(true);
            dispatch(getBulkoperationsIdList({ bulkId: data?.mainId })).then((resp) => {
                if (resp?.payload) {
                    setTableLoading(false);
                }
            });
        }
    }, []);

    const handleChange = () => {
        // setValue(newValue);
        setLoading(true);
        const formData = new FormData();
        formData.append('advertiser_id', data?.advertiser?.id);
        formData.append('campaign_file', data?.selectedFile, data?.selectedFile?.name);
        dispatch(postBulkOperation({ formData })).then(() => {
            setLoading(false);
            navigate('/bulk-operations-list');
        });
    };
    
    return (
        <MainCard>
            <Box style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
                        <Typography variant='h4'>Profile :</Typography>
                        <Typography variant='subtitle2' sx={{ fontSize: 16, ml: 1 }}>{verifyCampaignFile?.display_data?.Profile_name ? verifyCampaignFile?.display_data?.Profile_name : data?.advertiser?.name}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h4'>File :</Typography>
                        <Typography variant='subtitle2' sx={{ fontSize: 16, ml: 1 }}>{verifyCampaignFile?.display_data?.file_name ? verifyCampaignFile?.display_data?.file_name : data?.selectedFile?.name}</Typography>
                    </Box>
                </Box>
                {verifyCampaignFile?.display_data?.bulk_data && data?.isHideSubmit ?
                    <Box sx={{ display: 'flex', justifySelf: 'flex-end' }}>
                        <Button variant='contained' onClick={handleChange} disabled={loading} > {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}Submit</Button>
                    </Box>
                    : <Box sx={{ display: 'flex', justifySelf: 'flex-end' }}>
                        <Button variant='contained' onClick={() => navigate("/bulk-operations")} disabled={loading} startIcon={<ArrowBackIcon />}> {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}Go Back</Button>
                    </Box>}
            </Box>
            <Divider />
            {verifyCampaignFile?.url ? <BulkOperationFaild /> : <BulkOperationTable /> }
        </MainCard>
    )
}

export default BulkOperationResult