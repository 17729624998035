import { useAppSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { useEffect } from 'react';
import { formateValue } from 'helper/commonHelper';
import { Loader } from 'rsuite';
import { PageValue } from 'store/constant';

function CampaignReportList({ tableLoading }) {
  const { campaignReportData } = useAppSelector((state) => state?.reportDataSlice);

  const [newData, setNewData] = useState();

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });
    return formattedData;
  };

  useEffect(() => {
    const finalData = campaignReportData?.map((item) => formateData(Object.entries(item)));
    setNewData(finalData);
  }, [campaignReportData]);

  const columns = [
    {
      name: 'campaign_name',
      label: 'Campaign',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'num_ads_shown',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'num_ads_clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'units_sold',
      label: 'Units Sold',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '150px' } })
      },
      width: 200
    },
    {
      name: 'attributed_orders',
      label: 'Orders',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'cvr_units',
      label: 'Conversion Rate (Units Sold Based)',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'cvr_orders',
      label: 'Conversion Rate (Orders Sold Based)',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'ad_spend',
      label: 'Ad Spend',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'attributed_sales',
      label: 'Total Attr. Sales Revenue',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'advertised_sku_sales',
      label: 'Advertised SKU Sales',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'other_sku_sales',
      label: 'Other SKU Sales',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'ntb_orders',
      label: 'New-to-Brand Orders',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'percentage_new_brand_orders',
      label: '% of New-to-Brand Orders',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'ntb_units',
      label: 'New-to-Brand Units',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '150px' } })
      }
    },
    {
      name: 'percentage_new_brand_units',
      label: '% of New-to-Brand Units',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '180px' } })
      }
    },
    {
      name: 'ntb_revenue',
      label: 'New-to-Brand Sales',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '150px' } })
      }
    },
    {
      name: 'percentage_new_brand_sales',
      label: '% of New-to-Brand Sales',
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { minWidth: '170px' } })
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    jumpToPage: false,
    download: true,
    pagination: true,
    serverSide: true,
    selectableRows: 'none',
    rowsPerPageOptions: PageValue,
    textLabels: {
      body: {
        noMatch: tableLoading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    },
    toolbar: {
      search: 'Search',
      downloadCsv: 'Download CSV',
      print: 'Print',
      viewColumns: 'View Columns',
      filterTable: 'Filter Table'
    }
  };

  return (
    <MainCard>
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={tableLoading ? [] : newData}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />
    </MainCard>
  );
}

export default CampaignReportList;
