import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function RankTrackerIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ fill: 'none' }}>
      <path
        d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 16.5L15 15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.25 10.485L7.035 8.1675C7.29 7.8375 7.7625 7.7775 8.0925 8.0325L9.465 9.1125C9.795 9.3675 10.2675 9.3075 10.5225 8.985L12.255 6.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default RankTrackerIcon;
