import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';

const EnterKeywordTable = (props) => {
  const { setSelectedKeywordData, selectedKeywordData, data } = props;

  const [adGroupName, setAdGroupName] = useState();
  const [wordLimitExists, setWordLimitExists] = useState(false);
  // const matchType = ["exact", "phrase", "broad"];
  const [selectType, setSelectType] = useState([]);

  const matchTypeData = [
    { id: 1, name: 'exact' },
    { id: 2, name: 'phrase' },
    { id: 3, name: 'broad' }
  ];

  const [IsDropdownOpenCamSt, SetIsDropdownOpenCamSt] = useState(false);
  const [SearchText, setSearchText] = useState({ searchTextMatchType: null });

  const handleAddButton = () => {
    const values = adGroupName.trim().split('\n');
    var array = [...selectedKeywordData];
    values.map((item) => {
      selectType?.length > 0
        ? selectType?.map((type) => {
            array.push({
              keywordtext: item,
              matchtype: type?.name,
              bid_amount: '0.30',
              suggested_bid: null
            });
          })
        : matchTypeData?.map((type) => {
            array.push({
              keywordtext: item,
              matchtype: type?.name,
              bid_amount: data?.[0]?.campaign_type === 'video' ? '1.30' : '0.30',
              suggested_bid: null
            });
          });
    });
    setSelectedKeywordData(array);
    setAdGroupName('');
  };

  const handleSetAdgroupName = (e) => {
    const inputVal = e.target.value;
    const words = inputVal.trim().split('\n');
    if (words.length <= 200) {
      setAdGroupName(inputVal);
      setWordLimitExists(false);
    } else {
      setAdGroupName(inputVal);
      setWordLimitExists(true);
    }
  };

  return (
    <Box>
      <MainCard sx={{ padding: 0 }}>
        <CustomAutocomplete
          multiple
          label="Match Type"
          id="tags-outlined"
          name="adgroup_name"
          value={selectType || []}
          options={matchTypeData || []}
          onChange={(e, value) => {
            if (value.find((option) => option.all)) {
              const allIdsCam = matchTypeData?.map((obj) => obj.id);

              if (value.length - 1 === allIdsCam.length) {
                return setSelectType([]);
              } else {
                return setSelectType(matchTypeData);
              }
            }
            setSelectType(value);
          }}
          size={'small'}
          sx={{ width: '30%', mb: 2 }}
          onOpen={() => SetIsDropdownOpenCamSt(true)}
          onClose={() => SetIsDropdownOpenCamSt(false)}
          filterSelectedOptions
          color="secondary"
          optionLabelkey="name"
          onInputChange={(event, value, reason) => {
            if (reason !== 'reset') {
              setSearchText({ ...SearchText, searchTextMatchType: value });
            }
          }}
          inputValue={IsDropdownOpenCamSt ? SearchText?.searchTextMatchType : ''}
          selectAllCheckboxCondiion={!!(selectType?.length == matchTypeData.length)}
          placeholderCondition={selectType?.length > 0}
          isOptionEqualToValue={(option, value) => option?.id == value?.id}
        />

        <TextField
          name={'adgroup_name_keywords'}
          fullWidth
          type="text"
          multiline
          color="secondary"
          placeholder="Please enter keyword, one keyword per line"
          rows={4}
          value={adGroupName}
          onChange={handleSetAdgroupName}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box className={wordLimitExists ? 'red-star' : ''}>You can add up to 200 keywords.</Box>
          <Box>
            <Button variant="outlined" size="small" color="secondary" onClick={handleAddButton} disabled={wordLimitExists}>
              Add
            </Button>
          </Box>
        </Box>
      </MainCard>
    </Box>
  );
};

export default EnterKeywordTable;
