import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const BrandCampaignData = ({ media_data }) => {
  return (
    <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '10px' }}>
      <Grid item sm={12}>
        <Typography variant="h4">Video Details</Typography>
      </Grid>
      <Grid item sm={6}>
        <Grid container>
          <Grid item xs={6}>
            <Box padding={1}>
              <Typography sx={{ padding: 0.5, display: 'flex', fontWeight: 'bold' }}>Thumbnail</Typography>
              <img src={media_data?.media_thumbnail_url} alt={media_data?.media_name} width={'200px'} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box padding={1}>
              <Typography sx={{ padding: 0.5, display: 'flex', fontWeight: 'bold' }}>Review status</Typography>
              <Typography component="span" sx={{ pl: '4px' }}>
                {media_data?.media_status}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BrandCampaignData;
