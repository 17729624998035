import {
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  // Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useRef } from 'react';
import { MeasureArrayMain, dimensionsArrayMain } from 'store/constant';
import FilterBy from './filterBy';
import CustomTable from './customTable';
import { useEffect } from 'react';
import { getAllDataDimensions, getCustomReortData } from 'store/thunk/reportDataThunk';
import { useAppSelector } from 'store';
import { setCustomReportData } from 'store/slices/reportDataSlice';
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';

import { Search } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  deleteUserReport,
  // getAlldvertiserList,
  getSaveReportForCustom,
  postSaveReportForCustom,
  updateFilterForSaveReport
} from 'store/thunk/userThunk';
import ShowSplitFilterPlus from './showSplitFilterPlus';
import { useDataState } from 'context/DataContext';
import colors from 'assets/scss/_themes-vars.module.scss';
// import { useNavigate } from 'react-router';

function CustomReport() {
  const customRef = useRef(null);
  // const navigate = useNavigate();

  const dataState = useDataState();

  const [chips, setChips] = useState([
    { label: dataState.data ? dataState.data : 'Last 7 Days', count: 0, value: 'last_7_days' }
  ]);

  useEffect(() => {
    if (dataState.data) {
      setChips((prevChips) => {
        return prevChips.map((chip) => {
          if (chip.value === 'last_7_days') {
            return { ...chip, label: dataState.data };
          }
          return chip;
        });
      });
    }
  }, [dataState.data]);

  const [splitChips, setSplitChips] = useState([{ label: 'Day (Days)', value: 'date' }]);
  const [measureChips, setMeasureChips] = useState([
    {
      label: 'Impressions',
      value: 'impressions'
    },
    {
      label: 'Clicks',
      value: 'clicks'
    },
    {
      label: 'Ad Spend',
      value: 'ad_spend'
    },
    {
      label: 'CTR',
      value: 'ctr'
    }
  ]);
  const [checked, setChecked] = useState(true);

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const dispatch = useDispatch();

  const { allReportData } = useAppSelector((state) => state?.reportDataSlice);
  const { commonAdvertiser } = useAppSelector((state) => state?.userDetailsSlice);
  const { customSaveReportData } = useAppSelector((state) => state?.userDetailsSlice);
  const { loading } = useAppSelector((state) => state?.dataLoading);
  // console.log("chips ", chips);
  console.log('loading ', loading);
  //   filter_date: 7
  // split: days
  const [clickItem, setClickItem] = useState(false);
  const [selectChipData, setSelectChipData] = useState(false);
  const [selectMeasureChip, setSelectMeasureChip] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState('');
  const [searchKey, setSearchKey] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [searchKeyMeasure, setSearchKeyMeasure] = useState(false);
  const [splitPopup, setSplitPopup] = useState(false);
  const [clickItemData, setClickItemData] = useState('');
  const [selectedChip, setSelectedChip] = useState('');
  const [finalSendData, setFinalSendData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [dimensionsArray, setDimensionsArray] = useState([]);
  const [measuresArray, setMeasureArray] = useState([]);
  const [dimensionSplitArray, setDimensionSplitArray] = useState([]);
  const [dimensionFilterArray, setDimensionFilterArray] = useState([]);
  const [untilDate, setUntilDate] = useState('');
  const defaultStartDate = moment().subtract(7, 'days').startOf('day').toDate();
  const defaultEndDate = moment().endOf('day').toDate();
  const [anchorElLast7, setAnchorElLast7] = useState(null);
  const [checkedColorTable, setcheckedColorTable] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  // const [customReportName,setCustomReportName]=useState([])

  useEffect(() => {
    setLast7DaysData(false);
    getDataForReport();
  }, []);

  const getDataForReport = () => {
    dispatch(getSaveReportForCustom());
    // setCustomReportName(customSaveReportData)
  };

  useEffect(() => {
    setDimensionsArray(dimensionsArrayMain);
    setDimensionSplitArray(dimensionsArrayMain);
    setDimensionFilterArray(dimensionsArrayMain);
  }, [dimensionsArrayMain]);

  useEffect(() => {
    setMeasureArray(MeasureArrayMain);
  }, [MeasureArrayMain]);

  // useEffect(() => {
  //   let data = '';
  //   dispatch(getAlldvertiserList({ data }));
  // }, []);

  const [selectedNewDateStart, setSelectedNewDateStart] = useState();
  const [selectedNewDateEnd, setSelectedNewDateEnd] = useState();

  useEffect(() => {
    if (splitChips?.length > 0) {
      setUntilDate(endDate);
      const sendData = {
        // days: 30,
        // split: splitChips.map((item) => item.value).join(',')
        report_type: checkedColorTable ? 'collapse' : 'split',
        startData:
          selectedNewDateStart && selectedNewDateStart.length > 0 ? selectedNewDateStart : moment(startDate).format('YYYY-MM-DD'),
        endData: selectedNewDateEnd && selectedNewDateEnd.length > 0 ? selectedNewDateEnd : moment(endDate).format('YYYY-MM-DD'),
        split: splitChips.map((item) => item.value),
        filter_by: finalSendData
      };
      if (commonAdvertiser && commonAdvertiser?.length > 0) {
        sendData.advertiser_data = commonAdvertiser;
      }
      // console.log("sendData ", sendData);
      // if (splitChips?.length > 1) {
      //   sendData.sendKey =  "split";
      // }
      dispatch(getCustomReortData({ mainData: sendData }));
    } else {
      dispatch(setCustomReportData([]));
    }
  }, [
    splitChips,
    finalSendData,
    startDate,
    endDate,
    commonAdvertiser,
    checkedColorTable,
    selectedNewDateStart,
    selectedNewDateEnd
  ]);

  const [mainJson, setMainJson] = useState({
    filterBy: [],
    measureBy: [],
    split: []
  });

  useEffect(() => {
    let combinedFilterBy = chips.map((item) => {
      if (finalSendData[item.value]) {
        return {
          ...item,
          [item.value]: finalSendData[item.value]
        };
      } else {
        return item;
      }
    });

    setMainJson({
      filterBy: combinedFilterBy,
      measureBy: [...measureChips],
      split: [...splitChips],
      StartDateSaveReport: moment(startDate).format('YYYY-MM-DD'),
      EndDateSaveReport: moment(endDate).format('YYYY-MM-DD')
    });

    // console.log('mainJson ', mainJson);
  }, [measureChips, splitChips, chips, finalSendData, endDate, startDate]);

  const handleCancel = (deleteChip) => {
    if (finalSendData?.[deleteChip?.value]?.length > 0) {
      setSelectChipData(false);
      setFinalSendData({ ...finalSendData, [deleteChip?.value]: finalSendData?.[deleteChip?.value] });
    } else {
      const tempData = chips?.filter((item) => item?.label !== deleteChip?.label);
      setChips(tempData);
      const updatedFinalSendData = { ...finalSendData };
      delete updatedFinalSendData[deleteChip?.value];
      // setTempData([]);
      // setFinalSendData({ ...finalSendData, [deleteChip?.value]: [] });
      setFinalSendData(updatedFinalSendData);
      setChips(tempData);
      setSelectChipData(false);
    }
    setTempData([]);
    setTempData({ ...finalSendData, [deleteChip?.value]: [] });
  };

  const handleDelete = (deleteChipData, type) => {
    // console.log('deleteChipData, type ', deleteChipData, type);
    if (type == 'filter') {
      const tempData = chips?.filter((item) => item?.value !== deleteChipData?.value);
      setChips(tempData);
      // console.log('finalSendData ', finalSendData);
      const updatedFinalSendData = { ...finalSendData };
      delete updatedFinalSendData[deleteChipData?.value];

      // console.log('updatedFinalSendData ', updatedFinalSendData);
      // setFinalSendData({ ...finalSendData, [deleteChipData?.value]: [] });
      setFinalSendData(updatedFinalSendData);
      setTempData({ ...tempData, [deleteChipData?.value]: [] });
      setSelectChipData(false);
    } else if (type == 'measure') {
      const tempData = measureChips?.filter((item) => item?.value !== deleteChipData?.value);
      setMeasureChips(tempData);
      // setSelectedChip("");
    } else {
      const tempData = splitChips?.filter((item) => item?.value !== deleteChipData?.value);
      setSplitChips(tempData);
      setSelectedChip('');
    }
  };

  const onClickFilter = (exactVal) => {
    setSelectedChip(clickItemData);
    setSelectChipData(true);
    setTempData([]);

    const lastChipIndex = chips?.length - 1;
    if (selectChipData) {
      const updatedChips = [...chips];
      updatedChips?.splice(lastChipIndex, 1);
      updatedChips.push({ label: clickItemData, count: 0, value: exactVal });
      setChips(updatedChips);
    } else {
      const sendData = {
        fetchId: exactVal,
        start_date: startDate,
        end_date: endDate
      };
      dispatch(getAllDataDimensions(sendData));
      if (!chips.some((chip) => chip.value === exactVal)) {
        setChips((prevChips) => [...prevChips, { label: clickItemData, count: 0, value: exactVal }]);
      }
      setTempData(finalSendData[exactVal]);
    }
  };

  const onClickSplit = (exactVal) => {
    if (splitChips?.length < 3) {
      setSplitChips((prevChips) => [...prevChips, { label: clickItemData, value: exactVal }]);
      setSelectedChip(clickItemData);
    }
  };

  const showItemsPopup = (itemData) => {
    return (
      <Box className="item-dropdown">
        <Button className="filter action" onClick={() => onClickFilter(itemData?.value)}>
          <FilterAltIcon className="icon" />
          Filter
        </Button>
        <Button
          className="splite action"
          onClick={() => onClickSplit(itemData?.value)}
          disabled={splitChips?.some((item) => item?.value === itemData?.value)}
        >
          <AddIcon className="icon" />
          Split
        </Button>
      </Box>
    );
  };

  const onDimensionClick = (value, label) => {
    const lastChipIndexData = chips[chips?.length - 1];
    if (lastChipIndexData?.count < 0) {
      setSelectChipData(false);
    }
    const mainData = label;
    setClickItem(!clickItem);
    setClickItemData(mainData);
  };

  const [last7DaysData, setLast7DaysData] = useState(false);
  // const handleMenuOpenLast7Days = () => {
  //   console.log("inn 7 days");
  //   setLast7DaysData(true);
  // };

  // const handleMenuCloseFilter = () => {
  //   setFilterOpenMenu(null);
  // };

  const handleChipClick = (event, data) => {
    if (data?.value !== 'last_7_days') {
      if (!selectChipData) {
        const sendData = {
          fetchId: data?.value,
          start_date: startDate,
          end_date: endDate
        };
        dispatch(getAllDataDimensions(sendData));
      }
      setSelectChipData(!selectChipData);
      setTempData(finalSendData[data?.value]);
      setSelectedChip(data?.label);
      // setLast7DaysData(false);
    } else {
      setLast7DaysData(!last7DaysData);
      setAnchorElLast7(event.currentTarget);
    }
  };

  const dragStop = (obj) => {
    if (
      !chips.some((chip) => chip?.value === obj?.source?.droppableId) &&
      obj?.destination?.droppableId === 'filterBy' &&
      !measuresArray.some((item) => item.label === obj?.draggableId)
    ) {
      const sendData = {
        fetchId: obj?.source?.droppableId,
        start_date: startDate,
        end_date: endDate
      };
      dispatch(getAllDataDimensions(sendData));
      setSelectedChip(obj?.draggableId);
      setSelectChipData(true);
      setChips((prevChips) => [
        ...prevChips,
        {
          label: obj?.draggableId,
          count: 0,
          value: obj?.source?.droppableId
        }
      ]);
    } else if (
      obj?.destination?.droppableId === 'splitBy' &&
      obj?.draggableId &&
      !measuresArray.some((item) => item.label === obj?.draggableId)
    ) {
      if (!splitChips?.includes(obj?.draggableId) && splitChips?.length < 3) {
        setSplitChips((prevChips) => [
          ...prevChips,
          {
            label: obj?.draggableId,
            value: obj?.source?.droppableId
          }
        ]);
      }
    } else if (
      obj?.destination?.droppableId === 'measureBy' &&
      obj?.draggableId &&
      measuresArray.some((item) => item.label === obj?.draggableId)
    ) {
      console.log('objforMeasure', obj?.draggableId, measuresArray);
      let exists = measureChips.some((item) => item.value === obj.source.droppableId);
      if (!exists) {
        setMeasureChips((prevChips) => [
          ...prevChips,
          {
            label: obj?.draggableId,
            value: obj?.source?.droppableId
          }
        ]);
      }
    }
  };

  const dragStart = (obj) => {
    console.log('in drag start', obj);
    setTempData([]);
    const lastChipIndex = chips?.length - 1;
    if (selectChipData) {
      const updatedChips = [...chips];
      updatedChips?.splice(lastChipIndex, 1);
      setChips(updatedChips);
    }
    setSelectChipData(false);
    setSelectedChip('');
    setClickItem(false);
    setClickItemData('');
  };

  const handleClosePopups = (event) => {
    if (customRef.current && !customRef.current.contains(event.target)) {
      // setClickItem(false);
    } else {
      // Click is inside the MainCard component
      // You might want to handle other specific cases here if needed
    }
  };

  // While check box click on any check box from drop down
  const onCheckboxClick = (event, clickValue) => {
    const checkedVal = event.target.checked;
    // console.log("clickValue ", clickValue);
    // console.log("checkedVal ", checkedVal);
    if (checkedVal) {
      setTempData(tempData ? [...tempData, clickValue] : [clickValue]);
    } else {
      setTempData(tempData?.filter((item) => item != clickValue));
    }
  };

  const handleSelect = (selectData, type) => {
    if (type == 'filter') {
      setSelectChipData(false);
      setFinalSendData((prevData) => {
        return { ...prevData, [selectData?.value]: tempData };
      });
      // const finalData = { ...finalSendData, [selectData?.value]: tempData };
      // console.log("finalData ", finalData);
      // const sendData = {
      //   filter_by: finalSendData
      // }
      // dispatch(getCustomReortData({ mainData: sendData }));
      setTempData([]);
    }
  };

  const dargEndSplit = (result) => {
    if (!result?.destination) {
      return;
    }
    const sourceIndex = result?.source?.index;
    const destIndex = result?.destination?.index;
    const updateSplitChips = [...splitChips];
    const [removedChip] = updateSplitChips.splice(sourceIndex, 1);
    updateSplitChips.splice(destIndex, 0, removedChip);
    setSplitChips(updateSplitChips);
  };

  const onTextChange = (e) => {
    const text = e.target.value;
    if (text) {
      const filterData = dimensionsArray?.filter((obj) => obj?.label?.includes(text));
      setDimensionsArray(filterData);
    } else {
      setDimensionsArray(dimensionsArrayMain);
    }
  };

  const onTextChangeMeasure = (e) => {
    const text = e.target.value;
    if (text) {
      const filterData = measuresArray?.filter((obj) => obj?.label?.includes(text));
      setMeasureArray(filterData);
    } else {
      setMeasureArray(MeasureArrayMain);
    }
  };

  const [isModified, setIsModified] = useState(false);

  const handleButtonClick = () => {
    // Toggle between initial and modified column widths
    setIsModified((prevIsModified) => !prevIsModified);
    setIsSearchOpen(false);
  };

  const [countResize, setCountResize] = useState(0);
  let columnWidths = isModified ? [5.5 + countResize, 7.5 + countResize / 2] : [7.5 + countResize, 9.5 + countResize / 2];
  const columnWidths1 = isModified ? [2.5, 2.5] : [0.5, 0.5];
  const columnDragWidth = countResize !== 0 ? [1, 0.5] : [4, 2];
  useEffect(() => {
    columnWidths = isModified ? [5.5 + countResize, 7.5 + countResize / 2] : [7.5 + countResize, 9.5 + countResize / 2];
  }, [countResize]);

  // const savedReports = ['Report 1', 'Report 2', 'Report 3'];
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
    isSearchOpen;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedExistingOption, setSelectedExistingOption] = useState('');
  const [selectedExistingOptionName, setSelectedExistingOptionName] = useState('');
  const [createNewOption, setCreateNewOption] = useState('Save with Filter');
  const [createNewRadioOption, setCreateNewRadioOption] = useState('Save with Filter');
  // const [DropdownColumn,setDropdownColumn]=useState(false)
  const [updatedDateReport, setUpdatedDateForReport] = useState();
  useEffect(() => {
    if (customSaveReportData && typeof selectedExistingOption === 'number') {
      let result = customSaveReportData?.find((item) => item.id == selectedExistingOption);
      const formattedDate = moment(result?.updated_at).format('MM/DD/YYYY h:mma [PST]');
      setUpdatedDateForReport(formattedDate);
    }
  }, [selectedExistingOption, anchorEl1, customSaveReportData]);

  const handleSaveReport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleExistingOptionChange = (event) => {
    setSelectedExistingOptionName(event.target.value);
  };
  const getSelectedId = (id) => {
    setSelectedExistingOption(id);
  };

  const handleCreateNewOptionChange = (event) => {
    setCreateNewOption(event.target.value);
  };

  const handleCreateNewRadioChange = (event) => {
    setCreateNewRadioOption(event.target.value);
  };

  const [createInputValue, setCreateInputValue] = useState();
  const handleCreateInputValue = (event) => {
    setCreateInputValue(event.target.value);
  };

  // State for the input field
  // const [inputName, setInputName] = useState('');
  // State to manage the selected JSON key
  // const [selectedJsonKey, setSelectedJsonKey] = useState('');

  const handleSave = () => {
    if (selectedTab === 0) {
      if (createNewRadioOption == 'Save with Filter') {
        const payload = { filters: [{ name: selectedExistingOptionName, data: mainJson }] };
        const payload11 = {
          _request: payload,
          id: selectedExistingOption
        }; // console.log("payload11",payload,selectedExistingOptionName,selectedExistingOption)
        dispatch(updateFilterForSaveReport(payload11)).then(() => {
          handleMenuClose();
          getDataForReport();
        });
      } else {
        const { filterBy, ...filteredMainJson } = mainJson;
        console.log('filterBy ', filterBy);

        const payload = { filters: [{ name: selectedExistingOptionName, data: filteredMainJson }] };
        const payload11 = {
          _request: payload,
          id: selectedExistingOption
        }; // console.log("payload11",payload,selectedExistingOptionName,selectedExistingOption)
        dispatch(updateFilterForSaveReport(payload11)).then(() => {
          handleMenuClose();
          getDataForReport();
        });
      }
    } else if (selectedTab === 1) {
      if (createInputValue && createNewOption == 'Save with Filter') {
        console.log(createNewOption, 'create');
        const payload = { filters: [{ name: createInputValue, data: mainJson }] };
        dispatch(postSaveReportForCustom(payload)).then(() => {
          handleMenuClose();
          getDataForReport();
        });
      } else {
        if (createInputValue) {
          const { filterBy, ...filteredMainJson } = mainJson;
          console.log('filterBy ', filterBy);

          const payload = { filters: [{ name: createInputValue, data: filteredMainJson }] };
          dispatch(postSaveReportForCustom(payload)).then(() => {
            handleMenuClose();
            getDataForReport();
          });
        }
      }
    }
  };

  // const displaySavedJson = () => {
  //   const selectedJson = savedJsons.find(json => json.name === selectedJsonKey);

  //   if (selectedJson) {
  //     setMainJson(selectedJson.data);
  //   }
  // };

  const handleCancel1 = () => {
    handleMenuClose();
  };

  // const [selectedValues, setSelectedValues] = useState({});
  // const [finalData,setFinaData]=useState()

  //  useEffect(()=>{
  //   // setMeasureChips()
  //   let result = Object.keys(selectedValues).map(key => ({ label: key }));
  //   console.log(result,measureChips,"measure,Result")
  //   setFinaData()
  //  },[selectedValues,measureChips])

  // const [selectedItems, setSelectedItems] = useState([]);
  // useEffect(() => {
  //   console.log('selectedItems', measureChips);
  // }, [measureChips]);

  const handleCheckboxChange = (item) => (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected item to the array
      setMeasureChips((prevSelectedItems) => [...prevSelectedItems, { label: item.label, value: item.value }]);
    } else {
      // Remove the selected item from the array
      setMeasureChips((prevSelectedItems) => prevSelectedItems.filter((selectedItem) => selectedItem.label !== item.label));
    }
  };

  const [FilterOpenMuenu, setFilterOpenMenu] = useState(null);
  const handleMenuOpenFilter = (event) => {
    setFilterOpenMenu(event.currentTarget);
  };

  const handleMenuCloseFilter = () => {
    setFilterOpenMenu(null);
  };

  const [splitOpenMuenu, setSplitOpenMenu] = useState(null);
  const handleMenuOpenSplit = (event) => {
    setSplitOpenMenu(event.currentTarget);
  };

  const handleMenuClose1Split = () => {
    setSplitOpenMenu(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };

  const [confirmationModalAnchor, setConfirmationModalAnchor] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleOpenModal = (event) => {
    setConfirmationModalAnchor(event.currentTarget);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseModal = () => {
    setConfirmationModalAnchor(null);

    setIsConfirmationModalOpen(false);
  };

  const handleConfirmAction = () => {
    setMeasureChips([]);
    // Add your confirmation logic here
    handleCloseModal();
  };

  const onAddSplitData = (data, type) => {
    if (type === 'split' && splitChips?.length < 3) {
      setSplitOpenMenu(null);
      setSplitChips((prevChips) => [
        ...prevChips,
        {
          label: data?.label,
          value: data?.value
        }
      ]);
    } else if (type === 'filter') {
      setTempData([]);
      const lastChipIndex = chips?.length - 1;
      if (lastChipIndex !== -1 && selectChipData) {
        const updatedChips = [...chips];
        updatedChips?.splice(lastChipIndex, 1);
        setChips(updatedChips);
        setSelectChipData(false);
        setSelectedChip('');
        setClickItem(false);
        setClickItemData('');
      } else {
        const sendData = {
          fetchId: data?.value,
          start_date: startDate,
          end_date: endDate
        };
        dispatch(getAllDataDimensions(sendData));
        setFilterOpenMenu(null);
        setSelectedChip(data?.label);
        setSelectChipData(true);
        setChips((prevChips) => [
          ...prevChips,
          {
            label: data?.label,
            count: 0,
            value: data?.value
          }
        ]);
      }
    }
  };

  const onClickMeasureAdd = (mainData) => {
    console.log('mainData ', mainData);
    let exists = measureChips.some((item) => item.value === mainData?.value);
    if (!exists) {
      setMeasureChips((prevChips) => [
        ...prevChips,
        {
          label: mainData?.label,
          value: mainData?.value
        }
      ]);
    }
  };

  const onMeasureBy = (data) => {
    setSelectMeasureChip(!selectMeasureChip);
    setSelectedMeasure(data?.label);
  };
  // const dataDispatch = useDataDispatch();
  const handleApplyFilterData = (report) => {
    console.log(report?.data?.filterBy, 'reportss');

    if (report?.data?.filterBy == undefined) {
      setChips([{ count: 0, label: 'Last 7 Days', value: 'last_7_days' }]);
    } else {
      let result = {};
      report?.data.filterBy.forEach((item) => {
        if (item.value !== 'last_7_days') {
          if (item.value in result) {
            result[item.value].push(item[item.value]);
          } else {
            result[item.value] = [...item[item.value]];
          }
        }
      });
      console.log(result, 'results');
      setFinalSendData(result);
      // const latestYearIndex = report?.data?.filterBy.findIndex((item) => item.value === 'last_7_days');
      // const label = report?.data?.filterBy[latestYearIndex];
      // dataDispatch({ type: 'UPDATE_DATA', payload: label?.label });
      setChips(report?.data?.filterBy);
    }
    setMeasureChips(report?.data?.measureBy);
    setSplitChips(report?.data?.split);
    setSelectedNewDateStart(report?.data?.StartDateSaveReport);
    setSelectedNewDateEnd(report?.data?.EndDateSaveReport);
  };

  const showMeasurePopup = (itemData) => {
    return (
      <Box className="item-dropdown item-dropdown-measure">
        <Button className="filter action" onClick={() => onClickMeasureAdd(itemData)}>
          <FilterAltIcon className="icon" />
          Add
        </Button>
      </Box>
    );
  };

  // const handleDeleteReport=(id)=>{
  //  dispatch(deleteUserReport({id})).then(()=>{
  //   getDataForReport()
  //  })
  // }

  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const handleOpenDeleteConfirmation = (id) => {
    setReportToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setReportToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteReport = () => {
    if (reportToDelete) {
      dispatch(deleteUserReport({ id: reportToDelete })).then(() => {
        getDataForReport();
      });
      console.log(`Deleting report with ID: ${reportToDelete}`);
      handleCloseDeleteConfirmation();
    }
  };

  useEffect(() => {
    !isSearchOpen ? setSearchValueReport() : '';
  }, [isSearchOpen]);

  const [SerchedvalueReport, setSearchValueReport] = useState();
  const handaleSearchInputForSaveReport = (event) => {
    setSearchValueReport(event.target.value);
  };

  const filteredReportsWithSearch = customSaveReportData.filter((report) =>
    report?.name?.toLowerCase()?.includes(SerchedvalueReport?.toLowerCase())
  );

  const reportsToDisplay = SerchedvalueReport ? filteredReportsWithSearch : customSaveReportData;

  const onClickDemandReport = () => {
    window.open('/on-demand-reports', '_blank');
  };

  const handleChangeColorTable = () => {
    setcheckedColorTable(!checkedColorTable);
  };

  return (
    <>
      <DragDropContext onDragEnd={dragStop} onDragStart={dragStart}>
        <MainCard title="Custom Reports">
          <Grid container spacing={2}>
            <Box style={{ width: '-webkit-fill-available' }} mb={3} mt={2}>
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '22px',
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)', // Apply shadow to all corners
                  borderRadius: '4px' // Adjust the radius as needed
                }}
              >
                <div style={{ marginRight: '8px' }}>
                  <Typography variant="h4">Untitled Report</Typography>
                  <Typography variant="body2">This report is available until {moment(untilDate).format('DD/MM/YYY')}</Typography>
                </div>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    gap: '8px',
                    '@media(max-width:1024px)': {
                      display: 'block',
                      gap: '2px'
                    } // Adjust the gap between buttons
                  }}
                >
                  <Button
                    // variant="outlined"
                    fullWidth
                    sx={{
                      whiteSpace: 'nowrap',
                      border: 'white',
                      marginRight: '8px',
                      padding: '8px 33px',
                      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      '@media(max-width:1024px)': {
                        padding: '8px 1px'
                      }
                    }} // Set a minimum height for the buttons
                  >
                    <Typography color={'black'}>Advertiser Reports</Typography>
                  </Button>
                  <Button
                    onClick={onClickDemandReport}
                    // variant="outlined"
                    fullWidth
                    sx={{
                      whiteSpace: 'nowrap',
                      border: 'white',
                      marginRight: '8px',
                      padding: '8px 33px',
                      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      '@media(max-width:1024px)': {
                        padding: '8px 1px'
                      }
                    }}
                  >
                    <Typography color={'black'}> On Demand Reports</Typography>
                  </Button>
                  <Button
                    // variant="outlined"
                    fullWidth
                    sx={{
                      whiteSpace: 'nowrap',
                      border: 'white',
                      marginRight: '8px',
                      padding: '8px 33px',
                      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      '@media(max-width:1024px)': {
                        padding: '8px 1px'
                      }
                    }} // Set a minimum height for the buttons
                    onClick={handleSaveReport}
                  >
                    <Typography color={'black'}>Save Report </Typography>
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      elevation: 5,
                      style: {
                        borderRadius: '4px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                      }
                    }}
                  >
                    <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" start>
                      <Tab label="Select Existing" />
                      <Tab label="Create New" />
                    </Tabs>
                    <Box style={{ padding: '16px' }}>
                      {(selectedTab === 0 || selectedTab === 1) && (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {selectedTab === 0 && (
                              <>
                                <FormControl fullWidth>
                                  <Select
                                    value={selectedExistingOptionName}
                                    onChange={handleExistingOptionChange}
                                    displayEmpty
                                    size="small"
                                    inputProps={{ 'aria-label': 'Select Existing' }}
                                  >
                                    <MenuItem value="" disabled>
                                      Select Option
                                    </MenuItem>
                                    {customSaveReportData &&
                                      customSaveReportData.map((report, index) => (
                                        <MenuItem onClick={() => getSelectedId(report.id)} key={index} value={report.name}>
                                          {report.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body1" m={1}>
                                      {customSaveReportData.length}/20 Saved Report{' '}
                                    </Typography>
                                    <Typography m={1}>{updatedDateReport ? `Last saved on ${updatedDateReport}` : ''}</Typography>
                                  </Box>
                                </FormControl>
                                <RadioGroup
                                  value={createNewRadioOption}
                                  onChange={handleCreateNewRadioChange}
                                  style={{ display: 'flex', flexDirection: 'row' }}
                                  m={1}
                                >
                                  <FormControlLabel value="Save with Filter" control={<Radio />} label="Save with filter" />
                                  <FormControlLabel value="Save Without Filter" control={<Radio />} label="Save without filter" />
                                </RadioGroup>
                              </>
                            )}
                            {selectedTab === 1 && (
                              <>
                                <TextField
                                  label="Enter report name"
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  value={createInputValue}
                                  onChange={handleCreateInputValue}
                                />
                                <Typography variant="body1" m={1}>
                                  {customSaveReportData.length}/20 Saved Report
                                </Typography>
                                <RadioGroup
                                  value={createNewOption}
                                  onChange={handleCreateNewOptionChange}
                                  style={{ display: 'flex', flexDirection: 'row' }}
                                  m={1}
                                >
                                  <FormControlLabel value="Save with Filter" control={<Radio />} label="Save with filter" />
                                  <FormControlLabel value="Save Without Filter" control={<Radio />} label="Save without filter" />
                                </RadioGroup>
                              </>
                            )}
                            <div style={{ marginTop: '16px', textAlign: 'end' }}>
                              <Button
                                variant="contained"
                                onClick={handleSave}
                                color="primary"
                                style={{ marginTop: '8px', marginRight: '8px' }}
                              >
                                Save
                              </Button>
                              <Button variant="outlined" onClick={handleCancel1} color="secondary" style={{ marginTop: '8px' }}>
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Box>
                  </Menu>
                </Box>
              </Paper>
            </Box>

            <Grid
              item
              xs={columnDragWidth[0]}
              md={columnDragWidth[1]}
              ref={customRef}
              sx={{
                padding: '0px !important',
                border: countResize > 0 ? 'none' : 'none'
              }}
            >
              <Box>
                {countResize > 0 && (
                  <Typography
                    onClick={() => {
                      setCountResize(0);
                    }}
                    sx={{
                      background: colors?.darkSecondary200,
                      padding: '10px 0px',
                      borderRadius: '8px !important',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      src={require('../../assets/images/icons/left-side-arrow.png')}
                      alt="ddd"
                      style={{ display: 'flex', padding: '4px 10px !important', width: '-webkit-fill-available' }}
                    />
                  </Typography>
                )}
                {countResize == 0 && (
                  <Box boxShadow={2}>
                    <Box sx={{ padding: '10px' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                          variant="h6"
                          // sx={{ fontSize: "16px", fontWeight: "600", color: "#333" }}
                          style={{ fontSize: '14px', fontWeight: 700 }}
                        >
                          Dimensions
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Box onClick={() => setSearchKey(!searchKey)}>
                            <SearchOutlinedIcon sx={{ marginTop: '2px', mx: 1, color: colors?.secondaryMain }} />
                          </Box>

                          <Box
                            style={{ fontSize: '30px', fontWeight: 700, cursor: 'pointer', color: colors?.secondaryMain }}
                            onClick={() => setCountResize(3)}
                          >
                            -
                          </Box>
                        </Box>
                      </Box>
                      {searchKey && (
                        <TextField variant="outlined" name="searchText" onChange={onTextChange} className="search-textfiled" />
                      )}
                    </Box>
                    <Divider />
                    <ClickAwayListener onClickAway={handleClosePopups}>
                      <Box
                        sx={{
                          overflow: 'auto',
                          height: '150px',

                          // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                          '&::-webkit-scrollbar': {
                            width: '0.1em',
                            backgroundColor: colors?.grey400
                          },
                          '&::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: colors?.grey700,
                            outline: '1px solid slategrey'
                          }
                        }}
                      >
                        {dimensionsArray &&
                          dimensionsArray?.map((obj, index) => (
                            <>
                              <Droppable droppableId={obj?.value}>
                                {(provided) => (
                                  <Box ref={provided.innerRef} {...provided?.droppableProps}>
                                    {dimensionsArray?.length > 0 && (
                                      <>
                                        <Draggable key={obj?.label} draggableId={obj?.label} index={index} direction="horizontal">
                                          {(provided) => (
                                            <Box
                                              {...provided?.draggableProps}
                                              {...provided?.dragHandleProps}
                                              ref={provided?.innerRef}
                                              onClick={() => {
                                                onDimensionClick(obj?.value, obj?.label);
                                              }}
                                              className="item-dropdown-wrapper"
                                            >
                                              {clickItem && clickItemData === obj?.label && showItemsPopup(obj)}
                                              <Typography
                                                key={index}
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  margin: '10px 0px'
                                                }}
                                              >
                                                <DragIndicatorIcon /> {obj?.label}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Draggable>
                                      </>
                                    )}
                                  </Box>
                                )}
                              </Droppable>
                            </>
                          ))}
                      </Box>
                    </ClickAwayListener>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={columnWidths[0]}
              md={columnWidths[1]}
              sx={{ paddingTop: '0px !important', paddingLeft: '5px ! important' }}
            >
              <ClickAwayListener onClickAway={handleClosePopups}>
                <ul
                  style={{
                    padding: '0',
                    border: `1px solid ${colors?.grey200}`,
                    borderBottom: '0px',
                    borderRadius: ' 4px',
                    boxShadow: ' 0.5px 1px 1px 1px rgba(0,0,0,.05)'
                  }}
                >
                  <Droppable droppableId="filterBy">
                    {(provided) => (
                      <li
                        {...provided?.droppableProps}
                        ref={provided?.innerRef}
                        style={{
                          listStyle: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          border: `1px solid ${colors?.grey200}`
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <Typography
                            variant="h5"
                            sx={{
                              borderRight: `1px solid ${colors?.grey200}`,
                              fontSize: '16px',
                              padding: '20px',
                              width: '125px'
                            }}
                          >
                            Filter By
                          </Typography>
                          <FilterBy
                            chips={chips}
                            finalSendData={finalSendData}
                            selectChipData={selectChipData}
                            onCheckboxClick={onCheckboxClick}
                            handleSelect={handleSelect}
                            handleChipClick={handleChipClick}
                            handleCancel={handleCancel}
                            selectedChip={selectedChip}
                            tempData={tempData}
                            handleDelete={handleDelete}
                            last7DaysData={last7DaysData}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            startDate={startDate}
                            endDate={endDate}
                            setLast7DaysData={setLast7DaysData}
                            anchorElLast7={anchorElLast7}
                          />
                        </Box>

                        <Box
                          sx={{ display: 'flex', alignItems: 'center', fontSize: '26px', cursor: 'pointer' }}
                          onClick={handleMenuOpenFilter}
                          mr={2}
                        >
                          +
                        </Box>
                        <ShowSplitFilterPlus
                          anchorEl={anchorEl}
                          mainOpenMuenu={FilterOpenMuenu}
                          handleMenuClose1Split={handleMenuCloseFilter}
                          mainArray={dimensionFilterArray}
                          mainChips={chips}
                          onAddSplitData={onAddSplitData}
                          type="filter"
                          mainClose={handleMenuCloseFilter}
                        />
                      </li>
                    )}
                  </Droppable>

                  <Droppable droppableId="splitBy">
                    {(provided) => (
                      <li
                        {...provided?.droppableProps}
                        ref={provided?.innerRef}
                        style={{
                          listStyle: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          border: `1px solid ${colors?.grey200}`
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <Typography
                            variant="h5"
                            sx={{
                              borderRight: `1px solid ${colors?.grey200}`,
                              fontSize: '16px',
                              padding: '20px',
                              width: '125px'
                            }}
                          >
                            Split
                          </Typography>
                          <DragDropContext onDragEnd={dargEndSplit}>
                            <Droppable droppableId="splitChips" direction="horizontal">
                              {(provided) => (
                                <Box
                                  ref={provided?.innerRef}
                                  {...provided?.droppableProps}
                                  sx={{
                                    padding: '10px 20px',
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                  }}
                                >
                                  {splitChips &&
                                    splitChips?.length > 0 &&
                                    splitChips?.map((chip, index) => (
                                      <Draggable
                                        key={index}
                                        sx={{ dispaly: 'flex', flexWrap: 'wrap' }}
                                        draggableId={`chip-${index}`}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided?.innerRef}
                                            {...provided?.draggableProps}
                                            {...provided?.dragHandleProps}
                                          >
                                            <Chip
                                              key={index}
                                              label={chip?.label}
                                              variant="outlined"
                                              onClick={() => setSplitPopup(!splitPopup)}
                                              onDelete={() => handleDelete(chip)}
                                              sx={{
                                                marginRight: '15px',
                                                background: colors?.secondaryMain,
                                                color: 'white',
                                                '&:hover': {
                                                  color: 'black'
                                                }
                                              }}
                                            />
                                            {splitPopup && selectedChip === chip?.label && (
                                              <Box className="chip-dropdown">Hello</Box>
                                            )}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                </Box>
                              )}
                            </Droppable>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center', fontSize: '26px', cursor: 'pointer' }}
                              onClick={handleMenuOpenSplit}
                              mr={2}
                            >
                              +
                            </Box>
                            <ShowSplitFilterPlus
                              anchorEl={anchorEl}
                              mainOpenMuenu={splitOpenMuenu}
                              handleMenuClose1Split={handleMenuClose1Split}
                              mainArray={dimensionSplitArray}
                              mainChips={splitChips}
                              onAddSplitData={onAddSplitData}
                              type="split"
                              mainClose={handleMenuClose1Split}
                            />
                          </DragDropContext>
                        </Box>
                      </li>
                    )}
                  </Droppable>

                  <Droppable droppableId="measureBy">
                    {(provided) => (
                      <li
                        {...provided?.droppableProps}
                        ref={provided?.innerRef}
                        style={{
                          listStyle: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          border: `1px solid ${colors?.grey200}`
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <Typography
                            variant="h5"
                            sx={{
                              borderRight: `1px solid ${colors?.grey200}`,
                              fontSize: '16px',
                              padding: '20px',
                              width: '125px'
                            }}
                          >
                            Measure
                          </Typography>
                          <Box
                            sx={{
                              padding: '10px 20px',
                              width: '100%',
                              display: 'flex',
                              flexWrap: 'wrap'
                            }}
                          >
                            {measureChips &&
                              measureChips?.length > 0 &&
                              measureChips?.map((chip, index) => (
                                <>
                                  <Box>
                                    <Chip
                                      key={index}
                                      label={chip?.label}
                                      variant="outlined"
                                      onDelete={() => handleDelete(chip, 'measure')}
                                      sx={{
                                        marginRight: '15px',
                                        background: colors?.grey600,
                                        color: 'white',
                                        '&:hover': {
                                          color: 'black',
                                          background: 'transparent'
                                        }
                                      }}
                                    />
                                  </Box>
                                </>
                              ))}
                          </Box>
                          <Box
                            sx={{
                              fontSize: '12px',
                              display: 'flex',
                              whiteSpace: 'nowrap',
                              color: 'red',
                              px: 2,
                              cursor: 'pointer'
                            }}
                            onClick={handleOpenModal}
                          >
                            Clear All
                          </Box>
                          <Popover
                            open={isConfirmationModalOpen}
                            anchorEl={confirmationModalAnchor}
                            onClose={handleCloseModal}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}
                            sx={{ marginTop: '20px' }}
                            PaperProps={{ sx: { borderRadius: '2px' } }}
                            arrow
                          >
                            <Box
                              sx={{ padding: '10px', width: '200px', maxHeight: '200px', border: `1px solid ${colors?.grey400}` }}
                            >
                              <Typography sx={{ fontSize: '10px' }}>Are you sure you want to Celar All Measure?</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }} pt={1}>
                                <Button onClick={handleCloseModal} variant="outlined" size="small">
                                  Cancel
                                </Button>
                                <Button
                                  onClick={handleConfirmAction}
                                  sx={{ ml: 1 }}
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                >
                                  Clear
                                </Button>
                              </Box>
                            </Box>
                          </Popover>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center', fontSize: '26px', cursor: 'pointer' }}
                            onClick={handleMenuOpen}
                            mr={2}
                          >
                            +
                          </Box>
                          <Menu
                            anchorEl={anchorEl1}
                            open={Boolean(anchorEl1)}
                            onClose={handleMenuClose1}
                            PaperProps={{
                              elevation: 5,
                              style: { borderRadius: '4px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }
                            }}
                          >
                            <Box
                              sx={{
                                minWidth: '150px',
                                padding: '10px',
                                maxHeight: '200px', // Set max height for the container
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                  width: '0.2em',
                                  backgroundColor: colors?.grey100
                                },
                                '&::-webkit-scrollbar-thumb': { backgroundColor: colors?.grey600 }
                              }}
                            >
                              <Typography variant="subtitle1">Select Measures</Typography>
                              {measuresArray.map((item) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    background: measureChips.some((selectedItem) => selectedItem.label === item.label)
                                      ? colors?.secondaryLight
                                      : '',
                                    mb: 1
                                  }}
                                  key={item.index}
                                >
                                  <FormControlLabel
                                    key={item.dragMeasureId}
                                    control={
                                      <Checkbox
                                        checked={measureChips.some((selectedItem) => selectedItem.label === item.label)}
                                        style={{ color: colors?.secondaryMain }}
                                        onChange={handleCheckboxChange(item)}
                                      />
                                    }
                                    label={item.label}
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Menu>
                        </Box>
                      </li>
                    )}
                  </Droppable>
                </ul>
              </ClickAwayListener>
            </Grid>
            <Grid
              item
              xs={columnWidths1[0]}
              md={columnWidths1[1]}
              sx={{ paddingTop: '0px !important', paddingLeft: '5px !important', display: !isModified ? 'block' : 'flex' }}
            >
              <Box sx={{ width: '-webkit-fill-available' }}>
                {!isModified && (
                  <Typography
                    onClick={handleButtonClick}
                    sx={{
                      background: colors?.darkSecondary200,
                      padding: '14px 0px',
                      borderRadius: '8px !important',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      src={require('../../assets/images/icons/left-side-arrow.png')}
                      alt="ddd"
                      style={{ display: 'flex', padding: '4px 10px !important', width: '-webkit-fill-available' }}
                    />
                  </Typography>
                )}

                {isModified && (
                  <Box boxShadow={2} p={2} pt={1}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box style={{ fontSize: '14px', fontWeight: 700 }}>Saved Reports</Box>
                      <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <Typography sx={{ fontWeight: 700, cursor: 'pointer', display: 'flex' }} onClick={handleSearchToggle}>
                          <Search sx={{ color: colors?.secondaryMain }} />
                        </Typography>
                        <Typography
                          style={{ fontSize: '30px', fontWeight: 700, cursor: 'pointer', color: colors?.secondaryMain }}
                          onClick={handleButtonClick}
                        >
                          -
                        </Typography>
                      </Box>
                    </Box>

                    {isSearchOpen && (
                      <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                        <InputBase
                          placeholder="Search..."
                          fullWidth
                          onChange={handaleSearchInputForSaveReport}
                          // sx={{ ml: 1, flex: 1 }}
                        />
                      </Paper>
                    )}
                    {/* <Divider sx={{width:"-webkit-fill-available"}} my={1} /> */}
                    <List
                      sx={{
                        overflowX: 'hidden',
                        height: ' 150px',
                        overflowY: 'auto',

                        '&::-webkit-scrollbar': {
                          width: '0.1em',
                          backgroundColor: colors?.grey400
                        },
                        '&::-webkit-scrollbar-track': {
                          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: colors?.grey700,
                          outline: '1px solid slategrey'
                        }
                      }}
                    >
                      {reportsToDisplay &&
                        reportsToDisplay.map((report, index) => (
                          <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: 'inherit' }}>
                            <ListItemText
                              sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                              onDoubleClick={() => handleApplyFilterData(report)}
                              primaryTypographyProps={{ component: 'span', variant: 'body1' }}
                            >
                              &bull;&nbsp;{report.name}
                            </ListItemText>
                            <IconButton
                              edge="end"
                              sx={{ mr: '4px' }}
                              aria-label="delete"
                              onClick={() => handleOpenDeleteConfirmation(report.id)}
                            >
                              <GridDeleteIcon />
                            </IconButton>
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={columnDragWidth[0]}
              md={columnDragWidth[1]}
              ref={customRef}
              sx={{
                paddingLeft: '0px !important',
                border: countResize > 0 ? 'none' : 'none'
              }}
            >
              <Box>
                {countResize == 0 && (
                  <Box>
                    <Box boxShadow={2}>
                      <Box sx={{ padding: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography
                            variant="h6"
                            // sx={{ fontSize: "16px", fontWeight: "600", color: "#333" }}
                            style={{ fontSize: '14px', fontWeight: 700 }}
                          >
                            Measures
                          </Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box onClick={() => setSearchKeyMeasure(!searchKeyMeasure)}>
                              <SearchOutlinedIcon sx={{ marginTop: '2px', mx: 1, color: colors?.secondaryMain }} />
                            </Box>

                            <Box
                              style={{ fontSize: '30px', fontWeight: 700, cursor: 'pointer', color: colors?.secondaryMain }}
                              onClick={() => setCountResize(3)}
                            >
                              -
                            </Box>
                          </Box>
                        </Box>
                        {searchKeyMeasure && (
                          <TextField
                            variant="outlined"
                            name="searchText"
                            onChange={onTextChangeMeasure}
                            className="search-textfiled"
                          />
                        )}
                      </Box>
                      <Divider />
                      <ClickAwayListener onClickAway={handleClosePopups}>
                        <Box
                          sx={{
                            overflow: 'auto',
                            height: '150px',

                            // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                            '&::-webkit-scrollbar': {
                              width: '0.1em',
                              backgroundColor: colors?.grey400
                            },
                            '&::-webkit-scrollbar-track': {
                              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: colors?.grey700,
                              outline: '1px solid slategrey'
                            }
                          }}
                          // className="measures-box"
                        >
                          {measuresArray &&
                            measuresArray?.map((obj, index) => (
                              <>
                                <Droppable droppableId={obj?.value}>
                                  {(provided) => (
                                    <Box ref={provided.innerRef} {...provided?.droppableProps}>
                                      {measuresArray?.length > 0 && (
                                        <>
                                          <Draggable
                                            key={obj?.label}
                                            draggableId={obj?.label}
                                            index={index}
                                            direction="horizontal"
                                          >
                                            {(provided) => (
                                              <Box
                                                {...provided?.draggableProps}
                                                {...provided?.dragHandleProps}
                                                ref={provided?.innerRef}
                                                onClick={() => {
                                                  onMeasureBy(obj);
                                                }}
                                                className="item-dropdown-wrapper"
                                              >
                                                {selectMeasureChip && selectedMeasure === obj?.label && showMeasurePopup(obj)}
                                                <Typography
                                                  key={index}
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    margin: '10px 0px'
                                                  }}
                                                >
                                                  <DragIndicatorIcon /> {obj?.label}
                                                </Typography>
                                              </Box>
                                            )}
                                          </Draggable>
                                        </>
                                      )}
                                    </Box>
                                  )}
                                </Droppable>
                              </>
                            ))}
                        </Box>
                      </ClickAwayListener>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>

            <CustomTable
              splitChips={splitChips}
              allReportData={allReportData}
              setSplitChips={setSplitChips}
              customLoading={loading}
              measureChips={measureChips}
              mdSize={columnDragWidth[1]}
              checkedSwitch={checked}
              setChecked={setChecked}
              handleChange={handleChangeSwitch}
              handleChangeColorTable={handleChangeColorTable}
              checkedColorTable={checkedColorTable}
            />
          </Grid>
          {/* Delete Confirmation Modal */}
          <Dialog open={isDeleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
            <DialogTitle>Delete Confirmation Report</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>Are you sure you want to delete this report?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteConfirmation} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleDeleteReport} variant="contained" color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </MainCard>
      </DragDropContext>
    </>
  );
}

export default CustomReport;
