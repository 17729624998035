import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { Box } from '@mui/system';
import AdgroupContext from 'context/adgroup';
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const EnterKeywordTable = () => {
  // const { setSelectedKeywordData } = props ;
  const { setSelectedKeywordData, selectedKeywordData } = useContext(AdgroupContext);

  const [adGroupName, setAdGroupName] = useState();
  const [selectType, setSelectType] = useState([]);
  const matchTypeData = [{id: 1, name: "exact"},{id: 2, name: "phrase"}, {id: 3, name: "broad"}];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  const handleAddButton = () => {
    const values = adGroupName.trim().split("\n");
    var array = [...selectedKeywordData];
    values.map((item) => {
      selectType?.length > 0 ? selectType?.map((type) => {
        array.push({
          keywordtext: item, 
          matchtype: type?.name,
          bid_amount: "0.30",
          suggested_bid: null
        }) 
      }) : matchTypeData?.map((type) => {
        array.push({
          keywordtext: item,
          matchtype: type?.name,
          bid_amount: "0.30",
          suggested_bid: null
        }) 
      })
    })
    setSelectedKeywordData(array);
    setAdGroupName("")
  }

    const handleSetAdgroupName = (e) => {
      const inputVal = e.target.value;
      const words = inputVal.trim().split(/\s+/);
      if(words.length <= 200){
        setAdGroupName(inputVal);
      }
    }
  
    return (
        <>
        <Box>
          <MainCard sx={{ padding: 0 }}>
          <Autocomplete
              multiple={true}
              id="tags-outlined"
              name="adgroup_name"
              value={selectType}
              size={"small"}
              sx={{width: '30%', mb: 2}}
              options={matchTypeData}
              // value={filterValue.status}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) => {
                console.log("e ", e.target.value, value);
                setSelectType(value)
              }}
              renderOption={(props, option, { selected }) => {
                console.log("selected ", selected);
                return (<li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>)
              }}
              renderInput={(params) => (
                <TextField
                  color='secondary'
                  {...params}
                  placeholder="Select match type"
                />
              )}
            />
            <TextField
              name={"adgroup_name"}
              fullWidth
              type='text'
              multiline
              color="secondary"
              placeholder='Please enter keyword, one keyword per line'
              rows={4}
              value={adGroupName}
              // onChange={(e) => setAdGroupName(e.target.value)}
              onChange={handleSetAdgroupName}
              // error={errors?.adgroup_name}
              // helperText={errors?.adgroup_name?.message}
            />
            <Box sx={{mt:2 , display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Box>You can add up to 200 keywords.</Box>
              <Box> 
              <Button variant="outlined" size='small' color="secondary" onClick={handleAddButton}>Add</Button>
              </Box>
            </Box>
          </MainCard>  
          </Box>
        </>
    );
};

export default EnterKeywordTable;
