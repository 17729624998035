import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import { Box } from '@mui/system';
import colors from 'assets/scss/_themes-vars.module.scss';
import Spon_Brand from 'assets/images/dashna/SponsoredBrandsIcon.png';
import Spon_Product from 'assets/images/dashna/SponsoredProductsIcon.png';
import Spon_Video from 'assets/images/dashna/SponsoredVideosIcon.png';
import CloseIcon from '@mui/icons-material/Close';

// import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CampaignModal = (props) => {
  const { open, onClose } = props;
  // const [selected] = useState("product");
  const navigate = window.open;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="md"
      // sx={{ minWidth: '1000px'}}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="div"> Choose Campaign Type</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form noValidate>
        <DialogContent dividers sx={{ padding: '40px' }}>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Box
              sx={{
                display: 'flex',
                borderRadius: '10px',
                cursor: 'pointer',
                position: 'relative',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '24px 15px',
                border: `1px solid ${colors.borderColor}`,

                background: '#f4f5f6',
                '&:hover': {
                  borderColor: colors.secondaryMain,
                  background: colors.secondaryLight
                }
              }}
              onClick={() => navigate('/create-product', '_blank')}
            >
              <Typography className="custom-dashna-card-title" mb={'14px'} color={colors.darkPaper}>
                Sponsored Products
              </Typography>
              <Box component="img" src={Spon_Product} sx={{ width: 178, height: 118 }} />
            </Box>
            <Box
              onClick={() => navigate('/create-brand', '_blank')}
              sx={{
                display: 'flex',

                cursor: 'pointer',
                borderRadius: '10px',
                position: 'relative',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '24px 15px',
                border: `1px solid ${colors.borderColor}`,

                background: '#f4f5f6',
                '&:hover': {
                  borderColor: colors.secondaryMain,
                  background: colors.secondaryLight
                }
              }}
            >
              <Typography className="custom-dashna-card-title" mb={'14px'} color={colors.darkPaper}>
                Sponsored Brands
              </Typography>
              <Box component="img" src={Spon_Brand} sx={{ width: 178, height: 118 }} />
            </Box>
            <Box
              onClick={() => navigate('/create-video', '_blank')}
              sx={{
                display: 'flex',
                cursor: 'pointer',
                position: 'relative',
                borderRadius: '10px',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '24px 15px',
                border: `1px solid ${colors.borderColor}`,

                background: '#f4f5f6',
                '&:hover': {
                  borderColor: colors.secondaryMain,
                  background: colors.secondaryLight
                }
              }}
            >
              <Typography className="custom-dashna-card-title" mb={'14px'} color={colors.darkPaper}>
                Sponsored Videos
              </Typography>
              <Box component="img" src={Spon_Video} sx={{ width: 178, height: 118 }} />
            </Box>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" 
          >
            Ok
          </Button>
        </DialogActions> */}
      </form>
    </Dialog>
  );
};

export default CampaignModal;
