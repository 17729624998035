import React from "react";
import { Grid, Typography, Skeleton } from "@mui/material";

const CampaignSettingsDetailsSkaletone = () => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h4">Campaign Setting</Typography>
      </Grid>
      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>
      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>
      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>

      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>

      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>

      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>
      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>
      <Grid item sm={6}>
        <Skeleton variant="text" width="40%" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: "1rem" }} />
      </Grid>
    </Grid>
  );
};

export default CampaignSettingsDetailsSkaletone;
