import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, IconButton } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
// import { IconMenu2 } from '@tabler/icons';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
// import CustomDatePicker from '../DateRangePicker';
// import CompareDateRangePicker from 'ui-component/compare-date-range-picker';
import { useAppSelector } from 'store';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { setCommonAdvertiser /* , setCommonDateRange */ } from 'store/slices/userDetailsSlice';
import { useAppDispatch } from 'store';
import { getAlldvertiserList } from 'store/thunk/userThunk';
import CustomCompareDatePicker from 'ui-component/compare-date-range-picker/CustomCompareDatePicker';
import CustomAutocomplete from '../../../ui-component/multi-select/CustomAutocomplete';
import colors from 'assets/scss/_themes-vars.module.scss';
import { getRouteName } from 'helper/commonHelper';
import expandLess from 'assets/images/dashna/expandLess.svg';
import expandMore from 'assets/images/dashna/expandMore.svg';

//Hide Profile and Date Comparision filters for following routes
const hideHeaderFiltersForRoutes = ['/store'];

const Header = ({ handleLeftDrawerToggle }) => {
  const defaultStartDate = moment().subtract(30, 'days').startOf('day').toDate();
  const defaultEndDate = moment().endOf('day').toDate();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [compareStartDate, setCompareStartDate] = useState(defaultStartDate);
  const [compareEndDate, setCompareEndDate] = useState(defaultEndDate);
  const [isCompareDate, setIsCompareDate] = useState(false);

  const dispatch = useAppDispatch();
  const params = useParams();
  const { allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState({ profile: null });
  // useEffect(() => {
  //   dispatch(
  //     setCommonDateRange({
  //       startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
  //       endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
  //     })
  //   );
  // }, [startDate, endDate]);

  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    profile: []
  });
  const userData = useSelector((state) => state.authorization.userData);
  const location = useLocation();
  const route = location?.pathname;

  const theme = useTheme();
  const superAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));
  const [value, setValue] = useState([]);

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'profile') {
      paramKey = 'id';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];

    if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      if (value.length === allIdsCam.length) {
        localStorage.removeItem('selectedProfiles');
      } else {
        localStorage.setItem('selectedProfiles', allIdsCam.join(','));
      }

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setValue(newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns);
    } else {
      localStorage.setItem('selectedProfiles', idString);
      setValue(newValue);
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];

    if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
    }

    const status =
      value?.length && isSelectAll?.[filterKey]?.length
        ? !!(value?.length == baseArray?.length) || !!(value?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    dispatch(getAlldvertiserList({}));
  }, []);

  useEffect(() => {
    if (allUserDetails.length > 0 && params?.selectedProfile) {
      const allSelectedProfiles = params?.selectedProfile.split(',');
      const valueData = [...value];

      allSelectedProfiles.length > 0 &&
        allSelectedProfiles.map((val) => {
          valueData.push(allUserDetails.find((data) => data.id == val));
        });

      setValue(valueData);
    } else {
      if (allUserDetails.length > 0 && localStorage.getItem('selectedProfiles')) {
        const allSelectedProfiles = localStorage.getItem('selectedProfiles').split(',');
        const valueData = [];

        allSelectedProfiles.length > 0 &&
          allSelectedProfiles.map((val) => {
            if (valueData.findIndex((advert) => advert?.id === val) === -1) {
              valueData.push(allUserDetails.find((data) => data?.id == val));
            }
          });

        setValue(valueData);
      } else if (allUserDetails.length > 0 && !localStorage.getItem('selectedProfiles')) {
        //When User Login Initially then set the Profile Advertiser as he last selected
        const valueData = [];
        const lastAccessedAdvertiser = userData?.last_accessed_advertiser || [];
        lastAccessedAdvertiser.length &&
          lastAccessedAdvertiser.map((val) => {
            if (valueData.findIndex((advert) => advert?.id === val) === -1) {
              valueData.push(allUserDetails.find((data) => data?.id == val));
            }
          });
        setValue(valueData);
        localStorage.setItem('selectedProfiles', lastAccessedAdvertiser.join(','));
      }
    }
  }, [allUserDetails]);

  useEffect(() => {
    if (!isDropdownOpen) {
      dispatch(setCommonAdvertiser(value.map((item) => item?.id)));

      const profiles = localStorage.getItem('selectedProfiles');

      if (value.length === 0 && profiles?.length === 0) {
        localStorage.removeItem('selectedProfiles');
      }
    }
  }, [value, isDropdownOpen]);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: leftDrawerOpened ? 228 : 50,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>
      <IconButton onClick={handleLeftDrawerToggle}>
        {leftDrawerOpened ? <img src={expandLess} alt="icon" /> : <img src={expandMore} alt="icon" />}
      </IconButton>
      {/* header search */}
      <Box sx={{ flexGrow: 0.02 }} />
      {(userData?.data?.user_type && userData?.data?.user_type !== 1 && location?.pathname == '/') ||
        (userData?.data?.user_type && userData?.data?.user_type !== 1 && location?.pathname == '/dashboard')}
      <Box mx={2}>
        <Typography className="custom-dashna-page-title" color={colors?.darkLevel1}>
          {getRouteName(route)}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 0.1 }} />
      {!superAdmin && !hideHeaderFiltersForRoutes.includes(route) ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="custom-compare-date-picker" sx={{ width: '100%', mr: 2 }}>
            <CustomCompareDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              setCompareStartDate={setCompareStartDate}
              setCompareEndDate={setCompareEndDate}
              compareStartDate={compareStartDate}
              compareEndDate={compareEndDate}
              isCompareDate={isCompareDate}
              setIsCompareDate={setIsCompareDate}
              style={{ width: '250px' }}
            />
          </Box>
          <Box
            sx={
              isCompareDate
                ? { maxWidth: '250px', minWidth: '250px', width: '100%', mr: 2 }
                : { maxWidth: '250px', minWidth: '250px', width: '100%', mr: 2 }
            }
          >
            <CustomAutocomplete
              multiple
              id="profile_filter"
              name="Profile"
              color="secondary"
              label="Profile"
              options={allUserDetails || []}
              value={value || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'profile');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpen ? searchText?.profile : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, profile: value });
                }
              }}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('profile')}
              placeholderCondition={value?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
              extraClass={'profileDrp'}
            />
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
