import React from "react";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";

const renderLabel = (name) => {
  return (
    <Typography component="span" sx={{ padding: 0, fontWeight: "bold" }}>
      {name}
    </Typography>
  );
};

const renderValue = (value) => {
  return (
    <Typography component="span" sx={{ pl: "4px" }}>
      {value}
    </Typography>
  );
};

const renderData = (fieldName, fieldValue) => {
  return (
    <>
      <Grid item sm={5} xl={3} >
        {renderLabel(fieldName)}:
      </Grid>
      <Grid item sm={7} xl={9}>
        {renderValue(fieldValue)}
      </Grid>
    </>
  );
};

const BrandCampaignDataBox = (props) => {
  const { brandData } = props;
  return (
    <Box sx={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}>
      <Card sx={{ minWidth: "100%" }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item sm={3} xl={2}>
              <Box>
                <img
                  src={brandData?.logo}
                  alt={brandData?.name}
                  style={{
                    objectFit: "contain",
                    width: "100px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item sm={9} xl={10}>
              <Grid container spacing={1} sx={{paddingLeft:"10px"}}>
                {renderData("Adgroup Name", brandData?.adgroup_name)}
                {renderData("Adgroup Status", brandData?.adgroup_status)}
                {renderData("Brand Name", brandData?.name)}
                {renderData("Headline Text", brandData?.headlinetext)}
                {renderData("Review Status", brandData?.reviewStatus)}
                {renderData("Status", brandData?.status)}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BrandCampaignDataBox;
