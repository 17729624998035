import { Tooltip } from '@mui/material';
import colors from 'assets/scss/_themes-vars.module.scss';

//User Permissions to each modules as per its role
export const permission = [
  {
    user_type: 'Owner',
    permissions: {
      user: {
        access: true, //Page access
        create: true, //Add User
        update: true, //Edit
        delete: true //Delete
      },
      advertiser: {
        access: true, //Page access
        create: true, //Add Advertiser
        update: true, //Edit Advertiser
        delete: true //Delete Advertiser
      },
      dashboard: {
        access: true, //Page access
        download: true //Download chart
      },
      campaign: {
        access: true, //Page access
        create: true, //create campaign
        update: true, //edit campaign, Bulk Operation
        video_management: true, //video management
        download: true //Download Campaign csv
      },
      adgroup: {
        access: true, //Page access
        create: true, //create adgroup
        update: true, //Inline Update
        download: true //Download csv
      },
      keywords: {
        access: true, //Page access
        create: true, //Add keyword
        update: true, //Inline update, Bulk Operation
        download: true //Download csv
      },
      items: {
        access: true, //Page access
        update: true, //Inline update, Bulk Operation
        download: true //Download csv
      },
      bulk_operation: {
        access: true //Page access
      },
      budget_manager: {
        access: true, //Page access
        create: true, //Add Profile for budget manager
        update: true, //Inline update
        delete: true //Delete
      },
      default_report: {
        access: true, //Page access
        download: true //Download csv
      },
      my_report: {
        access: true //Page access
      },
      custom_report: {
        access: true, //Page access
        download: true //Download csv
      },
      on_demand_report: {
        access: true, //Page access
        download: true, //Download Report
        request_report: true //Request report button
      },
      dashboard_report: {
        access: true, //Page access
        download: true //Download csv
      },
      shared_dashboard: {
        access: true, //Page access
        create: true, //Create Share link
        update: true, //Inline update, Bulk Operation
        delete: true //Delete
      }
    }
  },
  {
    user_type: 'Admin',
    permissions: {
      user: {
        access: true,
        create: true,
        update: true,
        delete: true
      },
      advertiser: {
        access: true,
        create: true,
        update: true,
        delete: true
      },
      dashboard: {
        access: true,
        download: true
      },
      campaign: {
        access: true,
        create: true,
        update: true,
        video_management: true,
        download: true
      },
      adgroup: {
        access: true,
        create: true,
        update: true,
        download: true
      },
      keywords: {
        access: true,
        create: true,
        update: true,
        download: true
      },
      items: {
        access: true,
        update: true,
        download: true
      },
      bulk_operation: {
        access: true
      },
      budget_manager: {
        access: true,
        create: true,
        update: true,
        delete: true
      },
      default_report: {
        access: true,
        download: false
      },
      my_report: {
        access: true
      },
      custom_report: {
        access: true,
        download: true
      },
      on_demand_report: {
        access: true,
        download: true,
        request_report: true
      },
      dashboard_report: {
        access: true,
        download: true
      },
      shared_dashboard: {
        access: true,
        create: true,
        update: true,
        delete: true
      }
    }
  },
  {
    user_type: 'User',
    permissions: {
      user: {
        access: false,
        create: false,
        update: false,
        delete: false
      },
      advertiser: {
        access: false,
        create: false,
        update: false,
        delete: false
      },
      dashboard: {
        access: true,
        download: true
      },
      campaign: {
        access: true,
        create: true,
        update: true,
        video_management: true,
        download: true
      },
      adgroup: {
        access: true,
        create: true,
        update: true,
        download: true
      },
      keywords: {
        access: true,
        create: true,
        update: true,
        download: true
      },
      items: {
        access: true,
        update: true,
        download: true
      },
      bulk_operation: {
        access: true
      },
      budget_manager: {
        access: true,
        create: true,
        update: true,
        delete: true
      },
      default_report: {
        access: true,
        download: true
      },
      my_report: {
        access: true
      },
      custom_report: {
        access: true,
        download: true
      },
      on_demand_report: {
        access: true,
        download: true,
        request_report: true
      },
      dashboard_report: {
        access: true,
        download: true
      },
      shared_dashboard: {
        access: true,
        create: true,
        update: true,
        delete: true
      }
    }
  },
  {
    user_type: 'Viewer',
    permissions: {
      user: {
        access: false,
        create: false,
        update: false,
        delete: false
      },
      advertiser: {
        access: false,
        create: false,
        update: false,
        delete: false
      },
      dashboard: {
        access: true,
        download: true
      },
      campaign: {
        access: true,
        create: false,
        update: false,
        video_management: false,
        download: true
      },
      adgroup: {
        access: true,
        create: false,
        update: false,
        download: true
      },
      keywords: {
        access: true,
        create: false,
        update: false,
        download: true
      },
      items: {
        access: true,
        update: false,
        download: true
      },
      bulk_operation: {
        access: false
      },
      budget_manager: {
        access: true,
        create: false,
        update: false,
        delete: false
      },
      default_report: {
        access: true,
        download: true
      },
      my_report: {
        access: true
      },
      custom_report: {
        access: true,
        download: true
      },
      on_demand_report: {
        access: true,
        download: true,
        request_report: true
      },
      dashboard_report: {
        access: true,
        download: true
      },
      shared_dashboard: {
        access: true,
        create: false,
        update: false,
        delete: false
      }
    }
  }
];

//all user roles
export const userRoles = [
  // { name: 'Super Admin', value: 1 },
  // { name: 'Owner', value: 2 },
  { name: 'Admin', value: 3 },
  { name: 'User', value: 4 },
  { name: 'Viewer', value: 5 }
];

//all user roles
export const userRolesForFilter = [
  // { name: 'Super Admin', value: 1 },
  { name: 'Owner', value: 2 },
  { name: 'Admin', value: 3 },
  { name: 'User', value: 4 },
  { name: 'Viewer', value: 5 }
];

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 250;
export const appDrawerWidth = 320;

// Attribute Windows
export const namesForOnDemand = [
  { name: 'keyword', value: 'Keyword Performance' },
  { name: 'pageType', value: 'Page Type Performance' },
  { name: 'platform', value: 'Platform Performance' },
  { name: 'placement', value: 'Placement Performance' },
  { name: 'brand', value: 'Brand Performance' },
  { name: 'category', value: 'Category Performance' },
  { name: 'itemKeyword', value: 'Item Keyword Performance' },
  { name: 'itemHealthV2', value: 'Item Health' },
  { name: 'adItem', value: 'Item Performance' },
  { name: 'daily', value: 'Daily' },
  { name: 'done', value: 'Generated' },
  { name: 'done', value: 'Generated' },
  { name: 'pending', value: 'Pending' },
  { name: 'expired', value: 'Expired' },
  { name: 'failed', value: 'Failed' },
  { name: '3days', value: '3 Days' },
  { name: 'days3', value: '3 Days' },
  { name: 'days14', value: '14 Days' },
  { name: '14days', value: '14 Days' },
  { name: 'days30', value: '30 Days' },
  { name: '30days', value: '30 Days' },
  { name: 'cumulative', value: 'Cumulative' }
];

export const PageValue = [25, 50, 100];
export const pageValueKeyword = [25, 50, 100, 200, 300, 500];

export const attributeWindowList = [
  {
    value: '3',
    name: '3 days'
  },
  {
    value: '14',
    name: '14 days'
  },
  {
    value: '30',
    name: '30 days'
  }
];

export const OnDemandStatusList = [
  {
    id: 1,
    name: 'Pending',
    value: 'pending'
  },
  {
    id: 2,
    name: 'Generated',
    value: 'done'
  },
  // {
  //   id: 3,
  //   name: 'Generated',
  //   value: 'processing'
  // },
  {
    id: 4,
    name: 'Expired',
    value: 'expired'
  },
  {
    id: 5,
    name: 'Failed',
    value: 'failed'
  }
];

export const UserStatusList = [
  {
    id: 1,
    name: 'Active'
  },
  {
    id: 2,
    name: 'InActive'
  }
];

export const UserTypeList = [
  {
    id: 1,
    name: 'Pending',
    value: 'pending'
  },
  {
    id: 2,
    name: 'Generated',
    value: 'processing'
  }
];

export const ReportTypeOnDemand = [
  {
    id: 1,
    name: 'AdItem Performance',
    value: 'adItem'
  },
  {
    id: 2,
    name: 'Keyword Performance',
    value: 'keyword'
  },
  {
    id: 3,
    name: 'Page Type Performance',
    value: 'pageType'
  },
  {
    id: 4,
    name: 'Platform Performance',
    value: 'platform'
  },
  {
    id: 5,
    name: 'Placement Performance',
    value: 'placement'
  },
  {
    id: 6,
    name: 'Brand Performance',
    value: 'brand'
  },
  {
    id: 7,
    name: 'Category Performance',
    value: 'category'
  },
  {
    id: 8,
    name: 'Item Keyword Performance',
    value: 'itemKeyword'
  },
  {
    id: 9,
    name: 'Item Health',
    value: 'itemHealthV2'
  }
];

export const groupByList = [
  {
    value: 'total',
    name: 'Total'
  },
  {
    value: 'day',
    name: 'Day'
  },
  {
    value: 'week',
    name: 'Week'
  },
  {
    value: 'month',
    name: 'Month'
  },
  {
    value: 'quater',
    name: 'Quarter'
  },
  {
    value: 'year',
    name: 'Year'
  }
];

export const sortByList = [
  {
    value: 'campaign_name',
    name: 'Campaign Name'
  },
  {
    value: 'state',
    name: 'State'
  },
  {
    value: 'review_status',
    name: 'Review Status'
  },
  {
    value: 'adgroups',
    name: 'Adgroups'
  },
  {
    value: 'quater',
    name: 'Keywords'
  },
  {
    value: 'item',
    name: 'Item'
  },
  {
    value: 'campaign_type',
    name: 'Campaign Type'
  },
  {
    value: 'create_date',
    name: 'Create Date'
  },
  {
    value: 'start_time',
    name: 'Start Time'
  },
  {
    value: 'end_time',
    name: 'End Time'
  }
];

export const signList = [
  {
    value: 'less',
    name: '<'
  },
  {
    value: 'greter',
    name: '>'
  },
  {
    value: 'greter_equal',
    name: '>='
  },
  {
    value: 'less_equal',
    name: '<='
  }
];

export const itemTypeList = [
  {
    value: 'contains_or',
    name: 'Contains(or)'
  },
  {
    value: 'contains_and',
    name: 'Contains(and)'
  }
];

export const sortByListGroup = [
  {
    value: 'profile_name',
    name: 'Profile Name'
  },
  {
    value: 'name',
    name: 'Adgroup Name'
  },
  {
    value: 'status',
    name: 'Status'
  },
  {
    value: 'campaign_name',
    name: 'Campaign Name'
  },
  {
    value: 'campaign_type',
    name: 'Campaign Type'
  }
];

// add this options in future
// {
//     value: "3",
//         name: "3 days attribution"
// },{
//     value: "30",
//     name: "30 days attribution"
// }

export const campaigState = [
  { id: 1, name: 'Enabled', value: 'enabled' },
  { id: 2, name: 'Paused', value: 'paused' },
  { id: 3, name: 'Completed', value: 'completed' },
  { id: 4, name: 'Scheduled', value: 'scheduled' },
  { id: 5, name: 'Proposal', value: 'proposal' }
];

export const KeywordState = [
  { id: 1, name: 'Enabled', value: 'enabled', category: 'Ads Status' },
  { id: 2, name: 'Paused', value: 'paused', category: 'Ads Status' },
  { id: 3, name: 'Archived', value: 'archived', category: 'Ads Status' },
  { id: 4, name: 'Pending', value: 'pending', category: 'Review Status' },
  { id: 5, name: 'Approved', value: 'approved', category: 'Review Status' },
  { id: 6, name: 'Rejected', value: 'rejected', category: 'Review Status' }
];

export const adGroup_state = [
  { id: 1, name: 'Enabled', value: 'enabled' },
  { id: 2, name: 'Paused', value: 'paused' },
  { id: 3, name: 'Deleted', value: 'deleted' }
];

export const Seller_Type = [
  { id: 1, seller_type: '1p', displayValue: '1P' },
  { id: 2, seller_type: '3p', displayValue: '3P' }
];

export const attrWindow = [
  { id: 1, name: '3 days atribution', value: '3_days' },
  { id: 2, name: '14 days atribution', value: '14_days' },
  { id: 3, name: '30 days atribution', value: '30_days' }
];

export const itemStatus = [
  { id: 1, name: 'Enabled', value: 'enabled', category: 'Ads Status' },
  { id: 2, name: 'Paused', value: 'paused', category: 'Ads Status' },
  { id: 3, name: 'Archived', value: 'deleted', category: 'Ads Status' },
  { id: 4, name: 'Pending', value: 'pending', category: 'Review Status' },
  { id: 5, name: 'Rejected', value: 'rejected', category: 'Review Status' },
  { id: 6, name: 'Approved', value: 'approved', category: 'Review Status' }
];

export const campaignStatus = [
  { id: 1, name: 'Enabled', value: 'enabled' },
  { id: 2, name: 'Paused', value: 'paused' },
  { id: 3, name: 'Proposal', value: 'proposal' }
];

export const filterArray = {
  campaign_filter: {
    Common: [
      { id: 3, value: 'campaign', name: 'Campaign' },
      { id: 4, value: 'state', name: 'Status' },
      { id: 5, value: 'campaign_type', name: 'Campaign Type' },
      { id: 6, value: 'attr_window', name: 'Attr. Window', default: true },
      // { id: 7, value: "group_by", name: "Group by" },
      // { id: 8, value: "sort_by", name: "Sort by" },
      // { id: 9, value: "automation_status", name: "Automation Status" },
      { id: 10, value: 'search', name: 'Search' }
    ],
    Performance: [
      { id: 1, value: 'acos', name: 'ACOS' },
      { id: 2, value: 'average_cpc', name: 'CPC' },
      { id: 3, value: 'num_ads_clicks', name: 'Clicks' },
      { id: 4, value: 'ad_spend', name: 'Spend' },
      { id: 5, value: 'advertised_sku_sales', name: 'Sales' },
      { id: 6, value: 'units_sold', name: 'Sale Units' },
      { id: 7, value: 'num_ads_shown', name: 'Impressions' },
      { id: 8, value: 'ctr', name: 'CTR' },
      // { id: 9, value: "cpa", name: "CPA" },
      { id: 10, value: 'cvr_orders', name: 'CVR' },
      { id: 11, value: 'roas', name: 'ROAS' }
      // { id: 12, value: "ad_sku_roas", name: "Ad SKU ROAS" },
      // { id: 13, value: "target_roas", name: "Target ROAS" },
      // { id: 14, value: "target_acos", name: "Target ACOS" }
    ],
    Advanced: [
      { id: 1, value: 'item', name: 'Item' },
      // { id: 2, value: "actlbid_avlbid", name: "ActlBid / AvlBid" },
      { id: 3, value: 'daily_budget', name: 'Daily Budget' }
    ]
  },
  adgroup_filter: {
    Common: [
      { id: 1, value: 'state', name: 'Campaign State' },
      { id: 2, value: 'campaign', name: 'Campaign' },
      { id: 3, value: 'adgroup_state', name: 'Adgroup State' },
      { id: 4, value: 'adgroup', name: 'Adgroup' },
      { id: 5, value: 'campaign_type', name: 'Campaign Type' },
      { id: 6, value: 'adgroup_name', name: 'Adgroup Name' },
      // { id: 7, value: "group_by", name: "Group by" },
      // { id: 8, value: "sort_by", name: "Sort by" },
      {
        id: 9,
        value: 'attr_window',
        name: 'Attr. Window',
        default: true
      }
    ],
    Performance: [
      { id: 1, value: 'acos', name: 'ACOS' },
      { id: 2, value: 'average_cpc', name: 'CPC' },
      { id: 3, value: 'clicks', name: 'Clicks' },
      { id: 4, value: 'ad_spend', name: 'Spend' },
      { id: 5, value: 'ad_sales', name: 'Sales' },
      { id: 6, value: 'units_sold', name: 'Sale Units' },
      { id: 7, value: 'impressions', name: 'Impressions' },
      { id: 8, value: 'ctr', name: 'CTR' },
      // { id: 9, value: "cpa", name: "CPA" },
      { id: 10, value: 'cvr_orders', name: 'CVR' },
      { id: 11, value: 'roas', name: 'ROAS' }
      // { id: 12, value: "ad_sku_roas", name: "Ad SKU ROAS" },
    ]
  },
  aditem_filter: {
    Common: [
      { id: 1, value: 'state', name: 'Campaign State' },
      { id: 2, value: 'campaign', name: 'Campaign' },
      { id: 3, value: 'adgroup_state', name: 'Adgroup State' },
      { id: 4, value: 'adgroup', name: 'Group By', default: true },
      { id: 5, value: 'campaign_type', name: 'Campaign Type' },
      { id: 6, value: 'item_status', name: 'Item Status' },
      { id: 7, value: 'bid', name: 'Bid' },
      { id: 8, value: 'brand', name: 'Brand' },
      // { id: 9, value: "item_tag", name: "Item Tag" },
      { id: 10, value: 'add_date', name: 'Add Date' },
      // { id: 11, value: "sort_by", name: "Sort by" },
      { id: 11, value: 'adgroup_Filter', name: 'Adgroup' },
      { id: 12, value: 'attr_window', name: 'Attr. Window', default: true },
      { id: 13, value: 'group_by', name: 'Group by' },
      { id: 14, value: 'search', name: 'Search' }
    ],
    Performance: [
      { id: 1, value: 'acos', name: 'ACOS' },
      { id: 2, value: 'average_cpc', name: 'CPC' },
      { id: 3, value: 'clicks', name: 'Clicks' },
      { id: 4, value: 'ad_spend', name: 'Spend' },
      { id: 5, value: 'ad_sales', name: 'Sales' },
      { id: 6, value: 'units_sold', name: 'Sale Units' },
      { id: 7, value: 'impressions', name: 'Impressions' },
      { id: 8, value: 'ctr', name: 'CTR' },
      { id: 9, value: 'cpa', name: 'CPA' },
      { id: 10, value: 'cvr_orders', name: 'CVR' },
      { id: 11, value: 'roas', name: 'ROAS' }
      // { id: 12, value: "ad_sku_roas", name: "Ad SKU ROAS" },
    ]
  },
  keyword_filter: {
    Common: [
      { id: 1, value: 'state', name: 'Campaign State' },
      { id: 2, value: 'campaign', name: 'Campaign' },
      { id: 3, value: 'adgroup_state', name: 'Adgroup State' },
      { id: 4, value: 'adgroup', name: 'Adgroup' },
      { id: 5, value: 'campaign_type', name: 'Campaign Type' },
      // { id: 6, value: "adgroup_name", name: "Adgroup Name" },
      { id: 7, value: 'search', name: 'Search' },
      // { id: 8, value: "sort_by", name: "Sort by" },
      { id: 8, value: 'group_by', name: 'Group by', default: true },
      { id: 9, value: 'attr_window', name: 'Attr. Window', default: true },
      { id: 10, value: 'keyword_status', name: 'Keyword Status' },
      { id: 11, value: 'match_type', name: 'Match Type' }
    ],
    Performance: [
      { id: 1, value: 'acos', name: 'ACOS' },
      { id: 2, value: 'average_cpc', name: 'CPC' },
      { id: 3, value: 'clicks', name: 'Clicks' },
      { id: 4, value: 'ad_spend', name: 'Spend' },
      { id: 5, value: 'ad_sales', name: 'Sales' },
      { id: 6, value: 'units_sold', name: 'Sale Units' },
      { id: 7, value: 'impressions', name: 'Impressions' },
      { id: 8, value: 'ctr', name: 'CTR' },
      { id: 10, value: 'cvr_orders', name: 'CVR' },
      { id: 11, value: 'roas', name: 'ROAS' }
    ]
  },
  rank_tracker_filter: {
    Common: [
      { id: 1, value: 'group_by', name: 'Group by', default: true },
      { id: 6, value: 'rank_type', name: 'Rank Type', default: true },
      { id: 2, value: 'details', name: 'Item' },
      { id: 3, value: 'search_term', name: 'Search Term' },
      { id: 4, value: 'search_volume_rank', name: 'Search Volume Rank' },
      { id: 5, value: 'current_rank', name: 'Current Rank' }
    ]
  }
};

export const dimensionsArrayMain = [
  { dragId: 1, label: 'Ad Group Id', value: 'ad_group_id' },
  { dragId: 2, label: 'Ad Group Name', value: 'adgroup_name' },
  { dragId: 3, label: 'Bidded Keyword', value: 'bidded_keyword' },
  { dragId: 4, label: 'Brand', value: 'brand' },
  { dragId: 5, label: 'Campaign Id', value: 'campaign_id' },
  { dragId: 6, label: 'Campaign Name', value: 'campaign_name' },
  { dragId: 7, label: 'Campaign Type Name', value: 'campaign_type_name' },
  { dragId: 8, label: 'Item Id', value: 'item_id' },
  { dragId: 9, label: 'Item Name', value: 'item_name' },
  { dragId: 10, label: 'Match type', value: 'match_type' },
  { dragId: 11, label: 'Page Type', value: 'page_type' },
  { dragId: 12, label: 'Platform', value: 'platform' }
];

export const MeasureArrayMain = [
  { label: 'Impressions', value: 'impressions', dragMeasureId: 3 },
  { label: 'Clicks', value: 'clicks', dragMeasureId: 4 },
  { label: 'Ad Spend', value: 'ad_spend', dragMeasureId: 5 },
  { label: 'CTR', value: 'ctr', dragMeasureId: 6 },
  { label: 'CVR Units', value: 'cvr_units', dragMeasureId: 7 },
  { label: 'ROAS', value: 'roas', dragMeasureId: 8 },
  { label: 'Adverstised SKU Sales', value: 'advertised_sku_sales', dragMeasureId: 9 },
  { label: 'Other SKU Sales', value: 'other_sku_sales', dragMeasureId: 10 },
  { label: 'Advertised SKU Units 14 Days', value: 'advertised_sku_units_14_days', dragMeasureId: 11 },
  { label: 'Other SKU Units 14 Days', value: 'other_sku_units_14_days', dragMeasureId: 12 },
  { label: 'CVR Order 14 days', value: 'cvr_orders_14_days', dragMeasureId: 13 },
  { label: 'ROAS 14 days', value: 'roas_14_days', dragMeasureId: 14 },
  { label: 'CVR units 30 days', value: 'cvr_units_30_days', dragMeasureId: 15 },
  { label: 'CVR Orders 30 days', value: 'cvr_orders_30_days', dragMeasureId: 16 },
  { label: 'ROAS 30 days', value: 'roas_30_days', dragMeasureId: 17 },
  { label: 'CVR Units 3days', value: 'cvr_units_3days', dragMeasureId: 18 },
  { label: 'CVR Orders 3days', value: 'cvr_orders_3days', dragMeasureId: 19 },
  { label: 'ROAS 3days', value: 'roas_3days', dragMeasureId: 20 },
  { label: 'CVR units 14 days', value: 'cvr_units_14_days', dragMeasureId: 20 }

  // Add remaining fields here...

  // Add as many fields as needed
];

export const reportArray = {
  data: {
    impression: 0,
    clicks: 0,
    average_cpc: 0,
    ctr: 0,
    ad_spend: 0,
    total_units_sold_14days: 0,
    advertised_sku_units_14days: 0,
    other_sku_units_14days: 0,
    cvr_units: 0,
    total_sales_revenue_14days: 0,
    advertised_sku_sales_14days: 0,
    other_sku_sales_14days: 0,
    roas: 0,
    SPLIT: {
      keys: ['date'],
      data: [
        {
          date: '2023-12-14',
          average_cpc: 1.13,
          ctr: 0.2,
          ad_spend: 1.99,
          cvr_units: 0.0,
          advertised_sku_sales_14days: 0.0,
          other_sku_sales_14days: 0.0,
          advertised_sku_units_14days: 0.0,
          other_sku_units_14days: 0.0,
          roas: 0.0,
          impression: 175.0,
          clicks: 4.0,
          total_units_sold_14days: 0.0,
          total_sales_revenue_14days: 0.0,
          SPLIT: {
            keys: ['campaign_id'],
            data: [
              {
                date: '2023-12-14',
                campaign_id: 1833116025,
                ad_group_id: 844349,
                average_cpc: 1.4,
                ctr: 0.02,
                ad_spend: 1.4,
                cvr_units: 0.0,
                advertised_sku_sales_14days: 0.0,
                other_sku_sales_14days: 0.0,
                advertised_sku_units_14days: 0.0,
                other_sku_units_14days: 0.0,
                roas: 0.0,
                impression: 318.0,
                clicks: 2.0,
                total_units_sold_14days: 0.0,
                total_sales_revenue_14days: 0.0,
                SPLIT: {
                  keys: ['ad_group_id'],
                  data: [
                    {
                      date: '2023-12-14',
                      campaign_id: 1833116025,
                      ad_group_id: 844349,
                      average_cpc: 0.7,
                      ctr: 0.01,
                      ad_spend: 0.7,
                      cvr_units: 0.0,
                      advertised_sku_sales_14days: 0.0,
                      other_sku_sales_14days: 0.0,
                      advertised_sku_units_14days: 0.0,
                      other_sku_units_14days: 0.0,
                      roas: 0.0,
                      impression: 159.0,
                      clicks: 1.0,
                      total_units_sold_14days: 0.0,
                      total_sales_revenue_14days: 0.0
                    }
                  ]
                }
              },
              {
                date: '2023-12-14',
                campaign_id: 1419015,
                ad_group_id: 512036,
                average_cpc: 0.86,
                ctr: 0.38,
                ad_spend: 2.58,
                cvr_units: 0.0,
                advertised_sku_sales_14days: 0.0,
                other_sku_sales_14days: 0.0,
                advertised_sku_units_14days: 0.0,
                other_sku_units_14days: 0.0,
                roas: 0.0,
                impression: 32.0,
                clicks: 6.0,
                total_units_sold_14days: 0.0,
                total_sales_revenue_14days: 0.0,
                SPLIT: {
                  keys: ['ad_group_id'],
                  data: [
                    {
                      date: '2023-12-14',
                      campaign_id: 1419015,
                      ad_group_id: 512036,
                      average_cpc: 0.43,
                      ctr: 0.19,
                      ad_spend: 1.29,
                      cvr_units: 0.0,
                      advertised_sku_sales_14days: 0.0,
                      other_sku_sales_14days: 0.0,
                      advertised_sku_units_14days: 0.0,
                      other_sku_units_14days: 0.0,
                      roas: 0.0,
                      impression: 16.0,
                      clicks: 3.0,
                      total_units_sold_14days: 0.0,
                      total_sales_revenue_14days: 0.0
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          date: '2023-12-13',
          average_cpc: 0.86,
          ctr: 1.14,
          ad_spend: 0.86,
          cvr_units: 0.0,
          advertised_sku_sales_14days: 0.0,
          other_sku_sales_14days: 0.0,
          advertised_sku_units_14days: 0.0,
          other_sku_units_14days: 0.0,
          roas: 0.0,
          impression: 8.0,
          clicks: 2.0,
          total_units_sold_14days: 0.0,
          total_sales_revenue_14days: 0.0,
          SPLIT: {
            keys: ['campaign_id'],
            data: [
              {
                date: '2023-12-13',
                campaign_id: 1833117043,
                ad_group_id: 683799,
                average_cpc: 0.86,
                ctr: 2.0,
                ad_spend: 0.86,
                cvr_units: 0.0,
                advertised_sku_sales_14days: 0.0,
                other_sku_sales_14days: 0.0,
                advertised_sku_units_14days: 0.0,
                other_sku_units_14days: 0.0,
                roas: 0.0,
                impression: 2.0,
                clicks: 2.0,
                total_units_sold_14days: 0.0,
                total_sales_revenue_14days: 0.0,
                SPLIT: {
                  keys: ['ad_group_id'],
                  data: [
                    {
                      date: '2023-12-13',
                      campaign_id: 1833117043,
                      ad_group_id: 683799,
                      average_cpc: 0.43,
                      ctr: 1.0,
                      ad_spend: 0.43,
                      cvr_units: 0.0,
                      advertised_sku_sales_14days: 0.0,
                      other_sku_sales_14days: 0.0,
                      advertised_sku_units_14days: 0.0,
                      other_sku_units_14days: 0.0,
                      roas: 0.0,
                      impression: 1.0,
                      clicks: 1.0,
                      total_units_sold_14days: 0.0,
                      total_sales_revenue_14days: 0.0
                    }
                  ]
                }
              },
              {
                date: '2023-12-13',
                campaign_id: 1521026,
                ad_group_id: 683799,
                average_cpc: 0.86,
                ctr: 0.28,
                ad_spend: 0.86,
                cvr_units: 0.0,
                advertised_sku_sales_14days: 0.0,
                other_sku_sales_14days: 0.0,
                advertised_sku_units_14days: 0.0,
                other_sku_units_14days: 0.0,
                roas: 0.0,
                impression: 14.0,
                clicks: 2.0,
                total_units_sold_14days: 0.0,
                total_sales_revenue_14days: 0.0,
                SPLIT: {
                  keys: ['ad_group_id'],
                  data: [
                    {
                      date: '2023-12-13',
                      campaign_id: 1521026,
                      ad_group_id: 683799,
                      average_cpc: 0.43,
                      ctr: 0.14,
                      ad_spend: 0.43,
                      cvr_units: 0.0,
                      advertised_sku_sales_14days: 0.0,
                      other_sku_sales_14days: 0.0,
                      advertised_sku_units_14days: 0.0,
                      other_sku_units_14days: 0.0,
                      roas: 0.0,
                      impression: 7.0,
                      clicks: 1.0,
                      total_units_sold_14days: 0.0,
                      total_sales_revenue_14days: 0.0
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    }
  }
};

export const showValues = (data, lists, nameKey = 'name') => {
  const dispValue = `${data?.length == 1 ? data?.[0][nameKey] : `${data?.length} of ${lists?.length} selected`}`;
  return (
    <Tooltip title={dispValue}>
      <span className="dorpdown-text-trim">{dispValue}</span>
    </Tooltip>
  );
};

export const dateRangeTypes = [
  { name: 'previous_period', label: 'Previous Period' },
  { name: 'last_year', label: 'Last Year' },
  { name: 'custom_range', label: 'Custom Range' }
];

export const chartTypes = [
  { name: 'daily', label: 'Daily' },
  { name: 'week', label: 'Weekly' },
  { name: 'month', label: 'Monthly' }
];

export const themeColors = { primaryMain: colors?.primaryMain, secondaryMain: colors?.primary800 };

export const CURRENT_RANK_MINIMUM_VALUE = 201;

export const routeNameMapping = {
  '/adgroup': 'Ad groups',
  '/rank-tracker': 'Rank Tracker',
  '/campaign': 'Campaigns ',
  '/dashboard': 'Dashboard',
  '/dashboard-overview': 'Dashboard',
  '/budget-manager': 'Budget Manager',
  '/on-demand-reports': 'On Demand Reports',
  '/dashboard-report': 'Dashboard Report',
  '/items': 'Ad Items',
  '/custom-report': 'Custom Report',
  '/shared-dashboards': 'Shared Dashboard',
  '/store': 'Stores',
  '/advertiser': 'Profiles',
  '/user-management': 'Users',
  '/profile': 'Account Settings',
  '/bulk-operations': 'Bulk Operations',
  '/default-report': 'Default Reports',
  '/campaign-report': 'Campaign Reports',
  '/adgroup-report': 'AdGroup Reports',
  '/item-report': 'AdItem Reports',
  '/keyword-report': 'Keyword Reports',
  '/budget-manager/add-profile': 'Add Profile',
  '/video-management': 'Video Management',
  '/shared-dashboards/create-link': 'Create Link',
  '/keyword': 'Keywords'
};
