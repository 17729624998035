import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import AdgroupTable from './tables/adgroupTable';
import AditemTable from './tables/aditemTable';
import CampaignTable from './tables/campaignTable';
import KeywordTable from './tables/keywordTable';
import MainCard from 'ui-component/cards/MainCard';

const BulkOperationTable = () => {
    const [value, setValue] = useState('1');

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '20px 0px' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Campaigns" value="1" />
                        <Tab label="Adgroups" value="2" />
                        <Tab label="Items" value="3" />
                        <Tab label="Keywords" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: 0 }}>
                    <CampaignTable />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: 0 }}><AdgroupTable /></TabPanel>
                <TabPanel value="3" sx={{ padding: 0 }}><AditemTable /></TabPanel>
                <TabPanel value="4" sx={{ padding: 0 }}><KeywordTable /></TabPanel>
            </TabContext>
        </MainCard>
    )
}

export default BulkOperationTable