import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import colors from 'assets/scss/_themes-vars.module.scss';
import { Stack } from 'rsuite';
import { getVideLiabraryList, putUpdateMedia } from 'store/thunk/userThunk';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useAppDispatch } from 'store';
// import { setVideoLibraryList } from 'store/slices/userDetailsSlice';
import { useAppSelector } from 'store';
import { useNavigate } from 'react-router';

const CreativeSetting = (props) => {
  const { mediaData, campaignModal, onClose } = props;

  const { videoLibraryList } = useAppSelector((state) => state?.userDetailsSlice);
  const navigate = useNavigate();
  const [selectedFrom, setSelectedFrom] = useState('current');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [videoList, setVideoList] = useState([]);

  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const commonAdvertiserList = campaignModal?.advertiser_id;
    setVideoList([]);
    if (commonAdvertiserList) {
      const searchPage = 0;
      getVideoList(search, searchPage);
    }
  }, []);

  useEffect(() => {
    if (videoLibraryList.results?.length > 0) {
      let array = [...videoList, ...videoLibraryList.results];
      let uniqueMap = new Map(array.map((item) => [item.id, item]));
      let uniqueArray = Array.from(uniqueMap.values());
      setVideoList(uniqueArray);
    }
  }, [videoLibraryList]);

  const handleOkButtonClick = () => {
    setLoading(true);
    const _request = {
      adgroup_id: mediaData?.adgroup?.adgroup_id,
      media_id: selectedMedia?.id
    };
    dispatch(putUpdateMedia({ _request })).then(() => {
      setLoading(false);
      onClose();
    });
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }} variant="h5">
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
    handleSearch(e.target.value);
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setVideoList([]);
      getVideoList(data, page);
    }, 500),
    []
  );

  const getVideoList = (search, page) => {
    setMediaLoading(true);
    const commonAdvertiserList = campaignModal?.advertiser_id;
    const changedColumn = '';
    const direction = '';
    dispatch(getVideLiabraryList({ page, rowPerPage, search, changedColumn, direction, commonAdvertiserList })).then(() => {
      setMediaLoading(false);
    });
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    // Check if the user has scrolled to the bottom of the container
    if (scrollHeight - clientHeight === Math.round(scrollTop) && videoLibraryList.next !== null) {
      // Increment the page count when reaching the bottom
      setPage((prevPage) => prevPage + 1);
      getVideoList(_, page + 1);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box>
        {renderLabel('Media from', true)}
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedFrom}
          onChange={(e) => setSelectedFrom(e.target.value)}
        >
          <FormControlLabel value="current" control={<Radio />} label="Current Video" />
          <FormControlLabel value="new" control={<Radio />} label="Choose New Video" />
        </RadioGroup>
      </Box>
      {selectedFrom == 'current' && mediaData?.media?.length > 0 && (
        <Box>
          {/* <Grid item sm={6}> */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              {renderLabel('Review Status', false)}
              {mediaData?.media?.length > 0 && <Typography sx={{ padding: 0.5 }}>{mediaData?.media[0]?.status}</Typography>}
            </Grid>
            <Grid item md={12}>
              {renderLabel('Thumbnail', false)}
              <Grid item md={12}>
                <Tooltip title={`${mediaData?.media[0]?.name} (Id: ${mediaData?.media[0]?.media_id})`} placement="top" arrow>
                  <Box
                    sx={{
                      height: '100%',
                      width: '25%',
                      borderRadius: '5px',
                      padding: 0.3,
                      borderColor: colors.secondaryMain
                      // "&:hover": {
                      //     // background: colors.secondaryLight
                      // },
                    }}
                    component={'img'}
                    alt={mediaData?.media[0]?.name}
                    // onClick={() => setValue('media', item)}
                    src={mediaData?.media[0]?.thumbnail_url}
                  />
                </Tooltip>
              </Grid>
              {/* {mediaData?.media?.length > 0 && (<Typography>{mediaData?.media[0]?.status}</Typography>)} */}
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>
              CANCEL
            </Button>
            <Button variant="contained" color="secondary" disabled={loading} sx={{ ml: 1 }} type="submit" onClick={onClose}>
              {/* {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />} */}
              Ok
            </Button>
          </Box>
          {/* </Grid> */}
        </Box>
      )}
      {selectedFrom == 'new' && (
        <>
          <Box>
            {selectedMedia && (
              <Stack sx={{ width: '100%', margin: '5px 0px' }} spacing={1}>
                <Alert>Selected - {selectedMedia?.name}</Alert>
              </Stack>
            )}
            <Box sx={{ border: 1, borderColor: 'divider', borderRadius: '12px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderBottomColor: 'divider',
                  padding: '10px 15px'
                }}
              >
                <TextField placeholder="Search Media name/id" size="small" onChange={onSearchChange} />
                <Button onClick={() => navigate('/video-management')}>{'Video Management >'}</Button>
              </Box>
              <Box onScroll={handleScroll} sx={{ height: '50vh', padding: 2, overflow: 'overlay' }}>
                <Grid container spacing={3}>
                  {!mediaLoading && videoList?.length == 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        padding: 5
                      }}
                    >
                      <Typography variant="h5">No Video Record Found.</Typography>
                    </Box>
                  )}
                  {videoList?.length > 0 &&
                    videoList?.map((item, index) => (
                      <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                        <Tooltip title={`${item?.name} (Id: ${item?.media_id})`} placement="top" arrow>
                          <Box
                            sx={{
                              height: '100%',
                              width: '100%',
                              borderRadius: '5px',
                              border: `3px solid ${selectedMedia?.id == item?.id ? colors.secondaryMain : '#f4f5f6'}`,
                              padding: 0.3,
                              '&:hover': {
                                borderColor: colors.secondaryMain
                                // background: colors.secondaryLight
                              }
                            }}
                            alt={item?.name}
                            component={'img'}
                            onClick={() => setSelectedMedia(item)}
                            src={item?.thumbnail_url}
                          />
                        </Tooltip>
                      </Grid>
                    ))}

                  {mediaLoading &&
                    Array.from({ length: 8 }, (_, index) => (
                      <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                        <Skeleton
                          sx={{
                            width: '100%',
                            borderRadius: '5px'
                          }}
                          height={'150px'}
                          variant="rectangular"
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" style={{ color: '#000', borderColor: '#000' }} onClick={onClose}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              sx={{ ml: 1 }}
              type="submit"
              onClick={handleOkButtonClick}
            >
              {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="20px" />}
              Update
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreativeSetting;
