import React from 'react';
// import { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
  FormControlLabel,
  TableCell
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import BudgetManagerTable from 'ui-component/budget-manager/MUITable';
import { useNavigate } from 'react-router';
import CustomTooltipPlacement from 'views/campaign/uiComponenet';
import { CustomSwitch } from 'ui-component/customSwitch';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteOutline } from '@mui/icons-material';
import DeleteModalDialog from 'ui-component/DeleteModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { formateValue } from 'helper/commonHelper';
import { GetBudgetManagerData, UpdateBudget, deleteBudgetManagerData } from 'store/thunk/userThunk';
import { useAppSelector } from 'store';
import { useAppDispatch } from 'store';
import CustomDialogMessage from 'ui-component/MessageModal';
import TwoColorProgress from 'ui-component/progressBar/Two';
// import { useTheme } from '@mui/material/styles';
import WarningModalDialog from 'ui-component/warning-modal/WarningModal';
import { notificationFail } from 'store/slices/notificationSlice';
import colors from 'assets/scss/_themes-vars.module.scss';
import { Add } from '@mui/icons-material';
import upIcon from 'assets/images/dashna/up.svg';
import downIcon from 'assets/images/dashna/down.svg';
import __ from 'underscore';
import { setCommonLoader } from 'store/slices/userDetailsSlice';

const BudgetManager = () => {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const [deleteData, setDeleteData] = useState();
  const [confirmWaringModalData, setConfirmWarningModalData] = useState(null);
  const [newData, setNewData] = useState();
  const { BudgetData } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState({ monthly_budget: null });
  const [messageModal, setMessageModal] = useState(false);
  const dispatch = useAppDispatch();

  //Permission checking
  const budgetManager = userData?.permission?.budget_manager || {};
  const budgetManagerCreate = budgetManager?.create;
  const budgetManagerUpdate = budgetManager?.update;
  const budgetManagerDelete = budgetManager?.delete;

  // const theme = useTheme();
  // const ColumnColor = theme.palette.grey[700];
  const ColumnColor = '#1E1F21';
  const error = colors.errorMain;
  const orange = colors.orangeMain;
  const green = colors.greenFill;

  const handleOverspend = (e, id, type) => {
    dispatch(setCommonLoader(true));
    if (type == 'budget_evenly') {
      const _request = {
        split_budget_evenly: e.target.checked
      };
      const existingBudgetId = id;
      dispatch(UpdateBudget({ _request, existingBudgetId })).then((res) => {
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          getBudgetMangerListWithPagination(0, 25, '', '');
        }
      });
    } else {
      if (type == 'overspend') {
        const _request = {
          stop_over_spend: e.target.checked
        };
        if (e.target.checked == false) {
          Object.assign(_request, {
            auto_re_enable: false
          });
        }
        const existingBudgetId = id;

        dispatch(UpdateBudget({ _request, existingBudgetId })).then((res) => {
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            if (res?.payload?.data?.data?.error_over_spend) {
              setMessageModal(true);
            }
            getBudgetMangerListWithPagination(0, 25, '', '');
          }
        });
      } else {
        const _request = {
          auto_re_enable: e.target.checked
        };
        const existingBudgetId = id;

        dispatch(UpdateBudget({ _request, existingBudgetId })).then((res) => {
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            getBudgetMangerListWithPagination(0, 25, '', '');
          }
        });
      }
    }
  };

  const handleSaveButton = (value, id, data) => {
    if (data * 1.1 >= Number(value)) {
      setOpenWarning(true);
      if (value.length > 0) {
        const _request = {
          monthly_budget: Number(value?.replace(/\$|,/g, ''))
        };
        const existingBudgetId = id;

        const testingData = {
          message:
            data >= Number(value)
              ? 'Your entered budget has already been exceeded for this month. To prevent overspending, all campaigns will be paused immediately.'
              : data * 1.1 >= Number(value)
              ? 'Over 90% of your entered budget has been utilized this month. If you continue, your campaigns may exceed the budget soon and will be paused.'
              : ''
        };
        dispatch(setCommonLoader(false));
        setConfirmWarningModalData({ req: _request, id: existingBudgetId, message: testingData?.message });
      }
    } else {
      if (value.length > 0) {
        setLoading(true);
        console.log('value,id', value, id);
        const _request = {
          monthly_budget: Number(value?.replace(/\$|,/g, ''))
        };
        const existingBudgetId = id;
        dispatch(UpdateBudget({ _request, existingBudgetId })).then((res) => {
          setLoading(false);
          setEdit({ monthly_budget: null });
          if (res?.payload?.status == 201 || res?.payload?.status == 200) {
            getBudgetMangerListWithPagination(0, 25, '', '');
          }
          setConfirmWarningModalData(null);
        });
      }
    }
  };

  const handleBlur = (e, data) => {
    const value = e.target.value.trim();
    if (value === '' || parseFloat(value) <= 0) {
      dispatch(notificationFail('Monthly budget cannot be less than $1'));
      return;
    }
    dispatch(setCommonLoader(true));
    setLoading(true);
    setTimeout(() => {
      handleSaveButton(value, data.id, data.spend);
      setEdit({ monthly_budget: null });
      setLoading(false);
    }, 1000);
  };

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
            {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  const columns = [
    {
      name: 'profile_name',
      label: 'Profile',
      options: {
        filter: true,
        sort: false,

        setCellProps: () => ({
          className: 'clr3',
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            // left: 40,
            left: 0,
            minWidth: '380px',
            // background: 'white',
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            // left: 40,
            left: 0,
            minWidth: '380px',
            // background: 'white',
            zIndex: 101
          }
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            minWidth: '380px',
            // background: 'white',
            zIndex: 101
          };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'profile_name',
            columnLabel_1: 'Profile',
            customCss
          });
        }
      }
    },
    {
      name: 'stop_over_spend',
      label: (
        <CustomTooltipPlacement
          content="Stop Over-Spend"
          tooltipMessage={
            <Typography component="div">
              Pause all the campaigns in the Profile when the month-to-date spend is near or exceeds the monthly budget.
            </Typography>
          }
          color={ColumnColor}
          FontWeight="700"
        />
      ),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customBodyRender: (value, rowData) => {
          const values = BudgetData?.results[rowData?.rowIndex];

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} gap={2}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    sx={{ marginRight: '6px' }}
                    color="secondary"
                    checked={values?.stop_over_spend}
                    onChange={(e) => handleOverspend(e, values.id, 'overspend')}
                    disabled={!budgetManagerUpdate}
                  />
                }
                labelPlacement="end"
              />
            </Box>
          );
        }
      }
    },
    {
      name: 'stop_over_spend',
      label: (
        <CustomTooltipPlacement
          content="Auto Re-enable"
          tooltipMessage={
            <Typography component="div">
              Automatically enable the campaign that were paused by Stop Over Spend, when monthly budget is supplemented or next
              month comes.
            </Typography>
          }
          color={ColumnColor}
          FontWeight="700"
        />
      ),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customBodyRender: (value, rowData) => {
          const values = BudgetData?.results[rowData?.rowIndex];

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} gap={2}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    sx={{ marginRight: '6px' }}
                    color="secondary"
                    disabled={!values?.stop_over_spend || !budgetManagerUpdate}
                    checked={values?.auto_re_enable}
                    onChange={(e) => handleOverspend(e, values.id, 'AutoReenable')}
                  />
                }
                labelPlacement="end"
              />
            </Box>
          );
        }
      }
    },
    {
      name: 'split_budget_evenly',
      label: 'Split Budget Evenly',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'split_budget_evenly',
            columnLabel_1: 'Split Budget Evenly'
          });
        },
        customBodyRender: (value, rowData) => {
          const values = BudgetData?.results[rowData?.rowIndex];

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} gap={2}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    sx={{ marginRight: '6px' }}
                    color="secondary"
                    checked={values?.split_budget_evenly}
                    onChange={(e) => handleOverspend(e, values.id, 'budget_evenly')}
                    disabled={!budgetManagerUpdate}
                  />
                }
                // label={
                // <CustomTooltipPlacement
                // content=

                // tooltipMessage="Automatically enable the campaign that were paused by Stop Over Spend, when monthly budget is supplemented or next month comes."
                color={ColumnColor}
                FontWeight="700"
                // />
                // }
                labelPlacement="end"
              />
            </Box>
          );
        }
      }
    },
    {
      name: 'monthly_budget',
      label: 'Monthly Budget',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'monthly_budget',
            columnLabel_1: 'Monthly Budget'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = BudgetData?.results[rowData?.rowIndex];
          return (
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              {...(budgetManagerUpdate && {
                onMouseEnter: () => setHoveredRow({ monthly_budget: data?.id }),
                onMouseLeave: () => setHoveredRow(null)
              })}
            >
              {edit.monthly_budget == data?.id ? (
                <>
                  <TextField
                    defaultValue={data?.monthly_budget}
                    disabled={loading}
                    autoFocus
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{loading && <CircularProgress size={20} />}</InputAdornment>
                    }}
                    onBlur={(e) => handleBlur(e, data)}
                  />
                </>
              ) : (
                <>
                  <>
                    {value?.length > 40 ? (
                      <>
                        <Tooltip
                          placement="top"
                          sx={{ fontSize: '10px' }}
                          title={
                            <Typography variant="body1" sx={{ fontSize: 16 }}>
                              {value}
                            </Typography>
                          }
                          arrow
                        >
                          {value.substring(0, 40)}...
                        </Tooltip>
                      </>
                    ) : (
                      value
                    )}
                  </>
                  {hoveredRow?.monthly_budget === data?.id ? (
                    <IconButton
                      onClick={() => {
                        setEdit({
                          ...edit,
                          monthly_budget: data?.id
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <Box sx={{ height: '40px', width: '40px' }}></Box>
                  )}
                </>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'spend',
      label: (
        <CustomTooltipPlacement
          content="Current Spend"
          tooltipMessage="In order to effectively control the budget, actual real-time spend is used here, which includes today's and yesterday's spend ."
          color={ColumnColor}
          FontWeight="700"
        />
      ),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        })
      }
    },
    {
      name: 'percentage',
      label: (
        <CustomTooltipPlacement
          content="Spend %"
          tooltipMessage={
            <Typography component="div">
              Actual Spend % = (Spend / Monthly Budget) * 100% <br />
              Budgeted Spend % = (MTD Budgeted Spend / Monthly Budget)*100%
            </Typography>
          }
          color={ColumnColor}
          FontWeight="700"
        />
      ),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customBodyRender: (value, rowData) => {
          const data = BudgetData?.results[rowData?.rowIndex];
          const dataDelivery_rate = BudgetData?.results[rowData?.rowIndex]?.delivery_rate;
          let color = 'black';

          if (dataDelivery_rate > 90 && dataDelivery_rate < 110) {
            color = green;
          } else if (
            (dataDelivery_rate >= 110 && dataDelivery_rate < 150) ||
            (dataDelivery_rate > 50 && dataDelivery_rate <= 90)
          ) {
            color = orange;
          } else if (dataDelivery_rate >= 150 || dataDelivery_rate <= 50) {
            color = error;
          }
          return value && value !== 'null%' ? (
            <Tooltip
              title={
                <Typography component="div">
                  Actual Spend %:{data?.actual_spend}% <br />
                  Budgeted Spend %:{data?.planned_budget}%
                </Typography>
              }
              arrow
              placement="top"
            >
              <Box display="flex" alignItems="center">
                <Typography>{value}</Typography>
                {/* <Box marginLeft={1} flexGrow={1}>
                  <BorderLinearProgress variant="determinate" value={data?.percentage} />
                </Box> */}
                <Box marginLeft={1} flexGrow={1}>
                  <TwoColorProgress
                    variant="determinate"
                    value1={data?.actual_spend}
                    value2={data?.planned_budget}
                    value1Color={color}
                  />
                </Box>
              </Box>
            </Tooltip>
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'delivery_rate',
      label: (
        <CustomTooltipPlacement
          content="Budget Adherence Rate"
          tooltipMessage={
            <Box display="flex" flexDirection="column">
              <Typography className="tooltip-message" variant="div">
                Budget Adherence rate = Spend % / Planned Budget %{' '}
              </Typography>
              <Typography className="tooltip-message" variant="div">
                Green: 90% &lt; Budget Adherence rate &lt; 110%{' '}
              </Typography>
              <Typography className="tooltip-message" variant="div">
                Orange: 110% ≤ Budget Adherence rate &lt; 150% or 50% &lt; Budget Adherence rate ≤ 90%{' '}
              </Typography>
              <Typography className="tooltip-message" variant="div">
                Red: Budget Adherence rate ≥ 150% or Budget Adherence rate ≤ 50%
              </Typography>
            </Box>
          }
          color={ColumnColor}
          FontWeight="700"
        />
      ),
      options: {
        filter: true,
        sort: false,
        display: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customBodyRender: (value, rowData) => {
          const data = BudgetData?.results[rowData?.rowIndex]?.delivery_rate;
          let color = 'black';

          if (data > 90 && data < 110) {
            color = green;
          } else if ((data >= 110 && data < 150) || (data > 50 && data <= 90)) {
            color = orange;
          } else if (data >= 150 || data <= 50) {
            color = error;
          }

          return <Typography sx={{ color: color }}>{value}</Typography>;
        }
      }
    },
    {
      name: 'estimated_spend',
      label: (
        <CustomTooltipPlacement
          content="Est Spend"
          color={ColumnColor}
          FontWeight="700"
          tooltipMessage="Estimate the total spend of this month according to the historical spend of month to date"
        />
      ),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customBodyRender: (value) => {
          // const data = BudgetData?.results[rowData?.rowIndex];
          return value ? <Typography>{value}</Typography> : '-';
        }
      }
    },
    {
      name: 'roas',
      label: 'ROAS',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'roas',
            columnLabel_1: 'ROAS'
          });
        },
        customBodyRender: (value) => {
          // const data = BudgetData?.results[rowData?.rowIndex];
          return value ? <Typography>{value}</Typography> : '-';
        }
      }
    },
    {
      name: 'details',
      label: (
        <CustomTooltipPlacement
          content="Yday/Daily Goals"
          color={ColumnColor}
          FontWeight="700"
          tooltipMessage={
            <Typography component="div">
              Yday=Yesterday&#39;s Spend;
              <br />
              Daily Goal = (Total Budget - MTD spend)/ number of days remaining in the month.
            </Typography>
          }
        />
      ),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customBodyRender: (value) => {
          // const data = BudgetData?.results[rowData?.rowIndex];
          return value?.daily_goal || value?.yesterday_spend ? (
            <Typography>
              ${value?.yesterday_spend}/${value?.daily_goal}
            </Typography>
          ) : (
            '-'
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'id',
            columnLabel_1: 'Action'
          });
        },
        customBodyRender: (value) => {
          return budgetManagerDelete ? (
            <Box display="flex" alignItems="center">
              <DeleteOutline sx={{ color: 'red', cursor: 'pointer' }} onClick={() => toggleDeleteModal(value)} />
            </Box>
          ) : null;
        }
      }
    }
  ];

  const getBudgetMangerListWithPagination = (page, rowPerPage, changedColumn = '', direction = '') => {
    {
      dispatch(
        GetBudgetManagerData({
          page,
          rowPerPage,
          changedColumn,
          direction
        })
      );
    }
  };

  useEffect(() => {
    getBudgetMangerListWithPagination(0, 25, '', '');
  }, []);

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value || 0);
    });

    return formattedData;
  };

  useEffect(() => {
    const finalData = BudgetData?.results?.map((val) => {
      return {
        ...formateData(Object.entries(val)),
        details: {
          daily_goal: val?.daily_goal,
          yesterday_spend: val?.yesterday_spend
        }
      };
    });
    setNewData(finalData);
  }, [BudgetData]);

  const options = {
    search: false,
    filter: false,
    print: false,
    jumpToPage: false,
    responsive: 'standard',
    fixedHeader: true,
    selectableRows: false,
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: '550px',
    download: false,
    viewColumns: false,
    customToolbar: () => (
      <>
        {budgetManagerCreate && (
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              navigate('/budget-manager/add-profile');
            }}
          >
            Add Profile
          </Button>
        )}
      </>
    )
  };
  const handleWarningConfirm = () => {
    setLoading(true);
    dispatch(setCommonLoader(true));
    dispatch(UpdateBudget({ _request: confirmWaringModalData?.req, existingBudgetId: confirmWaringModalData?.id })).then(
      (res) => {
        setLoading(false);
        setEdit({ monthly_budget: null });
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          getBudgetMangerListWithPagination(0, 25, '', '');
          setOpenWarning(!openWarning);
          setConfirmWarningModalData(null);
        }
      }
    );
  };

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
  };

  const confirmDelete = () => {
    dispatch(deleteBudgetManagerData({ deleteData })).then((res) => {
      if (res.payload.status == 204) {
        getBudgetMangerListWithPagination(0, 25, '', '');
      }
      setOpenDelete(!openDelete);
    });
  };

  return (
    <MainCard title="Budget Manager">
      <BudgetManagerTable className="custom-budget-table" data={newData} columns={columns} options={options} />

      <DeleteModalDialog
        item="Profile"
        cancelCallback={() => setOpenDelete(!openDelete)}
        deleteCallback={confirmDelete}
        open={openDelete}
      />
      <WarningModalDialog
        item="Profile"
        cancelCallback={() => {
          setOpenWarning(!openWarning);
        }}
        warningCallback={handleWarningConfirm}
        open={openWarning}
        Message={confirmWaringModalData?.message}
      />

      <CustomDialogMessage
        open={messageModal}
        Message={`Since the monthly budget has already been exceeded, all campaigns have been paused.`}
        onClose={() => setMessageModal(false)}
        tittle="Stop Over-Spend"
      />
    </MainCard>
  );
};

export default BudgetManager;
