import React from "react";
import { Grid, Typography } from "@mui/material";

import { useAppSelector } from "store";
import BrandCampaignDataBox from "./BrandCampaignDataBox";

const BrandCampaignData = () => {
  const { campaignDetailViewData } = useAppSelector(
    (state) => state?.userDetailsSlice,
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Grid item sm={12}>
        <Typography variant="h4">Brand Details</Typography>
      </Grid>
      {campaignDetailViewData?.brand?.map((brandData) => (
        <Grid item sm={6} key={brandData?.id}>
          <BrandCampaignDataBox brandData={brandData} />
        </Grid>
      ))}
    </Grid>
  );
};

export default BrandCampaignData;
