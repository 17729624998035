import React from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';

const CustomLoader = () => {
  const dots = 12;
  const angle = (2 * Math.PI) / dots;
  const radius = 40; // Increase radius to create more space between dots

  const dotStyle = (index) => {
    const x = radius * Math.cos(angle * index) + radius + 10; // Center the dots
    const y = radius * Math.sin(angle * index) + radius + 10; // Center the dots
    return {
      top: `${y}px`,
      left: `${x}px`,
      backgroundColor: colors.darkPrimaryMain,
      animationDelay: `${index * 0.1}s`
    };
  };

  return (
    <div>
      <div className="dotted-loader">
        {[...Array(dots)].map((_, index) => (
          <div key={index} className="dot" style={dotStyle(index)}></div>
        ))}
      </div>
    </div>
  );
};

export default CustomLoader;
