//react
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
//mui
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
//project
import { useAppDispatch, useAppSelector } from 'store';
import { GetPerformanceComparisonData } from 'store/thunk/userThunk';
import { formateValue } from 'helper/commonHelper';
import CompareValueWithColor from 'ui-component/compareWithValue';

const PerformanceComparisonDashboard = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { selectedDashboardAdvertiser, commonAdvertiser, PerformanceComparisonData } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  const [dropdownValue, setDropdownValue] = useState('month');
  const [newData, setNewData] = useState([]);
  const [newDataColumn, setNewDataColumn] = useState([]);

  const handleChange = (event) => {
    setDropdownValue(event.target.value);
  };

  useEffect(() => {
    if (PerformanceComparisonData?.total_data?.length > 0) {
      const tempData = JSON.parse(
        JSON.stringify(PerformanceComparisonData.total_data.filter((obj) => obj.matrix != 'advertised_sku_sales_14days'))
      );
      const index = tempData.findIndex((obj) => obj.matrix == 'attributed_sales_14days');
      if (index !== -1) {
        let attributedSalesData = tempData.splice(index, 1)[0];
        tempData.unshift(attributedSalesData);
      }
      setNewData(tempData);
    }
    if (PerformanceComparisonData?.dates) {
      setNewDataColumn(PerformanceComparisonData.dates);
    }
  }, [PerformanceComparisonData]);

  useEffect(() => {
    if (params?.token) {
      if (selectedDashboardAdvertiser?.length > 0) {
        getPerformanceComparisionList(selectedDashboardAdvertiser, dropdownValue);
      }
    } else {
      const advertiserFilter = commonAdvertiser.length > 0 ? commonAdvertiser : '';
      if (advertiserFilter.length > 0) {
        getPerformanceComparisionList(advertiserFilter, dropdownValue);
      }
    }
  }, [selectedDashboardAdvertiser, commonAdvertiser, dropdownValue]);

  const getPerformanceComparisionList = (advertiserFilter, dropdownValue) => {
    let request = {
      advertiserFilter,
      dropdownValue
    };
    if (params?.token) {
      request = { ...request, secretKey: params.token };
    }
    dispatch(GetPerformanceComparisonData(request));
  };

  const options = {
    filter: false,
    sort: false,
    print: false,
    viewColumns: false,
    responsive: false,
    align: 'center',
    search: false,
    count: newData?.length || 0,
    page: 1,
    jumpToPage: false,
    download: false,
    pagination: false,
    serverSide: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: (
          <Box p={2} display="flex" justifyContent="center">
            Sorry, no matching records found
          </Box>
        )
      }
    }
  };

  const columnLabels = newDataColumn;

  const generateColumns = (labels) => {
    const commonOptions = {
      filter: true,
      sort: true,
      setCellProps: () => ({
        className: 'table-align-right'
      })
    };

    const columns = [
      {
        label: 'Metric',
        name: 'display_field',
        options: {
          filter: true,
          sort: false,
          setCellProps: () => ({
            style: {
              textAlign: 'left'
            }
          })
        }
      },
      ...labels.map((label) => ({
        label: label,
        name: label,
        options: {
          ...commonOptions,
          customBodyRender: (value, rowData) => {
            const data = newData[rowData?.rowIndex];
            const newValue = formateValue(data?.matrix, value);
            return (
              <Box>
                <Typography className="font-size-overview">{newValue?.length > 0 ? newValue : '-'}</Typography>

                <CompareValueWithColor
                  value={parseFloat(`${data[`comparison_${label}`]}`)}
                  Percentage={parseFloat(`${data[`percentage_${label}`]}`)}
                  UpDown={
                    typeof `${data[`is_higher_${label}`]}` != 'undefined' && `${data[`is_higher_${label}`]}` == 'true'
                      ? true
                      : false
                  }
                  keys={`${data[`matrix`]}`}
                  currency={true}
                  dashboardOverview={true}
                  tooltipApply={false}
                />
              </Box>
            );
          }
        }
      }))
    ];

    return columns;
  };

  const columns = generateColumns(columnLabels);
  return (
    <Box className={params?.token ? 'white-paper-card-dashboard chart-card' : 'white-paper-card-dashboard'}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box mb="12px">
          <Typography className="dashboard-card-title">Performance Comparison</Typography>
          <Typography className="dashboard-card-subtitle">Last 6 {dropdownValue == 'month' ? 'months' : 'weeks'}</Typography>
        </Box>
        <Box>
          <FormControl className="filter-select" sx={{ minWidth: '100px' }}>
            <Select
              value={dropdownValue}
              onChange={handleChange}
              size="small"
              className="ad-performance-select"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem className="top-product-value-dropdown" value="month">
                MoM
              </MenuItem>
              <MenuItem className="top-product-value-dropdown" value="week">
                WoW
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box my={1}>
        <MUIDataTable
          className="performance-comparison-table pnl-by-item"
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={newData}
          columns={columns}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default PerformanceComparisonDashboard;
