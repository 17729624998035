import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { createWalmartAdvertiser, updateWalmartAdvertiser, GetSelectedStoreData } from 'store/thunk/userThunk';
import { setUserData } from 'store/slices/authSlice';
import ConnectWalmartSeller from './connectWalmartSeller';

// material-ui
import {
  TextField,
  Dialog,
  Grid,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Link,
  Button,
  DialogTitle,
  DialogActions
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import __ from 'underscore';
import DashnaLogo from 'assets/images/dashna/dashnaIco.svg';
import SyncAlt from 'assets/images/dashna/syncAlt.svg';
import Connect from 'assets/images/dashna/connect.svg';
import Warning from 'assets/images/dashna/warning.svg';

const ConnectAdvertiser = (props) => {
  const { addNewStore = false, setIsOpenModalAddStore, editStore = false, setIsOpenModalEditStore } = props;

  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.authorization);
  const { selectedStoreData, allUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [formValues, setFormValues] = useState({
    profileNickname: '',
    advertiserId: ''
  });
  const [error, setError] = useState({});
  const [open, setOpen] = useState(true);
  const [isDisableNext, setIsDisableNext] = useState(true);
  const [goBackToCreateStore, setGoBackToCreateStore] = useState(false);
  const [isShowInputFields, setIsShowInputFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beError, setBeError] = useState('');

  //Color
  const borderColor = colors?.borderColor;
  const primaryTextColor = colors?.primaryTextColor;
  const linkColor = colors?.linkColor;
  const darkfigmaFontColor = colors?.darkfigmaFontColor;
  const errorBackground = colors?.errorBackground;

  //Prefilled valus while edit store and advertiser
  useEffect(() => {
    if (!editStore) {
      return;
    }
    if (__.isEmpty(selectedStoreData)) {
      return;
    }

    const storeId = selectedStoreData?.id; //Getting Store ID
    const getAdvertiserData = allUserDetails?.find((l) => l?.store_id === storeId);

    setIsShowInputFields(true); //Display Input Fields
    setFormValues({
      ...formValues,
      profileNickname: getAdvertiserData?.nick_name || '',
      advertiserId: getAdvertiserData?.advertiser_id || ''
    });
  }, [editStore]);

  //Next button disable status
  useEffect(() => {
    setIsDisableNext(checkIfDisabled());
  }, [formValues]);

  //Next button disable status
  const checkIfDisabled = () => {
    const { profileNickname, advertiserId } = formValues;

    if (!profileNickname || !advertiserId) {
      return true;
    }

    return false;
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpenModalAddStore(false);
    setIsOpenModalEditStore(false);
  };

  //While input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    setError((prevState) => {
      const newState = { ...prevState };
      delete newState[name];
      return newState;
    });

    setBeError(''); //Empty Backend Error
  };

  //Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();

    //Error checking
    let errs = {};
    const { profileNickname, advertiserId } = formValues;
    if (!profileNickname) {
      errs.profileNickname = true;
    }
    if (!advertiserId) {
      errs.advertiserId = true;
    }

    setError(errs);
    if (!__.isEmpty(errs)) {
      return;
    }

    setLoading(true);
    setBeError(''); //Empty Backend Error

    //API Calling Start
    const request = {
      nick_name: formValues?.profileNickname,
      advertiser_id: formValues?.advertiserId,
      store_id: userData?.store_id,
      addNewStore: addNewStore,
      editStore: editStore,
      setIsOpenModalAddStore: setIsOpenModalAddStore,
      setIsOpenModalEditStore: setIsOpenModalEditStore,
      navigate: navigate,
      setLoading: setLoading,
      setBeError: setBeError
    };

    if (editStore) {
      appDispatch(updateWalmartAdvertiser(request));
    } else {
      appDispatch(createWalmartAdvertiser(request));
    }

    // setOpen(false);
    // navigate('/dashboard')
  };

  const redirectToWalmartAdCenter = () => {
    setIsShowInputFields(true);
    window.open('https://advertising.walmart.com/sp/view/admin', '_blank');
  };

  const clickToBack = () => {
    if (!addNewStore) {
      //For fetch and display selected store data in Connect to walmart seller page
      const request = {
        storeId: userData?.store_id
      };
      appDispatch(GetSelectedStoreData(request)); //Call API for fetching selected store details

      //For Updating Store and localstorage setting for Back Button routes
      const restdata = { ...JSON.parse(localStorage.getItem('user_data')), display_module_fe: 'connect-to-walmart-seller' };
      appDispatch(setUserData(restdata));
      localStorage.setItem('user_data', JSON.stringify(restdata));

      navigate('/connect-to-walmart-seller');
    } else {
      //For fetch and display selected store data in Connect to walmart seller page
      const request = {
        storeId: userData?.store_id
      };
      appDispatch(GetSelectedStoreData(request));

      setGoBackToCreateStore(true);
    }
  };

  return (
    <>
      {goBackToCreateStore ? (
        <ConnectWalmartSeller addNewStore={addNewStore} setIsOpenModalAddStore={setIsOpenModalAddStore} />
      ) : (
        <Dialog
          maxWidth={'md'}
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
        >
          {' '}
          <DialogTitle alignItems={'center'}>
            <Box sx={{ display: 'flex' }} alignItems={'center'} gap={2}>
              <Box>
                <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                  Connect to Walmart
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Link to="#">
                  <img src={DashnaLogo} alt="Dashna Logo" />
                </Link>
                <Link to="#">
                  <img src={SyncAlt} alt="Sync Logo" />
                </Link>
                <Link to="#">
                  <img src={Connect} alt="Connect Logo" />
                </Link>
              </Box>
            </Box>

            {(addNewStore || editStore) && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container>
                <Grid item xs={12} pb={1}>
                  <Typography className={'fontInter'} color={primaryTextColor} fontSize={'20px'} fontWeight={700} pb={1.5}>
                    Connect to Walmart Connect
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  p={3}
                  mb={isShowInputFields ? 1 : 12}
                  border={`1px solid ${borderColor}`}
                  borderRadius={'10px'}
                >
                  <Box>
                    <Typography className={'fontInter'} color={darkfigmaFontColor} fontSize={'16px'}>
                      In order to manage your advertisments, Dashna needs to be added as an API Partner with write access in the
                      Ad Center.
                    </Typography>

                    <Link
                      onClick={() =>
                        window.open(
                          `https://support.dashna.com/portal/en/kb/articles/connect-your-walmart-connect-account-19-8-2024`,
                          '_blank'
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography className={'fontInter'} color={linkColor} fontSize={'16px'} py={2}>
                        Click here to view the documentation
                      </Typography>
                    </Link>
                    <Button
                      className={'actionBtn'}
                      sx={{ minWidth: '100px' }}
                      variant="contained"
                      color="primary"
                      onClick={() => redirectToWalmartAdCenter()}
                    >
                      Go to Walmart Ad Center
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {isShowInputFields && (
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                      <Grid item xs={12}>
                        <TextField
                          label="Profile Nickname"
                          name="profileNickname"
                          error={error?.profileNickname}
                          value={formValues?.profileNickname}
                          onChange={handleInputChange}
                          margin="normal"
                          fullWidth
                          helperText={error?.profileNickname ? 'Please add profile nickname' : ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Advertiser ID"
                          name="advertiserId"
                          error={error?.advertiserId}
                          value={formValues?.advertiserId}
                          onChange={handleInputChange}
                          margin="normal"
                          fullWidth
                          helperText={error?.advertiserId ? 'Please add advertiser id' : ''}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {beError?.length > 0 && (
                    <Grid item xs={12} pt={2}>
                      <Box
                        display={'flex'}
                        gap={2}
                        alignItems={'center'}
                        p={2}
                        borderRadius={'10px'}
                        backgroundColor={errorBackground}
                      >
                        <Box>
                          <img src={Warning} alt="Warning" />
                        </Box>
                        <Box>
                          <Typography>{beError}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {!editStore && (
                <Button
                  className={'actionBtnOutline'}
                  sx={{ minWidth: '100px' }}
                  onClick={() => clickToBack()}
                  variant="outlined"
                  color="primary"
                >
                  Back
                </Button>
              )}
              <LoadingButton
                disabled={isDisableNext}
                className={'actionBtn'}
                sx={{ minWidth: '100px' }}
                loading={loading}
                color="primary"
                variant="contained"
                type="submit"
              >
                {isShowInputFields ? 'Submit' : 'Next'}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default ConnectAdvertiser;
