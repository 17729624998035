import { createSlice } from '@reduxjs/toolkit';
import { getAuthUser } from '../../utils/services';

const initialState = {
  userData: getAuthUser(),
  verifyEmail: '',
  userProfile: ""
};

const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserData: (state, action) => ({
      ...state,
      userData: action.payload
    }),
    setVerifyEmail: (state, action) => ({
      ...state,
      verifyEmail: action.payload
    }),
    setUserProfile: (state, action) => ({
      ...state,
      userProfile: action.payload
    }),
  }
});

export const { setUserData, setVerifyEmail, setUserProfile } = authSlice.actions;

export default authSlice.reducer;
